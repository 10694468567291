import i18n from '../../i18n';
import { DefaultWebsocketClient } from '../../modules/websocket/module';

/**
 * 积分管理
 * - 账号在所在集团有多少积分
 * 
 * - 选购菜品时，更新优惠券视图禁用状态
 * - 选择优惠券。
 */
class PointsManager {
    // 原始积分
    public originPoints: number = 0;
    // 已经使用的积分
    public dishUsedPoints: number = 0;
    // 当前可用积分
    public availablePoints: number = 0;
    // 优惠券使用的积分
    public couponUsedPoints: number = 0;
    /**
     * 获取当前积分
     */
    getAvailablePoints() {
        this.availablePoints = this.originPoints - this.dishUsedPoints - this.couponUsedPoints;
        return this.availablePoints;
    }
    /**
     * 获取已使用积分
     */
    getUsedPoints() {
        return this.dishUsedPoints + this.couponUsedPoints;
    }

    /**
     * 更新积分
     */
    updatePoints(params: { key: 'originPoints' | 'couponUsedPoints' | 'dishUsedPoints', value: number, action?: 'cart' | 'menu' }, ignore?: boolean,) {
        const points = Math.ceil(params.value);
        let pass: boolean = false;
        if (params.key === 'originPoints') {
            this.originPoints = points;
            pass = true;
        } else if (['dishUsedPoints', 'couponUsedPoints'].includes(params.key)) {
            // 未登录时，忽略剩余积分校验
            if (ignore) {
                this[params.key] = points;
                pass = true;
            } else {
                const usedPoints = params.key === 'dishUsedPoints' ? this.dishUsedPoints : this.couponUsedPoints;
                pass = (this.availablePoints + usedPoints - (points || 0)) >= 0;
                if (params.action === 'cart') {
                    this[params.key] = points;
                }
                if (pass) {
                    this[params.key] = points;
                    // 操作菜品后，更新优惠券视图
                    if (params.key === 'dishUsedPoints') {
                        this.updateCouponView();
                    }
                }
            }
        }
        this.getAvailablePoints();
        return pass;
    }

    /**
     * 更新优惠券视图 - 禁用态
     */
    updateCouponView() {
        DefaultWebsocketClient.emit('REFRESH_COUPON_POINTS', null);
    }

    /**
     * 显示积分或价格
     */
    showPointsOrPrice(isPoints: boolean | undefined, price: number) {
        if (isPoints) {
            return `${Math.ceil(price)} ${i18n.t('my_fidelity_page_scores')}`
        } else {
            return `€${price.toFixed(2)}`
        }
    }
}


export const PM = new PointsManager();