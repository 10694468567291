import React from 'react';
import i18n from '../../i18n';
import TopBar from '../../component/top-bar/top-bar.component';
import './my-fidelity.style.scss';
import apiManage from '../../request';
import _ from 'lodash';
import CouponItem from '../../component/coupon/coupon-item.component';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { GetCuoponBox } from '../../component/coupon/get-cuopon-box.component';
import { InsertCode } from '../../component/insert-code-dialog/insert-code-dialog.component';
import ScanCode from '../../component/scan-code/scan.component';
import { getQueryString } from '../../constants/tool-get-parms';
import { showApiMessage } from '../../messenger/messageManager';
import { Coupon, Customer, Integral } from '../../models';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { inject_unount } from '../../component/decorators/helper.decorator';
import { getMessages } from '../../tool/notices.tool';
import Notification from '../../component/snackbar/snackbar';
import moment from 'moment';

@inject_unount
class MyFidelity extends React.Component<Props, State> {

    public state: State = {
        // button选中类型
        viewType: 'coupon',
        // 我的优惠券(原始数据)
        originCouponPackage: {},
        // 我的优惠券(显示数据)
        couponPackage: {},
        // 搜索框的值(优惠券)
        InputValueCoupon: '',
        // 搜索框的值(优惠券)
        InputValueScores: '',
        // 显示添加优惠券方式
        showGetCuoponBox: false,
        // 输入code
        showInsertCode: false,
        // 扫码
        showQR: false,
        // 我的积分(原始数据)
        originScores: [],
        // 我的积分(显示数据)
        scores: [],
        // 账户ID
        accountID: '',
        // 账号信息
        customerInfo: {},
        // 推荐人积分奖励
        referralRewards: [],
        // 从不同集团获得的积分
        groupScores: []
    }

    // *********************
    // Life Cycle Function
    // *********************
    componentDidMount() {
        this.init()
    }
    // *********************
    // Service Function
    // *********************

    // 获取数据
    public async init() {
        const account = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        const accountID = _.get(account, '_id')
        // 没有登陆不请求
        if (!accountID) {
            return false
        }
        // 获取用户信息(我的优惠券)
        const customerInfo = await apiManage.get_customer_info(accountID, undefined, undefined, true);
        let couponPackage: Coupon[] = _.get(customerInfo, 'coupons') || []
        // $ 过滤掉免领取的优惠券
        couponPackage = _.filter(couponPackage, (n) => { return !n.ignore_get_enable })
        // 优惠券数据重组 (优惠券的餐馆名显示出来)
        let restaurantsStringIds: Array<string> = []
        // 过滤出 可视 的优惠券
        couponPackage = _.filter(couponPackage, (n) => { return n.display_status === '0' })

        couponPackage.forEach((v) => {
            // 创建优惠券的餐馆的string_id。substring(0,5) 截取前5位置
            const restaurantStringId = v.coupon_code && v.coupon_code.substring(0, 5);
            const restaurantStringName = _.get(_.find(v.string_ids || [], (n) => { return n.string_id === restaurantStringId }), 'nickname');
            // 避免找不到对应的餐馆名，则选择第一个
            if (restaurantStringName) {
                restaurantsStringIds.push(`${restaurantStringId}_${restaurantStringName}`)
            } else {
                v.coupon_code = v.string_ids?.[0].string_id;
                restaurantsStringIds.push(`${v.string_ids?.[0].string_id}_${v.string_ids?.[0].nickname}`)
            }
            return v
        })
        restaurantsStringIds = Array.from(new Set(restaurantsStringIds))
        // 同一餐馆下的放在一起
        const newCouponPackage: any = {}
        restaurantsStringIds.forEach((v) => {
            const stingId = v.split('_')[0];
            const coupons = _.filter(couponPackage, (n) => {
                return n.coupon_code && n.coupon_code.substring(0, 5) === stingId
            })
            newCouponPackage[v] = coupons;
        })
        // 排序，可使用的放在对应餐馆的上面
        Object.keys(newCouponPackage).map((item) => {
            newCouponPackage[item].sort(this.compareCoupon);
            return item
        })
        // 获取通知信息
        getMessages(_.get(customerInfo, 'messages', 0));
        // 获取我的积分
        const scores = _.get(customerInfo, 'integrals') || []

        // 获取从不同集团获得的积分
        const groupScores = _.get(customerInfo, 'my_recommendation_integrals') || [];

        // 推荐积分记录
        const referralRewards = _.get(customerInfo, 'my_recommendation_integral_record') || [];

        this.setState({
            InputValueCoupon: '',
            InputValueScores: '',
            couponPackage: newCouponPackage,
            originCouponPackage: newCouponPackage,
            scores,
            originScores: scores,
            accountID,
            customerInfo,
            referralRewards,
            groupScores
        }, () => {
            // 从《餐厅主页》- 点击积分
            const group_id = getQueryString('group_id');
            if (group_id) {
                this.setState({ viewType: 'scores' });
                setTimeout(() => {
                    this.scrollToElement(group_id);
                }, 200);
            }
        })
    }
    /**
     * 选择视图类型
     * @param type
     */
    public onSelectViewType(viewType: TabType) {
        this.setState({ viewType })
    }
    /**
     * 根据优惠券的的可用状态排序
     * @param coupon
     */
    public compareCoupon(coupon: any) {
        const preValue = coupon.status === '1';
        if (preValue) {
            return 1
        } else {
            return -1
        }
    }

    /**
     * 处理搜索
     * @param inputValue inpit框的值
     */
    public async handleSearch(inputValue: string) {
        const { viewType, originCouponPackage, originScores } = this.state
        if (viewType === 'coupon') {
            if (inputValue) {
                const newCouponPackage: any = {}

                const cloneOriginCouponPackage = _.cloneDeep(originCouponPackage);

                if (Object.keys(cloneOriginCouponPackage).length > 0) {
                    Object.keys(cloneOriginCouponPackage).forEach((item) => {
                        // 模糊搜索
                        const regular = new RegExp(inputValue, 'gi')
                        const restaurantName = item.split('_')[1]
                        if (regular.test(restaurantName)) {
                            return newCouponPackage[item] = cloneOriginCouponPackage[item];
                        }
                    })
                }
                this.setState({ couponPackage: newCouponPackage })

            } else {
                this.setState({ couponPackage: originCouponPackage })
            }
        } else if (viewType === 'scores') {
            if (inputValue) {
                let newScores: any = []
                const cloneoriginScores = _.cloneDeep(originScores || []);
                if (cloneoriginScores.length > 0) {
                    cloneoriginScores.map((item) => {
                        // 模糊搜索
                        const regular = new RegExp(inputValue, `gi`)
                        if (item.nickname && regular.test(item.nickname)) {
                            return newScores = [...newScores, item]
                        }
                        return true
                    })
                }
                this.setState({ scores: newScores })

            } else {
                this.setState({ scores: originScores })
            }
        }
    }

    /**
     * 通过扫面领取优惠券
     * @param result 扫描结果
     */
    public async onScanCodeCoupon(result) {
        const info = getQueryString('info', result)
        if (info) {
            const result: any = await apiManage.getCouponByScan(info);
            if (_.get(result, 'error')) {
                showApiMessage(result, 'coupon')
            } else {
                showApiMessage(result, 'coupon', { message: i18n.t('coupon_text_successfully_received'), level: 'Success' })
                this.init()
            }
            this.setState({ showQR: false })
        }
    }
    /**
     * 输入编码领取优惠券
     * @param insertCode 输入code
     */
    public async onInsertCodeCoupon(insertCode: string) {
        const account = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        const accountID = _.get(account, '_id')
        if (accountID) {
            if (insertCode) {
                // 是否是会员
                const stringId = insertCode.split('-')?.[1];
                const isMember = (this.state.customerInfo?.restaurant_member_ids || []).includes(stringId);
                if (!isMember) {
                    await this.joinMember(stringId);
                }
                const result: any = await apiManage.onInsertCodeCoupon(accountID, insertCode);
                if (_.get(result, 'error')) {
                    showApiMessage(result, 'coupon')
                } else {
                    showApiMessage(result, 'coupon', { message: i18n.t('coupon_text_successfully_received'), level: 'Success' })
                    this.init()
                }
                this.setState({ showInsertCode: false })
            }
        }

    }
    /**
     * 聚焦对应的集团
     * @param index
     */
    scrollToElement(groupId: string) {
        try {
            const element = document.querySelector(`#scoresList`);
            const elementItem = document.getElementById(`${groupId}-score-item`);

            if (element && elementItem) {
                element.scrollTo({ top: elementItem.offsetTop - 40 < 0 ? 0 : elementItem.offsetTop - 40 })
            }
        } catch (error) { }
    }

    /**
     * 加入会员
     */
    async joinMember(restaurantStringId: string) {
        if (this.state.customerInfo?._id) {
            const res = await apiManage.join_member(restaurantStringId, this.state.customerInfo?._id);
            if (!res?.error) {
                Notification.notification(i18n.t('restaurant_info_page_text_you_have_joined_a_member'));
            } else {
                Notification.notification(i18n.t('request_failure_text_operation_failed'));
            }
        }
    }

    dateToText(date: Date) {
        const langage = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.LANGUAGE);
        switch (langage) {
            case 'ZH':
                moment.locale('zh-cn');
                break;
            case 'IT':
                moment.locale('it');
                break;
            case 'EN':
                moment.locale('en');
                break;
            case 'ES':
                moment.locale('es');
                break;
            default: moment.locale('en');
        }
        return moment(date).fromNow();
    }
    // *********************
    // View
    // *********************
    render() {
        const { viewType, couponPackage, InputValueCoupon, InputValueScores, showGetCuoponBox,
            showInsertCode, showQR, scores, accountID
        } = this.state
        return (
            <div className='MyFidelityPage'>
                <TopBar
                    title={i18n.t('my_fidelity_page')}
                />
                <div className='main'>
                    <div className='buttonbox'>
                        <div className={`button ${viewType === 'coupon' ? 'onSelect' : ''}`}
                            onClick={() => { this.onSelectViewType('coupon') }}>
                            {i18n.t('my_fidelity_page_coupons')}
                        </div>
                        <div className={`button ${viewType === 'scores' ? 'onSelect' : ''}`}
                            onClick={() => { this.onSelectViewType('scores') }}>
                            {i18n.t('my_fidelity_page_scores')}
                        </div>
                    </div>
                    <div className='content'>
                        <div className='inputBox'>
                            <div className='inputContainer'>
                                <input type='text'
                                    placeholder={viewType === 'coupon' ? i18n.t('my_fidelity_page_search_restaurant') : i18n.t('my_fidelity_page_search_group')}
                                    value={viewType === 'coupon' ? InputValueCoupon : InputValueScores}
                                    onChange={(evt) => {
                                        if (viewType === 'coupon') {
                                            this.setState({ InputValueCoupon: evt.target.value })
                                        } else {
                                            this.setState({ InputValueScores: evt.target.value })
                                        }
                                        if (evt.target.value === '') {
                                            this.handleSearch('')
                                        }
                                    }}
                                    onKeyUp={(evt) => {
                                        if (evt.keyCode === 13) {
                                            this.handleSearch(viewType === 'coupon'
                                                ? InputValueCoupon
                                                : InputValueScores)
                                        }

                                    }} />
                                <IconButton className='searchIcon'
                                    onClick={() => this.handleSearch(viewType === 'coupon'
                                        ? InputValueCoupon : InputValueScores)}
                                >
                                    <SearchIcon />
                                </IconButton>

                            </div>
                            {viewType === 'coupon'
                                ? <div onClick={() => this.setState({ showGetCuoponBox: true })}>
                                    <IconButton className='confrimicon' >
                                        <AddOutlinedIcon className='bar-menu tool-icon' />
                                    </IconButton>
                                </div>
                                : null
                            }
                        </div>
                        {viewType === 'coupon' ?
                            <div className='couponList'>
                                {
                                    Object.keys(couponPackage).length > 0 ?
                                        Object.keys(couponPackage).map((item, index) => {
                                            const restaurantName = item.split('_')[1]
                                            const couponsitem = couponPackage[item];
                                            return (
                                                <div key={index}>
                                                    <div className='titleName'>
                                                        {restaurantName}{` (${couponsitem.length})`}
                                                    </div>
                                                    {couponsitem.map((v, iindex) => {
                                                        return (
                                                            <CouponItem
                                                                key={v.coupon_id + iindex}
                                                                couponItem={v}
                                                                onClick={() => { }}
                                                                type='myCoupon'
                                                                accountID={accountID}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        }) :
                                        <div className='notfind'>{i18n.t('coupon_text_no_more_coupons')}</div>
                                }
                                {showGetCuoponBox &&
                                    <GetCuoponBox
                                        className='MyFidelityCuoponBox'
                                        onhandleClose={() => { this.setState({ showGetCuoponBox: false }) }}
                                        onhandleInsertCode={() => {
                                            this.setState({
                                                showInsertCode: true, showGetCuoponBox: false
                                            })
                                        }}
                                        onhandleQR={() => {
                                            this.setState({
                                                showQR: true, showGetCuoponBox: false
                                            })
                                        }} />}
                                {showInsertCode &&
                                    <InsertCode
                                        handleClose={() => { this.setState({ showInsertCode: false }) }}
                                        onConfirm={(insertCode) => { this.onInsertCodeCoupon(insertCode) }} />}

                                {showQR &&
                                    <ScanCode
                                        handleCloseScan={() => { this.setState({ showQR: false }) }}
                                        successfulCallback={(result) => { this.onScanCodeCoupon(result) }}
                                        type='coupon'
                                    />
                                }
                            </div>
                            : viewType === 'scores' ?
                                <div className='scoresList' id={'scoresList'}>
                                    {
                                        scores.length > 0 ? scores.map((item, index) => {
                                            const RecommendedScores = this.state.groupScores.find(
                                                e => item.group_string_id === e.group_string_id
                                            )?.value || 0;
                                            return (
                                                <div key={index} id={`${item.group_string_id}-score-item`}>
                                                    <div className='titleName'>
                                                        {item.nickname}
                                                    </div>
                                                    <div className='scoresBox'>
                                                        <div className='row nickname'>{item.nickname}</div>
                                                        <div className='row value'>
                                                            {item.value ? item.value : 0}
                                                        </div>
                                                        {RecommendedScores ? <div className='groupReferralScores'>
                                                            {i18n.t('coupon_text_x points_are_earned_by_recommendation', { x: RecommendedScores })}
                                                        </div> : null}
                                                        <div className='clear-rule'>
                                                            {item?.clear_type === '1'
                                                                ? i18n.t('coupon_text_Points_clearing_rules_annual_clearing_please_pay_attention_to_use')
                                                                : item?.clear_type === '2'
                                                                    ? i18n.t('coupon_text_Points_clearing_rules_monthly_clearing_keep_members_points_of_nearly_x_months_please_pay_attention_to_use', { x: item.clear_span || 1 }) : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                            : <div className='notfind'>{i18n.t('my_fidelity_page_text_no_more_scores')}</div>
                                    }
                                </div>
                                // 推荐奖励积分
                                : <div className='referralList'>

                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default MyFidelity


// *********************
// Types
// *********************


type Props = {
    // ...
};

type State = {
    // ...
    viewType: TabType;
    originCouponPackage: Coupon;
    couponPackage: Coupon;
    showGetCuoponBox: boolean;
    showInsertCode: boolean;
    showQR: boolean;
    scores: Integral[];
    originScores: Integral[];
    InputValueCoupon: string;
    InputValueScores: string;
    accountID: string;
    customerInfo: Customer;
    referralRewards: ReferralReward[]
    groupScores: Integral[]
};

type TabType = 'coupon' | 'scores' | 'referral'

type ReferralReward = {
    date: string,
    group_string_id: string,
    res_logo: {
        name: string,
        update_date: string,
        version: string,
        _id: string,
    }
    name: string,
    update_date: string,
    version: string,
    _id: string,
    res_nickname: string,
    res_string_id: string,
    type: string,
    value: string,
}

