import React from 'react';
import { inject_unount } from '../../component/decorators/helper.decorator';
import './my-notices.styles.scss';
import TopBar from '../../component/top-bar/top-bar.component';
import i18n from '../../i18n';
import Store from '../../rudex/store';
import apiManage from '../../request';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import Notification from '../../component/snackbar/snackbar';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/en-ca';
import 'moment/locale/it';
import 'moment/locale/es';
import { DefaultWebsocketClient } from '../../modules/websocket/module';
import _ from 'lodash';
import loginLogo from '../../static/images/ordelivery_login.png'
import { ToAuthentication } from '../../constants/checkRoute';
@inject_unount
export default class MyNoticesPage extends React.Component<Props, State> {
    public state: State = {
        noticesList: [],
    }

    // *********************
    // 构造函数
    // *********************

    constructor(props: Props) {
        super(props)
        this.handelWebsocketFun = this.handelWebsocketFun.bind(this)
        this.checkSharkCustomerVerify = this.checkSharkCustomerVerify.bind(this);
    }
    // *********************
    // 生命周期
    // *********************

    componentDidMount() {
        this.getNotices();
        // 监听外卖订单消息通知
        DefaultWebsocketClient.addListener('ORDER_UPDATED_EVENT', this.handelWebsocketFun);
        // 监听预定订单消息通知
        DefaultWebsocketClient.addListener('BOOKING_ORDER_UPDATE_EVENT', this.handelWebsocketFun);
        // 监听系统消息
        DefaultWebsocketClient.addListener('SYSTEM_UPDATE_EVENT', this.handelWebsocketFun);
        // 身份验证弹窗
        DefaultWebsocketClient.addListener('CHECK_SHARK_CUSTOMER_VERIFY_EVENT', this.checkSharkCustomerVerify);
    }

    componentWillUnmount() {
        DefaultWebsocketClient.removeListener('ORDER_UPDATED_EVENT', this.handelWebsocketFun);
        DefaultWebsocketClient.removeListener('BOOKING_ORDER_UPDATE_EVENT', this.handelWebsocketFun);
        DefaultWebsocketClient.removeListener('SYSTEM_UPDATE_EVENT', this.handelWebsocketFun);
        DefaultWebsocketClient.removeListener('CHECK_SHARK_CUSTOMER_VERIFY_EVENT', this.checkSharkCustomerVerify);
    }
    // *********************
    // 方法区
    // *********************

    checkSharkCustomerVerify() {
        this.handelWebsocketFun();
    }

    handelWebsocketFun() {
        this.getNotices()
    }

    /**
     * 时间转换
     */
    dateToText(date: Date) {
        const langage = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.LANGUAGE);
        switch (langage) {
            case 'ZH':
                moment.locale('zh-cn');
                break;
            case 'IT':
                moment.locale('it');
                break;
            case 'EN':
                moment.locale('en');
                break;
            case 'ES':
                moment.locale('es');
                break;
            default: moment.locale('en');
        }
        return moment(date).fromNow();
    }

    /**
     * 通过模板返回对应的消息
     */
    renderContentText(type: string, content: string) {
        let text;
        if (type === '0') {
            // 订单状态改变
            switch (content) {
                case '0':
                    text = i18n.t('app_page_text_your_order_is_unconfirmed')
                    break;
                case '1':
                    text = i18n.t('app_page_text_your_order_has_been_confirmed')
                    break;
                case '2':
                    text = i18n.t('app_page_text_your_order_has_been_locked')
                    break;
                case '3':
                    text = i18n.t('app_page_text_your_order_has_been_cancel')
                    break;
                case '4':
                    text = i18n.t('app_page_text_your_order_has_been_complete')
                    break;
                default: text = i18n.t('app_page_text_your_order_is_unconfirmed');
            }
        } else if (type === '1') {
            // 配送状态改变
            switch (content) {
                case '0':
                    text = `${i18n.t('my_notices_page_your_order')} ${i18n.t('my_notices_page_unshipped')}`
                    break;
                case '1':
                    text = i18n.t('app_page_text_your_order_is_being_deliver')
                    break;
                case '2':
                    text = i18n.t('app_page_text_your_order_has_been_delivered')
                    break;
                default: text = `${i18n.t('my_notices_page_your_order')} ${i18n.t('my_notices_page_unshipped')}`;
            }
        } else if (type === '2') {
            // 订单地址改变
            text = `${i18n.t('my_notices_page_your_order')} ${i18n.t('my_notices_page_order_address_has_been_modified')}`
        } else if (type === '3') {
            // 其它状态
        }
        return text;
    }

    /**
     * 通过模板返回对应的消息,预定订单
     * @param status
     */
    bookingOrderText(status) {
        let text = '';
        /**
         * 预定订单状态
         * 0: 未确认
         * 1: 已确认
         * 2: 已履约
         * 3: 未履约
         * 4: 已取消
         */
        switch (status) {
            case '0':
                text = 'app_page_text_your_booking_has_not_been_confirmed';
                break;
            case '1':
                text = 'app_page_text_your_booking_has_been_confirmed';
                break;
            case '2':
                text = 'app_page_text_your_booking_has_been_fulfilled';
                break;
            case '3':
                text = 'app_page_text_your_reservation_did_not_go_to_the_appointment';
                break;
            case '4':
                text = 'app_page_text_your_booking_has_been_cancelled';
                break;
            default:
                text = '';
                break;
        }
        return i18n.t(text);
    }

    /**
     * 获取消息
     */
    public async getNotices() {
        const account = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        try {
            const result = await apiManage.get_customer_message(account._id);
            if (result.error) {
                Notification.notification(i18n.t('my_notices_page_failed_to_get_message'))
            } else {
                const pinedDate: NOTICES[] = [];
                const notPinedDate: NOTICES[] = [];
                const data = result.messages;
                data.forEach((item: NOTICES) => {
                    // 外卖订单且type为3的不显示
                    if (_.get(item, 'metadata.type') !== '3' || _.get(item, 'metadata.is_booking_order') === true) {
                        if (item.isPined) {
                            pinedDate.push(item)
                        } else {
                            notPinedDate.push(item)
                        }
                    }

                });
                pinedDate.sort((a, b) => {
                    return moment(b.creation_time).diff(moment(a.creation_time))
                })
                notPinedDate.sort((a, b) => {
                    return moment(b.creation_time).diff(moment(a.creation_time))
                })
                this.setState({
                    noticesList: pinedDate.concat(notPinedDate)
                });
                Store.dispatch({
                    type: 'SET_STATE',
                    path: 'hasNewNotices',
                    value: false
                })
            }
        } catch (error) {
            //
        }
    }
    /**
     * 点击消息
     */
    handelNotice(item: NOTICES) {
        switch (item.type) {
            // 系统通知 '1'
            case '2':
                // 跳转到审核页面
                ToAuthentication(item.metadata.verify_id || '');
                break;
            default:
                break;
        }
    }
    // *********************
    // 视图区
    // *********************
    render() {
        return (
            <div className='myNotice'>
                <TopBar
                    title={i18n.t('my_notices_page')}
                >
                </TopBar>
                {
                    (this.state.noticesList || []).length > 0 ?

                        <div className='noticeList'>
                            {
                                (this.state.noticesList || []).map((item, index) => (
                                    <div className='noticeItem' key={`${item.title} -${index}`}
                                        onClick={() => {
                                            this.handelNotice(item);
                                        }}>
                                        <div className='top'>
                                            <div className='left'>
                                                <img
                                                    src={item.type === '0' ? item.icon : loginLogo}
                                                    alt='logo' />
                                                <span className='noticeName'>
                                                    {
                                                        item.type === '0' ? item.title : i18n.t('my_notices_page_system_information')
                                                    }
                                                </span>
                                            </div>
                                            <div className='right'>
                                                {
                                                    this.dateToText(item.creation_time)
                                                }
                                            </div>
                                        </div>
                                        <div className='bottom'>
                                            {item.type === '0' ? item.metadata?.is_booking_order ?
                                                this.bookingOrderText(item.metadata.new_value)
                                                : this.renderContentText(item.metadata.type, item.metadata.new_value)
                                                : item.type === '2' ? `${i18n.t(`auth_text_Do_you_agree_to_authorize_your_account_information_to_XX`, { x: item.metadata.project })}` : ''
                                            }
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                        :
                        <div className='noData'>
                            {i18n.t('general_text_no_relevant_data')}
                        </div>
                }
            </div>
        )

    }
}
// *********************
// State & Prop类型
// *********************

type State = {
    noticesList: Array<NOTICES>;
};

type Props = {};

type NOTICES = {
    icon: string;
    creation_time: Date;
    contentType: number;
    isPined: boolean;
    title: string;
    type: string;
    template: string;
    metadata: {
        type: string;
        new_value: string;
        is_booking_order?: boolean;
    } & VERIFY_NOTICES_METADATA;
}

type VERIFY_NOTICES_METADATA = {
    verify_id?: string;
    type?: 'PHONE' | 'QR_CODE' | string;
    string_id?: string;
    customer?: string;
    project?: string;
}