import React from 'react';
import i18n from '../../../i18n';
import './black-gold-order-buttons.styles.scss';

export function BlackGoldOrderButtons(props: {
    onOrder: (val: string) => void;
    restaurantTakeawayServiceStatus: boolean;
}) {
    const { onOrder, restaurantTakeawayServiceStatus } = props;
    return (
        <div className='BlackGoldOrderbuttons'>
            <div className={`pickup ${(!restaurantTakeawayServiceStatus) ? 'disable' : ''}`}
                onClick={() => {
                    onOrder('pickup')
                }}>
                <span className='title'>{i18n.t('restaurant_info_page_text_order_takeaway')}</span>
            </div>
            <div className={`delivery ${(!restaurantTakeawayServiceStatus) ? 'disable' : ''}`}
                onClick={() => {
                    onOrder('delivery')
                }} >
                <span className='title'>{i18n.t('restaurant_info_page_text_order_delivery')}</span>
            </div>
        </div>
    )
}