// import _ from 'lodash';
// import apiManage from '../request';

/**
 * 获取餐馆布局和主题色
 * @param restaurantSettingsLayout 餐馆设置主题布局(字段：settings_layout)
 *
 * // TODO 1.4.3暂时不需要，默认第一个主题（黄色）
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getRestaurantTheme(restaurantStringID: string, restaurantSettingsLayout: RestaurantSettingsLayout, notNeedLoading?: boolean) {
    let restaurantTheme: RestaurantTheme = 'FIRST_THEME'
    let restaurantThemeColor: RestaurantThemeColor = 'FIRST_THEME_COLOR'
    try {
        // // 检查主题色服务授权是否拥有 AU1017
        // let reservationService = await apiManage.checkCustomerVoucher(restaurantStringID, 'AU1017', false, { notNeedLoading: notNeedLoading || false });
        // if (!_.get(reservationService, 'error')) {
        //     if (_.get(reservationService, 'passedWarrant', []).length === 0) {
        //         return { restaurantTheme, restaurantThemeColor };
        //     }
        // } else {
        //     return { restaurantTheme, restaurantThemeColor };
        // }
        // if (restaurantSettingsLayout) {
        //     let defaultThemeLayout = _.find(restaurantSettingsLayout, { 'default': true });
        //     if (defaultThemeLayout) {
        //         if (defaultThemeLayout.key === 1) {
        //             restaurantTheme = 'FIRST_THEME';
        //         } else if (defaultThemeLayout.key === 2) {
        //             restaurantTheme = 'SECOND_THEME';
        //         } else if (defaultThemeLayout.key === 3) {
        //             restaurantTheme = 'THIRD_THEME';
        //         }
        //         let defaultThemeColor = _.find(defaultThemeLayout.theme, { 'default': true })
        //         if (defaultThemeColor) {
        //             if (defaultThemeColor.key === 1) {
        //                 restaurantThemeColor = 'FIRST_THEME_COLOR';
        //             } else if (defaultThemeColor.key === 2) {
        //                 restaurantThemeColor = 'SECOND_THEME_COLOR';
        //             } else if (defaultThemeColor.key === 3) {
        //                 restaurantThemeColor = 'THIRD_THEME_COLOR';
        //             }
        //         }
        //     }
        // }
    } catch (error) {
        // console.log('theme error')
        restaurantTheme = 'FIRST_THEME';
        restaurantThemeColor = 'FIRST_THEME_COLOR';
    }
    return { restaurantTheme, restaurantThemeColor };
}

export type RestaurantTheme = 'FIRST_THEME' | 'SECOND_THEME' | 'THIRD_THEME';

export type RestaurantThemeColor = 'FIRST_THEME_COLOR' | 'SECOND_THEME_COLOR' | 'THIRD_THEME_COLOR';

export type RestaurantSettingsLayout = Array<{
    key: number;
    default: boolean;
    theme: Array<{
        key: number;
        default: boolean;
    }>
}>