import _ from 'lodash';
import moment from 'moment';
import { CheckoutCoupon } from '../models';

/**
 * 分析使用规则
 * @param coupons 优惠券
 * @param originalTotalPrice 参与折扣的菜品总价
 * @param isPickUp 是否自取
 * @param fulfillmentTime 履约时间
 * @param selectedCoupons 已选择的优惠券
 * @param discountedOriginalTotalDiscountPrice 参与折扣的菜品的折扣后的价格
 *
 */
export function analyzeRulesForUseOfCoupons(
    coupons: Array<CheckoutCoupon>,
    originalTotalPrice: number,
    isPickUp: boolean,
    fulfillmentTime: Date,
    selectedCoupons: Array<CheckoutCoupon>
): {
    qualified: Array<CheckoutCoupon>,
    unqualified: Array<CheckoutCoupon>
} {
    // 通过的优惠券
    const qualified: Array<CheckoutCoupon> = [];
    // 没有通过的优惠券
    const unqualified: Array<CheckoutCoupon> = [];
    try {
        const couponsResult: Array<CheckoutCoupon> = _.cloneDeep(coupons);
        // $ 校验多张优惠卷
        const now = new Date();
        const create_time = now;
        const totalPriceWithTax = originalTotalPrice;
        const orderType = isPickUp ? '1' : '0';

        // 分析已选择的优惠券，是否符合满减价格
        _.remove(selectedCoupons, (item) => {
            return (
                ((item.order_price_rule_value || 0) > totalPriceWithTax) ||
                (item.meal_preparation_date_rule_enable && moment(create_time).add(item.meal_preparation_date_rule_value || 0, 'minute').isAfter(moment(fulfillmentTime), 'minute'))
            )
        })
        // 是否有`无法同其他优惠券一起使用`
        let hasExclusion = false;
        const hasExclusionCoupon = _.find(selectedCoupons, (n) => { return n.exclusion_rule_enable === true });
        if (hasExclusionCoupon) {
            hasExclusion = true
        }
        // 已选的优惠券所有的种类名，帮助性字段
        const minTypeRuleNumObj: { [key: string]: { max: number, hasLength: number } } = {};
        const hasTypeRuleCouponIds: string[] = [];
        selectedCoupons.forEach((item) => {
            if (item.coupon_id && item.type_rule_enable && item.type_rule_num !== 0) {
                hasTypeRuleCouponIds.push(item.coupon_id);
                if (_.has(minTypeRuleNumObj, (item.type_rule_name || ''))) {
                    if (minTypeRuleNumObj) {
                        const minTypeRuleNumObjItem = _.get(minTypeRuleNumObj, (item.type_rule_name || ''));
                        if (minTypeRuleNumObjItem) {
                            const max = minTypeRuleNumObjItem.max < (item.type_rule_num || 0) ? minTypeRuleNumObjItem.max : (item.type_rule_num || 0)
                            minTypeRuleNumObj[item.type_rule_name || ''] = { max, hasLength: minTypeRuleNumObjItem.hasLength + 1 }
                        }
                    }
                } else {
                    minTypeRuleNumObj[item.type_rule_name || ''] = { max: (item.type_rule_num || 0), hasLength: 1 }
                }
            }
        })
        // 参与折扣的菜品的折扣后的价格
        const discountedOriginalTotalDiscountPrice = getDiscountedOriginalTotalDiscountPrice(selectedCoupons, totalPriceWithTax)
        for (let i = 0; i < couponsResult.length!; i++) {
            // 不符合备餐时间
            let isMmealpreparationDate = false;
            // 不符合种类名规则
            let isTypeNameRule = false;
            // 不符合的规则
            const use_fail_rule: string[] = [];
            // $ 如果是领取规则不通过则一直加入unqualified数组
            if (couponsResult[i].is_customer_coupon === false && couponsResult[i].received_status === false) {
                unqualified.push(couponsResult[i]);
                continue;
            }
            // $ 时间规则
            if (couponsResult[i].date_rule_enable) {
                if (
                    moment(create_time).isBefore(moment(couponsResult[i].date_rule_effective_date)) ||
                    moment(create_time).isAfter(moment(couponsResult[i].date_rule_expiration_date))
                ) {
                    // console.log(couponConsoleTips, '不在时间内');
                    use_fail_rule.push('date_rule_enable');
                }
            }
            // $ 订单类型规则（即订单类型若为XXX之中，则可以使用优惠券)
            if (couponsResult[i].order_type_rule_enable) {
                if (!_.includes(couponsResult[i].order_type_rule_types, orderType)) {
                    // console.log(couponConsoleTips, '不满足订单类型---', orderType);
                    use_fail_rule.push('order_type_rule_enable');
                }
            }
            // $ 次数规则
            // if (couponsResult[i].count_rule_enable) {
            if ((couponsResult[i].count_rule_remaining_count || 0) <= 0) {
                // console.log(couponConsoleTips, '不满足次数规则');
                use_fail_rule.push('count_rule_enable');
            }
            // }
            // $ 种类名相同规则
            if (couponsResult[i].type_rule_enable && (hasTypeRuleCouponIds.length > 0)) {
                if (!_.includes(hasTypeRuleCouponIds, couponsResult[i].coupon_id)) {
                    const minTypeRuleNumObjItem = _.get(minTypeRuleNumObj, (couponsResult[i].type_rule_name || ''))
                    if (minTypeRuleNumObjItem) {
                        if (minTypeRuleNumObjItem.hasLength === minTypeRuleNumObjItem.max) {
                            couponsResult[i]['disClickstatus'] = true;
                            use_fail_rule.push('type_rule_enable');
                            isTypeNameRule = true;
                        } else if ((minTypeRuleNumObjItem.hasLength < minTypeRuleNumObjItem.max) && (couponsResult[i].type_rule_num || 0) <= minTypeRuleNumObjItem.hasLength) {
                            couponsResult[i]['disClickstatus'] = true;
                            use_fail_rule.push('type_rule_enable');
                            isTypeNameRule = true;
                        } else if ((couponsResult[i].type_rule_num || 0) <= minTypeRuleNumObjItem.hasLength) {
                            couponsResult[i]['disClickstatus'] = true;
                            use_fail_rule.push('type_rule_enable');
                            isTypeNameRule = true;
                        }
                    } else {
                        couponsResult[i]['disClickstatus'] = false;
                    }
                }
            }
            // $ 备餐时间
            if (fulfillmentTime) {
                if (couponsResult[i].meal_preparation_date_rule_enable) {
                    // 现在时间加上备餐时间，在履约时间之后，则不能使用。
                    if (moment(create_time).add(couponsResult[i].meal_preparation_date_rule_value || 0, 'minute').isAfter(moment(fulfillmentTime), 'minute')) {
                        couponsResult[i]['disClickstatus'] = true;
                        isMmealpreparationDate = true;
                        use_fail_rule.push('meal_preparation_date_rule_enable');
                    }
                }
            }
            // $ 订单总额规则（即订单总额满XXX元（不含配送费），则可以使用优惠券）
            // 此规则放在最后校验，不符合规则则设置规则互斥为true
            if (couponsResult[i].order_price_rule_enable) {
                if (_.findIndex(selectedCoupons, (n) => n.coupon_id === couponsResult[i].coupon_id) !== -1) {
                    if ((couponsResult[i]?.order_price_rule_value || 0) > totalPriceWithTax) {
                        // console.log(couponsResult[i].name, '不满足订单总额');
                        couponsResult[i]['disClickstatus'] = true;
                        use_fail_rule.push('order_price_rule_enable');
                    } else {
                        if (!(isMmealpreparationDate || hasExclusion || isTypeNameRule)) {
                            couponsResult[i]['disClickstatus'] = false;
                        }
                    }
                } else {
                    if ((couponsResult[i]?.order_price_rule_value || 0) > (discountedOriginalTotalDiscountPrice || 0)) {
                        // console.log(couponsResult[i].name, '不满足订单总额');
                        couponsResult[i]['disClickstatus'] = true;
                        use_fail_rule.push('order_price_rule_enable');
                    } else {
                        if (!(isMmealpreparationDate || hasExclusion || isTypeNameRule)) {
                            couponsResult[i]['disClickstatus'] = false;
                        }
                    }
                }
            }
            // $ 有已选择的优惠券且是`无法同其他优惠券一起使用`，则其他优惠券`无法同其他优惠券一起使用`
            if (hasExclusionCoupon) {
                if (hasExclusionCoupon?.coupon_id !== couponsResult[i].coupon_id) {
                    couponsResult[i]['disClickstatus'] = true;
                    use_fail_rule.push('exclusion_rule_enable')
                }
            }
            // 有已选择的优惠券，则其他优惠券`无法同其他优惠券一起使用`
            else if (selectedCoupons.length > 0 && couponsResult[i].exclusion_rule_enable) {
                couponsResult[i]['disClickstatus'] = true;
                use_fail_rule.push('exclusion_rule_enable')
            }

            if (use_fail_rule.length > 0) {
                couponsResult[i]['use_fail_rule'] = use_fail_rule;
                // `时间规则` `订单类型规则` `次数规则`
                if (_.includes(use_fail_rule, 'date_rule_enable') || _.includes(use_fail_rule, 'order_type_rule_enable') || _.includes(use_fail_rule, 'order_type_rule_enable')) {
                    unqualified.push(couponsResult[i]);
                } else {
                    qualified.push(couponsResult[i]);
                }
            } else {
                // 上面的都通过则可以
                // 错误的规则文本清空
                couponsResult[i]['use_fail_rule'] = [];
                // 互斥规则状态设置为可点击
                couponsResult[i]['disClickstatus'] = false;
                qualified.push(couponsResult[i]);
            }
        }
        // unqualified staus设置成不可用
        unqualified.map((item) => {
            return item.status = '1';
        })
        qualified.map((item) => {
            return item.status = '0';
        })
        return {
            qualified,
            unqualified
        };
    } catch (error) {
        return {
            qualified: [],
            unqualified: coupons
        };
    }
}


/**
 * 获取折扣后的参与折扣的菜品价格
 */
function getDiscountedOriginalTotalDiscountPrice(selectedCoupons: CheckoutCoupon[], originalTotalDiscountPrice: number) {
    let discountedOriginalTotalDiscountPrice = originalTotalDiscountPrice || 0;
    if (selectedCoupons.length > 0) {
        // $ 折扣类型 (0: 百分比 1: 现金) 计算顺序（先减后打折）
        const discountTypeCash = _.filter(selectedCoupons, (n) => { return n.discount_type === '1' });
        const discountTypePercent = _.filter(selectedCoupons, (n) => { return n.discount_type === '0' });
        // 参与折扣的菜品总价
        discountTypeCash.forEach((item) => {
            discountedOriginalTotalDiscountPrice = discountedOriginalTotalDiscountPrice - Number(item.discount_value || 0);
        });
        discountTypePercent.forEach((item) => {
            discountedOriginalTotalDiscountPrice = discountedOriginalTotalDiscountPrice * (1 - Number(item.discount_value || 0) * 0.01);
        });
        // 如果优惠后价格是负数则0
        if (discountedOriginalTotalDiscountPrice <= 0) {
            discountedOriginalTotalDiscountPrice = 0;
        }
    }
    discountedOriginalTotalDiscountPrice = Number(discountedOriginalTotalDiscountPrice.toFixed(2));
    return discountedOriginalTotalDiscountPrice
}