import React from 'react';
import './photo-view.styles.scss';
import { Dialog, DialogContent } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import QRCode from 'qrcode.react';

class PhotoView extends React.Component<Props, State> {

    public state: State = {
    }

    // *********************
    // Life Cycle Function
    // *********************
    componentDidMount() {

    }

    // *********************
    // Default Function
    // *********************

    // *********************
    // View
    // *********************

    render() {
        const { value, visible, onClose, className } = this.props;
        return (
            <Dialog
                open={visible}
                onClose={() => {
                    onClose()
                }}
                className={`QRCodeDialog ${className}`}
            >
                <DialogContent>
                    <div className='dialogContent'>
                        <div className='closeIconBox' onClick={() => {
                            onClose()
                        }}>
                            <ClearIcon className='clearIcon' />
                        </div>
                        <div className='QRcodeBox'>
                            <QRCode
                                value={value}
                                size={250}
                                level='H'
                            />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}
export default PhotoView

// *********************
// State & Props
// *********************

type State = {}

type Props = {
    value: string;
    visible: boolean;
    onClose: () => void;
    className?: string;
}