import React from 'react';
import './bind-phone-dialog.style.scss'
import { Dialog, DialogContent, Button } from '@material-ui/core';
import i18n from '../../i18n';
import ClearIcon from '@material-ui/icons/Clear';

/**
 * 绑定手机,合并账号提醒弹窗
 */
export function BindPhoneDialog(
    props: {
        className?: string;
        handleClose: () => void;
        onConfirm?: () => void;
        content?: string;
    }
) {
    const { className, handleClose, onConfirm, content } = props;
    return (
        <Dialog
            open={true}
            onClose={() => {
                handleClose()
            }}
            className={`BindPhoneDialog ${className}`}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                <div className='dialogContent'>

                    <div className='dialogName'>
                        <div className='fontStyle'>
                            {i18n.t('general_text_tip')}
                            <div className='close' onClick={() => {
                                handleClose()
                            }}>
                                <ClearIcon className='clearIcon' />
                            </div>
                        </div>
                    </div>
                    <div className='insertCode  orderlivery-bottom-96'>
                        {content ?? i18n.t('general_text_This_mobile_phone_number_has_been_registered_on_Ordelivery_should_I_merge_this_account')}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button className='btn ok'
                            onClick={() => {
                                onConfirm?.();
                            }}>
                            {i18n.t('general_text_ok')}
                        </Button>
                        <Button className='btn cancel'
                            onClick={() => {
                                handleClose()
                            }}>
                            {i18n.t('general_text_cancel')}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}