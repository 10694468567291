import React from 'react';
import './please-login.style.scss'
import { Dialog, DialogContent, Button } from '@material-ui/core';
import i18n from '../../i18n';
import ClearIcon from '@material-ui/icons/Clear';
import { createHashHistory } from 'history';
import { RestaurantTheme, RestaurantThemeColor } from '../../constants/tool-restaurant-theme';

/**
 * 请先登录;文本:'请先验证手机号码'
 * @param className 类名
 * @param handleClose 关闭
 * @param onConfirm 确认
 */
export function PleaseLogin(
    props: {
        className?: string;
        handleClose: () => void;
        onConfirm?: () => void;
        restaurantTheme?: RestaurantTheme;
        restaurantThemeColor?: RestaurantThemeColor;
    }
) {
    const { className, handleClose, onConfirm, restaurantTheme } = props;
    return (
        <Dialog
            open={true}
            onClose={() => {
                handleClose()
            }}
            className={`PleaseLoginDialog  ${restaurantTheme === 'SECOND_THEME' ? 'blackGoldPleaseLoginDialog' : ''} ${className}`}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                <div className='dialogContent'>

                    <div className='dialogName'>
                        <div className='fontStyle'>
                            {i18n.t('general_text_please_verify_your_mobile_phone_number_first')}
                            <div className='close' onClick={() => {
                                handleClose()
                            }}>
                                <ClearIcon className='clearIcon' />
                            </div>
                        </div>
                    </div>
                    <div className='insertCode  orderlivery-bottom-96'>

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button className='btn ok'
                            onClick={() => {
                                onConfirm ? onConfirm() : createHashHistory().push('/sign-in')
                            }}>
                            {i18n.t('general_text_ok')}
                        </Button>
                        <Button className='btn cancel'
                            onClick={() => {
                                handleClose()
                            }}>
                            {i18n.t('general_text_cancel')}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}