import React from 'react';
import { ICheckoutFulfillmentTime, ICheckoutFulfillmentTimeProps, ICheckoutFulfillmentTimeState } from '../../interfaces';
import i18n from '../../i18n';
import moment from 'moment';
import _ from 'lodash';
import DatePicker from 'react-mobile-datepicker';
import {
    MdAccessTime,
    MdKeyboardArrowDown,
    MdKeyboardArrowRight
} from 'react-icons/md';
import { IconHeadBox } from './checkout-icon-head-box';
import './scss/checkout-fulfillment-time.scss';
import { getTargetDateCanceledSchedule, ScheduleDialog } from '../restaurant/schedule-dialog.component';
import apiManage from '../../request';
import momentTimeZone from 'moment-timezone';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { checkSchedule, DT } from '../../constants/tool-constant';
import Notification from '../../component/snackbar/snackbar';
import { CURRENT_TIME } from '../../constants/tool-check-time';
import { getQueryString } from '../../constants/tool-get-parms';
import { updateScheduleData } from '../../constants/tool-update-schedule-data';
import { SettingClosedSchedule } from '../../models';

class CheckoutFulfillmentTime extends React.Component<Props, State> implements ICheckoutFulfillmentTime {

    dateConfig: { date: { format: string; caption: string; step: number; }; hour: { format: string; caption: string; step: number; }; minute: { format: string; caption: string; step: number; }; };

    constructor(props: Props) {
        super(props)
        const isPickUp = getQueryString('orderway') === '1';
        const step = isPickUp ? 15 : 20;
        // 日期插件格式定义
        this.dateConfig = {
            'date': {
                format: 'DD',
                caption: `${i18n.t('payment_page_text_date')}`,
                step: 1
            },
            'hour': {
                format: 'hh',
                caption: `${i18n.t('payment_page_text_hour')}`,
                step: 1
            },
            'minute': {
                format: 'mm',
                caption: `${i18n.t('payment_page_text_minute')}`,
                step
            }
        }
    }
    public state: State = {
        // 打开营业时间模态框
        isOpenSchedulesModel: false,
        // 是否打开时间选择器
        isOpenDatePickerModel: false,
        // 选择时间组件临时更改值
        datePickerChangeValue: CURRENT_TIME,
        // 是否商家忙碌时间
        isBusyHours: false,
        // 是否第一次进来
        isInitial: 1,

        isCurrantTime: true
    }

    // *********************
    // Life Cycle Function
    // *********************
    componentDidMount() {
        // $ 判断当前时间是否合法（页面刚进来的判断）
        // let showTime = `${moment(this.props.fulfillmentTime).format('HH:mm')}`;
        // let showYear = `${moment(this.props.fulfillmentTime).format('YYYY/MM/DD')}`;
        const isCurrantTime = this.handleDate(this.props.fulfillmentTime);
        this.setState({
            isCurrantTime
        });
        // 通知父组件是否在营业时间范围
        this.props.businessTime(isCurrantTime);
        // 忙碌状态
        this.CheckRestaurantBusyHours(this.props.fulfillmentTime);
    }

    shouldComponentUpdate(newProps: Props) {
        if (newProps.fulfillmentTime !== this.props.fulfillmentTime || (newProps.checkFulfillMentTimeFlat !== this.props.checkFulfillMentTimeFlat)) {
            // $ 判断当前时间是否合法（页面刚进来的判断）
            // let showTime = `${moment(newProps.fulfillmentTime).format('HH:mm')}`;
            // let showYear = `${moment(newProps.fulfillmentTime).format('YYYY/MM/DD')}`;
            const isCurrantTime = this.handleDate(newProps.fulfillmentTime);

            this.setState({
                isCurrantTime,
                datePickerChangeValue: newProps.fulfillmentTime
            });
            // 通知父组件是否在营业时间范围
            this.props.businessTime(isCurrantTime);
        }
        return true;
    }

    // *********************
    // Default Function
    // *********************


    /**
     * 验证时间是否合法
     * @param time 选择的日期时间
     * @param showTime 展示的时间字符串
     */
    handleDate(time: Date): boolean {

        const restaurantId = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
        const res = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS);
        const deliveryFee = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE);
        // 配送区域时间限制 deliveryFee只有配送时才有值
        let canceledScheduleTimeRanges: SettingClosedSchedule[] = [];
        if (deliveryFee && Object.keys(deliveryFee).length > 0) {
            canceledScheduleTimeRanges = getTargetDateCanceledSchedule(deliveryFee.canceled_schedule_time_ranges || []);
        }

        // 判断时间是否在营业时间段内 setting_app_takeaway_service_schedule
        let serviceSchedule = _.get(res, `[${restaurantId}].restaurant.setting_app_takeaway_service_schedule`, []);
        // 停业日历
        let settingClosedSchedule = _.get(res, `[${restaurantId}].restaurant.setting_closed_schedule`, []);
        // 选择地址后的区域限制配送时间
        settingClosedSchedule = _.concat(settingClosedSchedule, canceledScheduleTimeRanges);
        // 结合休业时间，算出营业时间
        serviceSchedule = updateScheduleData(serviceSchedule, settingClosedSchedule)
        const restaurantsInfo = _.get(res, `[${restaurantId}].restaurant`);
        // $ 时间表里面的时区  为null就是默认值
        const scheduleTimeZoneName = _.get(restaurantsInfo, 'time_zone') || momentTimeZone.tz.guess();
        // 当前时区
        const originTimeZoneName = momentTimeZone.tz.guess();
        const formatTime = DT(time);
        const isBusiness = checkSchedule(serviceSchedule, formatTime, scheduleTimeZoneName, originTimeZoneName);
        // $ 获取当餐馆配送最低时间  这里默认给定0分钟
        let orderIntervalTime = _.get(restaurantsInfo, 'setting_order_interval_time');
        // 在这里排除0的情况  null和undefined都设置为30分钟
        orderIntervalTime = orderIntervalTime ?? 30;

        // 判断是否是第一次进来
        const isInitial = this.state.isInitial;
        /**
         * 1、不需要判断它营业
         * 2、判断自取时间是否可用
         * 3、判断配送时间是否可用
         * 4、记录当前时间是否可用
         */

        let forbidCheckout = false;

        // 判断自取还是配送
        const orderWay = getQueryString('orderway');
        const isPickUp = orderWay === '2' ? false : true;

        if (isBusiness.isSelftakeAvailableInCurrentTime && isPickUp) {
            // 可以自己取
            forbidCheckout = false;
        } else if (isBusiness.isTakeawayAvailableInCurrentTime && !isPickUp) {
            // 可以配送
            forbidCheckout = false;
        } else {
            // 不允许配送和自取
            forbidCheckout = true;
        }

        /**
         * 1、第二步时间判断
         * 2、不能早于的两分钟
         * 3、不能晚于一周之后
         */

        //  配送时间不能早于现在
        if (moment(time, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
            .isBefore(moment().subtract(2, 'minutes'))) {

            forbidCheckout = true;
        }
        // // 配送时间不能晚于一周
        // if (moment(time, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
        //     .isAfter(moment().add(24 * 7, 'hours'))) {
        //     forbidCheckout = true;

        // }

        /**
         * 1 不能早于最低配送时间，setting_order_interval_time
         * 2 第一次进来不做比较
         */
        if (moment(time, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
            .isBefore(moment().add(orderIntervalTime, 'minutes')) && isInitial !== 1) {
            forbidCheckout = true;
        }
        this.updateState({
            isInitial: 0
        })
        // $ 营业总开关关闭则不在营业时间内
        if (!this.props.takeawayServiceStatus) {
            forbidCheckout = true;
        }

        return !forbidCheckout;
    }

    // !! 数据修改方法
    updateState(value) {
        this.setState({ ...value });
    }

    /**
     * 检查目标时间，是否是商家忙碌时间
     */
    async CheckRestaurantBusyHours(targetTime) {
        const restaurantId = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
        const res = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS);
        try {
            if (_.get(res, `[${restaurantId}].restaurant.setting_delivery_busy_mode_enable`, false)) {
                const start_time = moment(targetTime).format(moment.defaultFormatUtc);
                const param = { string_id: restaurantId, start_time, time_zone_name: String(momentTimeZone.tz.guess()) };
                const result = await apiManage.checkRestaurantBusyHours(param, { notNeedLoading: true })
                if (_.get(result, 'error')) {
                    //
                } else {
                    // 是忙碌时间
                    if (_.get(result, 'isPass') === true) {
                        this.setState({
                            isBusyHours: true
                        })
                    } else {
                        this.setState({
                            isBusyHours: false
                        })
                    }
                }
            }
        } catch (error) {
            this.setState({
                isBusyHours: false
            })
        }
    }


    /**
     * 关闭时间选择器
     */
    handleCancel() {
        this.setState({ isOpenDatePickerModel: false });
    }

    /**
     * 选择时间
     */
    handleSelect = async (time: Date) => {
        this.setState({
            isOpenDatePickerModel: false
        }, async () => {

            // let showTime = `${moment(time).format('HH:mm')}`;
            // let showYear = `${moment(time).format('YYYY/MM/DD')}`;
            // $ 判断当前时间是否合法（页面刚进来的判断）
            const isCurrantTime = this.handleDate(time);

            this.CheckRestaurantBusyHours(time);
            // 更新视图
            this.props.updateFulfillmentTime(time);

            if (!isCurrantTime) {
                Notification.notification(i18n.t('payment_page_text_selected_time_business_hours_unavailable'))
            }
            this.props.businessTime(isCurrantTime);
            this.setState({
                isCurrantTime
            })
        })
    }

    // *********************
    // View
    // *********************

    render() {
        const { datePickerChangeValue, isOpenDatePickerModel,
            isOpenSchedulesModel, isBusyHours, isCurrantTime } = this.state;
        const { serviceSchedules, closeSchedules, takeawayServiceStatus, fulfillmentTime, minTime, maxTime } = this.props;
        // 展示的时间
        const showTime = `${moment(fulfillmentTime).format('HH:mm')}`;
        const showYear = `${moment(fulfillmentTime).format('YYYY/MM/DD')}`;
        const deliveryFee = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE);
        let canceledScheduleTimeRanges: SettingClosedSchedule[] = [];
        if (deliveryFee && Object.keys(deliveryFee).length > 0) {
            canceledScheduleTimeRanges = getTargetDateCanceledSchedule(deliveryFee.canceled_schedule_time_ranges || [])
        }
        const isPickUp = getQueryString('orderway') === '1';
        return (
            <div className='fulfillmentTime' id='fulfillmentTime'>
                <IconHeadBox
                    iCon={() => <MdAccessTime />}
                    title={isPickUp
                        ? i18n.t('payment_page_text_takeaway_time')
                        : i18n.t('general_text_delivery_time')
                    }
                    actionLabel={() => <>{i18n.t('payment_page_text_opening_hours')} <MdKeyboardArrowRight /></>}
                    action={() => { this.setState({ isOpenSchedulesModel: true }) }}
                />
                <div className='selectTimeBox'>
                    <div className={`selectTimeBtn ${!isCurrantTime ? 'errorBtn' : ''}`} onClick={() => { this.setState({ isOpenDatePickerModel: true }) }}>
                        <span className='showYearText'>{showYear}</span>
                        <span>{showTime}-{moment(showTime, 'HH:mm').add(isPickUp ? 15 : 20, 'minute').format('HH:mm')}</span>
                        <MdKeyboardArrowDown className='arrow_btn' />
                    </div>
                    {/* <div className='selectTimeBtn' onClick={() => { this.setState({ isOpenDatePickerModel: true }) }}>
                        <span>{showTime}-{moment(showTime, 'HH:mm').add(20, 'minute').format('HH:mm')}</span>
                        <MdKeyboardArrowDown className='arrow_btn' />
                    </div> */}
                </div>
                {
                    isBusyHours && <div className='prompt-busy'>
                        <span className='text'>{i18n.t('payment_page_text_this_time_is_busy_for_restaurants')}</span>
                    </div>
                }
                {
                    !isCurrantTime && <div className='prompt-busy'>
                        <span className='text'>{i18n.t('payment_page_text_selected_time_business_hours_unavailable')}</span>
                    </div>
                }
                <LongtimeTip fulfillmentTime={fulfillmentTime} isPickUp={isPickUp} />
                {/* 日期插件 */}
                <DatePicker
                    showCaption={true}
                    value={datePickerChangeValue}
                    isOpen={isOpenDatePickerModel}
                    onSelect={(time: Date) => this.handleSelect(time)}
                    onCancel={() => this.handleCancel()}
                    onChange={(val) => { this.setState({ datePickerChangeValue: val }) }}
                    dateConfig={this.dateConfig}
                    min={minTime}
                    max={maxTime}
                    confirmText={i18n.t('general_text_confirm')}
                    cancelText={i18n.t('general_text_cancel')}
                />
                <ScheduleDialog
                    serviceAllBtn={takeawayServiceStatus}
                    className='scheduleDialog'
                    isPickUp={isPickUp}
                    scheduledata={serviceSchedules}
                    settingClosedSchedule={closeSchedules}
                    canceledScheduleTimeRanges={canceledScheduleTimeRanges}
                    open={isOpenSchedulesModel}
                    handleClose={() => this.setState({ isOpenSchedulesModel: false })}
                />
            </div>
        )
    }
}
export default CheckoutFulfillmentTime

// *********************
// Props & State
// *********************

interface State extends ICheckoutFulfillmentTimeState {
    datePickerChangeValue: Date;
    // 是否商家忙碌时间
    isBusyHours: boolean;
    // 是否第一次进来
    isInitial: number;

    isCurrantTime: boolean;

}

interface Props extends ICheckoutFulfillmentTimeProps {
    updateFulfillmentTime: (time: Date) => void;
    fulfillmentTime: Date;
    minTime: Date;
    maxTime: Date;
    businessTime: (isActive: boolean) => void;
    checkFulfillMentTimeFlat: boolean;
}

/**
 * 选择时间超过现在时间2小时 提示
 */
const LongtimeTip = React.memo(function LongtimeTip(props: { fulfillmentTime: Date, isPickUp: boolean }) {
    const [fromNow, setFromNow] = React.useState(0);
    React.useEffect(() => {
        setFromNow(moment(props.fulfillmentTime).diff(moment(), 'hour'));
    }, [props.fulfillmentTime])
    const text = props.isPickUp
        ? i18n.t('payment_page_text_takeaway_time')
        : i18n.t('general_text_delivery_time');

    return fromNow >= 2 ? <div className='prompt-busy'>
        <span className='text'>{i18n.t('restaurant_order_page_text_Your_x_will_be_n_hours_later_please_confirm', { x: text, n: fromNow })}</span>
    </div> : <></>
})