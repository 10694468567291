// import { DefaultWebsocketClient } from '../modules/websocket/module';
import apiManage from '../request';
import Store from '../rudex/store';
import { LOCAL_STORAGE_KEYS } from './local-storage.constant';
import { getQueryString } from './tool-get-parms';
import { createHashHistory } from 'history';
import { LocalStorageManager } from './tool-local-storage';
/**
 * 检测路由参数
 * - 身份审核地址校验
 * - 是否跳转餐厅主页
 */
export async function checkRoute(customer: { phone: string, phone_prefix: string }) {
    // 是否跳转了页面
    let isReplaceRoute = false;
    const verify_id = getQueryString('verify_id');
    const string_id = getQueryString('string_id');
    // 存在审核id,保存id,跳转到审核页面
    if (verify_id) {
        // 记录 verify_id
        ToAuthentication(verify_id, !customer?.phone);
    }
    // 在主页获取到string_id，跳转到餐厅主页
    else if (string_id) {
        replaceRoute(`restaurantPage?string_id=${string_id.toUpperCase()}`);
        isReplaceRoute = true;
    }
    return isReplaceRoute
}
/**
 * boxed下需要改window.top.location.href，非boxed也要改
 * @param route 
 */
function replaceRoute(route?: string) {
    window!.top!.location.href = `${window.location.origin}${window.location.pathname}#/${route || ''}`;
    window.location.href = `${window.location.origin}${window.location.pathname}#/${route || ''}`;
}

/**
 * 跳转到身份审核页面
 */
export function ToAuthentication(verifyId: string, needLogin?: boolean) {
    Store.dispatch({ type: 'SET_STATE', path: 'verifyId', value: verifyId });
    if (needLogin) {
        createHashHistory().push('/sign-in');
    } else {
        createHashHistory().replace('/authentication');
    }
}


/**
 * 路由返回上一页
 */
export function routeBack() {
    const prePathName = createHashHistory().location.pathname;
    createHashHistory().goBack();
    // 解决路由跳转问题
    const t1 = setTimeout(() => {
        const pathName = createHashHistory().location.pathname
        if (pathName === prePathName) {
            createHashHistory().push('/');
        }
        clearTimeout(t1)
    }, 200);
}

/**
 * 检查账号是否有审核记录
 * - 调用时机：1.进入应用时 2.成功登录后 
 */
export async function checkAccountVerify(customer?: { phone: string, phone_prefix: string }) {
    let isReplaceRoute = false;
    const verify_id = getQueryString('verify_id');
    if (!verify_id) {
        const _customer = customer ?? LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        if (_customer?.phone) {
            const verifyInfo = await apiManage.getVerifyByPhone({ phone: _customer.phone, phone_prefix: _customer.phone_prefix });
            // 存在授权记录且未审核，跳转到审核页面
            if (verifyInfo?.verify_id && ['0', '4'].includes(verifyInfo?.status)) {
                ToAuthentication(verifyInfo?.verify_id);
                isReplaceRoute = true;
            }
        }
    }
    return isReplaceRoute;
}
