import React from 'react';
import './coupon.style.scss';
import _ from 'lodash';
import TopBar from '../top-bar/top-bar.component';
import i18n from '../../i18n';
import CouponItem from './coupon-item.component';
import { Button } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import { InsertCode } from '../insert-code-dialog/insert-code-dialog.component';
import ScanCode from '../scan-code/scan.component';
import { GetCuoponBox } from './get-cuopon-box.component';
import { getQueryString } from '../../constants/tool-get-parms';
import { showApiMessage } from '../../messenger/messageManager';
import apiManage from '../../request';
// import { onSelectCoupons } from './tool.coupon';
import { Coupon, Customer } from '../../models';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
export default class CouponList extends React.Component<Props> {

    public state: State = {
        // 优惠券包
        couponPackage: [],
        // 选中优惠券数组
        usedCouponIds: [],
        // 添加优惠券方式
        showGetCuoponBox: false,
        // 输入code
        showInsertCode: false,
        // 扫码
        showQR: false,
        // 用户ID
        accountID: '',
        // 优惠后的价格
        discountedPrice: 0,
        // 优惠后的价格
        deducted: 0,
        // 防止反复点击出错
        updating: false
    }

    // *********************
    // Life Cycle Function
    // *********************

    componentDidMount() {
        const { usedCouponIds, couponPackage, deducted, discountedPrice } = this.props
        const account: Customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
        const accountID = _.get(account, '_id');
        this.setState({
            usedCouponIds: usedCouponIds || [],
            couponPackage,
            accountID: accountID || '',
            deducted,
            discountedPrice
        })
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.type === 'myCoupon') {
            if (newProps.couponPackage !== this.state.couponPackage) {
                this.setState({
                    couponPackage: newProps.couponPackage
                })
            }
        }
    }

    // *********************
    // Default Function
    // *********************

    /**
     * 选择优惠券
     * @param item 点击的优惠券
     *
     * @弃用
     */
    onSelect() {
        // if (!this.state.updating) {
        // const { couponPackage, usedCouponIds } = this.state
        // const { type, originalTotalPrice, integralValue } = this.props
        // let result = onSelectCoupons(couponPackage, item, usedCouponIds, originalTotalPrice || 0, integralValue || 0, type)
        // if (result) {
        //     let { newUsedCouponIds, discountedPrice, deducted, newCouponPackage } = result
        //     this.setState({
        //         usedCouponIds: newUsedCouponIds,
        //         discountedPrice,
        //         deducted,
        //         couponPackage: newCouponPackage,
        //         updating: true
        //     }, () => {
        //         this.setState({
        //             updating: false
        //         })
        //     })
        // }
        // }
    }

    /**
     * 通过扫面领取优惠券
     * @param result 扫描结果
     */
    public async onScanCodeCoupon(result) {
        const info = getQueryString('info', result)
        if (info) {
            const result: any = await apiManage.getCouponByScan(info);
            if (_.get(result, 'error')) {
                showApiMessage(result, 'coupon')
            } else {
                showApiMessage(result, 'coupon', { message: i18n.t('coupon_text_successfully_received'), level: 'Success' })
                if (this.props.onSucceeded) {
                    this.props.onSucceeded()
                }
            }
            this.setState({ showQR: false })
        }
    }
    /**
     * 输入编码领取优惠券
     * @param insertCode 输入code
     */
    public async onInsertCodeCoupon(insertCode) {
        const { accountID } = this.state
        if (accountID) {
            if (insertCode) {
                const result: any = await apiManage.onInsertCodeCoupon(accountID, insertCode);
                if (_.get(result, 'error')) {
                    showApiMessage(result, 'coupon')
                } else {
                    showApiMessage(result, 'coupon', { message: i18n.t('coupon_text_successfully_received'), level: 'Success' })
                    if (this.props.onSucceeded) {
                        this.props.onSucceeded()
                    }
                }
                this.setState({ showInsertCode: false })
            }
        }

    }
    /**
     * 点击返回，则重置到原来的选择情况
     */
    public reSetCoupons() {
        const { usedCouponIds, couponPackage, discountedPrice, deducted, originalTotalPrice } = this.props

        this.setState({
            usedCouponIds,
            discountedPrice: discountedPrice || originalTotalPrice,
            deducted,
            couponPackage
        })
    }


    /**
     * 重组 我的优惠券 布局
     */
    public resetCouponlsitView() {
        try {
            const { type } = this.props
            const { couponPackage, usedCouponIds, accountID } = this.state
            let cloneCouponPackage = _.cloneDeep(couponPackage);
            // 我的账户-我的优惠券、餐馆主页-我的优惠券
            if (type === 'myCoupon') {
                // $ 过滤掉免领取的优惠券
                cloneCouponPackage = _.filter(cloneCouponPackage, (n) => { return !n.ignore_get_enable });
                let restaurantsStringIds: Array<string> = [];
                // 过滤出 可视 的优惠券
                cloneCouponPackage = _.filter(cloneCouponPackage, (n) => { return n.display_status === '0' });

                cloneCouponPackage.forEach((v) => {
                    // 创建优惠券的餐馆的string_id。substring(0,5) 截取前5位置
                    const restaurantStringId = v.coupon_code && v.coupon_code.substring(0, 5);
                    const restaurantStringName = _.get(_.find(v.string_ids || [], (n) => { return n.string_id === restaurantStringId }), 'nickname');
                    // 避免找不到对应的餐馆名，则选择第一个
                    if (restaurantStringName) {
                        restaurantsStringIds.push(`${restaurantStringId}_${restaurantStringName}`)
                    } else {
                        v.coupon_code = v.string_ids?.[0].string_id;
                        restaurantsStringIds.push(`${v.string_ids?.[0].string_id}_${v.string_ids?.[0].nickname}`)
                    }
                    return v
                });
                restaurantsStringIds = Array.from(new Set(restaurantsStringIds));
                // 同一餐馆下的放在一起
                const newCouponPackage: any = {};
                restaurantsStringIds.forEach((v) => {
                    const stingId = v.split('_')[0];
                    const coupons = _.filter(cloneCouponPackage, (n) => {
                        return n.coupon_code && n.coupon_code.substring(0, 5) === stingId
                    })
                    newCouponPackage[v] = coupons;
                });
                if (Object.keys(newCouponPackage).length > 0) {
                    return (
                        Object.keys(newCouponPackage).map((item, index) => {
                            const restaurantName = item.split('_')[1]
                            // 排序，可使用的放在对应餐馆的上面
                            const couponsItem = newCouponPackage[item] && newCouponPackage[item].sort(this.compareCoupon);
                            if (couponsItem) {
                                return (
                                    <div key={index}>
                                        <div className='restaurantName'>
                                            {restaurantName}
                                            {`(${couponsItem.length})`}
                                        </div>
                                        {couponsItem.map((v, iindex) => {
                                            return (
                                                <CouponItem
                                                    key={v.coupon_id + iindex}
                                                    couponItem={v}
                                                    onClick={() => { this.onSelect() }}
                                                    type='myCoupon'
                                                    accountID={accountID}
                                                />
                                            )
                                        })}
                                    </div>
                                )
                            } else {
                                return null;
                            }
                        })
                    )
                } else {
                    return (
                        <div className='notfind'>{i18n.t('coupon_text_no_more_coupons')}</div>
                    )
                }
            } else {
                // 付款页面
                if (cloneCouponPackage.length > 0) {
                    return (
                        cloneCouponPackage.map((item, index) => {
                            return (
                                <CouponItem
                                    key={index}
                                    couponItem={item}
                                    onClick={() => { this.onSelect() }}
                                    isCheck={_.includes(usedCouponIds, item.coupon_id)}
                                    accountID={accountID}
                                />
                            )
                        })
                    )
                } else {
                    return (
                        <div className='notfind'>{i18n.t('coupon_text_no_more_coupons')}</div>
                    )
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * 根据优惠券的的可用状态排序
     * @param coupon
     */
    compareCoupon(coupon: any) {
        const preValue = coupon.status === '1';
        if (preValue) {
            return 1
        } else {
            return -1
        }
    }

    // *********************
    // View
    // *********************

    render() {
        const { usedCouponIds, couponPackage, showGetCuoponBox, showInsertCode, showQR,
            deducted, discountedPrice } = this.state
        const { className, type } = this.props
        return (
            <div className={`CouponList ${className}`}>
                <TopBar
                    title={i18n.t('coupon_text_my_coupons')}
                    isGoBack={() => {
                        this.props.onCancel()
                        if (type !== 'myCoupon') {
                            this.reSetCoupons()
                        }
                    }}
                    isHideMenu={type === 'myCoupon' ? false : true}
                    backgroundstyle={'backgroundTransparent'}
                    rightIcon={() => (
                        <Button className='confrimicon'>
                            <AiOutlinePlus className='bar-menu tool-icon' />
                        </Button>
                    )}
                    isConfirm={() => { this.setState({ showGetCuoponBox: true }) }}
                />
                <div className='content'>
                    {this.resetCouponlsitView()}

                </div>

                {this.props.onConfirm ?
                    <div className='footerBox'>
                        <div className='footerContent'>
                            <div className='selectText'>
                                <span className='number'>{usedCouponIds.length}</span>
                                <span>{`${i18n.t('coupon_text_selected')},`}</span>
                                <span className='number'>€{deducted.toFixed(2)}</span>
                                <span>{`${i18n.t('coupon_text_deducted')}`} </span>
                            </div>
                            <div className='confirmbutton' >
                                <button className={'checkoutBtn'}
                                    onClick={() => {
                                        if (this.props.onConfirm) {
                                            this.props.onConfirm({
                                                usedCouponIds,
                                                discountedPrice,
                                                deducted,
                                                couponPackage
                                            })
                                        }
                                        this.props.onCancel()
                                    }}>
                                    {i18n.t('coupon_button_confirm')}
                                </button>
                            </div>
                        </div>
                    </div> : null}
                {showGetCuoponBox &&
                    <GetCuoponBox
                        onhandleClose={() => { this.setState({ showGetCuoponBox: false }) }}
                        onhandleInsertCode={() => { this.setState({ showInsertCode: true, showGetCuoponBox: false }) }}
                        onhandleQR={() => { this.setState({ showQR: true, showGetCuoponBox: false }) }} />}
                {showInsertCode &&
                    <InsertCode
                        handleClose={() => { this.setState({ showInsertCode: false }) }}
                        onConfirm={(insertCode) => { this.onInsertCodeCoupon(insertCode) }} />}

                {showQR &&
                    <ScanCode
                        handleCloseScan={() => { this.setState({ showQR: false }) }}
                        successfulCallback={(result) => { this.onScanCodeCoupon(result) }}
                        type='coupon'
                    />
                }
            </div >
        )
    }
}

// *********************
// Props & State
// *********************
type Props = {
    couponPackage: Coupon[];
    onCancel: () => void;
    onConfirm?: (val: any) => void;
    usedCouponIds?: Array<string>;
    type?: string;
    className?: string;
    originalTotalPrice?: number;
    discountedPrice?: number;
    deducted?: number;
    onSucceeded?: () => void;
    integralValue?: number;
};
type State = {
    couponPackage: Coupon[];
    usedCouponIds: Array<string>;
    showGetCuoponBox: boolean;
    showInsertCode: boolean;
    showQR: boolean;
    accountID: string;
    discountedPrice: number;
    deducted: number;
    updating: boolean;
};
