import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { Schedule, ScheduleTimeRange } from '../models/index';
import _ from 'lodash';

/**
 * 检查目标时间是否在时间表中，并取出
 *
 * @param schedule 餐馆的营业时间数据
 * @param origin 目标业务履行日期
 * @param scheduleTimeZoneName 餐馆所处时区
 * @param originTimeZoneName 业务履行地方所处时区
 *
 * @returns
 * - `isDateAvailableInSchedule` 目标时间是否在日程表中存在
 * - `isTakeawayAvailableInCurrentWeek` 目标时间所在的星期数的外送服务，是否在日程表中存在
 * - `isSelftakeAvailableInCurrentWeek` 目标时间所在的星期数的自取服务，是否在日程表中存在
 * - `isTakeawayAvailableInCurrentTime` 目标时间的外送服务，是否在日程表中存在
 * - `isSelftakeAvailableInCurrentTime` 目标时间的自取服务，是否在日程表中存在
 * - `weekSchedule` 所有与目标时间所匹配的日程表数据
 */
export function checkSchedule(
    schedule: Array<Schedule>,
    origin: Date | string,
    scheduleTimeZoneName: string,
    originTimeZoneName: string
): CheckScheduleReport {
    // $ init
    // let originCloned1 = _.cloneDeep([origin])[0] || origin;
    // let originCloned2 = _.cloneDeep([origin])[0] || origin;
    let isDateAvailableInSchedule: boolean = false;
    const isTakeawayAvailableInCurrentWeek: boolean = false;
    const isSelftakeAvailableInCurrentWeek: boolean = false;
    let isTakeawayAvailableInCurrentTime: boolean = false;
    let isSelftakeAvailableInCurrentTime: boolean = false;
    const weekSchedule: Array<Schedule> = [];
    // scheduleTimeZoneName = scheduleTimeZoneName || momentTimeZone.tz.guess();
    // originTimeZoneName = originTimeZoneName || momentTimeZone.tz.guess();
    // // $ get schedule dates that mathced current week
    // // get current week number
    // let originWithScheduleTimeZone1 =
    //     convertDateByTimeZoneName(originCloned1, originTimeZoneName, scheduleTimeZoneName);
    const weekNumber: number = moment(origin, 'YYYY-MM-DD').isoWeekday();
    // // find all matched week
    schedule.forEach(t => {
        if (t.week === weekNumber) { weekSchedule.push(t); }
    });
    // // $ get origin time
    // let originWithScheduleTimeZone2 =
    //     convertDateByTimeZoneName(originCloned2, originTimeZoneName, scheduleTimeZoneName);
    // originWithScheduleTimeZone2 = originWithScheduleTimeZone2.split('T')[1].substring(0, 8);
    // const time = moment(originWithScheduleTimeZone2, 'hh:mm:ss');
    // $ check time range
    // check all time range
    origin = moment(origin, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').format('HH:mm:ss');
    weekSchedule.forEach((schedule: Schedule) => {
        // // check takeaway status / selftake status
        // if (schedule.takeaway_status) isTakeawayAvailableInCurrentWeek = true;
        // if (schedule.selftake_status) isSelftakeAvailableInCurrentWeek = true;
        // is 24 hours or check each time range
        if (schedule.time_24_hours) {
            isDateAvailableInSchedule = true;
            if (schedule.takeaway_status) isTakeawayAvailableInCurrentTime = true;
            if (schedule.selftake_status) isSelftakeAvailableInCurrentTime = true;
        }
        else
            (schedule.time_ranges || []).forEach((scheduleTimeRange: ScheduleTimeRange) => {
                const startTime = moment(scheduleTimeRange.start_time, 'hh:mm:ss');
                const endTime = moment(scheduleTimeRange.end_time, 'hh:mm:ss');
                // is matched
                if (moment(origin, 'hh:mm:ss').isBetween(startTime, endTime, 'm', '[]')) {
                    isDateAvailableInSchedule = true;
                    if (scheduleTimeRange.takeaway_status) isTakeawayAvailableInCurrentTime = true;
                    if (scheduleTimeRange.selftake_status) isSelftakeAvailableInCurrentTime = true;
                }
            });
    });
    // return
    return {
        isDateAvailableInSchedule,
        isTakeawayAvailableInCurrentWeek,
        isSelftakeAvailableInCurrentWeek,
        isTakeawayAvailableInCurrentTime,
        isSelftakeAvailableInCurrentTime,
        weekSchedule,
        details: {
            scheduleTimeZoneName,
            originTimeZoneName
            // originWithScheduleTimeZone1,
            // weekNumber,
            // originWithScheduleTimeZone2,
            // time
        }
    };
}

/**
 * 根据原日期、原时区、目标时区，将原日期转化为目标时区的日期
 * @param date 原日期
 * @param originTimeZoneName 原时区
 * @param targetTimeZoneName 目标时区
 */
export function convertDateByTimeZoneName(
    date: string | Date,
    originTimeZoneName: string,
    targetTimeZoneName: string
): any {
    // $ get cloned object
    const dateCloned = _.cloneDeep([date])[0] || date;
    let offsetedDate: any = null;
    // $ get utc offset
    const originTimeZoneOffset = momentTimeZone.tz(originTimeZoneName).format('ZZ');
    const targetTimeZoneOffset = momentTimeZone.tz(targetTimeZoneName).format('ZZ');
    const originTimeZoneOffsetNumValue = Number.parseInt(originTimeZoneOffset, 10);
    const targetTimeZoneOffsetNumValue = Number.parseInt(targetTimeZoneOffset, 10);
    let offset: number = 0;
    if (originTimeZoneOffsetNumValue < targetTimeZoneOffsetNumValue)
        offset = +(targetTimeZoneOffsetNumValue - originTimeZoneOffsetNumValue);
    else if (targetTimeZoneOffsetNumValue < originTimeZoneOffsetNumValue)
        offset = -(originTimeZoneOffsetNumValue - targetTimeZoneOffsetNumValue);
    offset = Number.parseInt(`${offset}`.replace(/0/g, ''), 10) || 0;
    // $ convert date by utc offset
    // equel
    if (offset === 0) {
        offsetedDate = momentTimeZone.tz(dateCloned, originTimeZoneName).toISOString();
        offsetedDate = moment(offsetedDate).toISOString();
    }
    // not equel
    else {
        offsetedDate = momentTimeZone.tz(dateCloned, originTimeZoneName).toISOString();
        offsetedDate = moment(offsetedDate).add(offset, 'hours').toISOString();
    }
    // $ return
    return offsetedDate;
}

/**
 *  获取所有时区名称
 *  例：
 *     'Africa/Addis_Ababa',
 *     'Africa/Algiers',
 */
export function getTimeZoneNames() {
    return momentTimeZone.tz.names();
}

/**
 * 根据时区名称转换日期时间
 * 例：
 *      2020-05-07T16:31:53+08:00
 *      2020-05-07T04:31:53-04:00
 */
export function convertDateWithTimeZone(date: Date | string, zoneName: string) {
    return momentTimeZone(moment(date, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').toISOString()).tz(zoneName).format();
}


/**
 * Try converting value to `YYYY-MM-DD[T]HH:mm:ss.SSS[Z]` format (date-time)
 * @param date default is current date time
 */
export function DT(date?: Date | string | number): any {
    try {
        return moment(
            date ? date : moment().local().toDate()
        ).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    } catch (error) {
        return date;
    }
}

/**
 * 转换 Error的对象
 * @param key
 * @param value
 */
export function replaceErrors(key: any, value: any) {
    if (value as any instanceof Error) {
        const error = {} as any;
        Object.getOwnPropertyNames(value).forEach((key: any) => {
            error[key] = value[key];
        });
        return error;
    }
    return value;
}

// 类型定义
export declare type CheckScheduleReport = {
    isDateAvailableInSchedule: boolean;
    isTakeawayAvailableInCurrentWeek: boolean;
    isSelftakeAvailableInCurrentWeek: boolean;
    isTakeawayAvailableInCurrentTime: boolean;
    isSelftakeAvailableInCurrentTime: boolean;
    weekSchedule: Array<Schedule>;
    details: {
        scheduleTimeZoneName: any;
        originTimeZoneName: any;
        originWithScheduleTimeZone1?: any;
        weekNumber?: any;
        originWithScheduleTimeZone2?: any;
        time?: any;
    }
};