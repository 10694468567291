import './dish.details.scss';

import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import React from 'react';
import { MdAdd, MdClear } from 'react-icons/md';

import i18n from '../../i18n';
import { Dish } from '../../models';
import { ALLERGEN, INGREDIENTS } from '../../static/dish-base-data';
import { allDishImages } from '../../static/dish-images';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { CartActions } from '../../interfaces/collective-dish-panel.interface';
import { showHDPic } from '../../constants/tool-show-hd-pic';
import Notification from '../../component/snackbar/snackbar';
import { DishDisabledMask } from '../../component/order/dish-disabled-mask';
import { analysisCart } from '../../component/order/order.component';
import { ShopCar } from '../../models/fixed/shop-car.entity';
import { PM } from '../../component/order/tool-points';

class DishDetail extends React.Component<Props, State> {

    public state: State = {
        dishData: {},
        // 判断是Ingredients还是Allergic
        isIngredients: true,
        shopCar: {
            shopCarList: [],
            totalPrice: 0
        },
        // 当前菜馆id
        currentResStringID: '',
        // 点击其他标识显示名称
        isOpenTooltip: false,
        // 点击其他标识的key
        selectkey: '',
        // 是否为包餐套餐菜品
        isSetMeal: '',
        // 菜品数量
        dishBuyNumber: 0
    }
    // *********************
    // Default Function
    // *********************

    // *********************
    // Life Cycle Function
    // *********************

    componentDidMount() {
        this.getDishesDetail()
    }

    // *********************
    // Service Function
    // *********************

    // 获取到菜品详情数据
    getDishesDetail() {
        try {
            const { dishesDetail } = this.props
            let shopCar;
            const currentResStringID = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
            const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
            // 菜品数量
            let dishBuyNumber = 0;
            // 判断store里面是否有shopCar购物车数据，没有赋值为空对象
            if (cacheShopCar[currentResStringID]) {
                shopCar = cacheShopCar[currentResStringID]
            } else {
                shopCar = {
                    shopCarList: [],
                    totalPrice: 0,
                    totalPoints: 0
                }
            }
            const dishItem = _.find((shopCar.shopCarList || []), (n) => n.firstId === dishesDetail.firstId);
            if (dishItem) {
                dishBuyNumber = _.get(dishItem, 'buyNumber')
            }
            this.updateState({
                dishData: dishesDetail,
                shopCar,
                currentResStringID,
                dishBuyNumber
            })
        } catch (error) { }
    }


    // 切换Ingredients和Allergic
    handleChangeOption(value: boolean) {
        this.updateState({
            isIngredients: value
        })
    }

    // !!!更改数据方法

    updateState(value: any) {
        this.setState({ ...value })
    }
    // 返回这个数据
    handleClose(shopCar) {
        // const history = createHashHistory()
        // history.goBack()
        const { onhandleClose } = this.props
        if (shopCar) {
            onhandleClose(shopCar)
        } else {
            onhandleClose()
        }
    }
    // 添加到购物车
    handleAddCart() {
        const dishData = this.state.dishData
        let shopCar = _.cloneDeep([this.state.shopCar])[0];

        // 改变store里面的数据
        if (Object.keys(shopCar).length > 0) {
            // 表示store里面有数据
            // 判断之前是否点击过 购买数据量的变动
            // 找到下单搜索
            const shopCarItem = shopCar.shopCarList?.find(item =>
                (dishData.firstId === item.firstId) && item.buyNumber);
            if (_.isObject(shopCarItem)) {
                if (shopCarItem.buyNumber) {
                    // 单个菜品数量不得超于99
                    if (shopCarItem.buyNumber < 99) {
                        shopCarItem.buyNumber++;
                    } else {
                        Notification.notification(`${i18n.t('checkout_page_text_the_number_of_dishes_cannot_be_greater_than', { number: 99 })}`);
                    }
                }
            } else {
                shopCar.shopCarList?.push({
                    ...dishData,
                    buyNumber: 1
                })
            }
            shopCar = analysisCart({ shopCarList: shopCar.shopCarList });
        } else {
            // 没有数据，直接添加
            shopCar = analysisCart({ shopCarList: [this.state.dishData] })
        }
        const pass = PM.updatePoints({ key: 'dishUsedPoints', value: shopCar.totalPoints || 0 });
        if (!pass) {
            Notification.notification(`${i18n.t('order_page_text_not_enough_for_points')}`);
            shopCar = this.state.shopCar;
        }
        // 返回上一个页面
        this.handleClose(shopCar)
    }
    // isSelctIcon
    isSelectIcon = (key) => [
        this.updateState({
            selectkey: key
        })
    ]

    // *********************
    // View
    // *********************
    render() {
        const { dishData, isIngredients, selectkey, dishBuyNumber } = this.state
        const { updateShoppoingBasket, dishesDetail, onhandleClose, goMenuValore, isCollectiveData, openPackageMeal, setDishesIsMax } = this.props
        const ACTION: Array<CartActions> = [{ type: 'ADD', id: _.get(dishesDetail, 'id', ''), isICollectiveDish: false, num: _.get(dishesDetail, 'num', 0) }]
        const HDPic = dishesDetail.realpics ? showHDPic(dishesDetail.realpics[0]) : '';
        return (
            <div className='dishDetailContainer'>
                <MdClear className='mdClearIco' onClick={this.handleClose.bind(this, false)} />
                {/* 禁用蒙层 */}
                {<DishDisabledMask className='dishDetail' soldOut={dishData.status === '2'} filterOut={!!dishData.filter_status} />}
                <div className='dishImg' >
                    <img src={HDPic} alt='' />
                </div>
                <div className='dishMainContainer'>
                    <p className='name'>{dishData.name}</p>
                    <ul className='logoList'>
                        {dishData.ingredients && dishData.ingredients.length > 0
                            && dishData.ingredients.map((item) => {
                                return (
                                    INGREDIENTS.map((iitem, index) => {
                                        if (iitem.key === item) {
                                            return (
                                                <Tooltip
                                                    className='ingredientsTooltip'
                                                    key={index}
                                                    title={`${i18n.t(iitem.text)}`}
                                                    placement='top'
                                                    PopperProps={{
                                                        disablePortal: true
                                                    }}
                                                    onClose={() => this.isSelectIcon('1')}
                                                    open={selectkey === iitem.key ? true : false}
                                                >
                                                    <li key={index} onClick={() => this.isSelectIcon(iitem.key)}>
                                                        <img className='logo' src={allDishImages[item]} alt='' />
                                                    </li>
                                                </Tooltip>
                                            )
                                        } else {
                                            return null
                                        }
                                    })

                                )
                            })}
                    </ul>
                    <div className='priceContainer'>
                        <p className='price'>
                            {PM.showPointsOrPrice(dishData.is_points_redemption, dishData?.price || 0)}
                        </p>
                        {dishData.quantity_description &&
                            <p className='symbol'>
                                {dishData.quantity_description}
                            </p>}
                    </div>
                    <div className='option' >
                        <p className={isIngredients ? '' : 'isIngredients'}
                            onClick={() => { this.handleChangeOption(true) }}>
                            {i18n.t('restaurant_order_page_text_dishes_description')}
                        </p>
                        <p className={!isIngredients ? '' : 'isIngredients'}
                            onClick={() => { this.handleChangeOption(false) }}>
                            {i18n.t('restaurant_order_page_text_allergen')}
                        </p>
                    </div>
                    {
                        isIngredients && dishData.ingredients ?
                            <div className='description'>{dishData.description}</div>
                            : dishData.allergen ?
                                <ul className='allergen'>
                                    {dishData.allergen.map((item, index) => {
                                        return (
                                            ALLERGEN.map((iitem) => {
                                                if (iitem.key === item) {
                                                    return (
                                                        <li key={index}>
                                                            <div className='placeholderBox'>
                                                                <img src={allDishImages[item]} alt='' />
                                                            </div>

                                                            <p>{`${i18n.t(iitem.text)}`}</p>
                                                        </li>
                                                    )
                                                } else {
                                                    return null
                                                }

                                            })
                                        )
                                    })}
                                </ul> : null
                    }
                    <button className={`${!_.get(dishData, 'addButtonStatus', true) || (setDishesIsMax || ((dishBuyNumber || 0) >= 99)) || (dishesDetail.unavailableTime) ? 'disButton' : ''}`}
                        onClick={async () => {
                            try {
                                if (!_.get(dishData, 'addButtonStatus', true) || (setDishesIsMax || ((dishBuyNumber || 0) >= 99)) || (dishesDetail.unavailableTime)) {
                                    return
                                }
                                if (dishData.dish_type !== '0') {
                                    // 包餐套餐
                                    if (isCollectiveData) {
                                        //  包餐套餐里面的包餐套餐
                                        goMenuValore?.(dishData._id || '', dishData.dish_type || '', _.get(dishData, 'belongedBasketId', ''))
                                    } else {
                                        openPackageMeal?.(dishData)
                                        onhandleClose()
                                    }
                                } else if (dishData.dish_type === '0') {
                                    // 普通菜品
                                    if (isCollectiveData) {
                                        // 包餐套餐里面的普通菜品
                                        updateShoppoingBasket?.(ACTION)
                                        onhandleClose()
                                    } else {
                                        this.handleAddCart()
                                    }
                                }
                            } catch (error) {
                            }
                        }}>
                        <MdAdd className='mdAddIco' />
                        <span> {isCollectiveData ? i18n.t('restaurant_order_page_text_add_to_shopping_basket') : i18n.t('restaurant_order_page_button_add_to_cart')}</span>
                    </button>
                </div>


            </div >
        )
    }
}

export default DishDetail


// *********************
// Types
// *********************


type Props = {
    // ...
    onhandleClose: (shopCar?: ShopCar) => void;
    dishesDetail: Dish;
    isCollectiveData: boolean;
    setDishesIsMax?: boolean;
    updateShoppoingBasket?: (action: Array<CartActions>) => void;
    goMenuValore?: (dishId: string, dishType: string, BasketId: string) => void; // 包餐套餐里面打开包餐套餐
    openPackageMeal?: (itemData: Dish) => void;
};

type State = {
    // ...
    dishData: Dish;
    isIngredients: boolean;
    shopCar: ShopCar;
    currentResStringID: string;
    isOpenTooltip: boolean;
    selectkey: string;
    isSetMeal: string;
    dishBuyNumber: number;
};