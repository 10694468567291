import Store from '../rudex/store';

export  function getMessages(message: number) {
    if (message > 0) {
        Store.dispatch({
            type: 'SET_STATE',
            path: 'hasNewNotices',
            value: true
        })
    } else {
        Store.dispatch({
            type: 'SET_STATE',
            path: 'hasNewNotices',
            value: false
        })
    }
}