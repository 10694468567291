import React from 'react';
import './package-meal-item.component.scss'

import { IconButton, Button } from '@material-ui/core';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import { BasketDish, CartActions } from '../../../interfaces/collective-dish-panel.interface';
import i18n from '../../../i18n';
import _ from 'lodash';
import { allDishImages } from '../../../static/dish-images';
import { DishDisabledMask } from '../dish-disabled-mask';
import { DishTagBox } from '../order.component';
import { PM } from '../tool-points';

// 套餐菜单每一项
export function PackageMealItem(
    props: {
        itemData: BasketDish;
        type: 'common' | 'shoppingBasket';
        goMenuValore?: () => void;
        updataShoppingBasket?: (actions: Array<CartActions>) => void;
        updateState: (val) => void;
        isRepeated?: boolean;
        isDisableAdd: boolean;
        setMealInside?: boolean;
        basketAreasId?: string;
        handleCollectiveData?: (itemData: BasketDish, basketAreasId: string) => void;
        allselectAllergen: string[];
        isPoints?: boolean;
    }
) {
    const { type, itemData, goMenuValore, updataShoppingBasket, isDisableAdd, updateState, handleCollectiveData, basketAreasId, allselectAllergen, isPoints } = props
    const IMGURL = itemData && itemData.realpics && itemData.realpics[0];
    const BASKETPRICE = itemData?.CollectiveDishTotalPrice ? itemData?.CollectiveDishTotalPrice : itemData?.price ? itemData?.price : 0
    // 过敏源、标识
    const allergenAndIngredients = (itemData.allergen || []).concat(itemData.ingredients || [])
    if (_.difference(allergenAndIngredients, allselectAllergen).length !== allergenAndIngredients.length) {
        itemData.filter_status = true;
    }

    return (
        <div className={`packageDishItem ${type === 'shoppingBasket' ?
            itemData?.dish_type === '2' ? 'BasketSetDishItem' : 'BasketDishItem' : ''}`}>
            <div className={`mainInfo ${itemData?.dish_type === '2' && type === 'shoppingBasket' ? 'basketMainInfo' : ''}`}>
                <DishTagBox dishItem={itemData} />
                <div className='dishImg'>
                    <img src={IMGURL} alt='' />
                </div>
                <div className='content'>
                    <div className='title'>
                        <p>{itemData?.name}</p>
                    </div>
                    {type === 'shoppingBasket' ?
                        itemData?.price || itemData?.CollectiveDishTotalPrice ?
                            <div className='left'>
                                <span className='price'>+ {PM.showPointsOrPrice(isPoints, BASKETPRICE)}×{itemData?.num}</span>
                            </div> : null
                        : <>
                            <div className='description'>
                                <p>{itemData?.description}</p>
                            </div>
                            <div className='' style={{ whiteSpace: 'nowrap' }}>
                                {itemData?.allergen?.map((allergenItem: string, allergenIndex: number) => {
                                    const pic = allDishImages[allergenItem];
                                    if (pic) {
                                        return (
                                            <img className='allergen-pic' src={pic} alt={allergenItem} key={allergenIndex} />
                                        )
                                    }
                                })}
                            </div>
                        </>}
                    <div className='dishsNumber'>
                        {/* 包餐里面的套餐价格不显示 */}
                        {type === 'shoppingBasket' ?
                            < div ></div> :
                            <div className='left'>
                                <span className='price'>
                                    {itemData?.price ? PM.showPointsOrPrice(isPoints, itemData?.price || 0) : null}
                                </span>
                            </div>}

                        {itemData?.dish_type === '2' && type === 'common' ?
                            <div className={`ordina ${itemData.addButtonStatus && !isDisableAdd ? '' : 'disOrdina'}`}>
                                {/* 包餐里面的套餐 */}
                                <Button className='setMenuBtn' onClick={(evt) => {
                                    evt.stopPropagation()
                                    goMenuValore?.()
                                }}>
                                    <span>{i18n.t('restaurant_order_page_text_order')}</span>
                                </Button>
                            </div> :
                            <div className='right'>
                                {/* 减号控件 */}
                                <IconButton onClick={(evt) => {
                                    evt.stopPropagation()
                                    let actions: Array<CartActions> = [{ type: 'REDUCE', id: itemData?.id || '', num: itemData?.num, isICollectiveDish: !!itemData?.isICollectiveDish }]
                                    if (itemData?.isICollectiveDish) {
                                        actions = [{ type: 'REDUCE', id: itemData?.id || '', num: itemData?.num, isICollectiveDish: itemData?.isICollectiveDish, differentSetDishId: itemData?.differentSetDishId }]
                                    }
                                    updataShoppingBasket?.(actions)
                                }}>
                                    <AiFillMinusCircle
                                        className={itemData?.reduceButtonStatus ? 'AiFillMinusCircle' : 'DisAiFillMinusCircle '}
                                    />
                                </IconButton>
                                <p className={`number ${itemData?.num ? '' : 'is_no_number'}`}>{itemData?.num}</p>
                                <IconButton onClick={(evt) => {
                                    evt.stopPropagation()
                                    let actions: Array<CartActions> = [{ type: 'ADD', id: itemData?.id || '', num: itemData?.num, isICollectiveDish: !!itemData?.isICollectiveDish }]
                                    if (itemData?.isICollectiveDish) {
                                        actions = [{ type: 'ADD', id: itemData?.id || '', num: itemData?.num, isICollectiveDish: itemData?.isICollectiveDish, differentSetDishId: itemData?.differentSetDishId }]
                                    }
                                    updataShoppingBasket?.(actions)
                                }}>
                                    <AiFillPlusCircle className={`${!itemData?.addButtonStatus || isDisableAdd ? 'DisAiFillPlusCircle' : 'AiFillPlusCircle'}`} />
                                </IconButton>
                            </div>}
                    </div>
                    {/* 点击进入菜品详情或套餐页 */}
                    <div className='clickBox' onClick={async () => {
                        if (type === 'shoppingBasket' && itemData.dish_type !== '0') {
                            // 修改包餐套餐
                            // await updateState({ basketAreasId: basketAreasId ?? '' })
                            handleCollectiveData?.(itemData, basketAreasId || '')
                            return
                        }
                        updateState({ dishesDetail: itemData, isShowDishDetails: true, isShowBasket: false, basketAreasId: basketAreasId ?? '' })
                    }}></div>
                    {/* 禁用蒙层 */}
                    <DishDisabledMask soldOut={itemData.status === '2'} filterOut={!!itemData.filter_status} />
                </div>
            </div>
        </div >
    )

}