import React from 'react';
import SwipeableTextMobileStepper from '../../component/restaurant/SwipeableTextMobileStepper-component';
import ReactMarkdown from 'react-markdown';
import Chip from '@material-ui/core/Chip';
import BlackGoldCouponItem from '../../component/coupon/blackGoldComponent/black-gold-coupon-item.component';
import i18n from '../../i18n';
import { Bardatatype, RestaurantPageState } from './restaurant-page';
import './black-gold-theme-restaurant-page.styles.scss';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { BusinessBox } from '../../component/restaurant/blackGoldThemeComponent/business-box';
import { MdLocalActivity } from 'react-icons/md';
import PhoneIcon from '@material-ui/icons/Phone';
import { FeaturedDishItem } from '../../component/restaurant/blackGoldThemeComponent/featured-dish-item';
import BlackGoldCouponList from '../../component/coupon/blackGoldComponent/black-gold-coupon-list.component';
import _ from 'lodash';
import apiManage from '../../request';
import { Dish } from '../../models';
import moment from 'moment';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';

class BlackGoldThemeRestaurantPage extends React.Component<Props, State> {

    public state: State = {
        // 我的优惠券开关
        couponListVisible: false,
        // 我的优惠券
        myCouponPackage: [],
        // 特色菜
        specialMenuDish: []
    }
    // *********************
    // Default Function
    // *********************

    // *********************
    // Life Cycle Function
    // *********************
    async componentDidMount() {
        // 获取特色菜
        await this.getMenuSpecialDish()
        // 获取特色菜图片
        await this.getMenuSpecialDishPic()
    }
    // *********************
    // Service Function
    // *********************

    // 获取我的优惠券
    getMycoupon() {
        try {
            const { customerInfo } = this.props.params
            const couponPackage = _.get(customerInfo, 'coupons') || []
            this.setState({ myCouponPackage: couponPackage })
        } catch (error) {
        }
    }
    // 获取特色菜
    async getMenuSpecialDish() {
        try {
            const { restaurantStringId } = this.props.params
            const specialMenuDish = await apiManage.getMenuSpecialDish({ stringId: restaurantStringId });
            if (!_.get(specialMenuDish, 'error')) {
                if (_.isArray(specialMenuDish)) {
                    specialMenuDish.map((dishItem) => {
                        dishItem.firstId = `${dishItem._id}${'-specialMenu'}`;
                        return dishItem
                    })
                }
                this.setState({ specialMenuDish })
            }
        } catch (error) {
        }
    }
    // 获取特色菜图片
    async getMenuSpecialDishPic() {
        try {
            const { restaurantStringId } = this.props.params
            const { specialMenuDish } = this.state
            const specialMenuDishPic = await apiManage.getMenuSpecialDishPic({ stringId: restaurantStringId }, { notNeedLoading: true });
            if (!_.get(specialMenuDishPic, 'error')) {
                specialMenuDish.map((dishItem) => {
                    if (_.isArray(specialMenuDishPic)) {
                        specialMenuDishPic.forEach((picItem) => {
                            if (picItem._id === dishItem._id) {
                                dishItem.realpics = [picItem.picUrl]
                            }
                        })
                    }
                    return dishItem
                })
                this.setState({ specialMenuDish })
            }
        } catch (error) {
        }
    }
    // 拨打电话
    callPhone() {
        const { allPaperbar } = this.props.params
        window.location.href = `tel:${allPaperbar[3].content}`
    }
    // 添加到购物车
    handleAddCart(dishData) {
        try {
            // 获取缓存的购物车
            const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
            const currentResStringID = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID) || {};
            let shopCar = _.cloneDeep([cacheShopCar])[0];
            const price = dishData.price || 0;
            if (shopCar && shopCar[currentResStringID]) {
                shopCar = shopCar[currentResStringID];
            } else {
                shopCar = {
                    shopCarList: [],
                    totalPrice: 0
                };
            }
            // 改变store里面的数据
            if (Object.keys(shopCar).length > 0) {
                // 表示store里面有数据
                // 判断之前是否点击过 购买数据量的变动
                // 找到下单搜索
                const shopCarItem: Dish = shopCar.shopCarList.find(item =>
                    (dishData.firstId === item.firstId) && item.buyNumber);
                if (_.isObject(shopCarItem)) {
                    if (shopCarItem.buyNumber) {
                        shopCarItem.buyNumber++;
                    }
                } else {
                    shopCar.shopCarList.push({
                        ...dishData,
                        buyNumber: 1
                    })
                }
                // 重新计算总价格
                const totalPrice = shopCar.totalPrice + price;
                shopCar = {
                    shopCarList: shopCar.shopCarList,
                    totalPrice
                }
            } else {
                // 没有数据，直接添加
                shopCar = {
                    shopCarList: [dishData],
                    totalPrice: price
                }
            }
            // 更新缓存购物车数据
            LocalStorageManager.setLocalStorage({
                path: LOCAL_STORAGE_KEYS.SHOP_CAR,
                value: {
                    ...cacheShopCar,
                    [currentResStringID]: shopCar
                }
            });
        } catch (error) { }
    }

    // *********************
    // View
    // *********************

    render() {
        const {
            tutorialSteps, description, Chipdata, restaurantlogo,
            couponPackage, accountID, restaurantStringId
        } = this.props.params
        const { updateState, onAddCoupon, todyOpenTime, getCustomerInfo } = this.props
        const { couponListVisible, myCouponPackage, specialMenuDish } = this.state
        // const firstThemeColor = restaurantThemeColor === 'FIRST_THEME_COLOR' ? 'darkSwipe' : '#fff';
        // const ItemColor = restaurantThemeColor === 'FIRST_THEME_COLOR' ? '#b07c4d' : '#fff';
        return (
            <div className='pagebody blackGoldPagebody'>
                <div className='IntroductionBox'>
                    <SwipeableTextMobileStepper tutorialSteps={tutorialSteps}
                        className={'darkSwipe'} />
                    <div className='businessHoursBox'>
                        <div className=''>
                            <span className='icon'><AccessTimeIcon /></span>
                            <span>{i18n.t('payment_page_text_opening_hours')}</span>
                        </div>
                        <div className=''>
                            {
                                todyOpenTime ?
                                    !todyOpenTime.restaurantTakeawayServiceStatus ?
                                        i18n.t('restaurant_info_page_restaurant_closed_today')
                                        : todyOpenTime.isAllDayOpen ?
                                            i18n.t('general_text_all_day_business') :
                                            todyOpenTime.newTimeRanges.length > 0
                                                ? todyOpenTime.newTimeRanges.map((arrayItem, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <span>
                                                                {`${moment(arrayItem.start_time, 'HH:mm').format('HH:mm')}-${moment(arrayItem.end_time, 'HH:mm').format('HH:mm')}`}
                                                            </span>
                                                        </div>
                                                    )
                                                })
                                                : i18n.t('restaurant_info_page_restaurant_closed_today')
                                    : null
                            }
                        </div>
                    </div>
                    <div className='restaurantInfoBox'>
                        <div className='imgbox' onClick={() => updateState({ isDialogVisible: true })}>
                            <img
                                className='restaurantLogo'
                                src={restaurantlogo}
                                alt=''
                            />
                        </div>
                        <div className='introductionbox'
                            onClick={() => updateState({ isDialogVisible: true })}>
                            <ReactMarkdown className='markdown' source={description} escapeHtml={false} />
                            <div className='chipbox'>
                                {Chipdata && Chipdata.map((value, index) => {
                                    return <Chip key={index} size='small' label={value}></Chip>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {/* 餐馆业务功能区域 */}
                <div className='businessLayout'>
                    <BusinessBox
                        title={i18n.t('coupon_text')}
                        icon={() => <MdLocalActivity />}
                        onClick={() => {
                            if (accountID) {
                                // 获取我的优惠券
                                this.getMycoupon()
                                this.setState({ couponListVisible: true })
                            } else {
                                updateState({ isShowTip: true })
                            }
                        }}
                    />
                    <BusinessBox
                        title={i18n.t('restaurant_info_page_text_contact_information')}
                        icon={() => <PhoneIcon />}
                        onClick={() => { this.callPhone() }}
                    />
                    <BusinessBox
                        className='noMargin'
                        title={i18n.t('restaurant_info_page_text_schedule')}
                        icon={() => <AccessTimeIcon />}
                        onClick={() => { updateState({ isScheduleDialogvisible: true }) }}
                    />
                </div>
                {/* 优惠券 */}
                <div className='restaurantCouponPackage'>
                    <div style={{ display: 'flex' }}>
                        {
                            Array.isArray(couponPackage) && couponPackage.map((item, index) => {
                                if (item.display_status === '0' && !item.ignore_get_enable) {
                                    return (
                                        <BlackGoldCouponItem
                                            key={index}
                                            couponItem={item}
                                            onClick={() => {
                                                onAddCoupon(item)
                                            }}
                                            onClickMyCoupon={() => {
                                                if (accountID) {
                                                    // 获取我的优惠券
                                                    this.getMycoupon()
                                                    this.setState({ couponListVisible: true })
                                                } else {
                                                    updateState({ isShowTip: true })
                                                }
                                            }}
                                            type='restaurant'
                                        />
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </div>
                </div>
                {/* 特色菜 */}
                {_.isArray(specialMenuDish) && specialMenuDish.length > 0 &&
                    <div className='featuredDishesTitle'>{i18n.t('restaurant_info_page_text_featured_dishes')}</div>}
                <div className='featuredDishes'>
                    <div className='featuredDishesLayout'>
                        {specialMenuDish.map((dishItem, index) => {
                            return (
                                <FeaturedDishItem
                                    key={index}
                                    dishItem={dishItem}
                                    onClick={() => { this.handleAddCart(dishItem) }}
                                />
                            )
                        })}
                    </div>
                </div>

                {/* 我的优惠券 */}
                {couponListVisible ?
                    <BlackGoldCouponList
                        restaurantStringId={restaurantStringId}
                        couponPackage={myCouponPackage}
                        onCancel={() => { this.setState({ couponListVisible: false }) }}
                        type={'myCoupon'}
                        className='myCoupon'
                        onSucceeded={async () => {
                            await getCustomerInfo()
                            this.getMycoupon()
                        }}
                    />
                    : null}
            </div>
        )
    }
}
export default BlackGoldThemeRestaurantPage

// *********************
// Types
// *********************

type Props = {
    params: RestaurantPageState;
    updateState: (value: any) => void;
    onPaper: (item: Bardatatype, index: number) => void;
    onOrder: (value: string) => void;
    onInsertCodeCoupon: (value: string) => void;
    onScanCodeCoupon: (value: string) => void;
    onAddCoupon: (value: any) => void;
    todyOpenTime: false | {
        restaurantTakeawayServiceStatus: boolean;
        isAllDayOpen: boolean;
        newTimeRanges: any;
    };
    getCustomerInfo: () => void;
}

type State = {
    couponListVisible: boolean;
    myCouponPackage: any;
    specialMenuDish: Dish[];
}
