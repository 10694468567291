import React from 'react';
import '../component/checkout/scss/checkout.styles.scss';

/**
 * 输入框组件
 */

export default class InputComponent extends React.Component<Props, State> {

    public render() {
        const { style, disabled, className, value, type, label, blurEvent } = this.props;
        return (
            <input
                className={className ?? 'input-component'}
                style={style}
                type={type}
                value={value}
                placeholder={label}
                disabled={disabled}
                onChange={(val) => {
                    if (typeof this.props.onChange === 'function') {
                        this.props.onChange(val.target.value)
                    }
                }}
                onBlur={(e) => blurEvent?.(e.target.value)}
            />
        )
    }
}


// **************
// TYPE
// **************

type State = {};

type Props = {
    className?: string;
    style?: object;
    value?: string | number;
    label?: string;
    type?: string;
    disabled?: boolean;
    onChange?: (val: string | number) => void;
    blurEvent?: (inputVal: string) => void
};