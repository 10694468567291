import { Button, Input } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import MotorcycleOutlinedIcon from '@material-ui/icons/MotorcycleOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import AlloyFinger from 'alloyfinger';
import BScroll from 'better-scroll';
import { createHashHistory } from 'history';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import * as React from 'react';
import { analyzeRestaurantSchedule } from '../component/restaurant/analyzeRestaurantSchedule.tool';
import ScanCode from '../component/scan-code/scan.component';
import TopBar from '../component/top-bar/top-bar.component';
import i18n from '../i18n';
import { Customer, Schedule, Address, Restaurant, SettingClosedSchedule } from '../models';
import apiManage from '../request';
import Store from '../rudex/store';
import '../static/css/ordelivery.scss';
import qrCodeBlack from '../static/images/baseline_center_focus_strong_black_48dp.png';
import searchBlack from '../static/images/baseline_search_black_48dp.png';
import tuneBlack from '../static/images/baseline_tune_black_48dp.png';
import keyBlack from '../static/images/baseline_vpn_key_black_48dp.png';
import RoomIcon from '@material-ui/icons/Room';
import { MdKeyboardArrowDown } from 'react-icons/md';
import DeliveryAddress from '../component/account/delivery-address.component';
// import { currentPositioning } from '../constants/tool-googlemap';
import { checkToken, cleanAccountCache } from '../constants/tool-check-token';
import { LOCAL_STORAGE_KEYS } from '../constants/local-storage.constant';
import { LocalStorageManager } from '../constants/tool-local-storage';
import { inject_unount } from '../component/decorators/helper.decorator';
import Notification from '../component/snackbar/snackbar';
import { getMessages } from '../tool/notices.tool';
import moment from 'moment';
import { businessSchedule, getTargetDateClosedSchedule } from '../component/restaurant/schedule-dialog.component';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import PersonIcon from '@material-ui/icons/Person';
import { checkRoute } from '../constants/checkRoute';
@inject_unount
class Ordelivery extends React.Component<Props, State> {
    // 二维码
    private qrCodeScanning: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            touchTop: 250,
            throttleFlag: true,
            startClientY: 0,    // 滑动的起始位置
            openDialog: false,
            openFilterDialog: false,
            insertCode: '',
            searchViewHeight: 0,
            filterLabeld: [],
            selectFilterLabeld: [],
            loading: false,
            customerInfo: {},
            filterBrowsingHistory: [],
            filterHistoryRestaurant: [],
            browsingHistory: [],
            filterTagsNumber: 0,
            scheduleTime: [],
            searcHistory: [],
            goBackDialog: false,
            isShowScanCode: 0,
            customerAddress: [],
            saveCurrentAddress: {},
            isShowAddress: false,
            currentPosition: {
                lat: 0,
                lng: 0
            }
        }
    }
    // 头部ref
    public historyToolRef: any
    public qrUploadRef: any
    public historyDom: any
    public qrScanner: any
    public historyScroll: any

    // ***************
    //  生命周期
    // ***************

    public async UNSAFE_componentWillMount() {
        // 检查是否是商家地址
        const merchantWebsite = _.get(Store.getState(), 'merchantWebsite');
        const currentResStringID = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
        if (merchantWebsite) {
            createHashHistory().push(`/restaurantPage?string_id=${currentResStringID}&merchant_website=true`)
            return
        }


        // 检查token是否失效
        setTimeout(() => {
            const isTokenInvalid = checkToken();
            if (isTokenInvalid) {
                Notification.notification(i18n.t('general_text_Not_logged_in_for_a_long_time_need_to_log_in_again'), { autoHideDuration: 5000 });
            }
        }, 1000);
        // 获取当前位置
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const newlat = Number(position.coords.latitude)
                const newlng = Number(position.coords.longitude)
                this.setState({
                    currentPosition: {
                        lat: newlat,
                        lng: newlng
                    }
                })
            }, () => {
                this.setState({
                    currentPosition: false
                })
            });
        } else {
            this.setState({
                currentPosition: false
            })
        }
        const SEARCH_HISTORY = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SEARCH_HISTORY);
        if (SEARCH_HISTORY) this.setState({ searcHistory: _.isArray(SEARCH_HISTORY) ? SEARCH_HISTORY : [] });

        const ACCOUNT = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        if (ACCOUNT) { this.setState({ customerInfo: { _id: ACCOUNT._id } }) }
    }

    async componentDidMount() {

        // $ 把内嵌网址内存值改成false(防止开着内嵌网址，又开正常网址，值会错乱)
        Store.dispatch({
            type: 'SET_STATE',
            path: 'merchantWebsite',
            value: false
        })
        // 添加监听: 移动端物理返回按钮
        this.watchBack();

        const ACCOUNT = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        // 检测路由参数
        const isReplaceRoute = await checkRoute(ACCOUNT);
        if (isReplaceRoute) {
            return
        }
        let customerId = '';
        if (ACCOUNT) { customerId = ACCOUNT._id }

        // 如果没有账号id，也不需要请求数据了
        if (!customerId) {
            this.cleanCachingAndDisconnect()
            return
        }

        // 有账号id，请求数据
        const customerInfo: any = await apiManage.get_customer_info(customerId);

        // 错误捕捉
        if (customerInfo.error) return;
        // 获取通知信息
        getMessages(_.get(customerInfo, 'messages', 0));
        // 把个人信息的存在存到缓存中
        this.setCustomerInfo(customerInfo)
        if (Object.keys(customerInfo).length === 0) {
            this.cleanCachingAndDisconnect()
            return
        }
        let labelFilter: string[] = [];
        // 餐馆标签过滤源处理
        if (customerInfo) {
            if (customerInfo.restaurant_string_ids) {

                let brownHistory = customerInfo.restaurant_string_ids || [];
                brownHistory = _.filter(brownHistory, (n) => { return !n.is_deleted })
                brownHistory.forEach((item) => {
                    if (item.label) labelFilter = labelFilter.concat(item.label)
                })
                const newLabelFilter = Array.from(new Set(labelFilter))

                // 提前给brownHistory的数组对象一个 logo占位
                brownHistory = brownHistory.map(item => {
                    // item.logo = '';
                    item.isWithin = true;
                    return item
                })
                let newAddress = customerInfo.address || []
                if (customerInfo.address && customerInfo.address.length > 0) {
                    newAddress = customerInfo.address.sort(this.compare)
                }
                // 用户没有地址时，获取当前定位地址
                // const { currentPosition } = this.state
                // let currentPositionResult
                // $ 暂时不需要
                // if (newAddress && newAddress.length === 0 && currentPosition) {
                //     const { lat, lng } = currentPosition
                //     currentPositionResult = await currentPositioning(lat, lng) || {}
                // }
                const saveCurrentAddress = _.find(newAddress, (val) => {
                    return val.default
                }) || {};
                this.setState({
                    filterLabeld: newLabelFilter,
                    browsingHistory: brownHistory,
                    filterBrowsingHistory: brownHistory,
                    customerInfo,
                    customerAddress: newAddress,
                    saveCurrentAddress
                }, async () => {
                    // $ 单独获取餐馆图片
                    const idList = brownHistory.map(item => {
                        return item._id
                    })
                    if (idList && idList.length > 0) {
                        const params = {
                            restaurantIds: idList
                        }
                        await this.getHistoryRestaurantLogo(params)
                    }
                    // ! 暂时注释，不需要显示是否在配送范围
                    // 开启isShowLoadScript需要时间
                    // setTimeout(async () => {
                    //     // 计算地址是否在餐馆范围内
                    //     const { saveCurrentAddress, filterBrowsingHistory } = this.state
                    //     if (saveCurrentAddress && Object.keys(saveCurrentAddress).length > 0 && filterBrowsingHistory) {
                    //         let result = await checkRestaurants(saveCurrentAddress, filterBrowsingHistory)
                    //         this.setState({
                    //             browsingHistory: result,
                    //             filterBrowsingHistory: result,
                    //         }, () => {
                    //             let findResult = this.findRestaurantLable()
                    //             if (findResult.length > 0) {
                    //                 this.setState({ browsingHistory: findResult.sort(this.compareRestaurant) })
                    //             } else {
                    //                 this.setState({
                    //                     browsingHistory: filterBrowsingHistory.sort(this.compareRestaurant)
                    //                 })
                    //             }
                    //         })
                    //     }
                    // }, 2000);
                });
            }
        }
        // 初始化手势库
        this.initAlloyFinger();
        // 初始化滚动条
        this.initBetterScroll();
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.backNative, false); // false阻止默认事件
        window.removeEventListener('popstate', this.watchBack, false); // false阻止默认事件
        this.setState = () => { return; };
        // 注销滚动事件
        if (this.historyScroll) this.historyScroll.destroy()
    }
    // ***************
    // 方法区
    // ***************

    /**
     * 清缓存
     */
    cleanCachingAndDisconnect() {
        cleanAccountCache();
    }
    /**
     * 获取URL问号后的参数值
     * @param name
     */
    public getQueryString() {
        const regular = /(string_id=[0-9a-zA-Z]{5})/gi
        const Url = window.location.href;
        const r = Url.match(regular);
        if (r != null) {
            return unescape(r[0]).split('=')[1];
        }
        return '';
    }
    /**
     * 获取到所有的历史餐厅的logo
     * @params 传递的参数
     */
    async getHistoryRestaurantLogo(params: any) {
        try {
            let historyRestaurantsLogo: Restaurant[] = []
            let result: any = []
            if (Array.isArray(params.restaurantIds) && params.restaurantIds.length > 0) {
                result = await apiManage.get_history_restaurant_logo(params, {
                    notNeedLoading: true
                });
                historyRestaurantsLogo = result
            }
            const browsingHistory: Restaurant[] = _.cloneDeep(this.state.browsingHistory);
            historyRestaurantsLogo.forEach(item => {
                browsingHistory.forEach(i => {
                    if (i._id === item._id) {
                        i.logo = item.logo;
                    }
                })
            })
            this.setState({
                browsingHistory,
                filterBrowsingHistory: browsingHistory
            })
        } catch (err) {
            console.log(err, 'errerr')
        }
    }

    /**
     * 监听移动端物理返回按钮
     */
    watchBack = () => {
        // 禁用页面前进后退
        // 如果支持 popstate （一般移动端都支持）而且只有再扫码也的时候才生效
        if (window.history && window.history.pushState
            && (window.location.hash === '#/scanCodePage' || window.location.hash === '#/')) {
            // 往历史记录里面添加一条新的当前页面的url
            window.history.pushState(null, null as any, document.URL);
            // 给 popstate 绑定一个方法 监听页面刷新
            window.addEventListener('popstate', this.backNative, false); // false阻止默认事件
        }
    }
    backNative = () => {
        // 点击物理返回键要执行的操作
        this.watchBack();
    }
    /**
     * 初始化手势库
     */
    public initAlloyFinger() {
        try {
            new AlloyFinger(this.historyToolRef, {
                touchStart: (evt: any) => {
                    this.handleTouchStart(evt)
                },
                touchMove: (evt: any) => {
                    this.handleTouchMove(evt)
                },
                touchEnd: () => {
                    this.handleTouchEnd()
                }
            });
        } catch (err) {

        }

    }
    // 触摸开始
    public handleTouchStart(evt: any) {
        const startClientY = evt.changedTouches[0].clientY;
        this.setState({
            throttleFlag: true,
            startClientY
        })
    }

    // 触摸移动过程
    public handleTouchMove(evt: any) {
        if (!this.state.throttleFlag) return
        // 获取到滑动的Y
        const maxHeight = document.body.clientHeight - 60;
        let clientY: number = evt.changedTouches[0].clientY;
        // 改变该区域的top值
        clientY = 70 + clientY - 80;
        if (clientY < 60 || clientY > maxHeight) return;
        this.updateState({
            touchTop: clientY
        })
        this.setState({
            throttleFlag: false
        }, () => {
            setTimeout(() => {
                this.setState({
                    throttleFlag: true
                })
            }, 100);
        })
    }
    // 触摸结束
    public handleTouchEnd() {
        if (this.state.touchTop >= 120 && this.state.touchTop <= 250) {
            this.updateState({
                touchTop: 250
            })
        } else if (this.state.touchTop <= 120) {
            this.updateState({
                touchTop: 60
            })
        } else {
            this.updateState({
                touchTop: 250
            })
        }

        // 初始化滚动条
        this.initBetterScroll();
    }

    public insertCode = '';

    updateState = (value: any) => {
        this.setState(value)
    }


    // 获取餐馆信息
    public searchRestaurant(string_id: string) {
        let stringID = string_id.replace(/\s+/g, '');
        stringID = stringID.toLocaleUpperCase();
        if (stringID) {
            createHashHistory().push({ pathname: `/restaurantPage?string_id=${stringID}` })
        }
    }

    public isSelected(val: string) {
        for (const value of this.state.selectFilterLabeld) {
            if (value === val) {
                return true;
            }
        }
        return;
    }

    public handleClose = (val: any) => {
        this.setState(val)
    }

    /**
     * 查找和筛选餐馆
     * @param filterSource 餐馆数据源
     */
    public filterRestaurants(filterSource: Restaurant[]) {
        const regStr = new RegExp(this.insertCode.trim(), 'gi');
        return _.filter(filterSource, (n) => {
            return regStr.test(n.nickname || '')
        });
    }

    /**
     * @param takeawayServiceStatus 营业总开关
     */
    public getBusinessHours(serviceSchedule: Array<Schedule>, takeawayServiceStatus: boolean, settingClosedSchedule: SettingClosedSchedule[]) {
        serviceSchedule = this.updateScheduleData(serviceSchedule, settingClosedSchedule);
        const resultBusinessHours = analyzeRestaurantSchedule(serviceSchedule);
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }} >
                <div className='text'>
                    {
                        (() => {
                            // 营业总开关打开
                            if (takeawayServiceStatus) {
                                // 全天营业
                                if (resultBusinessHours.isAlldayOpen) {
                                    return `${i18n.t('restaurant_info_page_restaurant_open')}`
                                }
                                // 不是全天营业 && 现在不营业 && 没有下一个营业时间  则属于停业
                                else if (!resultBusinessHours.isRestaurantOpen && !resultBusinessHours.isAlldayOpen &&
                                    !resultBusinessHours.nextOpenTimeText) {
                                    return `${i18n.t('restaurant_info_page_Takeaway_service_not_available')}`
                                }
                                // 有下个营业时间
                                else if (!resultBusinessHours.isRestaurantOpen
                                    && resultBusinessHours.nextOpenTimeText) {
                                    return resultBusinessHours.nextOpenTimeText
                                }
                                // 营业
                                else {
                                    return `${i18n.t('restaurant_info_page_restaurant_open')}`
                                }
                            }
                            // 营业总开关关闭
                            else {
                                return `${i18n.t('restaurant_info_page_Takeaway_service_not_available')}`
                            }
                        })()
                    }
                </div>
                <div className='startsIcon'>
                    {
                        (() => {
                            if (takeawayServiceStatus) {
                                // 不是全天营业 && 现在不营业 && 没有下一个营业时间  则属于停业
                                if (!resultBusinessHours.isRestaurantOpen && !resultBusinessHours.isAlldayOpen
                                    && !resultBusinessHours.nextOpenTimeText) {
                                    return (
                                        <div className='txs-end'>
                                            <RemoveCircleOutlineOutlinedIcon />
                                        </div>
                                    )
                                }
                                // 现在不营业 && 存在下一个营业时间
                                else if (!resultBusinessHours.isRestaurantOpen) {
                                    return (
                                        <div className='txs-end'>
                                            {
                                                resultBusinessHours.selfTakeStatus
                                                && <CardGiftcardOutlinedIcon className='endOfMeal' />
                                            }
                                            {
                                                resultBusinessHours.takeawayStatus
                                                && <MotorcycleOutlinedIcon className='endOfMeal' />
                                            }

                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className='txs-end'>
                                            {
                                                resultBusinessHours.selfTakeStatus
                                                && <CardGiftcardOutlinedIcon style={{ color: '#ffdc33' }} />
                                            }
                                            {
                                                resultBusinessHours.takeawayStatus
                                                && <MotorcycleOutlinedIcon style={{ color: '#ffdc33' }} />
                                            }

                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className='txs-end'>
                                        <RemoveCircleOutlineOutlinedIcon />
                                    </div>
                                )
                            }
                        })()
                    }
                </div>
            </div >
        );
    }

    /**
     * 把个人信息的存在存到缓存中
     * @param resultCustomer 个人信息
     */
    public setCustomerInfo(resultCustomer: Customer) {
        const account: Customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
        const customer: Customer = {
            ...account,
            _id: resultCustomer._id,
            nickname: resultCustomer.nickname,
            phone: resultCustomer.phone,
            phone_prefix: resultCustomer.phone_prefix,
            email: resultCustomer.email,
            first_name: resultCustomer.first_name,
            last_name: resultCustomer.last_name
        }
        LocalStorageManager.setLocalStorage({
            path: LOCAL_STORAGE_KEYS.ACCOUNT,
            value: customer
        });
    }

    // 添加滚动插件，解决滑动问题
    initBetterScroll() {
        if (this.historyScroll) {
            this.historyScroll.destroy()
        }
        try {
            const wrapper: any = this.historyDom;
            this.historyScroll = new BScroll(wrapper, {
                click: true, // 因为betterscroll默认会阻止点击事件。这里要设置一下
                mouseWheel: true, // 鼠标滚轮滚动
                probeType: 3,
                scrollY: true,
                preventDefault: false // 阻止了浏览器默认选中行为
            })
        } catch (err) {
        }
    }
    // 数组对象排序处理方法
    public compare(preAddress: Address) {
        if (preAddress.default) return -1;
        return 1;
    }
    // 数组对象排序处理方法(餐馆在范围内的在上面)
    public compareRestaurant(restaurants) {
        if (restaurants.isWithin) return -1;
        return 1;
    }
    // 选择地址后计算 地址是否在餐厅配送范围内
    public onAddressConfirm(customerInfo, status) {
        const addressCardInfo = (customerInfo !== null || !customerInfo) ? customerInfo.address : [];
        const newAddress = addressCardInfo.sort(this.compare)
        const saveCurrentAddress = _.find(newAddress, (val) => {
            return val.default
        }) || {};
        this.setState({
            customerAddress: newAddress,
            saveCurrentAddress,
            customerInfo,
            isShowAddress: status
        }, () => {
            // ! 暂时注释，不需要显示是否在配送范围
            // 计算地址是否在餐馆范围内
            // const { saveCurrentAddress, filterBrowsingHistory } = this.state
            // if (saveCurrentAddress && Object.keys(saveCurrentAddress).length > 0 && filterBrowsingHistory) {
            //     // 开启isShowLoadScript需要时间
            //     const { google_map_lat, google_map_lng } = saveCurrentAddress
            //     if (google_map_lat && google_map_lng) {
            //         setTimeout(async () => {

            //             let result = await checkRestaurants(saveCurrentAddress, filterBrowsingHistory)
            //             this.setState({
            //                 browsingHistory: result,
            //                 filterBrowsingHistory: result,
            //             }, () => {
            //                 let findResult = this.findRestaurantLable()
            //                 if (findResult.length) {
            //                     this.setState({ browsingHistory: findResult.sort(this.compareRestaurant) })
            //                 } else {
            //                     this.setState({ browsingHistory: filterBrowsingHistory.sort(this.compareRestaurant) })
            //                 }
            //             })
            //         }, 2000);
            //     }
            // }

        });
    }
    /**
     * 更换当前地址
     */
    public onSelectAddress() {
        const { customerInfo } = this.state
        if (_.get(customerInfo, '_id')) {
            this.setState({ isShowAddress: true })
        } else {

        }

    }
    /**
     * 找出符合标签的餐馆
     */
    public findRestaurantLable() {
        const { filterBrowsingHistory, selectFilterLabeld } = this.state
        let restaurantFindArr: any[] = []
        filterBrowsingHistory.forEach((historyItem) => {
            // 拿选中的跟餐馆的label做比较
            selectFilterLabeld.forEach((selectLabel) => {
                if (historyItem.label) {
                    if (_.includes(historyItem.label, selectLabel)) {
                        restaurantFindArr.push(historyItem)
                    }
                }
            })
        })
        restaurantFindArr = _.uniqWith(restaurantFindArr, _.isEqual);

        return restaurantFindArr
    }

    /**
     * 分析营业时间表和闭店时间表，返回新的营业时间表
     */
    updateScheduleData(serviceSchedule, settingClosedSchedule) {
        try {
            let newScheduleData = _.cloneDeep(serviceSchedule);
            const todyIsoWeekday = moment(new Date()).isoWeekday();
            // 排序（今天放在第一位）
            newScheduleData = newScheduleData.sort((n, m) => {
                const position = (n.week - todyIsoWeekday) < 0 ? n.week + 7 : n.week;
                const nextPosition = (m.week - todyIsoWeekday) < 0 ? m.week + 7 : m.week;
                return position - nextPosition;
            })
            // 设置新的营业时间表数据
            newScheduleData = newScheduleData.map((n, index) => {
                // 周几
                const todyIsoWeekday = moment(new Date()).add(index, 'day').isoWeekday();
                if (n.week === todyIsoWeekday) {
                    // 年月日
                    const targetDate = moment(new Date()).add(index, 'day').format('YYYY-MM-DD')
                    // 目标日期的营业时间段
                    const dateTimeRanges: Schedule = _.find(newScheduleData, (item) => { return item.week === todyIsoWeekday }) || {};
                    // 目标日期的营业时间段（是否是全体营业）
                    const timeRanges = dateTimeRanges.time_24_hours ? [{
                        start_time: '00:00:00',
                        end_time: '23:59:00',
                        selftake_status: dateTimeRanges.selftake_status,
                        takeaway_status: dateTimeRanges.takeaway_status
                    }] : (dateTimeRanges.time_ranges || [])
                    // 目标日期，在休业时间内，则24小时开关为false
                    const hasClose = _.find(settingClosedSchedule, (v) => {
                        return moment(targetDate, 'YYYY-MM-DD').isBetween(
                            moment(v.start_date, moment.HTML5_FMT.DATETIME_LOCAL_MS),
                            moment(v.end_date, moment.HTML5_FMT.DATETIME_LOCAL_MS),
                            'day',
                            '[]'
                        )
                    })
                    if (hasClose) {
                        _.set(n, 'time_24_hours', false)
                    }
                    // 最终显示的营业时间表（对应的周几）
                    const finalTimeRanges = businessSchedule(timeRanges, getTargetDateClosedSchedule(targetDate, settingClosedSchedule))
                    _.set(n, 'time_ranges', finalTimeRanges)
                }
                return n;
            })
            // 数组恢复原来的位置（周一第一位）
            newScheduleData = newScheduleData.sort((n, m) => {
                return n.week - m.week;
            })
            return newScheduleData
        } catch (error) {
            console.log(error);
        }
    }

    // ***************
    // VIEW
    // ***************

    // TODO 唤起扫码暂时关闭
    handleCamera() {
        this.updateState({
            isShowScanCode: 1
        })
    }


    public render() {
        const {
            touchTop, openDialog, insertCode, searchViewHeight, openFilterDialog,
            filterLabeld, loading, browsingHistory, filterTagsNumber, filterBrowsingHistory,
            searcHistory, goBackDialog, isShowScanCode, saveCurrentAddress, isShowAddress,
            customerInfo, filterHistoryRestaurant
        } = this.state;
        const isLogin = Object.keys(customerInfo).length > 0
        return (
            <div className='orderlivery-main' >
                <div className={loading ? 'loading' : 'loading-hide'}>
                    <CircularProgress style={{ color: '#ffdc33' }} />
                </div>
                {/* 后退提示 */}
                <IsToLogin goBackDialog={goBackDialog} updateState={(val) => {
                    this.setState({
                        goBackDialog: val
                    })
                }} />
                {/* 弹窗code */}
                <Dialog
                    open={openDialog}
                    onClose={() => {
                        this.handleClose({
                            openDialog: false
                        })
                    }}
                    className='orderliveryDialogModal'
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogContent>
                        <div className='dialogContent'>

                            <div className='dialogName'>
                                <div className='fontStyle'>
                                    {i18n.t('scan_reslist_page_text_restaurant_code')}
                                    <div className='close' onClick={() => {
                                        this.handleClose({
                                            openDialog: false
                                        })
                                    }}>
                                        <ClearIcon className='clearIcon' />
                                    </div>
                                </div>

                            </div>
                            <div className='insertCode orderlivery-bottom-96'>
                                <Input
                                    value={insertCode}
                                    rows={2}
                                    disableUnderline
                                    fullWidth
                                    onChange={(event) => {
                                        this.setState({
                                            insertCode: event.target.value
                                        })
                                    }}
                                />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button className='insertCodebtn ok'
                                    onClick={() => {
                                        if (insertCode) this.searchRestaurant(insertCode)
                                    }}>
                                    {i18n.t('general_text_ok')}
                                </Button>
                                <Button className='insertCodebtn cancel'
                                    onClick={() => {
                                        this.handleClose({
                                            openDialog: false
                                        })
                                    }}>
                                    {i18n.t('general_text_cancel')}
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

                {/* 弹窗 Filter */}
                <Dialog
                    open={openFilterDialog}
                    onClose={() => {
                        this.handleClose({
                            openFilterDialog: false
                        })
                    }}
                    className='orderliveryDialogModal'
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogContent>

                        <div className='dialogContent'>

                            <div className='dialogName'>
                                <div className='fontStyle'>
                                    {i18n.t('general_text_filter')}
                                    <div className='close' onClick={() => {
                                        this.handleClose({
                                            openFilterDialog: false
                                        })
                                    }}>
                                        <ClearIcon className='clearIcon' />
                                    </div>
                                </div>

                            </div>
                            {/* 清空选中所有过滤的标签 */}
                            <div className='clear-filter orderlivery-bottom-72 fontStyle' onClick={() => {
                                this.setState({
                                    selectFilterLabeld: [],
                                    filterTagsNumber: 0,
                                    browsingHistory: filterBrowsingHistory,
                                    filterHistoryRestaurant: filterBrowsingHistory
                                })
                            }}>{i18n.t('general_text_clear_all')}</div>
                            <div className='filter'>
                                <div className='filter-content'>
                                    {
                                        filterLabeld && filterLabeld.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className='filter-labeld'
                                                    style={this.isSelected(item) ? {
                                                        borderColor: '#5867B4',
                                                        color: '#5867B4',
                                                        backgroundColor: '#5867b426'
                                                    } : {}}
                                                    onClick={() => {
                                                        /**
                                                         *  check select filterLabeld
                                                         */
                                                        // tslint:disable-next-line: max-line-length
                                                        const selectFilterLabeld: any[] = _.cloneDeep(this.state.selectFilterLabeld);
                                                        if (this.isSelected(item)) {
                                                            _.remove(selectFilterLabeld, (v) => {
                                                                return item === v;
                                                            })
                                                        } else {
                                                            if (item) selectFilterLabeld.push(item);
                                                        }

                                                        this.setState({
                                                            selectFilterLabeld,
                                                            filterTagsNumber: selectFilterLabeld.length
                                                        }, () => {
                                                            const findResult = this.findRestaurantLable()
                                                            if (findResult.length > 0) {
                                                                this.setState({
                                                                    browsingHistory: findResult,
                                                                    filterHistoryRestaurant: findResult
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    browsingHistory: filterBrowsingHistory,
                                                                    filterHistoryRestaurant: filterBrowsingHistory
                                                                })
                                                            }
                                                        });


                                                    }}
                                                >{item}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                {/* Top bar */}
                <TopBar
                    title={i18n.t('scan_reslist_page_text_application_name')}
                    isHideGoback={true} />
                {/* QR code && Insert code */}
                <div className={`code ${isLogin ? '' : 'logout-code'}`}>
                    <Button className='code-btn qr-code-btn'
                        onClick={() => {
                            // TODO 唤起扫码暂时关闭
                            // this.handleCamera()
                        }}>
                        <div className='qr-code'>
                            <img className='qrCodeImg' src={qrCodeBlack} alt='qrCode' width='70' />
                            <div className='code-text' style={{ color: '#BCBCBC' }}>{i18n.t('scan_reslist_page_button_qr_code')}</div>
                        </div>
                    </Button>
                    <Button className='code-btn'>
                        <div className='insert-code' onClick={() => {
                            this.setState({
                                openDialog: true,
                                insertCode: ''
                            })
                        }}>
                            <img src={keyBlack} alt='keyCode' width='70' />
                            <div className='code-text'>{i18n.t('scan_reslist_page_button_insert_Code')}</div>
                        </div>
                    </Button>
                </div>
                {/* 登入 */}
                {!isLogin && <div className='FCC no-login-box' onClick={() => { createHashHistory().push('/sign-in'); }}>
                    <div className='FCC no-login-icon'>
                        <PersonIcon className='no-login-icon-icon' />
                    </div>
                    <span className='login-text'>{i18n.t('signin_page_text_log_in_Register')}</span>
                    <div className='FCC no-login-icon-right'>
                        <RightIcon className='no-login-icon-right' />
                    </div>
                </div>}
                {/* 历史餐馆 */}
                <div className='history-restaurant' style={{ top: touchTop === 250 ? '70vw' : '60px', display: isLogin ? '' : 'none' }}>
                    <div className='history-restaurant-main'>
                        {/* 历史搜索 */}
                        <div
                            className={searchViewHeight ? 'searchView' : 'hideSearchView'}
                            style={{ height: searchViewHeight }}>
                            {/* Search Tool */}
                            <div className='search-tool'>
                                <div className='search-input margin-right'>
                                    <div onClick={() => {
                                        if (this.insertCode || this.insertCode === '') {
                                            let filterHistory
                                            // filterHistoryRestaurant是过滤出来的餐馆
                                            if (filterHistoryRestaurant.length === 0) {
                                                filterHistory = this.filterRestaurants(filterBrowsingHistory)
                                            } else {
                                                filterHistory = this.filterRestaurants(filterHistoryRestaurant);
                                            }
                                            // console.log(filterHistory, 'filterHistory');
                                            this.setState({
                                                browsingHistory: filterHistory,
                                                touchTop: 250,
                                                searchViewHeight: 0
                                            })
                                            if (this.insertCode) {
                                                const newSearchHistory = Array.from(new Set([this.insertCode, ...searcHistory]))
                                                this.setState({
                                                    searcHistory: newSearchHistory
                                                })
                                                LocalStorageManager.setLocalStorage({
                                                    path: LOCAL_STORAGE_KEYS.SEARCH_HISTORY,
                                                    value: newSearchHistory
                                                });
                                            }
                                        }
                                    }}>
                                        <img
                                            src={searchBlack}
                                            className='tool-icon search-tool-icon'
                                            alt='searchBlack' width='72' />
                                    </div>

                                    <Input
                                        defaultValue={this.insertCode}
                                        rows={2}
                                        disableUnderline
                                        fullWidth
                                        onChange={(event) => {
                                            try {
                                                this.insertCode = event.target.value.trim()
                                            } catch (error) {
                                            }
                                        }}
                                        onKeyUp={(event) => {
                                            if (event.keyCode === 13) {
                                                let filterHistory
                                                if (filterHistoryRestaurant.length === 0) {
                                                    filterHistory = this.filterRestaurants(filterBrowsingHistory)
                                                } else {
                                                    filterHistory = this.filterRestaurants(filterHistoryRestaurant);
                                                }

                                                this.setState({
                                                    browsingHistory: filterHistory,
                                                    touchTop: 250,
                                                    searchViewHeight: 0
                                                })
                                                if (this.insertCode) {
                                                    const newSearchHistory = Array.from(new Set([this.insertCode, ...searcHistory]))
                                                    this.setState({
                                                        searcHistory: newSearchHistory
                                                    })
                                                    LocalStorageManager.setLocalStorage({
                                                        path: LOCAL_STORAGE_KEYS.SEARCH_HISTORY,
                                                        value: newSearchHistory
                                                    });
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div className='tune-tool' onClick={() => {
                                    this.setState({
                                        openFilterDialog: true
                                    })
                                }}>
                                    {/* 当前筛选的提示气泡 */}
                                    {
                                        filterTagsNumber !== 0 && <div className='cue-bubble'>{filterTagsNumber}</div>
                                    }
                                    <img src={tuneBlack} className='tool-icon' alt='tuneBlack' width='72' />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => {
                                    this.setState({
                                        touchTop: 250,
                                        searchViewHeight: 0
                                    })
                                }}>
                                    <ClearIcon className='clearIcon tool-icon margin-left' />
                                </div>
                            </div>
                            {/* Recently Searched */}
                            <div className='recently-searched'>
                                <div className='fontStyle description-title'>
                                    {i18n.t('general_text_recently_searched')}
                                </div>
                                <div className='fontStyle clear-all' onClick={() => {
                                    this.setState({ searcHistory: [] })
                                    localStorage.removeItem(LOCAL_STORAGE_KEYS.SEARCH_HISTORY)
                                }}>
                                    {i18n.t('general_text_clear_all')}
                                </div>
                            </div>
                            {/* recently-searched List */}
                            <div className='recently-searched-list'>
                                {
                                    _.isArray(searcHistory) && searcHistory.map((item, index) => {
                                        return (
                                            <div key={index} className='recently-history' onClick={() => {
                                                this.insertCode = item;
                                                // const filterHistory = this.filterRestaurants(filterHistoryRestaurant);
                                                let filterHistory
                                                if (filterHistoryRestaurant.length === 0) {
                                                    filterHistory = this.filterRestaurants(filterBrowsingHistory)
                                                } else {
                                                    filterHistory = this.filterRestaurants(filterHistoryRestaurant);
                                                }
                                                this.setState({
                                                    browsingHistory: filterHistory,
                                                    touchTop: 250,
                                                    searchViewHeight: 0
                                                })
                                            }}>
                                                <div className='fontStyle recently-history-tit'>{item}</div>
                                                <div onClick={(e) => {
                                                    e.stopPropagation()
                                                    const removeSearchHistory = _.remove(searcHistory, (n) => {
                                                        return n !== item
                                                    })
                                                    this.setState({ searcHistory: removeSearchHistory })
                                                    LocalStorageManager.setLocalStorage({
                                                        path: LOCAL_STORAGE_KEYS.SEARCH_HISTORY,
                                                        value: removeSearchHistory
                                                    });
                                                }}>
                                                    <ClearIcon className='clearIcon tool-icon' />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {/* 历史餐馆工具栏 */}
                        <div className='history-restaurant-tool-box'>
                            <div className='history-restaurant-tool'
                                ref={(historyToolRef: any) => { this.historyToolRef = historyToolRef }}>
                                <div onClick={() => {
                                    this.setState({
                                        touchTop: 60,
                                        searchViewHeight: 'auto'
                                    })
                                }}>
                                    <img src={searchBlack} className='tool-icon' alt='searchBlack' width='72' />
                                </div>
                                <div
                                    style={{ width: 100 }}
                                    onClick={() => {
                                        this.setState({
                                            touchTop: touchTop === 60 ? 250 : 60
                                        })
                                    }}
                                >
                                    {
                                        touchTop === 60 ?
                                            <ExpandMoreIcon className='orderlivery-expand' />
                                            :
                                            <ExpandLessIcon className='orderlivery-expand' />
                                    }

                                </div>
                                <div className='tune-tool' onClick={() => {
                                    this.setState({
                                        openFilterDialog: true
                                    })
                                }}>
                                    {/* 当前筛选的提示气泡 */}
                                    {
                                        filterTagsNumber !== 0 && <div className='cue-bubble'>{filterTagsNumber}</div>
                                    }
                                    <img src={tuneBlack} className='tool-icon' alt='tuneBlack' width='72' />
                                </div>
                            </div>
                        </div>
                        <div className='history-restaurant-title-box'>
                            <div className='history-restaurant-title'>
                                <div>{i18n.t('general_text_history_record')}</div>
                                <div className='currentAddress'
                                    onClick={() => {
                                        this.onSelectAddress()
                                    }}>
                                    <span className='currentAddressIcon'>
                                        <RoomIcon />
                                    </span>
                                    <span className='currentAddressText'>{`${(saveCurrentAddress && saveCurrentAddress.street) || ''}`}</span>
                                    <span className='currentAddressIcon'>
                                        <MdKeyboardArrowDown />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* 历史列表 */}
                        <div
                            className='history-restaurant-scroll-list'
                            ref={(historyDom) => { this.historyDom = historyDom }}
                        >
                            <div className='history_wrapp'>
                                {
                                    browsingHistory.length ?
                                        browsingHistory.map((item, index) => {
                                            return (
                                                <div key={index} className='history-restaurant-list'>
                                                    <div className='history-restaurant-list-main'>
                                                        <div className='restaurant-list-icon'>
                                                            <img src={item.logo} width='70' alt='' />
                                                        </div>
                                                        <div className='restaurant-list-info' onClick={() => {
                                                            if (item.string_id) {
                                                                this.searchRestaurant(item.string_id)
                                                            }
                                                        }}>
                                                            <div className='restaurant-list-info-tit'>
                                                                {item.nickname}
                                                            </div>
                                                            <div className='restaurant-list-info-mark'>
                                                                {
                                                                    // tslint:disable-next-line: max-line-length
                                                                    item.label && item.label.map((lableVal: string, index: number) => {
                                                                        return (
                                                                            <div key={index} className='mark-remark'>
                                                                                {lableVal}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div className='restaurant-list-info-startsTime'>
                                                                {/* 获取营业时间 */}
                                                                {
                                                                    // tslint:disable-next-line: max-line-length
                                                                    this.getBusinessHours(
                                                                        item.setting_app_takeaway_service_schedule || [],
                                                                        item.setting_app_takeaway_service_status || false,
                                                                        item.setting_closed_schedule || []
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* // ! 暂时注释，不需要显示是否在配送范围 */}
                                                    {/* {!item.isWithin &&
                                                        <div className='isNotWithinBox'>
                                                            <span className='isNotWithin'>
                                                                {i18n.t('scan_reslist_page_the_current_address_is_not_within_the_scope_of_shipping')}
                                                            </span>
                                                        </div>} */}
                                                </div>
                                            )
                                        }) :
                                        <div className='flex-center fontStyle-size-400'>{i18n.t('general_text_no_relevant_data')}</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* 扫码弹窗 */}
                {
                    isShowScanCode !== 0 ?
                        <ScanCode handleCloseScan={() => {
                            this.updateState({
                                isShowScanCode: 0
                            })
                        }} /> : null
                }
                {/* 修改/添加/确认 地址信息 */}
                {
                    isShowAddress &&
                    <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 9 }}>
                        <DeliveryAddress
                            type='scanCodePage'
                            customerInfo={customerInfo}
                            isGoBack={() => {
                                this.setState({ isShowAddress: false })
                            }}
                            changeStatus={(customerInfo, status) => {
                                this.onAddressConfirm(customerInfo, status)
                            }}
                            restaurantTheme={'FIRST_THEME'}
                        />
                    </div>
                }
            </div >
        )
    }

}


export default withSnackbar(Ordelivery as any) as any;

function IsToLogin(
    props: {
        goBackDialog: boolean;
        updateState: (val: boolean) => void;
    }
) {
    return (
        <div>
            {/* 弹窗code */}
            <Dialog
                open={props.goBackDialog}
                onClose={() => {
                    props.updateState(false)
                }}
                className='goBackDialogModal'
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogContent>
                    <div className='dialogContent'>
                        <div className='dialogName'>
                            <div className='fontStyle'>
                                {i18n.t('scan_reslist_page_sign_out_to_login_page')}
                                <div className='close' onClick={() => {
                                    props.updateState(false)
                                }}>
                                    <ClearIcon className='clearIcon' />
                                </div>
                            </div>
                        </div>

                        <div
                            className='orderlivery-bottom-72'
                            style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button className='confirm'
                                onClick={() => {
                                    cleanAccountCache();
                                    // createHashHistory().push('/signInPage');
                                }}>
                                {i18n.t('general_text_ok')}
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
// **********
// TYPE
// **********

type Props = {};

type State = {
    touchTop: number;
    // 节流开关
    throttleFlag: boolean;
    // 滑动的起始位置
    startClientY: number;
    openDialog: boolean;
    openFilterDialog: boolean;
    // 餐厅代码
    insertCode: string;
    // 搜索工具栏初始高
    searchViewHeight: number | string;
    // 过滤元
    filterLabeld: Array<string>;
    selectFilterLabeld: Array<any>;
    // 加载状态
    loading: boolean;
    // 用户信息
    customerInfo: Customer;
    // 用户浏览历史
    browsingHistory: Restaurant[];
    // 浏览历史（用于筛选）
    filterBrowsingHistory: Restaurant[];
    // 筛选的历史餐馆
    filterHistoryRestaurant: Restaurant[];
    // 当前筛选标签数
    filterTagsNumber: number;
    // 餐馆营业时间表
    scheduleTime: Array<Schedule>;
    // 用户的搜索历史
    searcHistory: Array<string>;
    // 后退提示
    goBackDialog: boolean;
    // 扫码开关  0 关闭  1打开
    isShowScanCode: number;
    // 顾客地址
    customerAddress: Array<Address>;
    // 现在选中的地址
    saveCurrentAddress: Address;
    // 显示地址
    isShowAddress: boolean;
    // 当前位置
    currentPosition: {
        lat: number,
        lng: number
    } | false;
}

