import React from 'react';
import moment from 'moment';
import i18n from '../../../i18n';
import { Schedule, SettingClosedSchedule } from '../../../models';
import { SwipeableDrawer } from '@material-ui/core';
import _ from 'lodash';
import './black-gold-schedule-dialog.style.scss'
import { businessSchedule, getTargetDateClosedSchedule } from '../schedule-dialog.component';

// 餐管营业时间表弹窗
export function BlackGoldScheduleDialog(
    props: {
        className?: string;
        open: boolean;
        handleClose: () => void;
        scheduledata: Array<Schedule>;
        isPickUp?: boolean;
        serviceAllBtn: boolean;
        settingClosedSchedule: SettingClosedSchedule[];
    }
) {
    const { open, handleClose, className, scheduledata, isPickUp, serviceAllBtn, settingClosedSchedule } = props
    function changeString(value?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | number) {
        let text = ''
        switch (value) {
            case 1:
                text = `${i18n.t('restaurant_info_page_text_monday')}`
                break;
            case 2:
                text = `${i18n.t('restaurant_info_page_text_tuesday')}`
                break;
            case 3:
                text = `${i18n.t('restaurant_info_page_text_wednesday')}`
                break;
            case 4:
                text = `${i18n.t('restaurant_info_page_text_thursday')}`
                break;
            case 5:
                text = `${i18n.t('restaurant_info_page_text_friday')}`
                break;
            case 6:
                text = `${i18n.t('restaurant_info_page_text_saturday')}`
                break;
            case 7:
                text = `${i18n.t('restaurant_info_page_text_sunday')}`
                break;
            default:
                break;
        }
        return text
    }
    /**
     * 1、先判断是否可配送或者可以自取 (时间表的要求)
     * 2、判断是否全体营业
     * 3、分时间断
     */
    return (
        <SwipeableDrawer
            className={`blackGoldScheduleDialog ${className || ''} `}
            open={open}
            onClose={handleClose}
            onOpen={() => { }}
            anchor={'bottom'}>
            <div className='blackGoldScheduleDialogContent' onClick={(evt) => { evt.stopPropagation() }}>
                <div className='linebox' onClick={() => { handleClose() }}><span className='line'></span></div>
                <div className='head'>{i18n.t('restaurant_info_page_text_schedule')}</div>
                <div className='dialogbody'>
                    <div>
                        {scheduledata.map((item: Schedule, index: number) => {
                            // 今天星期几
                            const todyIsoWeekday = moment(new Date()).isoWeekday();
                            // 之后星期几
                            const isoWeekday = moment(new Date()).add(index, 'day').isoWeekday();
                            // 目标日期的营业时间段
                            const dateTimeRanges: Schedule = _.find(scheduledata, (n) => { return n.week === isoWeekday }) || {};
                            // 目标日期
                            const targetDate = moment(new Date()).add(index, 'day').format('YYYY-MM-DD')
                            // 目标日期的营业时间段（是否是全体营业）
                            const timeRanges = dateTimeRanges.time_24_hours ? [{
                                start_time: '00:00:00',
                                end_time: '23:59:00',
                                selftake_status: dateTimeRanges.selftake_status,
                                takeaway_status: dateTimeRanges.takeaway_status
                            }] : (dateTimeRanges.time_ranges || [])
                            // 最终显示的营业时间表（对应的周几）
                            const finalTimeRanges = businessSchedule(timeRanges, getTargetDateClosedSchedule(targetDate, settingClosedSchedule))
                            // 在付款页面需要区分自取或配送
                            const filterFinalTimeRanges = _.filter(finalTimeRanges, (n) => {
                                return isPickUp === undefined ? (n.selftake_status || n.takeaway_status) : isPickUp === true ? n.selftake_status : n.takeaway_status
                            })

                            return (
                                <div className='scheduleBox' key={index}>
                                    <div className={`date ${index === 0 ? 'todyDate' : ''}`}>
                                        {todyIsoWeekday > isoWeekday ? i18n.t('restaurant_info_page_text_next_week') : ''}
                                        {` `}
                                        {index === 0 ? i18n.t('restaurant_info_page_text_today') : changeString(isoWeekday)}
                                    </div>
                                    <div className={`time ${index === 0 ? 'todyDate' : ''}`}>
                                        {/* 先判断判断是否可以配送和自取 */}
                                        {
                                            /**
                                             * 1、先判断是否可配送或者可以自取
                                             * 2、判断是否全体营业
                                             * 3、分时间段
                                             */
                                            // 如果设置一周不营业开关打开，那么 显示停业  最高级
                                            (!serviceAllBtn
                                                ? `${i18n.t('restaurant_info_page_restaurant_close')}`
                                                :
                                                finalTimeRanges.length > 0
                                                    ?
                                                    // 找出对应外卖方式（自取或配送）
                                                    (filterFinalTimeRanges.length > 0 ?
                                                        // 拆分成两两一组，便于处理显示
                                                        _.chunk(filterFinalTimeRanges, 2).map(
                                                            (timeItem, timeIndex) => {
                                                                return (
                                                                    <div key={timeIndex}>
                                                                        {timeItem.map((iitem: any, I) => {
                                                                            // 满足一整天的显示24小时营业
                                                                            if (iitem.start_time === '00:00:00' && iitem.end_time === '23:59:00') {
                                                                                return (
                                                                                    <span key={I} className='showTip'>
                                                                                        {i18n.t('general_text_all_day_business')}
                                                                                    </span>
                                                                                )
                                                                            }
                                                                            // 否则，显示设置的时间
                                                                            else {
                                                                                return (
                                                                                    <span key={I} className='showTip'>
                                                                                        {`${moment(iitem.start_time, 'HH:mm').format('HH:mm')}-${moment(iitem.end_time, 'HH:mm').format('HH:mm')}`}
                                                                                    </span>
                                                                                )
                                                                            }

                                                                        })}
                                                                    </div>
                                                                )
                                                            })
                                                        : <div>
                                                            <span className='showTip'>
                                                                {/* 要区分自取或配送，显示不可配送或不可自取 */}
                                                                {isPickUp === undefined
                                                                    ? i18n.t('payment_page_text_close_down')
                                                                    : isPickUp === true
                                                                        ? i18n.t('payment_page_text_takeaway_unavailable')
                                                                        : i18n.t('payment_page_text_delivery_unavailable')}
                                                            </span>
                                                        </div>)
                                                    : <div>
                                                        <span className='showTip'>
                                                            {/* 没有营业时间显示休息 */}
                                                            {i18n.t('payment_page_text_close_down')}
                                                        </span>
                                                    </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </SwipeableDrawer>
    )
}