import React from 'react';
import './reorder-dialog.scss'
import { Dialog, DialogContent, Button } from '@material-ui/core';
import i18n from '../../i18n';
import ClearIcon from '@material-ui/icons/Clear';

/**
 * 再次订单弹窗
 */
export function ReorderDialog(
    props: {
        className?: string;
        handleClose: () => void;
        onConfirm?: () => void;
        dishes: { name: string }[];
    }
) {
    const { className, handleClose, onConfirm, dishes } = props;
    return (
        <Dialog
            open={true}
            onClose={() => {
                handleClose()
            }}
            className={`ReorderDialog ${className}`}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                <div className='ReorderDialog-dialogContent'>

                    <div className='dialogName'>
                        <div className='fontStyle'>
                            {i18n.t('order_page_text_order_again')}
                            <div className='close' onClick={() => {
                                handleClose()
                            }}>
                                <ClearIcon className='clearIcon' />
                            </div>
                        </div>
                    </div>
                    <div className='content '>
                        <div>
                            <p className='content-title'>{i18n.t('order_page_text_There_are_changes_to_the_dishes_in_the_order_please_note')}：</p>
                        </div>
                        <div className='dish-text-box '>
                            {dishes?.map((item, index) => {
                                return (<p className='dish-text' key={`${index}`}>{item.name}</p>
                                )
                            })}
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button className='btn ok'
                            onClick={() => {
                                onConfirm?.();
                            }}>
                            {i18n.t('general_text_ok')}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}