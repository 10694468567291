import React from 'react';
import './menu-list.style.scss';
import AutoSizer from 'react-virtualized-auto-sizer';
import List from 'react-virtualized/dist/commonjs/List';
import { DishFixed } from '../../../models/fixed/dish.entity';
import { DishListItem, getDishNum } from '../order.component';
import i18n from '../../../i18n';
import _ from 'lodash';
import { Dish, Schedule } from '../../../models';
import { ScrollParams } from 'react-virtualized';

class MenuList extends React.Component<Props, State> {

    public AutoSizerRef: List | null = null;

    constructor(props: Props) {
        super(props);
        this._rowRenderer = this._rowRenderer.bind(this);
    }

    public state: State = {
    }

    // *********************
    // Life Cycle Function
    // *********************
    componentDidMount() {
    }

    // *********************
    // Default Function
    // *********************


    _rowRenderer({ index, key, style }: any) {

        const item: any = this.props.dishList[index] || {};
        const dishTitle = _.has(item, 'title_name');

        const { number: buyNumber, historyNumber } = getDishNum({ dishItem: item, saveShopCatDate: this.props.saveShopCatDate, orderInfo: {} });

        return (
            <div key={key} style={style} >
                {dishTitle ? <h6 className='menu-title-text'>{item.title_name === 'special_dish' ? i18n.t('restaurant_info_page_text_featured_dishes') : item.title_name}</h6> : null}
                {!dishTitle && <DishListItem
                    itemData={item}
                    updateShopCatList={(itemData, status) => {
                        this.props.updateShopCatList(itemData, status)
                    }}
                    number={buyNumber}
                    historyNumber={historyNumber}
                    onshowDishDetails={(dishes) => this.props?.onshowDishDetails?.(dishes)}
                    choosePackageMeal={(itemData: Dish) => { this.props?.choosePackageMeal?.(itemData) }}
                    orderType={this.props.orderType}
                    openSchedulesModel={() => this.props.openSchedulesModel(item.availableSchedule)}
                />}
            </div>)
    }

    /**
     * 重新计算 GridSize
     */
    recomputeGridSize() {
        this.AutoSizerRef?.recomputeGridSize();
    }

    /**
     * 移动到
     */
    scrollToPosition(scrollTop: number) {
        this.AutoSizerRef?.scrollToPosition(scrollTop);
    }

    // *********************
    // View
    // *********************

    render() {
        const { dishList } = this.props;
        return (
            <div className='order-page-menu-list' id={'order-page-menu-list'}>
                <AutoSizer>
                    {({ width, height }) => (
                        <List
                            containerStyle={{ pointerEvents: 'auto', paddingBottom: '200px' }}
                            ref={refs => { this.AutoSizerRef = refs }}
                            onScroll={this.props.onScroll}
                            height={height}
                            overscanRowCount={1}
                            rowCount={dishList.length}
                            rowHeight={
                                ({ index }) => {
                                    const item = dishList[index];
                                    let height = 0;
                                    if (item && !_.has(item, 'title_name')) {
                                        height = 300 / 1080 * window.innerWidth;
                                    } else {
                                        height = 42;
                                    }
                                    return Number(height.toFixed(2));
                                }
                            }
                            rowRenderer={this._rowRenderer}
                            width={width}
                        />
                    )}
                </AutoSizer>
            </div>
        )
    }
}
export default MenuList

// *********************
// State & Props
// *********************

type State = {

}

type Props = {
    dishList: DishFixed[];
    updateShopCatList: (itemData: Dish, status: boolean) => void;
    saveShopCatDate: Array<Dish>;
    onshowDishDetails?: (itemData: Dish) => void;
    // 选择套餐、包餐
    choosePackageMeal?: (itemData: Dish) => void;
    onScroll?: ((params: ScrollParams) => any) | undefined
    // 订单详情
    // orderInfo: Order;
    // 订单类型1：自取 2：外送 3：堂食
    orderType: string;
    openSchedulesModel: (dishSchedules: Schedule[]) => void;
}