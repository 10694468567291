/**
 * @CE AKclown
 */
import './scroll.scss';

import { CircularProgress } from '@material-ui/core';
import BScroll from 'better-scroll';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

// BScroll的option配置项
const DEFAULT_OPTIONS = {
    observeDOM: true,
    click: true,
    probeType: 3,
    scrollbar: false,
    pullDownRefresh: false,
    pullUpLoad: false,
    mouseWheel: true
}

class Scroll extends Component<Props, State> {
    private scroll: any;
    private scrollWrapper: any;
    private refreshTimer: any;
    constructor(props) {
        super(props)
        this.state = {
            isPullingDown: false, // 是否锁定下拉事件
            isPullUpLoad: false, // 是否锁定上拉事件
            isLoadMore: true
        }
    }

    // *********************
    // 生命周期
    // *********************
    componentDidMount() {
        this.initScroll()
    }

    shouldComponentUpdate(newProps) {
        if (this.scroll.options.pullDownRefresh || this.scroll.options.pullUpLoad) {
            if (newProps.data.length > 0) {
                const newList = newProps.data;
                const List = this.props.data;
                if (newList.length !== List.length) {
                    this.refresh()
                }
            }
        }
        return true
    }

    componentWillUnmount() {
        this.scroll.destroy()
        clearTimeout(this.refreshTimer)
    }

    // *********************
    // 方法区
    // *********************

    // 初始化
    initScroll() {
        if (!this.scroll && this.scrollWrapper) {
            const options = Object.assign({}, DEFAULT_OPTIONS, this.props.options)
            this.scroll = new BScroll(this.scrollWrapper, options)
        }
        if (this.props.options && this.props.options.pullDownRefresh) {
            this.scroll.on('pullingDown', this.onPullingDown)
        }
        if (this.props.options && this.props.options.pullUpLoad) {
            this.scroll.on('pullingUp', this.onPullingUp)
        }
    }

    // 重新计算
    refresh() {
        clearTimeout(this.refreshTimer)
        this.refreshTimer = setTimeout(() => {
            this.scrollForceUpdate(true)
        }, this.props.refreshDelay ? this.props.refreshDelay : 20)
    }

    // 下拉刷新
    onPullingDown = async () => {
        if (typeof this.props.pullDownRefresh === 'function') {
            this.setState({
                isPullingDown: true
            });
            try {
                await this.props.pullDownRefresh()
            } catch (error) {
            }
            setTimeout(() => {
                this.scrollForceUpdate()
            }, 600)
        }
    }

    // 上拉加载
    onPullingUp = async () => {
        if (typeof this.props.pullUpLoad === 'function') {
            this.setState({
                isPullUpLoad: true
            })
            let isLoadMore = true;
            try {
                isLoadMore = await this.props.pullUpLoad()
            } catch (error) {
                isLoadMore = false
            }
            setTimeout(() => {
                this.scrollForceUpdate(isLoadMore)
            }, 600)
        }
    }

    // 数据更新
    scrollForceUpdate = (dirty?: boolean) => {
        if (this.props.options && this.props.options.pullDownRefresh && this.state.isPullingDown) {
            this.setState({
                isPullingDown: false
            })
            this.scroll.finishPullDown()
        } else if (this.props.options && this.props.options.pullUpLoad && this.state.isPullUpLoad) {
            this.setState({
                isPullUpLoad: false,
                isLoadMore: !!dirty
            })
            this.scroll.finishPullUp()
            if (dirty) this.scroll.refresh()
        } else {
            if (dirty) this.scroll.refresh()
        }
    }

    // *********************
    // 视图区
    // *********************

    render() {
        const { className = '', t } = this.props;
        const { isPullingDown, isPullUpLoad, isLoadMore } = this.state;
        return (
            <div className={`scroll-wrapper ${className}`} ref={(refs) => { this.scrollWrapper = refs }}>
                {/*获取子组件*/}
                <div>
                    {
                        isPullingDown ? <CircularProgress style={{ color: '#ffdc33' }} /> : null
                    }
                    {this.props.children}
                    {
                        this.props.options && this.props.options.pullUpLoad ?
                            <div className='scrollLoad'>
                                {
                                    isPullUpLoad ? <CircularProgress style={{ color: '#ffdc33' }} /> :
                                        isLoadMore ? null : <span>{t('general_text_no_more_data')}</span>
                                }
                            </div> : null
                    }
                </div>
            </div>
        )
    }
}

export default withTranslation()<Props>(Scroll as any)


// *********************
// Types
// *********************

type State = {
    // 是否下拉
    isPullingDown: boolean,
    // 是否上拉
    isPullUpLoad: boolean
    // 是否有读取更多数据
    isLoadMore: boolean
}

type Props = {
    data: any[]
    className?: string;
    options?: any;
    refreshDelay?: number;
    pullUpLoad?: () => boolean | Promise<boolean>
    pullDownRefresh?: () => void
} & WithTranslation
