/**
 * @author liweikang
 */

import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import BookStatusButtonComponent from './booking-order-status-button.component'
import StatusButtonComponent from './order-status-button.component'
import { CustomerOrder, Restaurant } from '../../models';
import moment from 'moment';
import _ from 'lodash';
class OrderCardComponent extends React.Component<Props, State> {

    // *********************
    // 构造函数
    // *********************
    constructor(props: Props) {
        super(props);
        this.state = {
            selectIndex: 0
        }
    }

    // *********************
    // 生命周期
    // *********************
    componentDidMount() {
        // we check if already has a account
    }

    async componentWillUnmount() {
        // $ alwyas unsubscribe in un mount function
    }

    // *********************
    // 方法区
    // *********************

    // *********************
    // 视图区
    // *********************

    render() {
        const { onClick, processing, cardData, type } = this.props;
        const orderTime = cardData && cardData.orders && cardData.orders.origin_create_time;
        const isSameDay = moment(_.get(cardData, 'booking_orders.origin_actual_start_time'),
            moment.HTML5_FMT.DATETIME_LOCAL_MS).format('DD/MM/YYYY') ===
            moment(_.get(cardData, 'booking_orders.origin_actual_end_time'),
                moment.HTML5_FMT.DATETIME_LOCAL_MS).format('DD/MM/YYYY')
        return (
            <div className={`orderCard ${processing ? 'processing' : ''}`} onClick={(e) => {
                if (typeof onClick === 'function') onClick(cardData, e)
            }}>
                <span className='title'>
                    {cardData?.belonged_restaurant?.nickname}
                </span>
                {/* 预定订单 */}
                {type === 'myReservations' ?
                    <>
                        <div className='info myReservationsInfo'>
                            <span className='time'>
                                {moment(_.get(cardData, 'booking_orders.origin_actual_start_time'),
                                    moment.HTML5_FMT.DATETIME_LOCAL_MS).format('HH:mm')}
                                {isSameDay ? ` - ` : ''}
                                {isSameDay ? moment(_.get(cardData, 'booking_orders.origin_actual_end_time'),
                                    moment.HTML5_FMT.DATETIME_LOCAL_MS).format('HH:mm') : ''}
                            </span>
                            <span className='date'>
                                {moment(_.get(cardData, 'booking_orders.origin_actual_start_time'),
                                    moment.HTML5_FMT.DATETIME_LOCAL_MS).format('DD/MM/YYYY')}
                            </span>
                            {isSameDay ? <BookStatusButtonComponent
                                status={_.get(cardData, 'booking_orders.origin_status')}
                            /> : null}
                        </div>
                        {!isSameDay &&
                            <div className='info myReservationsInfo'>
                                <span className='time'>
                                    {` - `}
                                    {moment(_.get(cardData, 'booking_orders.origin_actual_end_time'),
                                        moment.HTML5_FMT.DATETIME_LOCAL_MS).format('HH:mm')}
                                </span>
                                <span className='date'>
                                    {moment(_.get(cardData, 'booking_orders.origin_actual_end_time'),
                                        moment.HTML5_FMT.DATETIME_LOCAL_MS).format('DD/MM/YYYY')}
                                </span>
                                <BookStatusButtonComponent
                                    status={_.get(cardData, 'booking_orders.origin_status')}
                                />
                            </div>}
                    </>
                    :
                    // 外卖订单
                    <div className='info'>
                        <span className='price'>
                            {cardData && cardData.orders && cardData.orders.origin_total_price_with_tax ?
                                `€${cardData.orders.origin_total_price_with_tax.toFixed(2)}` : '€0.00'}
                        </span>
                        <span className='date'>
                            {orderTime ?
                                moment(orderTime, moment.HTML5_FMT.DATETIME_LOCAL_MS).format('DD/MM/YYYY')
                                : ''}
                        </span>
                        <StatusButtonComponent
                            payMentStatus={cardData?.orders?.origin_payment_status}
                            status={cardData && cardData.orders && cardData.orders.origin_status}
                            deliveryStatus={cardData && cardData.orders && cardData.orders.origin_delivery_status}
                            paymentType={cardData?.orders?.origin_payment_type}
                            active={processing ? true : false}>
                        </StatusButtonComponent>
                    </div>
                }
            </div>
        );
    }

}

export default withTranslation()<Props>(OrderCardComponent as any);

// *********************
// Types
// *********************

type State = {
    selectIndex: number;
}

type Props = {
    // 卡片点击事件
    onClick?: (value: {
        orders: CustomerOrder,
        belonged_restaurant: Restaurant
    }, event?: any) => void;
    // 卡片是否处理中
    processing?: boolean;
    // 订单卡片数据
    cardData: {
        orders: any,
        belonged_restaurant: Restaurant
    };
    // 类型 - myReservations:我的预定
    type?: string
} & WithTranslation
