
import { Chip, Drawer } from '@material-ui/core';
import React from 'react';
import { orderHeaderDatatype } from '../../../page/restaurant/restaurant-page'
import ReactMarkdown from 'react-markdown';
import './black-gold-restaurant-info-dialog.style.scss'

// 餐管简介弹窗
export function BlackGoldRestaurantInfoDialog(
    props: {
        className?: string;
        open: boolean;
        handleClose: () => void;
        orderHeaderData: orderHeaderDatatype;
        Chipdata: Array<string>;
        description: string;
        restaurantlogo: string;
    }
) {
    const { open, handleClose, Chipdata, description, className, restaurantlogo } = props
    return (
        <Drawer
            className={`blackGoldRestaurantInfo ${className || ''} `}
            open={open}
            onClose={handleClose}
            // onOpen={() => { }}
            anchor={'bottom'}>
            <div className='blackGoldRestaurantInfoContent'>
                <div className='linebox' onClick={() => { handleClose() }}><span className='line'></span></div>
                <div className={'dialogbody'}>
                    <div>
                        <div className='imgbox'>
                            <img
                                className='restaurant-logo'
                                src={restaurantlogo} alt=''
                            />
                        </div>
                        <div className='chipbox'>
                            {Chipdata && Chipdata.map((value, index) => {
                                return <Chip key={index} size='small' label={value}></Chip>
                            })}
                        </div>
                    </div>
                    <div className='introductiontext'>
                        <ReactMarkdown className='markdown markdown1' source={description} escapeHtml={false} />
                    </div>
                </div>
            </div>
        </Drawer>
    )
}