import React from 'react';
import { Dialog, DialogContent, Button } from '@material-ui/core';
import i18n from '../../i18n';
import ClearIcon from '@material-ui/icons/Clear';
import './booking-box-send.styles.scss'
/**
 * 预定成功弹窗
 * @param
 */
export function BookingMessageDialog(
    props: {
        handleClose: () => void;
        onConfirm: () => void;
        onHere: () => void;
    }
) {
    const { handleClose, onConfirm, onHere } = props;
    return (
        <Dialog
            open={true}
            onClose={() => {
                handleClose()
            }}
            className='bookingBoxSendDialog'
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                <div className='dialogContent'>
                    <div className='dialogName'>
                        <div className='fontStyle'>
                            {i18n.t('reservation_page_text_reservation_sent')}
                            <div className='close' onClick={() => {
                                handleClose()
                            }}>
                                <ClearIcon className='clearIcon' />
                            </div>
                        </div>
                    </div>
                    <div className='contentText'>
                        <p className='p1'>{i18n.t('reservation_page_text_thank_you')}</p>
                        <p className='p2'>{i18n.t('reservation_page_text_we_have_received_your_reservation')}</p>
                        <p className='p3'>{i18n.t('reservation_page_text_track_your_reservation_status')}
                            <span className='here' onClick={() => { onHere() }}>
                                {` ${i18n.t('reservation_page_text_here')}`}
                            </span>
                        </p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button className='insertCodeBtn ok'
                            onClick={() => {
                                onConfirm()
                            }} >
                            {i18n.t('reservation_page_button_return')}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}