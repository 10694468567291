import { OptionsObject } from 'notistack';
import { DetailMessageObject } from './type';
import i18nService from '../i18n';
import SnackMessageComponent from '../component/snack-message.component';
import React from 'react';
import { ERROR_000 } from '../constants/error.constant'
import _ from 'lodash';

export class MessengerModule {

    private sk: (message: string | React.ReactNode, options?: OptionsObject) => OptionsObject['key'] | null;

    constructor(
        sk: (message: string | React.ReactNode, options?: OptionsObject) => OptionsObject['key'] | null
    ) {
        this.sk = sk;
    }

    private isDetailMessageObject(val: any): val is DetailMessageObject {
        return val && Object.prototype.hasOwnProperty.call(val, 'message') && Object.prototype.hasOwnProperty.call(val, 'level');
    }

    private stringifySafe(val: any): string | null {
        try {
            if (val === undefined || val === null) return null;
            else if (typeof val === 'string') return val;
            else return JSON.stringify(val);
        } catch (error) {
            return null;
        }
    }

    /**
     * show snack bar with `DetailMessageObject`, if is not the correct type, the raw error will be showed
     * @param dmo
     */
    public say(dmo: DetailMessageObject, options: { vertical?: string; horizontal?: string } = {}) {
        try {
            const message = (dmo && dmo.message) || ''
            // init
            if (!this.isDetailMessageObject(dmo)) dmo = {
                message: message || ERROR_000.message,
                level: 'Error',
                details: dmo || {}
            };
            // build details
            if (dmo.details && typeof dmo.details === 'object') {
                const detailsArray: any[] = [];
                _.forIn(dmo.details, (value: any, key: any) => {
                    detailsArray.push(this.stringifySafe({ [key]: value }));
                });
                dmo.details = detailsArray;
            }
            // show snack
            this.sk('', {
                persist: false,
                anchorOrigin: {
                    vertical: options.vertical || 'top',
                    horizontal: options.horizontal || 'center'
                },
                // eslint-disable-next-line react/display-name
                content: (key: any) => (
                    <SnackMessageComponent
                        id={key}
                        level={dmo.level as any}
                        message={`${i18nService.t(dmo.message)}`}
                        details={dmo.details}
                    />
                )
            } as any);
        } catch (error) {

        }
    }

}