
/**
 * @CE AKclown
 */
import _ from 'lodash';
import moment from 'moment';

import i18n from '../../i18n';
import { Schedule, ScheduleTimeRange } from '../../models';

/**
 * 分析餐馆营业时间
 * @param scheduleData // 餐馆营业时间表
 */
export function analyzeRestaurantSchedule(
    scheduleData: Array<Schedule> = []
) {
    // 重新设置营业时间表数据(将全天营业的当天的营业时间范围改成空)
    scheduleData = resetScheduleData(scheduleData);
    const now = new Date();
    const today = now.getDay();
    const todayRecord: Schedule = getCheduleData(today, scheduleData);
    let result: resultType = {};

    if (Object.keys(todayRecord).length === 0) {
        return result = {}
    }

    const { time_ranges, takeaway_status, selftake_status, time_24_hours } = todayRecord
    let isRestaurantOpen = false
    let nextOpenTime: any = ''
    const nextDayAndTime = getNextTimeRanges(1, scheduleData)
    const isAlldayOpen = time_24_hours
    let isInTodayOpenTimeRanges = false
    let nextOpenTimeisAlldayOpen = false
    let selfTakeStatus = selftake_status
    let takeawayStatus = takeaway_status
    // 今天休息
    let todyClose = false;
    if (time_ranges) {
        // 今天是否有营业时间
        if (time_ranges.length > 0) {
            for (const item of time_ranges) {
                const start = moment(item.start_time, 'HH:mm')
                const end = moment(item.end_time, 'HH:mm')
                // 是否在营业时间范围内，是就跳出循环
                if (moment(now).isBetween(start, end)) {
                    todyClose = false;
                    isRestaurantOpen = true
                    nextOpenTime = ''
                    selfTakeStatus = item.selftake_status
                    takeawayStatus = item.takeaway_status
                    break;
                } else if (moment(now).isBefore(start)) {
                    // 是在营业开始时间之前，是就跳出循环
                    todyClose = false;
                    isRestaurantOpen = false
                    nextOpenTime = item.start_time
                    selfTakeStatus = item.selftake_status
                    takeawayStatus = item.takeaway_status
                    break;
                } else if (moment(now).isAfter(end)) {
                    todyClose = true;
                    // 是否在营业结束之后，是就继续校验下个时间范围
                    isRestaurantOpen = false
                    nextOpenTime = nextDayAndTime.nextOpenTime
                    // 判断下一个时间是否24小时营业
                    if (nextDayAndTime.nextOpenTimeisAlldayOpen) {
                        // 24小时营业直接辅助营业状态
                        selfTakeStatus = nextDayAndTime.nextSelfTakeStatus
                        takeawayStatus = nextDayAndTime.nextTakeawayStatus
                    } else {
                        // 下一营业时间在营业时间范围
                        selfTakeStatus = _.get(nextDayAndTime.nextTimeRanges, '[0].selftake_status')
                        takeawayStatus = _.get(nextDayAndTime.nextTimeRanges, '[0].takeaway_status')
                    }
                }
            }

            // 找出今天最早的时间
            let firstTimeRangesStartTime = moment(time_ranges[0].start_time, 'HH:mm');
            // 找出今天最晚的时间
            let lastTimeRangesEndTime = moment(time_ranges[time_ranges.length - 1].end_time, 'HH:mm');
            for (const item of time_ranges) {
                if (firstTimeRangesStartTime.isAfter(moment(item.start_time, 'HH:mm'))) {
                    firstTimeRangesStartTime = moment(item.start_time, 'HH:mm');
                }
                if (lastTimeRangesEndTime.isBefore(moment(item.end_time, 'HH:mm'))) {
                    lastTimeRangesEndTime = moment(item.end_time, 'HH:mm');
                }
            }
            // 现在时间是否在最早营业开始时间和最后营业结束时间之间 || 在最早营业时间之前
            if (moment(now).isBetween(firstTimeRangesStartTime, lastTimeRangesEndTime)
                || moment(now).isBefore(firstTimeRangesStartTime)) {
                isInTodayOpenTimeRanges = true
            } else if (moment(now).isAfter(lastTimeRangesEndTime)) {
                // 现在时间是否在最后营业时间之后，判断下个营业时间是否是24小时营业
                nextOpenTimeisAlldayOpen = nextDayAndTime.nextOpenTimeisAlldayOpen || false
            }
            // 是否全天营业
        }
        // 今天是否全天
        else if (isAlldayOpen) {
            isRestaurantOpen = true
            nextOpenTime = ''
            selfTakeStatus = selftake_status
            takeawayStatus = takeaway_status
        }
        // 有下个营业时间
        else {
            todyClose = true;
            isRestaurantOpen = false;
            nextOpenTime = nextDayAndTime.nextOpenTime;
            nextOpenTimeisAlldayOpen = nextDayAndTime.nextOpenTimeisAlldayOpen || false;
            // 判断下一个时间是否24小时营业
            if (nextDayAndTime.nextOpenTimeisAlldayOpen) {
                // 24小时营业直接辅助营业状态
                selfTakeStatus = nextDayAndTime.nextSelfTakeStatus
                takeawayStatus = nextDayAndTime.nextTakeawayStatus
            } else {
                // 下一营业时间在营业时间范围
                selfTakeStatus = _.get(nextDayAndTime.nextTimeRanges, '[0].selftake_status')
                takeawayStatus = _.get(nextDayAndTime.nextTimeRanges, '[0].takeaway_status')
            }
        }
        const nextday = nextDayAndTime.nextday || 1;
        const newtoday = today === 0 ? 7 : today
        const weekTextArray = [i18n.t('restaurant_info_page_text_monday'), i18n.t('restaurant_info_page_text_tuesday'),
        i18n.t('restaurant_info_page_text_wednesday'), i18n.t('restaurant_info_page_text_thursday'),
        i18n.t('restaurant_info_page_text_friday'), i18n.t('restaurant_info_page_text_saturday'),
        i18n.t('restaurant_info_page_text_sunday')]
        const whichDay = weekTextArray[nextday - 1]
        const isNext = nextday <= newtoday ? i18n.t('restaurant_info_page_text_next_week') : i18n.t('restaurant_info_page_text_this_week')

        const istomorrow = isInTodayOpenTimeRanges
            ? i18n.t('restaurant_info_page_text_today')
            : ((nextday - newtoday) === 1 || (nextday - newtoday) === -6)
                ? i18n.t('restaurant_info_page_text_tomorrow')
                : ((nextday - newtoday) === 2 || (nextday - newtoday) === -5)
                    ? i18n.t('restaurant_info_page_text_day_after_tomorrow')
                    : `${isNext}${whichDay}`

        nextOpenTime = nextOpenTime ? moment(nextOpenTime, 'HH:mm').format('HH:mm') : ''

        result = {
            // 是否全天营业
            isAlldayOpen,
            // 现在是否营业
            isRestaurantOpen,
            // 下一个营业时间
            nextOpenTime,
            // 下一个营业时间（加描述）先判断下个营业时间是否时全天营业
            nextOpenTimeText: nextOpenTimeisAlldayOpen
                ? `${istomorrow} ${i18n.t('general_text_all_day_business')}`
                : nextOpenTime
                    ? `${istomorrow} ${nextOpenTime} ${i18n.t('restaurant_info_page_restaurant_open')}`
                    : '',
            // 是否自取
            selfTakeStatus,
            // 是否派送
            takeawayStatus,
            // 今天休息
            todyClose
        }

    }
    return result
}
/**
 * 获取某天营业的数据
 * @param value // 1:今天 2:下一天 n:下n天
 */
function getCheduleData(value: number, scheduleData: Array<Schedule>) {
    let newObj = {}
    const newValue = value === 0 ? 7 : value
    scheduleData.forEach((item) => {
        if (item.week === newValue) {
            return newObj = item
        }
    })
    return newObj
}

/**
 * 获取下个营业时间范围
 * @param value // 1:今天 2:下一天 n:下n天
 */
export function getNextTimeRanges(value: number, scheduleData: Array<Schedule>): {
    nextOpenTime?: string;
    nextday?: number;
    nextDate?: string;
    nextOpenTimeisAlldayOpen?: boolean;
    nextTimeRanges?: ScheduleTimeRange[];
    nextSelfTakeStatus?: boolean;
    nextTakeawayStatus?: boolean;
} {
    const now = new Date();
    const nextday = moment(now).add(value, 'days').isoWeekday();
    const nextDate = moment(now).add(value, 'days').format('YYYY-MM-DD');
    const nextdayRecord: Schedule = getCheduleData(nextday, scheduleData);
    const nextTimeRanges = nextdayRecord.time_ranges;
    let nextOpenTime: string | undefined = '';
    let nextOpenTimeisAlldayOpen: boolean | undefined = false;
    let nextSelfTakeStatus: boolean | undefined = false;
    let nextTakeawayStatus: boolean | undefined = false;
    if (nextdayRecord.time_24_hours) {
        nextOpenTimeisAlldayOpen = true
        nextSelfTakeStatus = nextdayRecord.selftake_status
        nextTakeawayStatus = nextdayRecord.takeaway_status
        return { nextOpenTime, nextday, nextDate, nextOpenTimeisAlldayOpen, nextTimeRanges, nextSelfTakeStatus, nextTakeawayStatus }
    } else if (nextTimeRanges && nextTimeRanges.length > 0) {
        nextOpenTime = nextTimeRanges[0].start_time
        nextSelfTakeStatus = nextTimeRanges[0].selftake_status;
        nextTakeawayStatus = nextTimeRanges[0].takeaway_status;
        return { nextOpenTime, nextday, nextDate, nextOpenTimeisAlldayOpen, nextTimeRanges, nextSelfTakeStatus, nextTakeawayStatus }
    } else {
        // 找到下个营业时间-超过8天就输出{}
        if (value < 8) {
            return getNextTimeRanges(value + 1, scheduleData);
        } else {
            return {}
        }
    }
}
/**
 * 重新设置营业时间表数据(将全天营业的当天的营业时间范围改成空)
 */
function resetScheduleData(scheduleData) {
    try {
        let newSCheduleData = scheduleData;
        newSCheduleData = newSCheduleData.map((item) => {
            if (item.time_24_hours) {
                item.time_ranges = []
            }
            return item
        })
        return newSCheduleData
    } catch (error) {
        return []
    }
}


export type resultType = {
    // 是否全天营业
    isAlldayOpen?: boolean,
    // 现在是否营业
    isRestaurantOpen?: boolean,
    // 下一个营业时间
    nextOpenTime?: string,
    // 下一个营业时间（加描述）
    nextOpenTimeText?: string,
    // 是否自取
    selfTakeStatus?: boolean,
    // 是否派送
    takeawayStatus?: boolean;
    // 今天休息
    todyClose?: boolean;
}