import * as React from 'react';
import DatePicker from 'react-mobile-datepicker';
import _ from 'lodash';
import TopBar from '../top-bar/top-bar.component';
import { Button } from '@material-ui/core';
import { MdCreate } from 'react-icons/md';
import { MdKeyboardArrowDown } from 'react-icons/md';
import './account-information.component.scss';
import ConfirmationDialog from './dialog.component';
import apiManage from '../../request';
import moment from 'moment';
import { MessengerModule } from '../../messenger/module';
import { withSnackbar } from 'notistack';
import { withTranslation, WithTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { IS_CUSTOMER_NAME } from '../../constants/base.constant';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import Notification from '../../component/snackbar/snackbar';

class Account extends React.Component<Props, State> {
	// 提示框消息
	private messengerModule: MessengerModule;

	constructor(props: Props) {
		super(props);
		this.messengerModule = new MessengerModule(this.props.enqueueSnackbar);
		this.state = {
			showBirthday: props.customerInfo.birth ? moment(props.customerInfo.birth).format('DD/MM/YYYY') : '',
			inputfirstName: props.customerInfo.first_name ? props.customerInfo.first_name : '',
			inputlastName: props.customerInfo.last_name ? props.customerInfo.last_name : '',
			time: props.customerInfo.birth ? new Date(props.customerInfo.birth) : new Date(),
			isOpen: false,
			_id: '',
			birthday: props.customerInfo.birth ? props.customerInfo.birth : '',
			changeGender: props.customerInfo.gender ? props.customerInfo.gender : ''
		};
	}

	// ***************
	//  生命周期
	// ***************

	// 判断用户是否存在
	UNSAFE_componentWillMount() {
		const accountInfo = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
		if (accountInfo) {
			this.setState({
				_id: accountInfo._id
			});
		}
	}

	// ***************
	// 方法区
	// ***************

	public async setCustomerInfo() {
		const {
			_id,
			inputfirstName,
			inputlastName,
			birthday,
			changeGender
		} = this.state;
		if (this.state.inputfirstName.trim() === '' || this.state.inputlastName.trim() === '' || this.state.showBirthday === '') {
			Notification.notification(i18n.t('account_page_text_please_fill_in_the_complete_information'));
		} else {
			try {
				const setaccountInfo: any = await apiManage.patch_customer_info(
					{
						first_name: inputfirstName,
						last_name: inputlastName,
						birth: birthday,
						gender: changeGender
					},
					_id
				);
				if (setaccountInfo.error) {
					return
				}
				Notification.notification(i18n.t('account_page_text_edit_successful'));
				const account = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
				_.set(account, 'first_name', inputfirstName);
				_.set(account, 'last_name', inputlastName);
				LocalStorageManager.setLocalStorage({
					path: LOCAL_STORAGE_KEYS.ACCOUNT,
					value: account
				});
				if (typeof this.props.onchange === 'function') this.props.onchange({
					first_name: inputfirstName,
					last_name: inputlastName,
					birth: birthday,
					gender: changeGender
				})
				if (typeof this.props.changeStatus === 'function') this.props.changeStatus(false, true);
			} catch (error) { }
		}

	}

	// 日期选择组件方法
	updateState = (value: any) => {
		this.setState(value);
	};

	handleClick = () => {
		this.setState({ isOpen: true });
	};

	handleCancel = () => {
		this.setState({ isOpen: false });
	};

	handleSelect = (time) => {
		const momentTime = moment(time).format();
		const showBirthday = `${moment(time).format('DD/MM/YYYY')}`;
		this.setState({ time, isOpen: false, showBirthday, birthday: momentTime });
	};
	// ***************
	// VIEW
	// ***************

	public render() {
		const { changeStatus } = this.props;
		const { inputfirstName, inputlastName, showBirthday, changeGender } = this.state;

		return (
			<div className='personal-information-main overall-center'>
				{/* Top bar */}
				<TopBar
					backgroundstyle={'backgroundTransparent'}
					isHideMenu={true}
					title={this.props.t('text_personal_information')}
					isGoBack={() => {
						changeStatus(false);
					}}
				/>

				{/* 个人信息 */}
				<div className='personal-information-content'>
					<div className='main-body'>
						<div style={{ overflow: 'auto' }}>
							<div className='main-icon'>
								<MdCreate />
							</div>

							<div className='item-style'>
								<div className='tag-style'><em>*</em>{i18n.t('signup_page_text_first_name')}</div>
								<input
									className='first-name-insert-in input-main'
									value={inputfirstName}
									onChange={(val: any) => {
										if (val.target.value) {
											if (IS_CUSTOMER_NAME.test(val.target.value)) {
												this.setState({
													inputfirstName: val.target.value
												});
											}
										} else {
											this.setState({
												inputfirstName: ''
											});
										}
									}}
								/>
							</div>

							<div className='item-style'>
								<div className='tag-style'><em>*</em>{i18n.t('signup_page_text_last_name')}</div>
								<input
									className='last-name-insert-in input-main'
									value={inputlastName}
									onChange={(val: any) => {
										if (val.target.value) {
											// if (val.target.value?.trim().length <= 30) {
											if (IS_CUSTOMER_NAME.test(val.target.value)) {
												this.setState({
													inputlastName: val.target.value
												});
											}
										} else {
											this.setState({
												inputlastName: ''
											});
										}
									}}
								/>
							</div>

							<div className='item-style'>
								<div className='tag-style'><em>*</em>{i18n.t('signup_page_text_gender')}</div>
								{/* 性别选择组件 */}
								<ConfirmationDialog
									className='change-gender input-main'
									defaultValue={changeGender}
									changeState={(val: number) => {
										this.setState({
											changeGender: val.toString()
										});
									}}
								/>
							</div>

							<div className='item-style'>
								<div className='tag-style'><em>*</em>{i18n.t('text_birthday')}</div>
								<div
									className='change-birthday input-main'
									onClick={() => {
										this.handleClick();
									}}
								>
									<div>{showBirthday}</div>

									<div>
										<MdKeyboardArrowDown />
									</div>
									{
										<DatePicker
											confirmText={i18n.t('general_text_confirm')}
											cancelText={i18n.t('general_text_cancel')}
											value={this.state.time}
											isOpen={this.state.isOpen}
											onSelect={this.handleSelect}
											onCancel={this.handleCancel}
											min={new Date(1900, 0, 1)}
											max={new Date()}
										/>
									}
								</div>
							</div>
						</div>
						<Button
							className='confirm boxShadow-btn'
							variant='contained'
							onClick={() => this.setCustomerInfo()}
						>
							{i18n.t('signup_page_button_confirm')}
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()<Props>(withSnackbar(Account as any) as any);

// **********
// TYPE
// **********

type Props = {
	changeStatus: (val: boolean, isUpdata?: boolean) => void;
	enqueueSnackbar?: any;
	onchange?: (value: any) => void;
	customerInfo: any;
} & WithTranslation;

type State = {
	time: any;
	// 是否弹出日期选择框
	isOpen: boolean;
	// 显示日期
	showBirthday: string;
	// 姓名
	inputfirstName: string;
	inputlastName: string;
	// 用户ID
	_id: string;
	birthday: any;
	// 性别
	changeGender: string;
};
