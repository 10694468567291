/**
 * @CE AKclown
 */
import './swipeableTextMobileStepper.style.scss';

import MobileStepper from '@material-ui/core/MobileStepper';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// 餐馆轮播图组件
export default function SwipeableTextMobileStepper(
	props: {
		tutorialSteps: Array<tutorialStepsObj>;
		style?: object;
		className?: string;
	}
) {
	const { tutorialSteps, style, className } = props
	const [activeStep, setActiveStep] = React.useState(0);
	const maxSteps = tutorialSteps.length;
	const handleStepChange = (step: number) => {
		setActiveStep(step);
	};

	return (
		<div className={`restaurantSwipeableTextMobileStepper ${className || ''}`} style={{ ...style }}>
			<AutoPlaySwipeableViews
				index={activeStep}
				onChangeIndex={handleStepChange}
				enableMouseEvents
				className='swipeableimg'
			>
				{Array.isArray(tutorialSteps) && tutorialSteps.length > 0 ? tutorialSteps.map((step, index) => (
					<div key={step.label}>
						{Math.abs(activeStep - index) <= 2 ? (
							<div className='imgbox'>
								<img className={'itemimg'} src={step.imgPath} alt={step.label} />
							</div>
						) : null}
					</div>
				)) : <></>}
			</AutoPlaySwipeableViews>
			<MobileStepper
				className='Stepperdots'
				steps={maxSteps}
				position='static'
				variant='dots'
				activeStep={activeStep}
				nextButton={null}
				backButton={null}
			/>
		</div >
	);
}
export type tutorialStepsObj = {
	label: string
	imgPath: string
}