import { Typography } from '@material-ui/core';
import { ArrowBackIos, Person, Room, VpnKey, StarsOutlined } from '@material-ui/icons';
import _ from 'lodash';
import React from 'react';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { ICheckoutCompleteCustomerInfo, ICheckoutCompleteCustomerInfoProps, ICheckoutCompleteCustomerInfoState, ICheckoutShowedPageNames } from '../../interfaces';
import { Address } from '../../models';
import apiManage from '../../request';
import ButtonComponent from '../button.component';
import GoogleMapComponent from '../googlemap-search/google-search';
import InputComponent from '../input-component';
import { PhoneInput } from '../payment/payment.componet';
import './scss/checkout-sign-in-phone.scss';
import Notification from '../../component/snackbar/snackbar';
import i18n from '../../i18n';
import { IS_CUSTOMER_NAME, IS_PASSWORD, IS_PHONE } from '../../constants/base.constant';
import { getBrowserPhoneCode } from '../../constants/tool-get-browser-language';
import PasswordInput from '../password-input.component';
import vLogo from '../../static/images/v.png';
import { BookingInfo } from '../restaurant/booking-box';
import Store from '../../rudex/store';
import { TimeoutAtCodeDelay } from '../../page/sign-in/sign-in.page';
import { showApiMessage } from '../../messenger/messageManager';
import VerificationCode from '../account/verification-code.component';
import { BindPhoneDialog } from '../bind-phone/bind-phone-dialog.component';
import { getBindAccountUserInfo } from '../../constants/tool-check-token';

class CheckoutCompleteCustomerInfo extends React.Component<Props, State> implements ICheckoutCompleteCustomerInfo {
    signInBtnAvailable: boolean = true;

    public state: State = {
        firstName: '',
        lastName: '',
        isReplaceRepi: false,
        isDefaultAddress: false,
        addressLocal: '',
        // 代替收件人姓
        replaceLastName: '',
        // 代替收件人姓
        replaceFirstName: '',
        doorbellNnumber: '',
        phonePrefix: getBrowserPhoneCode(),
        phone: '',
        zipCode: '',
        password: '',
        newPassword: '',
        // 是否为谷歌选中地址
        isGoogleAddress: true,
        // 谷歌选中地址的邮编
        isGoogleCodeInput: '',
        // 谷歌选中地址的具体位置
        googleInputVal: '',
        vcodeTimeMS: 90,
        insertCode: '',
        isCountDown: false,
        isShowSendText: false,
        accountPhonePrefix: getBrowserPhoneCode(),
        accountPhone: '',
        isOpenDialog: false
    }

    // ************
    // 生命周期
    // ************
    public componentDidMount() {
        this.showPasswrod();
        this.autoFill();
    }

    // ***************
    // 方法区
    // ***************

    autoFill() {
        const bookingInfo: BookingInfo = _.get(Store.getState(), 'bookingInfo', {}) as BookingInfo;

        if (bookingInfo?.first_name) {
            this.setState({ firstName: bookingInfo.first_name, lastName: bookingInfo.last_name });
        }

    }

    public async updateCustomerInformation(force?: boolean) {
        const { address, isDefaultAddress, doorbellNnumber, firstName, lastName, phonePrefix,
            phone, isReplaceRepi, replaceFirstName, replaceLastName, zipCode, accountPhone, accountPhonePrefix } = this.state;
        const { isVisibleAddress, isVisibleCustomerName, isReferralsCode } = this.props;
        const customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        const customer_first_name = firstName?.trim() !== '' ? firstName : _.get(customer, 'first_name', '');
        const customer_last_name = lastName?.trim() !== '' ? lastName : _.get(customer, 'last_name', '');
        const newAddress = {
            default: isDefaultAddress,
            details: '',
            city: _.get(address, 'locality', ''),
            street: _.get(address, 'google_map_formatted_address', ''),
            home_number: doorbellNnumber,
            state: _.get(address, 'administrative_area_level_1', ''),
            country: _.get(address, 'country', ''),
            code: zipCode,
            first_name: isReplaceRepi ? replaceFirstName : customer_first_name,
            last_name: isReplaceRepi ? replaceLastName : customer_last_name,
            nickname: isReplaceRepi ? `${replaceFirstName}${replaceLastName}` : `${customer_first_name}${customer_last_name}`,
            phone: isReplaceRepi ? phone : accountPhone ? accountPhone : _.get(customer, 'phone', ''),
            phone_prefix: isReplaceRepi ? phonePrefix : accountPhone ? accountPhonePrefix : _.get(customer, 'phone_prefix', ''),
            google_map_place_id: _.get(address, 'google_map_place_id', ''),
            google_map_formatted_address: _.get(address, 'google_map_formatted_address', ''),
            google_map_lat: _.get(address, 'google_map_lat', ''),
            google_map_lng: _.get(address, 'google_map_lng', '')
        }

        // const params = {
        //     address: [newAddress]
        // }
        try {
            const customerInfo = {};
            // if (isVisibleCustomerName && isVisibleAddress && this.props.isOrderway) {
            //     customerInfo = {
            //         last_name: this.state.lastName,
            //         first_name: this.state.firstName,
            //         address: [newAddress]
            //     }
            // } else if (isVisibleCustomerName) {
            //     customerInfo = {
            //         last_name: this.state.lastName,
            //         first_name: this.state.firstName,
            //     }
            // } else if (isVisibleAddress) {
            //     customerInfo = {
            //         address: [newAddress]
            //     }
            // }
            if (force) {
                _.set(customerInfo, 'merge_customer', '1');
            }
            if (this.state.accountPhone && this.state.insertCode) {
                _.set(customerInfo, 'phone', this.state.accountPhone);
                _.set(customerInfo, 'phone_prefix', this.state.accountPhonePrefix);
                _.set(customerInfo, 'code_value', this.state.insertCode);
            }
            if (this.state.password?.trim() !== '') {
                _.set(customerInfo, 'password', this.state.password);
            }
            if (isVisibleCustomerName) {
                _.set(customerInfo, 'last_name', this.state.lastName);
                _.set(customerInfo, 'first_name', this.state.firstName);
            }
            if (isVisibleAddress && this.props.isOrderway && this.state.addressLocal && this.state.isGoogleAddress) {
                _.set(customerInfo, 'address', [newAddress]);
            }

            // 邀请码
            if (isReferralsCode && this.props.referralsCode && this.props.referralsCode !== '') {
                _.set(customerInfo, 'my_superior_reference_recommendation_code', this.props.referralsCode);
            }

            if (this.props.thirdPartyToken && this.props.thirdPartyType !== undefined) {
                _.set(customerInfo, 'third_party', {
                    type: this.props.thirdPartyType.toLocaleUpperCase(),
                    token: this.props.thirdPartyToken
                });
            }
            const res_complace: any = await apiManage.patch_customer_info(customerInfo, _.get(customer, '_id', ''))
            if (!res_complace?.error) {
                const account = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
                let accountInfo = _.assign(account, customerInfo);
                if (this.state.accountPhone && this.state.insertCode) {
                    // 绑定手机号后，获取新的账号token和详情
                    const mergeCustomerInfo = await getBindAccountUserInfo()
                    accountInfo = mergeCustomerInfo || {};
                }
                LocalStorageManager.setLocalStorage({
                    path: LOCAL_STORAGE_KEYS.ACCOUNT,
                    value: accountInfo
                });
                if (this.props.componentType === 'BOOKING') {
                    // 更新餐馆主页
                    this.props?.updateOrderPageState?.();
                    const bookingInfo: BookingInfo = _.get(Store.getState(), 'bookingInfo', {}) as BookingInfo;
                    Store.dispatch({
                        type: 'SET_STATE',
                        path: 'bookingInfo',
                        value: { ...bookingInfo, shouldAction: true }
                    })
                    this.props.updateShowedPage(ICheckoutShowedPageNames.BOOKING_ORDER_PAGE);
                }
                else if (this.props.componentType === 'COMPLETE_CUSTOMER') {
                    this.props.closeCheckoutComponent?.();
                    // 更新餐馆主页
                    this.props?.updateOrderPageState?.();
                } else {
                    // 成功跳转页面到detail
                    this.props.updateShowedPage(ICheckoutShowedPageNames.ORDER_DETAIL_PAGE);
                }
            } else {
                // 绑定手机号已存在
                if (res_complace?.result?.code === 1062) {
                    this.setState({ isOpenDialog: true });
                    return;
                } else if (res_complace?.result?.code === 1008) {
                    Notification.notification(i18n.t('signin_page_textphone_number_and_verification_code_do_not_match'))
                    return
                }
                Notification.notification(i18n.t('payment_page_text_update_failed'))
            }
            // const result = await apiManage.patch_customer_info(params, _.get(customer, '_id', ''));

        } catch (error) {
            //
        }
    }

    /*
    * 通知后台显示了密码
    */
    public async showPasswrod() {
        const customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        try {
            await apiManage.patch_customer_is_password_page(_.get(customer, '_id', ''));
        } catch (error) {
            //
        }
    }

    /**
     * 开启倒计时
     * @param countdownValue 倒计时秒钟
     */
    public startTiming() {
        this.checkoutClearInterval();
        this.setState({
            vcodeTimeoutObject: setInterval(
                () => {
                    this.setState({
                        vcodeTimeMS: this.state.vcodeTimeMS - 1,
                        isCountDown: true
                    })
                    if (this.state.vcodeTimeMS <= 0) {
                        this.checkoutClearInterval();
                    }
                },
                1000
            ),
            vcodeTimeMS: this.state.vcodeTimeMS - 1
        });
    }
    /**
     * 清定时器
     */
    public checkoutClearInterval() {
        if (this.state.vcodeTimeoutObject) {
            clearInterval(this.state.vcodeTimeoutObject)
        }
        this.setState({ vcodeTimeMS: TimeoutAtCodeDelay, isCountDown: false });
    }

    public render() {
        const { isVisibleAddress, isVisibleCustomerName, isVisiblePassword, isOrderway, isReferralsCode, isVisibleAccount } = this.props;
        const { isReplaceRepi, isDefaultAddress, firstName, lastName, addressLocal, phonePrefix, phone,
            replaceLastName, replaceFirstName, doorbellNnumber, zipCode, password, newPassword,
            isGoogleAddress, isGoogleCodeInput, googleInputVal, vcodeTimeMS, isCountDown, insertCode, accountPhonePrefix, accountPhone, isOpenDialog } = this.state;
        // 确认按钮可用态
        const confirmBtnAvailable = (isVisibleCustomerName ? (firstName?.trim() !== '' && lastName?.trim() !== '') : true)
            && (password === '' ? true : (IS_PASSWORD.test(newPassword) && password === newPassword))
            && ((isOrderway && isVisibleAddress) ? (addressLocal ? doorbellNnumber?.trim() !== '' && zipCode?.trim() !== '' : true) : true)
            && (isReplaceRepi ? (IS_PHONE(phonePrefix, phone) && replaceFirstName?.trim() !== '' && replaceLastName?.trim() !== '') : true)
            && isGoogleAddress
            && (isVisibleAccount && (accountPhone || ['BOOKING', 'DELIVERY'].includes(this.props.componentType!)) ? IS_PHONE(accountPhonePrefix, accountPhone) && insertCode : true);
        return (
            <div className='complete-info-component'>
                <div className='info-top-bar'>
                    <ArrowBackIos
                        onClick={() => {
                            this.props.closeCheckoutComponent()
                        }}
                    />
                    <Typography variant='subtitle1' className='complete-title'>
                        {i18n.t('signup_page_text_perfect_information')}
                    </Typography>
                </div>
                {/* 账号信息 */}
                {isVisibleAccount && <div className='customer-com'>
                    <div className='customer-name'>
                        <div className='icon'>
                            <Person />
                        </div>
                        <Typography variant='subtitle1' className='customer-name-title'>
                            {i18n.t('text_client_fiscal_information')}{['BOOKING', 'DELIVERY'].includes(this.props.componentType!) ? '*' : ''}
                        </Typography>
                    </div>
                    <div className='customer-phone-bind'>
                        <PhoneInput
                            className={'signUpPhoneInput'}
                            hideLabel={true}
                            selectItem={accountPhonePrefix}
                            defaultInput={accountPhone}
                            handleChange={(val) => {
                                this.setState({ accountPhone: val?.trim() })
                            }}
                            handleSelect={(val) => {
                                this.setState({ accountPhonePrefix: val })
                            }}
                            type='autoComplete'
                        />
                        <div className='interval' />
                        {/* 输入验证码 */}
                        <VerificationCode
                            codeSecond={vcodeTimeMS}
                            className='code-main code-input-main'
                            defaultValue={insertCode}
                            isCountDown={isCountDown}
                            onChangeSecond={async () => {
                                if (this.signInBtnAvailable === false) return;
                                this.signInBtnAvailable = false;
                                const insertType = IS_PHONE(accountPhonePrefix, accountPhone)
                                if (insertType) {
                                    // 发送验证码类型 
                                    const postData = {
                                        account_type: 'CUSTOMER',
                                        type: 'BIND_PHONE',
                                        phone_prefix: accountPhonePrefix,
                                        phone: accountPhone
                                    }
                                    //  请求验证码
                                    const RESULT_VCODE = await apiManage.post_admin_code(postData)
                                    // 错误捕捉
                                    if (RESULT_VCODE.error) {
                                        showApiMessage(RESULT_VCODE, 'vCodeEmail')
                                        return
                                    }

                                    this.setState({
                                        isShowSendText: true
                                    })
                                    this.startTiming();
                                } else {
                                    Notification.notification(i18n.t(`${i18n.t('delivery_address_page_text_please_enter_correct_phone')}`));
                                }
                                this.signInBtnAvailable = true;

                            }}
                            onChange={(val: string) => {
                                this.setState({
                                    insertCode: val
                                });
                            }} />
                    </div>
                </div>}
                {
                    isVisibleCustomerName &&
                    <div className='customer-com'>
                        <div className='customer-name'>
                            <div className='icon'>
                                <Person />
                            </div>
                            <Typography variant='subtitle1' className='customer-name-title'>
                                {i18n.t('text_personal_information')}*
                            </Typography>
                        </div>
                        <div className='name'>
                            <InputComponent
                                label={i18n.t('delivery_address_page_text_enter_your_first_name')}
                                value={firstName}
                                onChange={(val: string | number) => {
                                    if (val) {
                                        if (IS_CUSTOMER_NAME.test(val.toString())) {
                                            this.setState({ firstName: val.toString() })
                                        }
                                    } else {
                                        this.setState({ firstName: '' })
                                    }
                                }}
                            />
                            <div className='interval' />
                            <InputComponent
                                label={i18n.t('delivery_address_page_text_enter_your_last_name')}
                                value={lastName}
                                onChange={(val: string | number) => {
                                    if (val) {
                                        if (IS_CUSTOMER_NAME.test(val.toString())) {
                                            this.setState({ lastName: val.toString() })
                                        }
                                    } else {
                                        this.setState({ lastName: '' })
                                    }
                                }}
                            />
                        </div>
                    </div>
                }
                {/* 密码确认密码 */}
                {isVisiblePassword ?
                    <div className='password-box'>
                        <div className='password-title'>
                            <div className='icon'>
                                <VpnKey />
                            </div>
                            <span className='password-text'>{i18n.t('signin_page_text_password_optional')}</span>
                        </div>
                        <div className='password-container'>
                            <PasswordInput
                                className='password'
                                defaultValue={this.state.password}
                                isSame={true}
                                onChange={(val: string) => {
                                    this.setState({
                                        password: val
                                    });
                                }}
                            />
                        </div>
                        <div className='password-title'>
                            <div className='icon'>
                                <VpnKey />
                            </div>
                            <span className='password-text'>{i18n.t('signup_page_text_repeat_password')}</span>
                        </div>
                        <div className='password-container'>
                            <PasswordInput
                                className='password'
                                defaultValue={this.state.newPassword}
                                isSame={this.state.password === this.state.newPassword}
                                onChange={(val: string) => {
                                    this.setState({
                                        newPassword: val
                                    });
                                }}
                            />
                        </div>
                    </div>
                    : null}
                {
                    isOrderway && isVisibleAddress &&
                    <div className='customer-com'>
                        <div className='customer-name'>
                            <div className='icon'>
                                <Room />
                            </div>
                            <Typography variant='subtitle1' className='customer-name-title'>
                                {i18n.t('payment_page_text_customer_info')}
                            </Typography>
                        </div>
                        <div className='name'>
                            <GoogleMapComponent
                                componentType={'completeInfo'}
                                defaultValue={addressLocal}
                                className='complete-google-map'
                                placeholder={i18n.t('delivery_address_page_text_enter_your_address')}
                                setInputVal={(value: string) => this.setState({ googleInputVal: value })}
                                setIsGoogleAdress={(val: boolean) => this.setState({ isGoogleAddress: val })}
                                onSave={(val: Address) => {
                                    this.setState({
                                        address: val,
                                        addressLocal: _.get(val, 'google_map_formatted_address') || '',
                                        zipCode: _.get(val, 'postal_code') || '',
                                        isGoogleCodeInput: _.get(val, 'postal_code') || ''
                                    });
                                }}
                            />
                            {!isGoogleAddress ? <div className={'tipsText'}>{i18n.t('general_text_incorrect_address_entered')}</div> : null}
                            <div className='interval' />
                            <InputComponent
                                label={i18n.t('delivery_address_page_text_enter_your_home_number')}
                                value={doorbellNnumber}
                                onChange={(val: string | number) => this.setState({ doorbellNnumber: val.toString() })}
                            />
                            <div className='interval' />
                            <div id='input-component' >
                                <InputComponent
                                    label={i18n.t('delivery_address_page_text_enter_your_zip_code')}
                                    value={zipCode}
                                    onChange={(val: string | number) => this.setState({ zipCode: val.toString() })}
                                    blurEvent={(inputValue: string) => {
                                        try {
                                            const input = document.getElementById('input-component');
                                            const searchBox = document.getElementById('google-map-search');
                                            const completeInfoBox = document.getElementById('completeInfoBox');
                                            if (isGoogleCodeInput === inputValue || !inputValue || !isGoogleCodeInput) {
                                                if (input) {
                                                    input.style.border = 'unset'
                                                }
                                                // 这里还要在判断谷歌地址和输入地址是否相同
                                                if (searchBox && (googleInputVal === addressLocal || !googleInputVal)) {
                                                    searchBox.style.border = 'unset';
                                                    this.setState({ isGoogleAddress: true })
                                                }
                                            } else {
                                                if (input) {
                                                    input.style.border = '1px solid #fd7b88';
                                                    input.style.borderRadius = '88px'
                                                }
                                                if (searchBox) {
                                                    searchBox.style.boxSizing = 'border-box';
                                                    searchBox.style.border = '1px solid #fd7b88';
                                                    searchBox.style.height = 'unset';
                                                }
                                                if (completeInfoBox) completeInfoBox.style.margin = 'unset';
                                                this.setState({ isGoogleAddress: false })
                                            }
                                        } catch (error) {

                                        }
                                    }}
                                />
                            </div>

                        </div>
                    </div>

                }
                {
                    isReferralsCode && <div className='customer-com'>
                        {/* 邀请码选填 */}
                        <div className='customer-name'>
                            <div className='icon'>
                                <StarsOutlined />
                            </div>
                            <Typography variant='subtitle1' className='customer-name-title'>
                                {`${i18n.t('sing_in_phone_page_referrals_code')}`}
                            </Typography>
                        </div>
                        <div className='name'>
                            <InputComponent
                                label={i18n.t('general_option')}
                                value={this.props.referralsCode ?? ''}
                                onChange={(e: string | number) => {
                                    console.log(e);
                                    // 正则输入验证合法性 大写字母和数字随机生成5位
                                    const value = e.toString().toLocaleUpperCase();
                                    const regex = /^[A-Z0-9]{0,5}$/;
                                    console.log(value, regex.test(value));
                                    if (regex.test(value)) {
                                        this.props.updateState({ referralsCode: value });
                                    }
                                }}
                            />
                        </div>
                    </div>
                }

                {
                    isOrderway && isVisibleAddress &&
                    <div className='checkbox-repi-com'>
                        <div className='checkbox' onClick={() => this.setState({ isReplaceRepi: !isReplaceRepi })}>
                            {
                                isReplaceRepi && <img className='img' alt='' src={vLogo} />
                            }
                        </div>
                        <Typography variant='subtitle1' className='text'>
                            {i18n.t('delivery_address_page_text_contact_person')}
                        </Typography>
                    </div>
                }
                {
                    (isVisibleAddress && isReplaceRepi) && <div className='replce-repi-area'>
                        <InputComponent
                            label={i18n.t('delivery_address_page_text_enter_your_first_name')}
                            value={replaceFirstName}
                            onChange={(val: string | number) => {
                                if (val) {
                                    if (IS_CUSTOMER_NAME.test(val.toString())) {
                                        this.setState({ replaceFirstName: val.toString() })
                                    }
                                } else {
                                    this.setState({ replaceFirstName: '' })
                                }
                            }}
                        />
                        <div className='interval' />
                        <InputComponent
                            label={i18n.t('delivery_address_page_text_enter_your_last_name')}
                            value={replaceLastName}
                            onChange={(val: string | number) => this.setState({ replaceLastName: val.toString() })}
                        />
                        <div className='interval' />

                        <PhoneInput
                            className={'signUpPhoneInput'}
                            hideLabel={true}
                            selectItem={phonePrefix}
                            defaultInput={phone}
                            handleChange={(val) => {
                                this.setState({
                                    phone: val
                                })
                            }}
                            handleSelect={(val) => {
                                this.setState({
                                    phonePrefix: val
                                });
                            }}
                            type='autoComplete'
                        />
                    </div>
                }
                {
                    isOrderway && isVisibleAddress &&
                    <div className='checkbox-repi-com'>
                        <div className='checkbox' onClick={() => this.setState({ isDefaultAddress: !isDefaultAddress })}>
                            {
                                isDefaultAddress && <img className='img' alt='' src={vLogo} />
                            }
                        </div>
                        <Typography variant='subtitle1' className='text'>
                            {i18n.t('delivery_address_page_text_set_as_default')}
                        </Typography>
                    </div>
                }

                <ButtonComponent
                    className={confirmBtnAvailable ? 'complace-btn-active' : 'complace-btn'}
                    value={i18n.t('payment_page_text_next')}
                    onClick={() => {
                        if (confirmBtnAvailable) {
                            this.updateCustomerInformation();
                        }
                    }}
                />
                {isOpenDialog &&
                    <BindPhoneDialog
                        onConfirm={() => { this.updateCustomerInformation(true); }}
                        handleClose={() => { this.setState({ isOpenDialog: false }) }} />}
            </div>
        )
    }

}

export default CheckoutCompleteCustomerInfo;

interface State extends ICheckoutCompleteCustomerInfoState {
    addressLocal: string;
    // 代替收件人姓
    replaceLastName: string;
    // 代替收件人姓
    replaceFirstName: string;
    doorbellNnumber: string;
    phonePrefix: string;
    phone: string;
    zipCode: string;
    password: string;
    newPassword: string;
    isGoogleAddress: boolean;
    isGoogleCodeInput: string;
    googleInputVal: string;
    // 验证码
    insertCode: string;
    // 是否在倒计时
    isCountDown: boolean;
    // 显示已发送的文本
    isShowSendText: boolean;
    accountPhonePrefix: string;
    accountPhone: string;
    isOpenDialog: boolean;
}

interface Props extends ICheckoutCompleteCustomerInfoProps {
    thirdPartyToken: string;
    thirdPartyType: 'GOOGLE' | 'FACEBOOK' | undefined;
    componentType?: 'BOOKING' | 'DELIVERY' | 'COMPLETE_CUSTOMER';
    referralsCode?: string | null;
    updateOrderPageState?: () => void;
}