import React from 'react';
import { ICheckoutShowedPageNames, ICheckoutSignInInputPhone, ICheckoutSignInInputPhoneProps, ICheckoutSignInInputPhoneState } from '../../interfaces';
import { Typography, FormControlLabel, Checkbox, Drawer, Button } from '@material-ui/core';
import { PhoneInput } from '../../component/payment/payment.componet';
import ButtonComponent from '../button.component';
import './scss/checkout-sign-in-phone.scss';
import { MdChevronLeft, MdClose } from 'react-icons/md';
import { IS_PHONE } from '../../constants/base.constant';

import ReCaptChaDialog from '../recaptcha-dialog/recaptcha-dialog';
import { FACKBOOK_APP_ID, GOOGLE_ROBOT_SCORE } from '../../gconfig';
import apiManage from '../../request';
import Notification from '../../component/snackbar/snackbar';
import i18n from '../../i18n';
import _ from 'lodash';
import { PrivacyApgreementDialog } from '../privacy-apgreement/privacy-apgreement-dialog.componet';
import { FeatGoogleReCaptcha } from '../../page/sign-in/sign-in.page';
import { GoogleLoginFixed } from '../google-login/google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import facebookPic from '../../static/images/facebook.png';

class SignInPhonePage extends React.Component<Props, State> implements ICheckoutSignInInputPhone {
    public state: State = {
        // 谷歌人机校验获取token
        showGoogleReCaptcha: false,
        // 谷歌人机校验图像弹窗
        showReCaptChaDialog: false,
        // 是否通过人机校验
        isPassReCaptcha: false,
        isShowPrivacyApgreementDialog: false,
        isAgree: false,
        bottomPanelVisible: false
    }

    signInBtnAvailable = true;

    /**
     * 通过reCaptchaToken解析分值
     */
    public async checkReCaptchaToken(reCaptchaToken) {
        try {
            if (reCaptchaToken) {
                const result = await apiManage.googleReCaptchaSiteverify({ token: reCaptchaToken })
                if (!_.get(result, 'error')) {
                    if (result.score <= GOOGLE_ROBOT_SCORE) {
                        this.setState({
                            showReCaptChaDialog: true
                        })
                    } else {
                        // 评分高，已验证不是机器人
                        this.setState({
                            isPassReCaptcha: true
                        })
                        // 发送验证码
                        await this.sendPhoneGetVerificationCode();
                    }
                }
            }
        } catch (error) { }
    }

    /**
     * 处理ReCaptchaV2回调
     */
    public handelReCaptchaCallBack(result: 'Success' | 'Expire' | 'Error') {
        // 操作成功
        let message = 'general_text_successful_operation';
        if (result === 'Success') {
            // 通过成功
            this.setState({
                isPassReCaptcha: true
            })
            // 发送验证码
            this.sendPhoneGetVerificationCode();
        } else if (result === 'Expire' || result === 'Error') {
            // 操作失败
            message = 'general_text_operation_failed';
        }
        // 提示
        Notification.notification(i18n.t(message));
    }

    /**
     * 发送验证码并且跳转验证码页面
     * 根据isShowForgetPassword判断跳转普通验证码页面还是忘记密码页面
     */
    public async sendPhoneGetVerificationCode() {
        const { isShowForgetPassword } = this.props;
        // 跳转验证码页面
        if (!isShowForgetPassword) {
            this.props.updateShowedPage(ICheckoutShowedPageNames.SIGN_IN_INPUT_VCODE_PAGE)
        }
        // 发送验证码
        await this.props.sendPhoneGetVerificationCode();
    }

    // *********************
    // View
    // *********************
    render() {
        const { showGoogleReCaptcha, showReCaptChaDialog, isPassReCaptcha, isShowPrivacyApgreementDialog, isAgree, bottomPanelVisible } = this.state;
        const { phonePrefix, phone, isShowBack } = this.props;

        return (
            <div className='checkout-sign-in-phone'>
                <div className='headBox'>
                    <span className='actionIcon' onClick={() => this.props.closeCheckoutComponent()}>
                        {
                            isShowBack ?
                                <MdChevronLeft />
                                :
                                <MdClose />
                        }
                    </span>
                    <span className='title'>
                        {i18n.t('delivery_address_page_text_enter_your_phone')}
                    </span>
                </div >
                <PhoneInput
                    className={'signUpPhoneInput'}
                    hideLabel={true}
                    selectItem={phonePrefix}
                    defaultInput={phone}
                    handleChange={(val) => {
                        this.props.updateState({
                            phone: val?.trim()
                        });
                    }}
                    handleSelect={(val) => {
                        this.props.updateState({
                            phonePrefix: val
                        });
                    }}
                    type='autoComplete'
                />
                <Typography className='tip-text'>
                    {i18n.t('delivery_address_page_signin_prompt_message')}
                </Typography>
                {/* 选择其他登录方式 */}
                <Button
                    disabled={!isAgree}
                    fullWidth
                    className={'select-other-login-method-button'}
                    variant='contained'
                    onClick={() => {
                        this.setState({
                            bottomPanelVisible: true
                        })
                    }}
                >
                    {i18n.t('signin_page_select_other_methods')}
                </Button>
                <ButtonComponent
                    className={!(IS_PHONE(phonePrefix, phone) && isAgree) ? 'signup-button-not-phone' : 'signup-button-div'}
                    value={i18n.t('payment_page_text_next')}
                    onClick={async () => {
                        if (!(IS_PHONE(phonePrefix, phone) && isAgree)) { return; }
                        if (this.signInBtnAvailable === false) { return; }
                        this.signInBtnAvailable = false;
                        try {
                            if (isPassReCaptcha) {
                                await this.sendPhoneGetVerificationCode();
                            } else {
                                this.props.checkoutClearInterval();
                                this.setState({ showGoogleReCaptcha: true });
                            }
                        } finally {
                            this.signInBtnAvailable = true;
                        }
                    }}
                />

                <div className='agreePolicy'  >
                    <FormControlLabel
                        className='agreePolicyCheckBox'
                        control={
                            <Checkbox
                                checked={this.state.isAgree}
                                onChange={() => {
                                    this.setState({
                                        isAgree: !this.state.isAgree
                                    })
                                }}
                                value='checkBoxValue'
                                style={{ color: '#ffdc33' }}
                            />
                        }
                        label={<>
                            <span className='agreeText'>
                                {i18n.t('privacy_agreement_text_by_logging_in_registering_you_agree_to')}
                            </span>
                            <span className='clickPolicyText'
                                onClick={(e) => { e.stopPropagation(); this.setState({ isShowPrivacyApgreementDialog: true }) }}
                            >
                                {i18n.t('privacy_agreement_text')}
                            </span>
                        </>}
                    />
                </div>
                {/* 谷歌人机校验获取token*/}
                {<FeatGoogleReCaptcha
                    showGoogleReCaptcha={showGoogleReCaptcha}
                    onVerify={async (token) => {
                        this.setState({
                            showGoogleReCaptcha: false
                        }, () => {
                            this.checkReCaptchaToken(token);
                        })
                    }} />}
                {/* 谷歌人机选择图像校验*/}
                {showReCaptChaDialog &&
                    <ReCaptChaDialog
                        handleClose={() => { this.setState({ showReCaptChaDialog: false }) }}
                        handelReCaptchaCallBack={(result) => { this.handelReCaptchaCallBack(result) }}
                    />}
                {isShowPrivacyApgreementDialog
                    ? <PrivacyApgreementDialog
                        // onConfirm={() => { this.isAgree() }}
                        handleClose={() => { this.setState({ isShowPrivacyApgreementDialog: false }) }}
                    />
                    : null}
                <Drawer
                    anchor='bottom'
                    onClose={() => {
                        this.setState({
                            bottomPanelVisible: false
                        })
                    }}
                    open={bottomPanelVisible}>
                    <div className='bottomPanel'>
                        <GoogleLoginFixed
                            callback={(response) => {
                                this.props.jumpToPerfectInfo('GOOGLE', response.credential);
                                this.setState({ bottomPanelVisible: false });
                            }}
                        />
                        <FacebookLogin
                            appId={FACKBOOK_APP_ID}             // facebook应用id
                            autoLoad={false}                         // 自动加载
                            disableMobileRedirect={true}
                            fields='name,email,picture'
                            scope='public_profile'     // 自定义权限
                            callback={async (response) => {
                                this.props.jumpToPerfectInfo('FACEBOOK', response.accessToken)
                            }}        // 调用 responseFacebook 方法
                            render={renderProps => (
                                <div className='facebook' onClick={() => { renderProps.onClick() }}>
                                    <img alt='' className='img' src={facebookPic} />
                                    <span className='text'>{i18n.t('signin_page_text_login_with_Facebook')}</span>
                                </div>
                            )}
                        />
                        <Button
                            fullWidth
                            className='bottomPanelButton cancelPanelButton'
                            variant='contained'
                            onClick={() => this.setState({
                                bottomPanelVisible: false
                            })}>
                            {i18n.t('general_text_cancel')}
                        </Button>
                    </div>
                </Drawer>
            </div>
        )
    }
}

export default SignInPhonePage


// *********************
// Props & State
// *********************

interface State extends ICheckoutSignInInputPhoneState {
    showGoogleReCaptcha: boolean;
    showReCaptChaDialog: boolean;
    isPassReCaptcha: boolean;
    isShowPrivacyApgreementDialog: boolean;
    isAgree: boolean;
    bottomPanelVisible: boolean;
}

interface Props extends ICheckoutSignInInputPhoneProps {
    sendPhoneGetVerificationCode: () => void;
    // 显示返回还是关闭按钮 默认显示关闭按钮
    isShowBack?: boolean;
    // 点击下一步是否跳转忘记密码页面 默认为否
    isShowForgetPassword?: boolean;
    checkoutClearInterval: () => void;
}


