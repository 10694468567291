
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    // default
    card: {
        maxWidth: 800,
        // minWidth: 350,
        width: 'auto',
        backgroundColor: 'transparent'
    },
    typography: {
        fontWeight: 'bold'
    },
    icons: {
        marginLeft: 'auto'
    },
    expand: {
        padding: '8px 8px',
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },
    collapse: {
        padding: 16
    },
    checkIcon: {
        fontSize: 20,
        color: '#b3b3b3',
        paddingRight: 4
    },
    button: {
        padding: 0,
        textTransform: 'none'
    },
    // root style
    actionRootWarning: {
        padding: '8px 8px 8px 16px',
        backgroundColor: '#ffa027'
    },
    actionRootError: {
        padding: '8px 8px 8px 16px',
        backgroundColor: '#d43032'
    },
    actionRootDefault: {
        padding: '8px 8px 8px 16px',
        backgroundColor: '#3e51b2'
    },
    actionRootSuccess: {
        padding: '8px 8px 8px 16px',
        backgroundColor: '#42a04e'
    },
    actionRootInfo: {
        padding: '8px 8px 8px 16px',
        backgroundColor: '#2579fb'
    }
}));

const SnackMessageComponent = React.forwardRef<any, SnackMessageComponentProps>(function SnackMessageComponent(props, ref) {
    // init
    const classes = useStyles('' as any);
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);

    return (
        <Card className={classes.card} ref={ref}>
            <CardActions classes={{ root: (classes as any)[`actionRoot${props.level}`] }}>
                <Typography variant='subtitle2' className={classes.typography}>{props.message}</Typography>
                <div className={classes.icons}>
                    {
                        Array.isArray(props.details) && props.details.length > 0 ?
                            (
                                <IconButton
                                    onClick={() => {
                                        setExpanded(!expanded);
                                    }}
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            )
                            :
                            typeof props.details === 'string' ?
                                (
                                    <IconButton
                                        onClick={() => {
                                            setExpanded(!expanded);
                                        }}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                )
                                : null
                    }
                    <IconButton
                        className={classes.expand}
                        onClick={() => {
                            closeSnackbar((props as any).id);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </CardActions>
            {
                Array.isArray(props.details) && props.details.length > 0 ?
                    (
                        <Collapse in={expanded} timeout='auto' unmountOnExit>
                            <Paper className={classes.collapse}>
                                {
                                    props.details.map((detail, index) =>
                                        <Typography key={`snack-message-${detail}-${index}`}>{`${detail}`}</Typography>
                                    )
                                }
                            </Paper>
                        </Collapse>
                    )
                    :
                    typeof props.details === 'string' ?
                        (
                            <Collapse in={expanded} timeout='auto' unmountOnExit>
                                <Paper className={classes.collapse}>
                                    <Typography >{`${props.details}`}</Typography>
                                </Paper>
                            </Collapse>
                        ) : null
            }
        </Card>
    );
});

type SnackMessageComponentProps = {
    id: any;
    level: 'Warning' | 'Error' | 'Default' | 'Info' | 'Success';
    message: string;
    details?: any[] | string;
};

export default SnackMessageComponent;