/**
 * @author liweikang
 */

import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FaceIcon from '@material-ui/icons/Face';
import RingVolumeIcon from '@material-ui/icons/RingVolume';
import StatusButtonComponent from './order-status-button.component'
import BookStatusButtonComponent from './booking-order-status-button.component'
import { Address, Bag, Customer, Restaurant, OrderDish, Order } from '../../models';
import GridLayoutComponent from './grid-layout.component';
import moment from 'moment';
import _ from 'lodash';
import { getPaymentText } from '../../constants/order-tool';
class OrderDetailPanelComponent extends React.Component<Props, State> {

    // *********************
    // 构造函数
    // *********************
    constructor(props: Props) {
        super(props);
        this.state = {
        }
    }

    // *********************
    // 生命周期
    // *********************
    componentDidMount() {
        // we check if already has a account
    }

    // *********************
    // 方法区
    // *********************

    /**
     * 格式化日期
     * @param date 日期
     * @param format 要转换的格式
     */
    formateDate(date: string, format: string) {
        return date ? moment(date, moment.HTML5_FMT.DATETIME_LOCAL_MS).format(format) : ''
    }
    newFormateDate(date: string, format: string) {
        if (date) {
            const step = this.props.orderDetailData.type === '1' ? 15 : 20;
            return `${moment(date, moment.HTML5_FMT.DATETIME_LOCAL_MS).format(format)} - ${moment(date, moment.HTML5_FMT.DATETIME_LOCAL_MS).add(step, 'minute').format(format)}`
        }
        return ''
    }
    // *********************
    // 视图区
    // *********************

    render() {
        const { orderDetailData, t, componentType } = this.props
        const restaurantName = _.get(orderDetailData, 'belonged_restaurant.nickname', '');
        const street = orderDetailData?.address?.street || '';
        const homeNumber = '';
        const code = orderDetailData?.address?.code || '';
        const city = orderDetailData?.address?.city || '';
        const state = orderDetailData?.address?.state || '';
        const country = orderDetailData?.address?.country || '';
        const customerPhonePrefix = orderDetailData?.address?.phone_prefix || '';
        const customerPhone = orderDetailData?.address?.phone || '';
        const firstName = orderDetailData?.address?.first_name || '';
        const lastName = orderDetailData?.address?.last_name || '';
        const orderTime = orderDetailData?.create_time || '';
        const deliveryTime = orderDetailData?.delivery_time || '';
        const pickupTime = orderDetailData?.selftake_time || '';
        const staffPickupArr = _.filter(orderDetailData?.records, (n) => { return n.key === 'staff_selftake_time' });
        const staffPickupTime = _.get(staffPickupArr[0], 'value') || '';
        const staffDeliveryArr = _.filter(orderDetailData?.records, (n) => { return n.key === 'staff_delivery_time' });
        const staffDeliveryTime = _.get(staffDeliveryArr[0], 'value') || '';
        const comoleteTime = orderDetailData?.comolete_time || '';
        const type = orderDetailData.type || '';
        const isSameDay = this.formateDate(
            _.get(orderDetailData, 'booking_orders.origin_actual_start_time')
            , 'DD/MM/YYYY') ===
            this.formateDate(
                _.get(orderDetailData, 'booking_orders.origin_actual_end_time')
                , 'DD/MM/YYYY')
        // 订单创建来源 OD_WEB OD_APP OD_POS
        const orderCreationOrigin = _.get(orderDetailData, 'order_creation_origin');
        // 支付方式文本（区分POS下单、顾客端下单）
        const paymentTypeText = orderCreationOrigin === 'OD_POS' ? _.get(orderDetailData, 'payment_detail.payment_way_pos_name') : t(getPaymentText(orderDetailData as unknown as Order));
        return (
            <div className='orderDetailPanel'>
                <div className='showDetail'>
                    <div className='detailHead'>
                        <h1 className='title'>{restaurantName}</h1>
                        {componentType === 'myReservations' ?
                            <BookStatusButtonComponent
                                status={orderDetailData?.booking_orders?.origin_status || ''}
                            ></BookStatusButtonComponent> :
                            <StatusButtonComponent
                                status={orderDetailData.status || ''}
                                deliveryStatus={orderDetailData.delivery_status || ''}
                                payMentStatus={orderDetailData.payment_status || ''}
                                paymentType={orderDetailData?.payment_type}
                            ></StatusButtonComponent>}
                    </div>
                    {/* 外卖订单 */}
                    {componentType !== 'myReservations' ?
                        <div className='container'>
                            <GridLayoutComponent
                                columns={3}
                                leftText={t('order_page_text_order_time')}
                                centerText={this.formateDate(orderTime, 'DD/MM/YYYY')}
                                rightText={this.formateDate(orderTime, 'HH:mm')}
                            ></GridLayoutComponent>
                            {
                                type === '1' ?
                                    <GridLayoutComponent
                                        columns={3}
                                        leftText={t('payment_page_text_takeaway_time')}
                                        centerText={this.formateDate(pickupTime, 'DD/MM/YYYY')}
                                        rightText={this.newFormateDate(pickupTime, 'HH:mm')}
                                    ></GridLayoutComponent>
                                    : type === '0' ?
                                        <GridLayoutComponent
                                            columns={3}
                                            leftText={t('general_text_delivery_time')}
                                            centerText={this.formateDate(deliveryTime, 'DD/MM/YYYY')}
                                            rightText={this.newFormateDate(deliveryTime, 'HH:mm')}
                                        ></GridLayoutComponent> : null
                            }
                            {
                                type === '1' ?
                                    (staffPickupTime ?
                                        <GridLayoutComponent
                                            columns={3}
                                            leftText={t('general_text_restaurant_estimated_delivery_time')}
                                            centerText={this.formateDate(staffPickupTime, 'DD/MM/YYYY')}
                                            rightText={this.formateDate(staffPickupTime, 'HH:mm')}
                                        ></GridLayoutComponent> : null)
                                    : type === '0' ?
                                        (staffDeliveryTime ?
                                            <GridLayoutComponent
                                                columns={3}
                                                leftText={t('general_text_restaurant_estimated_delivery_time')}
                                                centerText={this.formateDate(staffDeliveryTime, 'DD/MM/YYYY')}
                                                rightText={this.formateDate(staffDeliveryTime, 'HH:mm')}
                                            ></GridLayoutComponent>
                                            : null)
                                        : null
                            }
                            <GridLayoutComponent
                                columns={3}
                                leftText={t('order_page_text_complete_time')}
                                centerText={this.formateDate(comoleteTime, 'DD/MM/YYYY')}
                                rightText={this.formateDate(comoleteTime, 'HH:mm')}
                            ></GridLayoutComponent>
                            <GridLayoutComponent
                                columns={2}
                                leftText={t('order_page_text_payment_method')}
                                rightText={paymentTypeText}
                            ></GridLayoutComponent>
                            <GridLayoutComponent
                                columns={3}
                                leftText={t('my_reservations_page_text_name')}
                                rightText={
                                    `${_.get(orderDetailData, 'belonged_customer.first_name', '')}
                                        ${_.get(orderDetailData, 'belonged_customer.last_name', '')}
                                        `
                                }
                            ></GridLayoutComponent>
                            <GridLayoutComponent
                                columns={3}
                                leftText={t('reservation_page_text_phone')}
                                rightText={
                                    `+${_.get(orderDetailData, 'belonged_customer.phone_prefix', '')}
                                        ${_.get(orderDetailData, 'belonged_customer.phone', '')}
                                        `
                                }
                            ></GridLayoutComponent>
                            {
                                type === '0' ?
                                    <div className='contact'>
                                        <h3 className='title'>{t('order_page_text_delivery_to')}</h3>
                                        <div className='contactDetail'>
                                            <Grid container wrap='nowrap' alignItems={'center'} spacing={2}>
                                                <Grid item xs={2}>
                                                    <RoomIcon style={{ color: '#ffdc33' }}></RoomIcon>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {/* <div>Modena</div> */}
                                                    <div className='address'>{`${street} ${homeNumber} ${code} ${city} ${state} ${country}`}</div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ArrowForwardIosIcon style={{ display: 'none' }} />
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap='nowrap' alignItems={'center'} spacing={2}>
                                                <Grid item xs={2}>
                                                    <FaceIcon></FaceIcon>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <div>{`${firstName} ${lastName}`}</div>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap='nowrap' alignItems={'center'} spacing={2}>
                                                <Grid item xs={2}>
                                                    <RingVolumeIcon></RingVolumeIcon>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <div>{customerPhonePrefix} {customerPhone}</div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div> : null}
                    {/* 预定订单 */}
                    {componentType === 'myReservations' ?
                        <div className='container'>
                            <GridLayoutComponent
                                columns={2}
                                leftText={t('my_reservations_page_text_book_the_order_number')}
                                rightText={_.get(orderDetailData, 'booking_orders.origin_id', '')}
                            ></GridLayoutComponent>
                            <GridLayoutComponent
                                columns={3}
                                leftText={t('my_reservations_page_text_book_time')}
                                centerText={
                                    this.formateDate(_.get(orderDetailData,
                                        'booking_orders.origin_actual_start_time'), 'DD/MM/YYYY')
                                }
                                rightText={
                                    `${this.formateDate(_.get(orderDetailData,
                                        'booking_orders.origin_actual_start_time'), 'HH:mm')} ${isSameDay ? '-' : ''} ${isSameDay ? this.formateDate(_.get(orderDetailData,
                                            'booking_orders.origin_actual_end_time'), 'HH:mm') : ''}`
                                }
                            ></GridLayoutComponent>
                            {!isSameDay ?
                                <GridLayoutComponent
                                    columns={3}
                                    leftText={t('')}
                                    centerText={
                                        this.formateDate(
                                            _.get(orderDetailData, 'booking_orders.origin_actual_end_time')
                                            , 'DD/MM/YYYY')
                                    }
                                    rightText={`- ${this.formateDate(
                                        _.get(orderDetailData, 'booking_orders.origin_actual_end_time'), 'HH:mm')}`
                                    }
                                ></GridLayoutComponent> : null
                            }
                            <GridLayoutComponent
                                columns={3}
                                leftText={t('reservation_page_text_number_of_people')}
                                rightText={_.get(orderDetailData, 'booking_orders.origin_customer_num', '')}
                            ></GridLayoutComponent>
                            <GridLayoutComponent
                                columns={3}
                                leftText={t('my_reservations_page_text_name')}
                                rightText={
                                    `${_.get(orderDetailData, 'booking_orders.origin_first_name', '')}
                                        ${_.get(orderDetailData, 'booking_orders.origin_last_name', '')}
                                        `
                                }
                            ></GridLayoutComponent>
                            <GridLayoutComponent
                                columns={3}
                                leftText={t('reservation_page_text_phone')}
                                rightText={
                                    `+${_.get(orderDetailData, 'booking_orders.origin_phone_prefix', '')}
                                        ${_.get(orderDetailData, 'booking_orders.origin_phone', '')}
                                        `
                                }
                            ></GridLayoutComponent>
                            {
                                _.get(orderDetailData, 'booking_orders.origin_remark') ? <div>
                                    <GridLayoutComponent
                                        columns={1}
                                        leftText={t('restaurant_order_page_text_dish_remarks')}
                                    ></GridLayoutComponent>
                                    <div className='requirementsText'>
                                        {_.get(orderDetailData, 'booking_orders.origin_remark', '')}
                                    </div>
                                </div> : null
                            }
                        </div>
                        : null}
                </div>
            </div >
        );
    }

}

export default withTranslation()<Props>(OrderDetailPanelComponent as any);

// *********************
// Types
// *********************

type State = {
}

type Props = {
    onClick?: (value: string[], event?: any) => void;
    orderDetailData: {
        _id: string;
        address: Address;
        bags: Bag[];
        belonged_customer: Array<Customer>;
        belonged_restaurant: Restaurant;
        dishes: {
            totalNum: number;
            data: OrderDish[]
        };
        [key: string]: any
    };
    // 类型 - myReservations:我的预定
    componentType?: string;
} & WithTranslation
