import { ICheckoutIncomingProps } from '.';
import { PropType } from '../constants/base.constant';
import { Address, Schedule, CheckoutCoupon, SettingClosedSchedule, Customer } from '../models';
import { CheckoutDetailCartDish } from './checkout-cart.interface';

/**
 * 订单详情页面
 */
export interface ICheckoutOrderDetail {

    state: ICheckoutOrderDetailState;

    props: ICheckoutOrderDetailProps;

    /**
     * 初始化
     *
     * @步骤
     * - 获取订单交付方式：地址渠道
     * - 获取餐馆信息：缓存渠道
     * - 获取顾客信息：接口渠道
     * - 获取餐馆免领取优惠券：接口渠道
     * - 获取餐馆LOGO图片：接口渠道
     * - 调用子组件初始化方法：`initFulfillmentTime()`
     * - 调用子组件初始化方法：`initFulfillmentAddress()`
     * - 调用子组件初始化方法：`initCoupon()`
     *
     * @注意 禁止在此方法调用setSate方法
     */
    init<T extends ICheckoutOrderDetailState>(): Promise<T>;

    /**
     * 切换子页面
     *
     * #### 子页面对应的顶部栏的样式
     * - `MAIN_PAGE` 左上角为关闭结算托盘（关闭icon），中间标题为“结算页面”
     * - `NEW_ADDRESS_PAGE` 左上角为返回`MAIN_PAGE`页面（返回icon），中间标题为“新增地址”
     * - `COUPON_LIST_PAGE` 左上角为返回`MAIN_PAGE`页面（返回icon），中间标题为“浏览优惠券”
     *
     * @param targetPage 目标子页面
     */
    updateShowedPage(targetPage: PropType<ICheckoutOrderDetailState, 'showedPage'>): void;

    /**
     * 更新State
     *
     * @param update 需要更新的对象
     */
    updateState<S extends Partial<ICheckoutOrderDetailState>>(update: S): void;

    // ***************
    // 子组件初始化类方法
    // ***************

    /**
     * 初始化类 - 初始化履约时间
     *
     * @步骤
     * - 重置履约时间（自动选择距离当前时间最近的履约时间）
     * - 提示是否违反规则
     */
    initFulfillmentTime(): void;

    /**
     * 初始化类 - 初始化履约地址
     *
     * @步骤
     * - 提示不在配送范围内的地址并禁用
     */
    initFulfillmentAddress(customer: Customer, originalTotalPrice: number): void;

    /**
     * 初始化类 - 初始化优惠券
     *
     * @步骤
     * - 将优惠券数据整合
     * - 将优惠券按照规则隐藏或禁用
     */
    initCoupon(): void;

    // ***************
    // 子组件分析类方法
    // ***************

    /**
     * 分析类 - 分析交付时间是否合法
     *
     * 判断的逻辑：
     * - 对应的营业时间表
     * - 对应的停业时间表
     * - 对应的临时停业设置
     *
     * @param clonedStateForFns 克隆的state（注意，所有改动或分析需要基于此克隆版本）
     */
    analysisFulfillmentTime(
        clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions
    ): boolean;

    /**
     * 分析类 - 分析交付地址是否合法
     *
     * 判断的逻辑：
     * - 餐馆的地址
     * - 餐馆的圆形配送费设置
     *
     * @param clonedStateForFns 克隆的state（注意，所有改动或分析需要基于此克隆版本）
     * @param selectedAddress 选择的交付地址
     */
    analysisFulfillmentAddress(
        clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions
    ): boolean;

    /**
     * 分析类 - 分析购物车是否合法
     *
     * 判断的逻辑：
     * - 菜品数量不能大于99
     *
     * @param clonedStateForFns 克隆的state（注意，所有改动或分析需要基于此克隆版本）
     */
    analysisCart(
        clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions,
        ignorePoints: boolean
    ): boolean;

    /**
     * 分析类 - 分析优惠券是否合法
     *
     * 判断的逻辑：
     * - 无
     *
     * @param clonedStateForFns 克隆的state（注意，所有改动或分析需要基于此克隆版本）
     */
    analysisCoupon(
        clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions
    ): boolean;

    // ***************
    // 子组件调整类方法
    // ***************

    /**
     * 调整类 - 购物车
     *
     * @注意 此方法不会对传参`clonedStateForFns`进行任何形式的修改≈
     * @param clonedStateForFns 克隆的state（注意，此方法内的逻辑需要参考此克隆版本的state）
     */
    adjustCart(
        clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions
    ): { cartDishes: Array<CheckoutDetailCartDish>, totalCartDishes: number, originalTotalPrice: number };

    /**
     * 调整类 - 优惠券
     *
     * @注意 此方法不会对传参`clonedStateForFns`进行任何形式的修改
     * @param clonedStateForFns 克隆的state（注意，此方法内的逻辑需要参考此克隆版本的state）
     * @returns
     *
     * - `.allCoupons` 需要被渲染的优惠券
     * - `.selectedCoupons` 当前选择的优惠券
     */
    adjustCoupon(
        clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions
    ): { allCoupons: Array<CheckoutCoupon>, selectedCoupons: Array<CheckoutCoupon> };

    /**
     * 调整类 - 总价
     *
     * @注意 此方法不会对传参`clonedStateForFns`进行任何形式的修改
     * @param clonedStateForFns 克隆的state（注意，此方法内的逻辑需要参考此克隆版本的state）
     * @returns
     *
     * - `.subtotal` 小计
     * - `.discount` 折扣
     * - `.originalDeliveryFee` 原始配送费
     * - `.discountedDeliveryFee` 折扣后配送费
     * - `.totalPrice` 总价格
     */
    adjustTotal(
        clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions
    ): {
        discount: number,
        discountedDeliveryFee: number,
        finalOrderPrice: number
    };

    // ***************
    // 子组件分析并调整类方法
    // ***************

    /**
     * 分析和修改的方法
     *
     * 初始阶段：
     * - 调用setState()方法（isDisableCustomerAction设置为true）
     * - 制作克隆版本的state
     * - 执行修改操作
     *
     * 分析阶段：
     * - analysisFulfillmentTime()（若不通过，则不会运行剩余阶段）
     *
     * 调整阶段：
     * - adjustCoupon()
     * - adjustCart()
     * - adjustTotal()
     *
     * 应用阶段：
     * - 调用setState()更新视图（isDisableCustomerAction设置为false）
     */
    analysisAndAdjustFulfillmentTime(
        selectedDate: Date
    ): void;

    /**
     * 分析和修改的方法
     *
     * 初始阶段：
     * - 调用setState()方法（isDisableCustomerAction设置为true）
     * - 制作克隆版本的state
     * - 执行修改操作
     *
     * 分析阶段：
     * - analysisFulfillmentAddress() （若不通过，则不会运行剩余阶段）
     *
     * 调整阶段：
     * - adjustTotal()
     *
     * 应用阶段：
     * - 调用setState()更新视图（isDisableCustomerAction设置为false）
     */
    analysisAndAdjustFulfillmentAddress(
        selectedAddress: Address,
        allAddress: Array<Address>
    ): void;

    /**
     * 分析和修改的方法
     *
     * 初始阶段：
     * - 调用setState()方法（isDisableCustomerAction设置为true）
     * - 制作克隆版本的state
     * - 执行修改操作
     *
     * 分析阶段：
     * - analysisCart()（若不通过，则不会运行剩余阶段）
     *
     * 调整阶段：
     * - adjustCart()
     * - adjustCoupon()
     * - adjustCart()（注意，第二次修改）
     * - adjustTotal()
     *
     * 应用阶段：
     * - 调用setState()更新视图（isDisableCustomerAction设置为false）
     *
     * @param actions 购物车修改事件数组
     */
    analysisAndAdjustCart(
        actions: Array<CartActions>
    ): void;

    /**
     * 分析和修改的方法
     *
     * 初始阶段：
     * - 调用setState()方法（isDisableCustomerAction设置为true）
     * - 制作克隆版本的state
     * - 执行修改操作
     *
     * 分析阶段：
     * - analysisCoupon()（若不通过，则不会运行剩余阶段）
     *
     * 调整阶段：
     * - adjustCart()
     * - adjustTotal()
     *
     * 应用阶段：
     * - 调用setState()更新视图（isDisableCustomerAction设置为false）
     */
    analysisAndAdjustCoupon(
        couponItem: CheckoutCoupon
    ): void;

}

export interface ICheckoutOrderDetailState extends
    ICheckoutFulfillmentTimeInformation, ICheckoutFulfillmentAddressInformation,
    ICheckoutCartInformation, ICheckoutCouponInformation, ICheckoutTotalPriceInformation,
    ICheckoutPaymentButtonInformation, ICheckoutOrderRemarksInformation {

    /**
     * 订单的描述状态
     * - TAKEAWAY_ORDER_PICKUP：外卖订单 + 自取交付方式
     * - TAKEAWAY_ORDER_DELIVERY：外卖订单 + 配送交付方式
     */
    orderDescribeStatus: ICheckoutOrderDetailOrderDescribeStatusNames;

    /**
     * 当前显示的子页面
     * MAIN_PAGE - 带有多个子组件的主页面
     * NEW_ADDRESS_PAGE - 新增地址页面
     * COUPON_LIST_PAGE - 优惠券列表页面
     */
    showedPage: ICheckoutOrderDetailShowedPageNames;

    /**
     * 是否禁用用户操作（在执行一些操作时，应当禁止用户进行其他操作）
     */
    isDisableCustomerAction: boolean;

}

export interface ICheckoutOrderDetailProps extends ICheckoutIncomingProps {

}

export interface ICheckoutOrderDetailIncomingProps {
    // $ 父组件的方法
    // 默认类
    updateShowedPage?: PropType<ICheckoutOrderDetail, 'updateShowedPage'>;
    // 分析和调整类
    analysisFulfillmentTime?: PropType<ICheckoutOrderDetail, 'analysisFulfillmentTime'>;
    analysisFulfillmentAddress?: PropType<ICheckoutOrderDetail, 'analysisFulfillmentAddress'>;
    analysisCart?: PropType<ICheckoutOrderDetail, 'analysisCart'>;
    analysisCoupon?: PropType<ICheckoutOrderDetail, 'analysisCoupon'>;
    adjustCart?: PropType<ICheckoutOrderDetail, 'adjustCart'>;
    adjustCoupon?: PropType<ICheckoutOrderDetail, 'adjustCoupon'>;
    adjustTotal?: PropType<ICheckoutOrderDetail, 'adjustTotal'>;
    // 分析并调整类
    analysisAndAdjustFulfillmentTime?: PropType<ICheckoutOrderDetail, 'analysisAndAdjustFulfillmentTime'>;
    analysisAndAdjustFulfillmentAddress?: PropType<ICheckoutOrderDetail, 'analysisAndAdjustFulfillmentAddress'>;
    analysisAndAdjustCart?: PropType<ICheckoutOrderDetail, 'analysisAndAdjustCart'>;
    analysisAndAdjustCoupon?: PropType<ICheckoutOrderDetail, 'analysisAndAdjustCoupon'>;
}

export enum ICheckoutOrderDetailOrderDescribeStatusNames {
    'TAKEAWAY_ORDER_PICKUP' = 'TAKEAWAY_ORDER_PICKUP',
    'TAKEAWAY_ORDER_DELIVERY' = 'TAKEAWAY_ORDER_DELIVERY'
}

export enum ICheckoutOrderDetailShowedPageNames {
    'MAIN_PAGE' = 'MAIN_PAGE',
    'NEW_ADDRESS_PAGE' = 'NEW_ADDRESS_PAGE',
    'COUPON_LIST_PAGE' = 'COUPON_LIST_PAGE'
}

/**
 * 由于分析和调整的方法是基于一个克隆的state的对象，所以需要列举出此克隆对象的类型，即此类型
 */
export type ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions =
    ICheckoutFulfillmentAddressInformation | ICheckoutNewAddressInformation |
    ICheckoutFulfillmentTimeInformation | ICheckoutCartInformation |
    ICheckoutCouponInformation | ICheckoutTotalPriceInformation |
    ICheckoutPaymentButtonInformation;

// *********************
// 按照子组件划分的state类型
// *********************

export type ICheckoutFulfillmentAddressInformation = {
    // 当前地址
    currentAddress: Address;
    // 所有地址
    allAddresses: Array<Address>;
}

export type ICheckoutNewAddressInformation = {
    // 所有地址
    allAddresses: Array<Address>;
}

export type ICheckoutFulfillmentTimeInformation = {
    // 下单间隔时间
    orderIntervalTime: number;
    // 营业时间
    serviceSchedules: Schedule[];
    // 停业时间
    closeSchedules: SettingClosedSchedule[];
    // 营业总开关
    takeawayServiceStatus: boolean;
}

export type ICheckoutCartInformation = {
    // 购物车
    cartDishes: Array<CheckoutDetailCartDish>;
    // 购物车菜品总数量
    totalCartDishes: number;
}

export type ICheckoutCouponInformation = {
    // 优惠券
    allCoupons: Array<CheckoutCoupon>;
    // 已选择的优惠券
    selectedCoupons: Array<CheckoutCoupon>;
}

export type ICheckoutTotalPriceInformation = {
    // 折扣
    discount: number;
    // 原始配送费
    originalDeliveryFee: number;
    // 折扣后配送费
    discountedDeliveryFee: number;
    // 原始总价格（菜品总价格）
    originalTotalPrice: number;
    // 总积分
    originalTotalPoints?: number;
}

export type ICheckoutPaymentButtonInformation = {
    // 最终价格
    finalOrderPrice: number;
    // 是否可以付款,没有错误则可付款
    errorTips: CheckoutErrorTips[];
}

export type ICheckoutOrderRemarksInformation = {
    // 订单备注
    orderRemark: string;
}

// *********************
// 购物车事件
// *********************

export type CartActions = CartAddAction | CartReduceAction | CartRemarkAction | CartRefreshAction;

/**
 * 购物车菜品添加类操作
 *
 * @property type 操作类型
 * @property id 菜品的唯一标识码
 * @property num 菜品的操作的数量
 */
export type CartAddAction = {
    type: 'ADD';
    id: string;
    num: number;
    dishTotalNum: number;
};

/**
 * 购物车菜品减少类操作
 *
 * @property type 操作类型
 * @property id 菜品的唯一标识码
 * @property num 菜品的操作的数量
 */
export type CartReduceAction = {
    type: 'REDUCE';
    id: string;
    num: number;
};

/**
 * 购物车菜品备注类操作
 *
 * @property type 操作类型
 * @property id 菜品的唯一标识码
 * @property remark 菜品备注
 */
export type CartRemarkAction = {
    type: 'REMARK';
    id: string;
    remark: string;
};

/**
 * 购物车刷新类操作（重置菜品优惠券信息等）
 *
 * @property type 操作类型
 */
export type CartRefreshAction = {
    type: 'REFRESH';
};

/**
 * 外卖结算托盘错误提示
 */
export type CheckoutErrorTips = 'TEMPORARY_CLOSE_TIME' | 'BUSINESS_TIME' | 'ADDRESS' | 'PRICE' | 'POINTS' | 'UNAVAILABLE_DISH';
