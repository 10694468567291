import React from 'react';
import { GOOGLE_CLIENT_ID, GOOGLE_CLIENT_LIBRARY } from '../../gconfig';

let mountedGooleClient = false;


export function GoogleLoginFixed(props: {
    callback: (response: GoogleLoginResponse) => void;
    styleType?: 'icon' | 'standard';
    styleShape?: 'pill' | 'circle'
    dataText?: 'signin' | 'signin_with'
    style?: React.CSSProperties;
}) {
    LoadingGooleClient();
    React.useEffect(() => {
        window['GoogleLoginCallback'] = (response: GoogleLoginResponse) => {
            props.callback(response);
        }
        return () => {
            window['GoogleLoginCallback'] = null;
        }
    }, [props])

    return (
        <div className='google-box' style={props.style}>
            <div id='g_id_onload'
                data-client_id={GOOGLE_CLIENT_ID}
                data-context='signin'
                data-ux_mode='popup'
                data-callback={'GoogleLoginCallback'}
                data-auto_prompt='false'
            >
            </div>

            <div className='g_id_signin'
                data-type={props.styleType ?? 'standard'}
                data-shape={props.styleShape ?? 'pill'}
                data-theme='outline'
                data-text={props.dataText ?? 'signin_with'}
                data-size='large'
                data-logo_alignment='left'
                style={{}}
            >
            </div>
        </div>
    )
}

export function LoadingGooleClient() {
    // 加载客户端库
    React.useEffect(() => {
        // console.log(mountedGooleClient, '开始加载');

        if (!mountedGooleClient) {
            BaseLoadScript(
                document,
                'script',
                'google-login',
                GOOGLE_CLIENT_LIBRARY,
                () => {
                    mountedGooleClient = true;
                    // console.log(mountedGooleClient, '加载成功');
                },
                (err: any) => {
                    // onFailure(err)
                    console.warn(err);
                }
            )
        }
        return () => {
            mountedGooleClient = false;
            BaseRemoveScript(document, 'google-login');
            BaseRemoveLink(document, 'googleidentityservice')
            // console.log(mountedGooleClient, '卸载成功');
        }
    }, [])
    return (<></>)
}


function BaseLoadScript(d: Document, s: string, id: string, jsSrc: string, cb: () => void, onError: (err: any) => void) {
    const element = d.getElementsByTagName(s)[0];
    let js: any = element;
    js = d.createElement(s);
    js.id = id;
    js.src = jsSrc;
    d.head.appendChild(js);
    js.onerror = onError;
    js.onload = cb;
}
function BaseRemoveScript(d: Document, id: string) {
    const element = d.getElementById(id)
    if (element) {
        element.parentNode?.removeChild(element)
    }
}
function BaseRemoveLink(d: Document, id: string) {
    const element = d.getElementById(id)
    if (element) {
        element.parentNode?.removeChild(element)
    }
}

type GoogleLoginResponse = {
    clientId: string;
    client_id: string;
    // TOKEN
    credential: string;
    select_by: string;
}