import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';
class StatusButtonComponent extends Component<ButtonComponentProps, ButtonComponentState> {
    private orderStatus = {
        '0': this.props.t('order_page_text_unconfirmed'),
        '1': this.props.t('order_page_text_confirmed'),
        '2': this.props.t('order_page_text_frozen'),
        '3': this.props.t('order_page_text_cancelled'),
        '4': this.props.t('order_page_text_completed'),
        // '2-2': this.props.t('order_page_text_shipped'),
        '0-0': this.props.t('order_page_text_waiting_for_payment')
    }
    constructor(props: ButtonComponentProps) {
        super(props);

        // binding `this`
        this.render = this.render.bind(this);

        // state
        this.state = {
            btnActive: false
        }
    }

    // *********************
    // Function
    // *********************

    /**
     * 根据状态判断当前按钮显示的内容
     * #### 后端字段订单状态 origin_status：
     * - 0: 未确认
     * - 1: 已确认
     * - 2: 已冻结
     * - 3: 已取消
     * - 4: 已完成
     * #### 后端字段付款状态 origin_payment_status：
     * - 0: 未付款
     * - 1: 已付款
     * - 2: 已申请退款
     * - 3: 已退款
     */
    getBtnStatus = () => {
        const { status, payMentStatus, paymentType } = this.props;
        if (status === '0' && payMentStatus === '0') {
            if (paymentType === '1') {
                return this.orderStatus['0'];
            } else {
                return this.orderStatus['0-0'];
            }
        } else if (status) {
            return this.orderStatus[status];
        } else {
            return '';
        }
    }
    // *********************
    // View
    // *********************

    render(this: any) {
        const { btnText, onClick, active, isNormalBtn } = this.props;
        const { btnActive } = this.state;
        return (
            isNormalBtn ?
                <Button className={`orderStatusBtn normalActiveBackgroundColor ${btnActive || active ? 'active' : ''}`}
                    size='small'
                    variant='outlined'
                    onClick={(e) => {
                        this.setState({
                            btnActive: !btnActive
                        })
                        if (typeof onClick === 'function') onClick(e)
                    }}
                >{btnText}</Button> :
                <Button className={`orderStatusBtn ${active ? 'active' : ''}`} size='small' variant='outlined'
                    onClick={(e) => { if (typeof onClick === 'function') onClick(e) }}
                >{this.getBtnStatus()}</Button>

        )
    }
}

export default withTranslation()<ButtonComponentProps>(StatusButtonComponent as any);

// *********************
// States
// *********************

type ButtonComponentProps = {
    /**
     * 订单状态
     * 0: 未确认
     * 1: 已确认
     * 2: 已冻结
     * 3: 已取消
     * 4: 已完成
     */
    status?: string;
    /**
     * 外送状态
     * 0: 未配送
     * 1: 配送中
     * 2: 已配送
     */
    deliveryStatus?: string;

    /**
     *  付款状态
     *  0: 未付款
     *  1: 已付款
     *  2: 已申请退款
     *  3: 已退款
     */
    payMentStatus?: string;

    /**
     * 支付方式
     * 0:线上付款
     * 1:线下付款
     */
    paymentType?: string;

    // 按钮文本
    btnText?: string;
    // 点击事件
    onClick?: () => void;
    // 是否处于激活状态
    active?: boolean;
    // 是否普通按钮，否则的话就是状态按钮
    isNormalBtn?: boolean;
} & WithTranslation

type ButtonComponentState = {
    // 按钮激活状态
    btnActive: boolean
}
