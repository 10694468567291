import Dialog from '@material-ui/core/Dialog';
import ClearIcon from '@material-ui/icons/Clear';
import DialogContent from '@material-ui/core/DialogContent';
import './confirm-clear-cart.modal.scss';
import React from 'react';
import { Button } from '@material-ui/core';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import i18n from '../../i18n';
import { RestaurantTheme } from '../../constants/tool-restaurant-theme';

export function ConfirmClearCartModal({ isVisibleClearCartModal, restaurantTheme, handleClearCartModal, currentResStringID, updateOrderPageState }
    : { isVisibleClearCartModal: boolean; handleClearCartModal: (state: boolean) => void; currentResStringID: string, restaurantTheme?: RestaurantTheme; updateOrderPageState: () => void }) {
    const onConfirm = () => {
        // 清空购物车
        const initShop = {
            shopCarList: [],
            totalPrice: 0
        }
        // 更新存储里面的购物车数据
        const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
        LocalStorageManager.setLocalStorage({
            path: LOCAL_STORAGE_KEYS.SHOP_CAR,
            value: {
                ...cacheShopCar,
                [currentResStringID]: initShop
            }
        });
        updateOrderPageState();
        // 关闭
        handleClearCartModal(false);
    }
    return <Dialog
        open={isVisibleClearCartModal}
        onClose={() => {
            handleClearCartModal(false)
        }}
        style={{
            position: 'absolute',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0'
        }}
        className={`PleaseLoginDialog  ${restaurantTheme === 'SECOND_THEME' ? 'blackGoldPleaseLoginDialog' : ''}`}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
    >
        <DialogContent>
            <div className='dialogContent'>

                <div className='dialogName'>
                    <div className='fontStyle'>
                        {i18n.t('restaurant_order_page_text_confirm_clear_cart')}
                        <div className='close' onClick={() => {
                            handleClearCartModal(false)
                        }}>
                            <ClearIcon className='clearIcon' />
                        </div>
                    </div>
                </div>
                <div className='insertCode  orderlivery-bottom-96'>

                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button className='btn ok'
                        onClick={() => {
                            onConfirm()
                        }}>
                        {i18n.t('general_text_ok')}
                    </Button>
                    <Button className='btn cancel'
                        onClick={() => {
                            handleClearCartModal(false)
                        }}>
                        {i18n.t('general_text_cancel')}
                    </Button>
                </div>
            </div>
        </DialogContent>
    </Dialog>;
}
