import { Typography } from '@material-ui/core';
import { LocalOffer } from '@material-ui/icons';
import React from 'react';
import i18n from '../../i18n';
import { Coupon } from '../../models';
import './temporary-closed.scss';

/**
 * 轮播显示餐厅优惠劵
 */

class CouponComponent extends React.Component<Props, State> {

    // *************
    // VIEW
    // *************

    public render() {
        const { coupons } = this.props;
        return (
            <div className='coupon-com'>
                <div className='content'>
                    <Typography variant={'subtitle1'} className='text'>
                        {i18n.t('checkout_page_text_our_offers')}
                    </Typography>
                    <div className='coupon-container'>
                        {
                            coupons?.map((item: Coupon, index: number) => (
                                <div key={`${index}-coupon`} className='conpon'>
                                    <LocalOffer className='icon' />
                                    <Typography variant='subtitle1' className='text-coupon'>
                                        {item.name}
                                    </Typography>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='tail-bar'>
                    <Typography variant={'subtitle1'} className='text'>
                        {i18n.t('checkout_page_text_use_offers')}
                    </Typography>
                </div>
            </div>
        )
    }

}

export default CouponComponent;

// **************
// TYPE
// **************

type State = {};

type Props = {
    // 优惠劵
    coupons: Array<Coupon>;
};