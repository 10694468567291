import { Component } from 'react';
import { Button } from '@material-ui/core';
import Select, { components } from 'react-select';
import React from 'react';
import { COUNTRY_DATA } from '../constants/country';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import i18n from '../i18n';
// React.createElement(ReactCountryFlag);
const stateOptions = COUNTRY_DATA

const selectStyles = {
	control: (provided: any) => ({ ...provided, minWidth: 240, margin: 8 }),
	menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' })
};



export default class CountrySelect extends Component<Props, State> {
	public state = { isOpen: false, value: {} };
	toggleOpen = () => {
		this.setState(() => ({ isOpen: !this.state.isOpen }));
	};
	onSelectChange = (value: any) => {
		this.toggleOpen();
		if (typeof this.props.onChange === 'function') this.props.onChange(value)
		this.setState({ value });
	};
	render() {
		// console.log('stateOptions',stateOptions)
		const { isOpen, value }: any = this.state;
		return (
			<Dropdown
				isOpen={isOpen}
				onClose={this.toggleOpen}
				target={
					<Button
						endIcon={<ArrowDropDownIcon />}
						onClick={this.toggleOpen}
						disabled={this.props.disabled}
					>
						{value && value.value ? `${value.value}` : this.props.defaultValue}
					</Button>
				}
			>
				<Select
					autoFocus={false}
					backspaceRemovesValue={false}
					components={{ DropdownIndicator, IndicatorSeparator: null, Option }}
					controlShouldRenderValue={false}
					hideSelectedOptions={false}
					isClearable={false}
					menuIsOpen
					onChange={this.onSelectChange}
					options={stateOptions}
					placeholder={i18n.t('general_text_search')}
					styles={selectStyles}
					tabSelectsValue={false}
					value={value}
				/>
			</Dropdown>
		);
	}
}

const Option = (props: any) => {
	return (
		<div>
			<components.Option {...props} />
		</div>
	);
};

// styled components

const Menu = (props: any) => {
	const shadow = 'hsla(218, 50%, 10%, 0.1)';
	return (
		<div
			className='country-select-menu'
			style={{
				backgroundColor: 'white',
				borderRadius: 4,
				boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
				marginTop: 8,
				position: 'absolute',
				zIndex: 2
			}}
			{...props}
		/>
	);
};
const Blanket = (props: any) => (
	<div
		style={{
			bottom: 0,
			left: 0,
			top: 0,
			right: 0,
			position: 'fixed',
			zIndex: 1
		}}
		{...props}
	/>
);
const Dropdown = (props: { children: any, isOpen: any, target: any, onClose: any }) => (
	<div style={{ position: 'relative' }}>
		{props.target}
		{props.isOpen ? <Menu>{props.children}</Menu> : null}
		{props.isOpen ? <Blanket onClick={props.onClose} /> : null}
	</div>
);
const Svg = (p: any) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		focusable='false'
		role='presentation'
		{...p}
	/>
);
const DropdownIndicator = () => (
	<div style={{ color: '#fff', height: 24, width: 32 }}>
		<Svg>
			<path
				d='M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'
				fill='currentColor'
				fillRule='evenodd'
			/>
		</Svg>
	</div>
);
// const ChevronDown = () => (
//   <Svg style={{ marginRight: -6 }}>
//     <path
//       d=`M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2
// .93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z`
//       fill='currentColor'
//       fillRule='evenodd'
//     />
//   </Svg>
// );

// **********
// TYPE
// **********
type State = { isOpen: boolean, value: {} };
type Props = {
	defaultValue?: string | number;
	onChange?: (value: any) => void;
	disabled?: boolean
}