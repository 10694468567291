import React from 'react';
import './restaurant-page.style.scss';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import ReorderIcon from '@material-ui/icons/Reorder';
import RoomIcon from '@material-ui/icons/Room';
import { createHashHistory } from 'history';
import _ from 'lodash';
import moment from 'moment';
import { FaFacebookF, FaGoogle } from 'react-icons/fa';
import { analyzeRestaurantSchedule } from '../../component/restaurant/analyzeRestaurantSchedule.tool';
import TopBar from '../../component/top-bar/top-bar.component';
import i18n from '../../i18n';
import { Schedule, Customer, BookingServiceSchedule, SettingClosedSchedule, Token, Restaurant, Dish, Address } from '../../models';
import apiManage from '../../request';
import Store from '../../rudex/store';
import errorCode from '../../static/error-code';
import { BsPlus } from 'react-icons/bs';
import { getQueryString } from '../../constants/tool-get-parms';
import { showApiMessage } from '../../messenger/messageManager';
import DeliveryAddress from '../../component/account/delivery-address.component';
import { checkVersion } from '../../constants/tool-check-resource';
import BScroll from 'better-scroll';
import { ShareBox } from '../../component/restaurant/share-box';
import { checkToken } from '../../constants/tool-check-token';
import { businessSchedule, getTargetDateClosedSchedule, ScheduleDialog } from '../../component/restaurant/schedule-dialog.component';
import DefaultThemeRestaurantPage from './default-theme-restaurant-page';
import BlackGoldThemeRestaurantPage from './black-gold-theme-restaurant-page';
import { BlackGoldScheduleDialog } from '../../component/restaurant/blackGoldThemeComponent/black-gold-schedule-dialog.component';
import { RestaurantInfoDialog } from '../../component/restaurant/restaurant-info-dialog.component';
import { BlackGoldRestaurantInfoDialog } from '../../component/restaurant/blackGoldThemeComponent/black-gold-restaurant-info-dialog.component';
import BlackGoldThemeDeliceryAddress from '../../component/account/black-gold-theme-component/black-gold-theme-delivery-address.component';
import { getRestaurantTheme, RestaurantTheme, RestaurantThemeColor } from '../../constants/tool-restaurant-theme';
import { DefaultWebsocketClient } from '../../modules/websocket/module';
import { convertDateByTimeZoneName } from '../../constants/tool-constant';
import momentTimeZone from 'moment-timezone';
import { OrderButtons } from '../../component/restaurant/order-buttons.component';
import { BlackGoldOrderButtons } from '../../component/restaurant/blackGoldThemeComponent/black-gold-order-buttons.component';
import { PleaseLogin } from '../../component/please-login/please-login.component';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import Notification from '../../component/snackbar/snackbar'
import SwipeableTemporaryClosedDialog from '../../component/restaurant/swipeable -temporary-closed-dialog';
import LoadingPage from '../../component/loading';
import { inject_unount } from '../../component/decorators/helper.decorator';
import { CheckoutCouponPackage } from '../../models/fixed/checkout-coupon-package.entity';
import { getMessages } from '../../tool/notices.tool';
import Checkout from '../../component/checkout/checkout';
import { LevelDeliveryFee } from '../../models/abstract/level-delivery-fee.entity';
import { updateScheduleData } from '../../constants/tool-update-schedule-data';
import { MdLocalActivity } from 'react-icons/md';
import { CircularProgress } from '@material-ui/core';
import { ReactComponent as VipIcon } from '../../static/images/vip.svg';
import { ICheckoutProps } from '../../interfaces';

@inject_unount
class RestaurantPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.handelWebsocketFunRestaurant = this.handelWebsocketFunRestaurant.bind(this)
        this.handleWebsocketBlacklistedUser = this.handleWebsocketBlacklistedUser.bind(this)
        this.handleListenerLogout = this.handleListenerLogout.bind(this);
    }
    // 用于滑动忧患券
    public scroll: any
    // 临时停业倒计时
    public countdownInterval!: NodeJS.Timeout;

    /**
     * 数据初始化(默认值)
     */
    public state: State = {
        orderHeaderData: {
            leftIco: (className?: string) => <KeyboardArrowLeftIcon className={className} />,
            title: '',
            subtitle: '',
            rightIco: (className?: string) => <ReorderIcon className={className} />
        },
        tutorialSteps: [],
        description: '',
        Chipdata: [],
        isDialogVisible: false,
        allPaperbar: [
            {
                leftIcon: () => <AccessTimeIcon />,
                title: '', content: '', rightIcon: () => <ChevronRightIcon />,
                key: 'time'
            },
            {
                leftIcon: () => <MdLocalActivity />,
                content: () => (
                    <div className='todybox'>
                        <div className='tody'>{i18n.t('coupon_text')}</div>
                        <div className='time'>{i18n.t('coupon_text_add_coupon_by_encoding')}</div>
                    </div>),
                rightIcon: () => <BsPlus color='#000' />,
                key: 'coupon'
            },
            {
                leftIcon: () => <VipIcon />, content: '', rightIcon: () => <BsPlus color='#000' />,
                key: 'member'
            },
            { leftIcon: () => <RoomIcon />, title: '', content: '', key: 'address' },
            { leftIcon: () => <PhoneIcon />, content: '', key: 'phone' },
            { leftIcon: () => <LanguageIcon />, content: '', key: 'website' },
            { leftIcon: () => <FaFacebookF />, content: '', key: 'facebookWebsite' },
            { leftIcon: () => <FaGoogle />, content: '', key: 'googleWebsite' }
        ],
        isScheduleDialogvisible: false,
        scheduledata: [],
        selftake_status: true,
        takeaway_status: true,
        restaurantlogo: '',
        restaurantStringId: '',
        restaurantId: '',
        // 0：当前餐馆找不到 1：显示餐馆 2：加载中
        isShowRestaurant: 2,
        isRestaurantOpen: false,
        restaurantTakeawayServiceStatus: false,
        // 添加优惠券方式
        showGetCuoponBox: false,
        // 输入code
        showInsertCode: false,
        // 扫码
        showQR: false,
        // 餐馆优惠券Arr
        couponPackage: [],
        // 用户ID
        accountID: '',
        // 登录提示
        showTip: {
            show: false,
            actionType: ''
        },
        // 显示选择地址
        isShowAddress: false,
        // 用户信息
        customerInfo: {},
        // 点餐方式
        orderway: '1',
        // 显示确认地址(没有登录)
        isShowAddressNotlogin: false,
        // 是否显示分享弹窗
        isShowShareBox: false,
        // 是否显示预约弹窗
        isShowBookingBox: false,
        // 餐馆二维码图片
        QRcodePic: '',
        // 餐馆可预约时间段
        bookingServiceSchedule: [],
        // 餐馆不可预约时间段
        bookingNoServiceSchedule: [],
        // 餐馆预定服务开关
        restaurantBookingServiceStatus: false,
        // 餐馆外卖服务休业时间表
        settingClosedSchedule: [],
        // 餐馆主题布局
        restaurantTheme: 'FIRST_THEME',
        // 餐馆主题色
        restaurantThemeColor: 'FIRST_THEME_COLOR',
        // 显示谷歌地址
        isshowGoogleMap: false,
        // 餐馆地址谷歌地图坐标
        restaurantGoogleMapLat: 0,
        restaurantGoogleMapLng: 0,
        restaurantGoogleMapPlaceId: '',
        // 餐馆临时停业状态
        temporaryClosureStatus: false,
        countdownText: '',
        // 倒计时时分秒
        // 倒计时初始值
        countdownValue: 0,
        // 餐馆时区
        restaurantsTimeZoneName: '',
        // 餐馆地址坐标
        restaurantPosition: undefined,
        // 停业弹窗
        isVisibleClosedInfo: false,
        // 停业弹窗显示内容 停业'closed', 临时停业'TemporaryClosed', 优惠券'coupon'
        closedDialogshowPage: ['coupon'],
        // 下个营业时间文本
        nextOpenTimeText: '',
        // 今天是否关闭
        todyClose: false,
        // 停业时间
        resClosedTime: 0,
        start_time: new Date(),
        end_time: new Date(),
        // 加载动画
        loadingStatus: false,
        // 黑名单用户
        blacklistedUser: false,
        // 黑名单用户提示气泡
        showBubbles: false,
        // 所属集团id
        belongedGroupId: '',
        // 禁止外送
        stopTakeawayStatus: false,
        // 禁止自取
        stopSelftakeStatus: false,
        // 最大可预定时间（天数）
        settingMaxBookingDay: 14,
        // 当前餐厅所在的集团积分
        currentIntegrals: 0,
        // 是否是会员
        isMember: false,
        // 免登录预定
        restaurantsNeedSignIn: false,
        // 托盘类型
        checkoutComponent: 'BOOKING'
    }

    // *********************
    // Life Cycle Function
    // *********************
    public UNSAFE_componentWillMount() {
        // 处理商家页面
        const merchantWebsite = this.getQueryString('merchant_website')
        Store.dispatch({
            type: 'SET_STATE',
            path: 'merchantWebsite',
            value: merchantWebsite === 'true' ? true : false
        })
        // 检查token是否失效
        setTimeout(() => {
            checkToken()
        }, 1000);
    }
    async componentDidMount() {
        // 从地址中拿到餐馆的string_id
        const string_id = this.getQueryString('string_id')
        if (string_id) {
            await this.searchRestaurant(string_id.toUpperCase())
        } else {
            this.setState({ isShowRestaurant: 0 })
        }
        // 监听餐馆临时休业开关
        DefaultWebsocketClient.addListener('RESTAURANT_RESOURCE_UPDATED_EVENT', this.handelWebsocketFunRestaurant);
        // 监听用户是否被屏蔽
        DefaultWebsocketClient.addListener('BLOCKED_USERS_CREATE_EVENT', this.handleWebsocketBlacklistedUser);
        // 监听用户是否解除屏蔽
        DefaultWebsocketClient.addListener('BLOCKED_USERS_RELIEVE_EVENT', this.handleWebsocketBlacklistedUser);
        // 监听用户退出登录
        DefaultWebsocketClient.addListener('LOGOUT_EVENT', this.handleListenerLogout);

    }
    componentWillUnmount() {
        if (this.scroll) {
            this.scroll.destroy()
        }
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval)
        }
        DefaultWebsocketClient.removeListener('RESTAURANT_RESOURCE_UPDATED_EVENT', this.handelWebsocketFunRestaurant);
        DefaultWebsocketClient.removeListener('BLOCKED_USERS_CREATE_EVENT', this.handleWebsocketBlacklistedUser);
        DefaultWebsocketClient.removeListener('BLOCKED_USERS_RELIEVE_EVENT', this.handleWebsocketBlacklistedUser);
        DefaultWebsocketClient.removeListener('LOGOUT_EVENT', this.handleListenerLogout);

        // 清除预定托盘的暂存数据
        Store.dispatch({
            type: 'SET_STATE',
            path: 'bookingInfo',
            value: undefined
        })
    }

    // *********************
    // Service Function
    // *********************

    /**
     * 检查 登录成功回调操作
     */
    async checkShowTipCallBackAction() {
        const storeState = Store.getState();

        const showTipCallBackAction: {
            member: boolean;
            getCoupon: boolean | CheckoutCouponPackage;
            openCouponDialog: boolean;
        } = _.get(storeState, 'showTipCallBackAction');
        if (this.state.accountID && showTipCallBackAction) {
            // 加入会员 & 不是会员
            if (showTipCallBackAction.member && !this.state.isMember) {
                // 校验姓名
                const cacheAccount: Customer = await LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
                if (cacheAccount.first_name && cacheAccount.last_name) {
                    await this.joinMember();
                }
            }
            // 领取优惠券
            if (showTipCallBackAction.getCoupon) {
                await this.onAddCoupon(showTipCallBackAction.getCoupon, false);
            }
            if (showTipCallBackAction.openCouponDialog) {
                this.setState({ showInsertCode: true });
            }
            Store.dispatch({
                type: 'SET_STATE',
                path: 'showTipCallBackAction',
                value: undefined
            })
        }
    }

    /**
     * 退出账号
     */
    async handleListenerLogout() {
        this.setState({ accountID: '', customerInfo: {}, currentIntegrals: 0, isMember: false }, () => {
            const allPaperbar = this.setPaperBarInfo({ init: { key: 'member' } });
            this.setState({ allPaperbar });
        });
    }

    handelWebsocketFunRestaurant(data) {

        // 缓存的餐厅string id
        const res_string_id = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
        // 已经展示过的停业时间
        let res_closed_time = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CLOSED_TIME) || {};
        // 是否已登录， token
        const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
        // 当前时间转成相同时区格式
        let currentTime = moment(new Date()).format(moment.defaultFormatUtc);
        currentTime = convertDateByTimeZoneName(currentTime, momentTimeZone.tz.guess(), this.state.restaurantsTimeZoneName)

        const start_time = _.get(data, 'newValue.setting_temporarily_closed_start_data', '');
        const end_time = _.get(data, 'newValue.setting_temporarily_closed_end_data', '');
        const closed_status = _.get(data, 'newValue.setting_temporarily_closed_enable', false);
        const closed_res_id = _.get(data, 'id', '');

        // 现在时间与结束时间的差（结束时间-现在时间）
        const countdownValue = moment(end_time).diff(currentTime, 'seconds');

        // 现在时间与开始时间的差（现在时间-开始时间）
        const startTimeValue = moment(currentTime).diff(start_time, 'seconds');
        const isBetween = moment(currentTime).isBetween(start_time, end_time);
        // 临时停业时间
        res_closed_time = _.get(res_closed_time, `[${closed_res_id}]`);
        // 判断是否同一家餐厅
        if (closed_res_id === res_string_id && token) {
            // 判断是否显示过弹窗 或者 已经停业了 或者 不在当前时间范围内
            if (moment(_.get(res_closed_time, 'startTime', '')).format('YYYY-MM-DD HH:mm:ss') === moment(start_time).format('YYYY-MM-DD HH:mm:ss')
                || start_time === end_time || !closed_status || startTimeValue < -5 || countdownValue < 0) {
                // 判断是否暂停停业
                if (start_time === '' || end_time === '' || !closed_status || !isBetween) {
                    this.setState({
                        isVisibleClosedInfo: false,
                        resClosedTime: 0,
                        countdownValue: 0,
                        temporaryClosureStatus: false
                    })
                    // 餐厅当前时间是否临时停业
                    LocalStorageManager.setLocalStorage({
                        'path': LOCAL_STORAGE_KEYS.CURRENT_CLOSED_STATUS,
                        'value': false
                    })
                    return false;
                } else {
                    // 已经显示过
                    this.setTemporaryClosure(closed_status, start_time, end_time);
                    return true;
                }
            }
        } else {
            return false;
        }
        // 没有展示过的直接缓存
        const closedTime = {
            startTime: start_time,
            endTime: end_time
        }

        const cacheClosedTime: { [key: string]: { startTime: string, endTime: string } } = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CLOSED_TIME) || {};
        _.set(cacheClosedTime, `${closed_res_id}`, closedTime);

        LocalStorageManager.setLocalStorage({
            'path': LOCAL_STORAGE_KEYS.CLOSED_TIME,
            'value': cacheClosedTime
        })

        // 显示弹窗
        this.setState({
            resClosedTime: countdownValue,
            isVisibleClosedInfo: true,
            closedDialogshowPage: ['TemporaryClosed', 'coupon'],
            start_time,
            end_time,
            temporaryClosureStatus: true,
            countdownValue
        }, async () => {
            this.startCountdown()
        })
        return true;
    }

    // 用户被纳入黑名单或恢复正常
    handleWebsocketBlacklistedUser(data) {
        try {
            if (_.get(data, 'type', '') === 'BLOCKED_USERS_CLEAN_EVENT') {
                this.setState({ blacklistedUser: false, showBubbles: false });
            } else {
                const { belongedGroupId, restaurantStringId } = this.state;
                const groupStringId = _.get(data, 'group_string_id', '')
                const resStringIdArr = _.get(data, 'value', [])
                let isBlackUser = false
                // 该用户是否为所属集团下被禁用或解禁的餐馆
                if (belongedGroupId === groupStringId && _.isArray(resStringIdArr)) isBlackUser = resStringIdArr.includes(restaurantStringId)
                if (isBlackUser) {
                    if (_.get(data, 'type', '') === 'BLOCKED_USERS_CREATE_EVENT') {
                        Notification.notification(i18n.t('general_text_you_have_been_banned_by_this_restaurant'));
                        this.setState({ blacklistedUser: true });
                    } else if (_.get(data, 'type', '') === 'BLOCKED_USERS_RELIEVE_EVENT') {
                        Notification.notification(i18n.t('general_text_unbanned'))
                        this.setState({ blacklistedUser: false, showBubbles: false });
                    }
                }
            }
        } catch (error) {

        }
    }
    /**
     * 关闭停业弹窗
     */
    onCloseDialog() {
        this.setState({
            isVisibleClosedInfo: false,
            resClosedTime: 0
        })
    }
    /**
     * 获取URL问号后的参数值
     * @param name
     */
    public getQueryString(name) {
        const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        const r = this.props.location && this.props.location.search.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        }
        return null;
    }
    /**
     * 设置滚动插件初始化
     */
    initBetterScroll() {
        // 使用插件解决滑动问题
        const wrapper: any = document.querySelector('#restaurantCouponPackage')
        if (wrapper) {
            this.scroll = new BScroll(wrapper, {
                scrollX: true,
                scrollY: false,
                mouseWheel: true,
                click: true
            })
        }
    }
    /**
     * 获取餐馆信息
     * @param stringId
     */
    public async searchRestaurant(stringId: string) {
        let restaurantsMag = {};
        this.setState({
            restaurantStringId: stringId
        }, async () => {
            try {
                // 存储当前餐馆stringID
                LocalStorageManager.setLocalStorage({
                    path: LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID,
                    value: stringId
                });
                const cacheAccount: Customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
                //  校验是否有餐馆基础权限（api设置餐馆暂时停业）
                const restaurantService = await apiManage.checkCustomerVoucher(stringId, 'AU1012')
                if (restaurantService) {
                    if (_.get(restaurantService, 'passedWarrant', []).length === 0 || _.get(restaurantService, 'error')) {
                        // 暂无操作
                    }
                }

                // 餐馆信息
                const resInfo: any = apiManage.getRestaurantInfo(stringId, { noNeedShowMssages: false }, cacheAccount._id);
                // 获取餐馆 配送区域
                const levelDeliveryApi: Promise<{ levelDelivery: LevelDeliveryFee[] }> = apiManage.get_restaurant_level_delivery(stringId);
                const [results, settinglevelDelivery] = await Promise.all([resInfo, levelDeliveryApi]);
                if (results.error) {
                    // 标识后台返回的错误
                    const errorTip = errorCode.filter(item => (item.code === results.error.code))
                    Notification.notification(i18n.t(errorTip[0].message));
                    this.updateState({
                        isShowRestaurant: 0
                    })
                    return
                }
                if (results.dbResults && results.dbResults[0]) {
                    const restaurantInfo: Restaurant = results.dbResults[0] || {};
                    // 保存当前餐厅名称
                    LocalStorageManager.setLocalStorage({
                        path: LOCAL_STORAGE_KEYS.CURRENT_RES_NICK_NAME,
                        value: _.get(restaurantInfo, 'nickname')
                    });
                    // 保存当前集团stringID
                    LocalStorageManager.setLocalStorage({
                        path: LOCAL_STORAGE_KEYS.CURRENT_GROUP_STRING_ID,
                        value: restaurantInfo.belonged_group
                    });
                    const cacheToken: Token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN) || {};
                    const cacheRestaurants: { [key: string]: { menuDish: Dish, restaurant: Restaurant } } = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS) || {};
                    // 是否有登入
                    if (cacheToken && cacheAccount) {
                        const isLogin = cacheToken.access_token;
                        const accountID = cacheAccount._id;
                        restaurantsMag = { ...cacheRestaurants };
                        if (isLogin && accountID) {
                            const patchData = { _id: accountID, restaurant_string_ids: [stringId] };
                            try {
                                await apiManage.updateHistoryRestaurants(patchData);
                                await this.getCustomerInfo(accountID, restaurantInfo.belonged_group);
                                this.setState({ accountID }, async () => {
                                    // 检查 登录成功回调操作
                                    await this.checkShowTipCallBackAction();
                                });
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    }
                    // $ 设置最新的餐馆配送区域
                    restaurantInfo.setting_level_delivery = settinglevelDelivery?.levelDelivery || [];
                    // 存储用户餐馆信息
                    const menuDishInfo = _.get(cacheRestaurants, `${stringId}.menuDish`) || [];
                    _.set(restaurantsMag, `${stringId}.menuDish`, menuDishInfo);
                    _.set(restaurantsMag, `${stringId}.restaurant`, restaurantInfo);
                    LocalStorageManager.setLocalStorage({
                        path: LOCAL_STORAGE_KEYS.RESTAURANTS,
                        value: restaurantsMag
                    });
                    // 预定时间表
                    const bookingServiceSchedule = _.get(restaurantInfo,
                        'setting_booking_service_schedule') || [];
                    // 预定休业时间表
                    const bookingNoServiceSchedule = _.get(restaurantInfo,
                        'setting_booking_no_service_schedule') || [];
                    // 预定服务是否开启
                    let restaurantBookingServiceStatus = _.get(restaurantInfo, 'setting_booking_service_status', false);

                    // 最大可预定时间（天数）未设置则14天
                    const settingMaxBookingDay = _.get(restaurantInfo,
                        'setting_max_booking_day', 14);
                    // 商家地址谷歌地图坐标
                    const restaurantGoogleMapLat = Number(_.get(restaurantInfo,
                        'google_map_lat', 0));
                    const restaurantGoogleMapLng = Number(_.get(restaurantInfo,
                        'google_map_lng', 0));
                    const restaurantGoogleMapPlaceId = _.get(restaurantInfo,
                        'google_map_place_id', '');
                    // 外卖服务休业时间表
                    const settingClosedSchedule = _.get(restaurantInfo,
                        'setting_closed_schedule', []);
                    // 检查预定服务授权是否拥有
                    const reservationService = await apiManage.checkCustomerVoucher(stringId, 'AU1004')
                    if (reservationService) {
                        if (_.get(reservationService, 'passedWarrant', []).length === 0 || _.get(reservationService, 'error')) {
                            restaurantBookingServiceStatus = false;
                        }
                    }

                    // 获取餐馆布局和主题色
                    const { restaurantTheme, restaurantThemeColor } = await getRestaurantTheme(restaurantInfo.string_id!, _.get(restaurantInfo,
                        'settings_layout'))
                    // 餐馆临时停业开关
                    const temporaryClosureStatus = _.get(restaurantInfo,
                        'setting_temporarily_closed_enable', false);
                    // 餐馆临时停业开始时间
                    const temporaryClosureStartTime = _.get(restaurantInfo,
                        'setting_temporarily_closed_start_data', '');
                    // 餐馆临时停业结束时间
                    const temporaryClosureEndTime = _.get(restaurantInfo,
                        'setting_temporarily_closed_end_data', '');
                    // 餐馆时区
                    const restaurantsTimeZoneName = _.get(restaurantInfo,
                        'time_zone', momentTimeZone.tz.guess());
                    // 餐馆所属集团id
                    const belongedGroupId = _.get(restaurantInfo, 'belonged_group', '');
                    // 用户是否在集团下餐厅的黑名单
                    const blacklistedUser = _.get(restaurantInfo, 'is_blocked_user', false);

                    this.getRestaurant(settingClosedSchedule);
                    this.setState({
                        isShowRestaurant: 1,
                        bookingServiceSchedule,
                        settingClosedSchedule,
                        restaurantBookingServiceStatus,
                        bookingNoServiceSchedule,
                        restaurantTheme,
                        restaurantThemeColor,
                        restaurantGoogleMapLat,
                        restaurantGoogleMapLng,
                        restaurantGoogleMapPlaceId,
                        restaurantsTimeZoneName,
                        belongedGroupId,
                        blacklistedUser,
                        showBubbles: blacklistedUser,
                        settingMaxBookingDay,
                        // 不存在，默认是 true
                        restaurantsNeedSignIn: _.get(restaurantInfo, 'setting_booking_requires_login', true)
                    }, () => {
                        // 更新餐馆信息bar信息
                        const allPaperbar = this.setPaperBarInfo({ restaurants: restaurantInfo });

                        this.setState({ allPaperbar });
                        // 是否在临时停业时间内
                        // this.setTemporaryClosure(temporaryClosureStatus, temporaryClosureStartTime, temporaryClosureEndTime);
                        const data = {
                            id: stringId,
                            newValue: {
                                setting_temporarily_closed_start_data: temporaryClosureStartTime,
                                setting_temporarily_closed_end_data: temporaryClosureEndTime,
                                setting_temporarily_closed_enable: temporaryClosureStatus
                            }
                        }
                        // 是否临时停业
                        const isTemporaryClosedClose = this.handelWebsocketFunRestaurant(data);

                        // 当前不营业 & 今天不营业 & 有下个营业时间 & 总开关开启 ，显示弹窗
                        if (!isTemporaryClosedClose && this.state.nextOpenTimeText && this.state.todyClose && this.state.restaurantTakeawayServiceStatus) {
                            this.setState({
                                isVisibleClosedInfo: true,
                                closedDialogshowPage: ['closed', 'coupon']
                            })
                        }
                    })

                    const restaurantId = _.get(restaurantInfo, '_id')

                    this.getRestaurantlogoAndpics(restaurantInfo)
                    this.getRestaurantCouponPackage(stringId)
                    this.getRestaurantQRcodePic(restaurantId!, stringId)
                } else {
                    this.updateState({
                        isShowRestaurant: 0
                    })
                }
            } catch (error) {
                this.updateState({
                    isShowRestaurant: 0
                })
            }
        })

    }
    /**
     * 获取个人信息（配送地址)
     */
    public async getCustomerInfo(ID?: string, belongedGroupId?: string) {
        const { accountID } = this.state
        const param = accountID || ID
        if (param) {
            const customerInfo = await apiManage.get_customer_info(param);
            // 用户所在餐厅的所属集团积分
            const currentIntegrals = _.get(customerInfo, 'integrals', []).find(((item: any) => { return item.group_string_id === (belongedGroupId ?? this.state.belongedGroupId) }))?.value || 0;
            // 是否是会员
            const isMember = (customerInfo?.restaurant_member_ids || []).includes(this.state.restaurantStringId);

            this.setState({ customerInfo, currentIntegrals, isMember });
            // 获取通知信息
            getMessages(_.get(customerInfo, 'messages', 0));
        }
    }
    /**
     * 获取餐馆当天营业时间并布局第一个paperbar
     */
    public calculate(settingClosedSchedule: SettingClosedSchedule[]) {
        const { orderHeaderData, scheduledata } = this.state;
        const newServiceSchedule = updateScheduleData(scheduledata, settingClosedSchedule);
        const analyzeResult = analyzeRestaurantSchedule(newServiceSchedule);
        const { isRestaurantOpen, selfTakeStatus, takeawayStatus, todyClose, nextOpenTimeText } = analyzeResult;
        const subtitle = (takeawayStatus && selfTakeStatus)
            ? `${i18n.t('restaurant_info_page_text_order_takeaway')} & ${i18n.t('restaurant_info_page_text_order_delivery')}` : takeawayStatus
                ? i18n.t('restaurant_info_page_text_order_delivery') : selfTakeStatus
                    ? i18n.t('restaurant_info_page_text_order_takeaway') : '';

        this.updateState({
            orderHeaderData: { ...orderHeaderData, subtitle: todyClose ? '' : subtitle },
            selftake_status: selfTakeStatus,
            takeaway_status: takeawayStatus,
            isRestaurantOpen,
            nextOpenTimeText,
            todyClose
        })
    }
    /**
     * 获取餐馆信息并更新paperbar的值
     */
    public getRestaurant(settingClosedSchedule: SettingClosedSchedule[]) {

        try {
            // 直接从缓存里面拿数据据
            const cacheRestaurants: Restaurant = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS) || {};
            let restaurants: Restaurant = {};
            if (Object.keys(cacheRestaurants).length > 0) {
                restaurants = _.get(cacheRestaurants, `${this.state.restaurantStringId}`).restaurant
            }

            const { orderHeaderData } = this.state
            const { nickname,
                description, setting_app_takeaway_service_schedule,
                label, setting_app_takeaway_service_status,
                setting_app_stop_takeaway_status,
                setting_app_stop_selftake_status } = restaurants;

            this.setState({
                orderHeaderData: { ...orderHeaderData, title: nickname || '' },
                description: description || '',
                scheduledata: setting_app_takeaway_service_schedule || [],
                restaurantTakeawayServiceStatus: setting_app_takeaway_service_status || false,
                Chipdata: label || [],
                stopTakeawayStatus: setting_app_stop_takeaway_status || false,
                stopSelftakeStatus: setting_app_stop_selftake_status || false
            }, () => {
                this.calculate(settingClosedSchedule)

            })
        } catch (error) {
        }
    }


    /**
     * 设置区域内容
     */
    public setPaperBarInfo(params: { restaurants?: Restaurant, bardatatype?: Bardatatype, init?: { key: string } }) {
        const { allPaperbar } = this.state
        let newAllPaperbar = [...allPaperbar];

        const memberBarContent = () => (
            <div className='todybox'>
                <div className='tody'>{i18n.t('restaurant_info_page_text_member')}</div>
                <div className='time'>{
                    this.state.isMember ? (
                        <><span style={{ color: '#858585', marginRight: 10 }}>{i18n.t('my_fidelity_page_scores')}</span>{this.state.currentIntegrals}</>) :
                        i18n.t('restaurant_info_page_text_click_to_join_a_member')
                }</div>
            </div>
        )

        if (params.bardatatype) {
            newAllPaperbar = newAllPaperbar.map((item) => {
                if (item.key === params.bardatatype?.key) {
                    return { ...item, ...params.bardatatype }
                }
                return item
            })
        } else if (params.restaurants) {
            const { country, local, tel, tel_prefix, city, website, facebook_website, google_website, home_number, google_map_formatted_address } = params.restaurants;
            newAllPaperbar.forEach((item) => {
                switch (item.key) {
                    case 'coupon':
                        item.content = () => (<div className='todybox'>
                            <div className='tody'>{i18n.t('coupon_text')}</div>
                            <div className='time'>{i18n.t('coupon_text_add_coupon_by_encoding')}</div>
                        </div>);
                        break;
                    case 'member':
                        item.content = memberBarContent;
                        item.rightIcon = () => (
                            this.state.isMember ? <ChevronRightIcon /> : <BsPlus color='#000' />
                        )
                        break;
                    case 'time':
                        item.content = () => this.setcontent();
                        break;
                    case 'address':
                        item.title = country || '';
                        item.content = google_map_formatted_address ?? `${city} ${local} ${home_number}`;
                        break;
                    case 'phone':
                        item.content = tel_prefix && tel_prefix.length > 0 ? `+${tel_prefix} ${tel}` : ``;
                        break;
                    case 'website':
                        item.content = website;
                        break;
                    case 'facebookWebsite':
                        item.content = facebook_website;
                        break;
                    case 'googleWebsite':
                        item.content = google_website;
                        break;
                    default:
                        break;
                }
            })
        } else if (params.init) {
            newAllPaperbar = newAllPaperbar.map((item) => {
                if (item.key === params.init?.key && item.key === 'member') {
                    return { ...item, content: memberBarContent }
                }
                return item
            })
        }

        return newAllPaperbar
    }


    /**
     * 获取餐馆信息logo和宣传图
     * @param restaurantId 餐馆id
     */
    public async getRestaurantlogoAndpics(RestaurantInfo) {
        try {
            const restaurantId = _.get(RestaurantInfo, '_id');
            // 餐馆logo
            const restaurantlogo: any = await apiManage.get_restaurant_logo(restaurantId, { notNeedLoading: true }) || '';
            if (restaurantlogo && !_.get(restaurantlogo, 'error')) {
                this.updateState({ restaurantlogo });
            }
            // pr_exist_pic为true时，接口只返回有效图片
            const pr_exist_pic = true;
            // 餐馆宣传图
            const tutorialSteps = await apiManage.get_restaurant_pics(restaurantId,
                pr_exist_pic, { notNeedLoading: true });
            // 需要缓存的餐馆的宣传图
            const newAlbum: Array<{ label: string, imgPath: any }> = [];
            if (Array.isArray(tutorialSteps) && tutorialSteps.length > 0) {
                tutorialSteps.forEach((item, index) => {
                    newAlbum.push({ label: `${index}`, imgPath: item.url })
                })
                this.updateState({ tutorialSteps: newAlbum });
            }
        } catch (error) { }
    }

    /**
     * 获取今天的营业时间范围
     */
    getTodyOpenTime() {
        const { restaurantTakeawayServiceStatus } = this.state;
        // 今天星期几
        const todyIsoWeekday = moment(new Date()).isoWeekday();
        // 目标日期的营业时间段
        const dateTimeRanges: Schedule = _.find(this.state.scheduledata, (n) => { return n.week === todyIsoWeekday }) || {};
        if (Object.keys(dateTimeRanges).length === 0) {
            return false
        }
        // 目标日期
        const targetDate = moment(new Date()).format('YYYY-MM-DD');
        // 目标日期的营业时间段（是否是全体营业）
        const timeRanges = dateTimeRanges.time_24_hours ? [{
            start_time: '00:00:00',
            end_time: '23:59:00',
            selftake_status: dateTimeRanges.selftake_status,
            takeaway_status: dateTimeRanges.takeaway_status
        }] : (dateTimeRanges.time_ranges || []);
        // 最终显示的营业时间表（对应的周几）
        const finalTimeRanges = businessSchedule(timeRanges, getTargetDateClosedSchedule(targetDate, this.state.settingClosedSchedule));
        // 找出有配送方式的时间
        const filterFinalTimeRanges = _.filter(finalTimeRanges, (n) => {
            return n.selftake_status || n.takeaway_status
        });
        // 计算现在时间，在当天营业时间
        const newTimeRanges = this.analyzeTodyTimeRanges(filterFinalTimeRanges);
        // 是否是全天营业
        let isAllDayOpen = finalTimeRanges.length === 1 && finalTimeRanges[0].start_time === '00:00:00' && finalTimeRanges[0].end_time === '23:59:00';
        // 全天营业是否有配送方式
        if (isAllDayOpen) {
            if (!(finalTimeRanges[0].selftake_status || finalTimeRanges[0].takeaway_status)) {
                isAllDayOpen = false
            }
        }
        return { restaurantTakeawayServiceStatus, isAllDayOpen, newTimeRanges };
    }
    /**
     * 设置Paperbar的content布局
     */
    public setcontent = () => {
        const result = this.getTodyOpenTime()
        if (result) {
            const { restaurantTakeawayServiceStatus, isAllDayOpen, newTimeRanges } = result
            return (
                <div className='todybox'>
                    <div className='tody'>{i18n.t('restaurant_info_page_text_today')}</div>
                    <div className='time'>
                        {!restaurantTakeawayServiceStatus
                            ? <div><span>{i18n.t('restaurant_info_page_Takeaway_service_not_available')}</span></div>
                            : isAllDayOpen
                                ? <div><span>{i18n.t('general_text_all_day_business')}</span></div>
                                : newTimeRanges.length > 0
                                    ? newTimeRanges.map((Arrayitem: any, index) => {
                                        return (
                                            <div key={index}>
                                                <span>
                                                    {`${moment(Arrayitem.start_time, 'HH:mm').format('HH:mm')}-${moment(Arrayitem.end_time, 'HH:mm').format('HH:mm')}`}
                                                </span>
                                            </div>
                                        )
                                    })
                                    : <div><span style={{ color: 'red', fontWeight: 'bold' }}>{i18n.t('restaurant_info_page_restaurant_closed_today')}</span></div>
                        }
                    </div>
                </div>
            )
        } else {
            return (<div></div>)
        }

    }
    // 计算现在时间，在当天营业时间
    public analyzeTodyTimeRanges = (timeRanges) => {
        let result: any = []
        const now = new Date()
        if (Array.isArray(timeRanges) && timeRanges.length > 0) {

            for (const item of timeRanges) {
                const start = moment(item.start_time, 'hh:mm')
                const end = moment(item.end_time, 'hh:mm')
                // 是否在营业时间范围内，是就跳出循环
                if (moment(now).isBefore(start)) {
                    result = [item]
                    break;
                } else if (moment(now).isBetween(start, end)) {
                    result = [item]
                    break;
                } else if (moment(now).isAfter(end)) {
                    result = []
                }
            }
        }
        return result
    }
    /**
     * 获取某天营业的数据
     *  @param value 0 | 1 | 2 | 3 | 4 | 5 | 6
     */
    public getcheduledata = (value) => {
        const { scheduledata } = this.state
        // const deepscheduledata = _.cloneDeep(scheduledata)
        let newobj = {}
        const newvalue = value === 0 ? 7 : value
        // tslint:disable-next-line:no-unused-expression
        scheduledata && scheduledata.length > 0 && scheduledata.forEach((item) => {
            if (item.week === newvalue) {
                return newobj = item
            }
        })
        return newobj
    }
    /**
     * 点击paper的触发事件
     *  @param item 组件文本
     *  @param index index
     */
    public onPaper = async (item: Bardatatype) => {
        switch (item.key) {
            case 'coupon':
                if (this.state.accountID) {
                    this.updateState({ showGetCuoponBox: true })
                } else {
                    this.setState({
                        showTip: {
                            show: true,
                            actionType: 'couponDialog'
                        }
                    })
                }
                break;
            case 'member':
                // 未登录，请先登入
                if (!this.state.accountID) {
                    this.setState({
                        showTip: {
                            show: true,
                            actionType: 'member'
                        }
                    })
                } else {
                    if (this.state.isMember) {
                        // 已经是会员，点击进去《我的优惠》- 滚动到 对应集团
                        createHashHistory().push(`/my-fidelity-page?group_id=${this.state.belongedGroupId}`)
                    } else {
                        // 已经登录，但是账号姓名不全，打开托盘-补全信息
                        const account: Customer = await LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
                        // 判断信息是否缺失（姓名）
                        if (!account.first_name || !account.last_name) {
                            Store.dispatch({
                                type: 'SET_STATE',
                                path: 'showTipCallBackAction',
                                value: {
                                    member: true,
                                }
                            })
                            this.setState({
                                checkoutComponent: 'COMPLETE_CUSTOMER',
                                isShowBookingBox: true,
                            })
                        } else {
                            // 加入会员
                            await this.joinMember();
                        }
                    }
                }

                break;
            case 'time':
                this.updateState({ isScheduleDialogvisible: true })
                break;
            case 'address':
                window.open(`https://www.google.com/maps/search/?api=1&query=${this.state.restaurantGoogleMapLat},${this.state.restaurantGoogleMapLng}&z=17&query_place_id=${this.state.restaurantGoogleMapPlaceId}`)
                break;
            case 'website':
            case 'googleWebsite':
            case 'facebookWebsite':
                if (_.isString(item.content)) {
                    window.open(item.content)
                }
                break;
            default:
                break;
        }
    }
    /**
     * 加入会员
     */
    async joinMember() {
        if (this.state.customerInfo?._id) {
            // 加载圈开启
            const allPaperbar = this.setPaperBarInfo({
                bardatatype: {
                    key: 'member',
                    rightIcon: () => <CircularProgress style={{ color: '#858585CC', width: 25, height: 25 }} />
                }
            })
            this.setState({ allPaperbar });
            const res = await apiManage.join_member(this.state.restaurantStringId, this.state.customerInfo?._id, { notNeedLoading: true });
            if (!res?.error) {
                Notification.notification(i18n.t('restaurant_info_page_text_you_have_joined_a_member'));
                this.setState({ isMember: true })
            } else {
                Notification.notification(i18n.t('request_failure_text_operation_failed'));
            }
            const newAllPaperbar = this.setPaperBarInfo({
                bardatatype: {
                    key: 'member',
                    rightIcon: () => !res?.error ? <ChevronRightIcon /> : <BsPlus color='#000' />
                }
            })
            this.setState({ allPaperbar: newAllPaperbar });
        }
    }

    /**
     * 点击order按钮跳转到点餐页面
     *  @param value pickup自取 delivery配送
     */
    public onOrder = (value: string) => {
        const { restaurantTakeawayServiceStatus, restaurantStringId, blacklistedUser, stopTakeawayStatus, stopSelftakeStatus } = this.state;
        const cacheAccount: Customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
        const accountID = cacheAccount._id;
        if (restaurantTakeawayServiceStatus && !blacklistedUser) {
            if (value === 'pickup' && !stopSelftakeStatus) {
                // 跳到点餐页面，自取 orderway=1
                createHashHistory().push(`orderpage?string_id=${restaurantStringId}&orderway=1`)
            } else if (value === 'delivery' && !stopTakeawayStatus) {
                if (accountID) {
                    this.setState({ isShowAddress: true, orderway: '2' });
                } else {
                    this.setState({ orderway: '2' }, () => {
                        // 跳到点餐页面，自取 orderway=1
                        createHashHistory().push(`orderpage?string_id=${restaurantStringId}&orderway=2`)
                        // this.getCurrentPosition();
                    })
                }
            }
        }
    }

    /**
     * 非登录状态下，点击配送，获取当前位置，计算距离并获取餐馆最低配送价
     *
     */
    // getCurrentPosition() {
    //     this.setLoading(true);
    //     // 获取当前位置
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(async (position) => {
    //             try {
    //                 let lat = Number(position.coords.latitude);
    //                 let lng = Number(position.coords.longitude);
    //                 // 商家信息
    //                 let stringId: string | null = this.state.restaurantStringId;
    //                 const restaurantsMag = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS) || {};
    //                 const restaurantInfo = _.get(restaurantsMag, `${stringId}.restaurant`);
    //                 const { google_map_lat, google_map_lng } = restaurantInfo;
    //                 // 餐馆环形配送数组(已经校验过授权点)
    //                 let settingLevelDelivery = restaurantInfo.setting_level_delivery;
    //                 const customerLatLng = new google.maps.LatLng(lat, lng);
    //                 const restaurantsLatLng = new google.maps.LatLng(google_map_lat, google_map_lng);
    //                 // 餐厅与客户的距离转成公里
    //                 // tslint:disable-next-line: max-line-length
    //                 const line = google.maps.geometry.spherical.computeDistanceBetween(restaurantsLatLng, customerLatLng) / 1000;
    //                 // 是否在餐厅的圆环内
    //                 let getDeliveryLevel = _.find(settingLevelDelivery,
    //                     (n) => { return Number(n.start_radius) <= line && Number(n.end_radius) >= line; })
    //                 let minimumDeliveryAmount = 0;
    //                 if (getDeliveryLevel) {
    //                     if (getDeliveryLevel) {
    //                         minimumDeliveryAmount = _.get(getDeliveryLevel, 'delivery_minimum_fee')
    //                     }
    //                     // 设置缓存
    //                     this.setCache(minimumDeliveryAmount)
    //                     // 进入点餐页面
    //                     createHashHistory().push(`orderpage?string_id=${stringId}&orderway=2`)
    //                     console.log('最近配送金额：', minimumDeliveryAmount);
    //                     this.setLoading(false);
    //                 } else {
    //                     // 谷歌地址框
    //                     this.setState({ isShowAddressNotlogin: true })
    //                     console.log('当前位置不在配送范围内');
    //                     this.setLoading(false);
    //                 }
    //             } catch (error) {
    //                 this.setState({ isShowAddressNotlogin: true })
    //                 console.log('定位失败:', error);
    //                 this.setLoading(false);
    //             }
    //         }, () => {
    //             // 谷歌地址框
    //             this.setState({ isShowAddressNotlogin: true })
    //             console.log('定位失败');
    //             this.setLoading(false);
    //         }, { timeout: 5000 });
    //     } else {
    //         // 谷歌地址框
    //         this.setState({ isShowAddressNotlogin: true })
    //         console.log('定位失败');
    //         this.setLoading(false);
    //     }
    // }

    /**
     * 开启或关闭全局loading
     */
    setLoading(status: boolean) {
        this.setState({ loadingStatus: status })
    }

    /**
     * （更换地址）点击确认
     *  orderway:   pickup=1自取 delivery=2配送
     */
    public onAddressConfirm = async (customerInfo?: Customer, status?: boolean) => {
        this.setLoading(true);
        let originCustomerInfo = _.cloneDeep(this.state.customerInfo)
        if (customerInfo && _.has(customerInfo, '_id')) {
            this.setState({
                customerInfo
            })
            originCustomerInfo = customerInfo
        }
        const cacheRestaurants = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS);
        if (!cacheRestaurants) {
            this.setLoading(false);
            return
        }
        // 获取当前餐馆的信息
        const restaurant = _.cloneDeep(cacheRestaurants[this.state.restaurantStringId]['restaurant'])

        const settingLevelDeliveryStatus = _.get(restaurant, 'setting_level_delivery_status')
        const currentVersion = _.get(restaurant, 'version') || '0'
        const isUpdate = checkVersion(currentVersion, '1.2.0');

        // $ 需要判断餐馆的版本号是否是1.2之后。1.2之后才有圆环
        if (isUpdate) {
            // 用户的地址
            const address = _.get(originCustomerInfo, 'address') || []
            // 选中的默认地址
            let defaultAddress: Address = {}
            // 判断用户是否有地址
            if (_.isArray(address) && address.length > 0) {
                defaultAddress = _.find(address, (n) => { return n.default === true }) || {};
                // 点击跳过，如果自己没有默认地址，就拿自己地址的第一个
                // if (!defaultAddress) {
                //     defaultAddress = address[0];
                // }
            }
            if (!defaultAddress) {
                // 关闭全局loading
                this.setLoading(false);
                return
            }
            // $ 是否开启圆环配送费（v1.3版本才有圆环配送费）
            if (settingLevelDeliveryStatus) {
                // 进入点餐页面
                this.goOrederPage(status);
            }
        }
    }

    /**
     * 进入点餐页面
     */
    public goOrederPage(status?: boolean) {
        const { restaurantStringId, restaurantTakeawayServiceStatus, orderway } = this.state
        if (restaurantTakeawayServiceStatus && !status) {
            // 跳到点餐页面，自取 orderway=1 配送 orderway=2
            createHashHistory().push(`orderpage?string_id=${restaurantStringId}&orderway=${orderway}`);
        }
        this.setLoading(false);
    }

    /**
     * 设置缓存（餐厅的配送最低金额）
     * @param DELIVERY_FEE
     */
    // public setCache(deliveryFee) {
    //     LocalStorageManager.setLocalStorage(
    //         {
    //             path: LOCAL_STORAGE_KEYS.DELIVERY_FEE,
    //             value: deliveryFee
    //         }
    //     )
    // }
    /**
     * 优惠券包资源
     * @param stringId
     */
    public async getRestaurantCouponPackage(stringId) {
        const couponPackage: CheckoutCouponPackage[] = await apiManage.getRestaurantCouponPackage(stringId, { notNeedLoading: true });
        this.setState({
            couponPackage
        })
        if (couponPackage.length > 0) {
            // 初始化滚动插件
            this.initBetterScroll();
        }
    }
    /**
     * 添加优惠券到个人账户
     * @param item 优惠券信息
     */
    public async onAddCoupon(item, joinMember: boolean) {
        const cacheAccount: Customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
        const accountID = cacheAccount._id;
        const { _id, belonged_restaurant } = item
        if (accountID) {
            // 检查token是否失效
            const isTokenInvalid = checkToken()
            if (isTokenInvalid) {
                return
            }
            // $ 领取优惠券自动加入会员
            if (joinMember && !this.state.isMember) {
                if (cacheAccount.first_name && cacheAccount.last_name) {
                    await this.joinMember();
                }
            }
            const result: any = await apiManage.getCoupon(belonged_restaurant, _id, accountID, { notNeedLoading: true });
            if (_.get(result, 'error')) {
                showApiMessage(result, 'coupon')
            } else {
                if (this.state.restaurantTheme === 'SECOND_THEME') {
                    await this.getCustomerInfo()
                }
                showApiMessage(result, 'coupon', { message: i18n.t('coupon_text_successfully_received'), level: 'Success' })
            }
        } else {
            this.setState({
                showTip: {
                    show: true,
                    actionType: 'getCoupon',
                    couponItem: item
                }
            })
        }
    }
    /**
     * 通过扫面领取优惠券
     * @param result 扫描结果
     */
    public async onScanCodeCoupon(result) {
        const cacheAccount: Customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
        const accountID = cacheAccount._id;
        const info = getQueryString(result, 'info')
        if (accountID) {
            if (info) {
                const result: any = await apiManage.getCouponByScan(info);
                if (_.get(result, 'error')) {
                    showApiMessage(result, 'coupon')
                } else {
                    showApiMessage(result, 'coupon', { message: i18n.t('coupon_text_successfully_received'), level: 'Success' })
                }
                this.setState({ showQR: false })
            }
        }
    }
    /**
     * 输入编码领取优惠券
     * @param insertCode 输入code
     */
    public async onInsertCodeCoupon(insertCode) {
        const cacheAccount: Customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
        const accountID = cacheAccount._id;
        if (accountID) {
            if (insertCode) {
                // 检查token是否失效
                const isTokenInvalid = checkToken()
                if (isTokenInvalid) {
                    return
                }
                // $ 领取优惠券自动加入会员
                if (!this.state.isMember) {
                    await this.joinMember();
                }
                const result: any = await apiManage.onInsertCodeCoupon(accountID, insertCode);
                if (_.get(result, 'error')) {
                    showApiMessage(result, 'coupon')
                } else {
                    showApiMessage(result, 'coupon', { message: i18n.t('coupon_text_successfully_received'), level: 'Success' })
                }
                this.setState({ showInsertCode: false })
            }
        }
    }

    /**
     * 获取餐馆的二维码图片
     */
    public async getRestaurantQRcodePic(restaurantId: string, restaurantStringId: string) {
        const QRcodePic: any = await apiManage.getRestaurantQRcodePic(restaurantId, restaurantStringId,
            { notNeedLoading: true })
        if (!QRcodePic.error) {
            this.setState({ QRcodePic: String(QRcodePic) })
        }
    }

    /**
     * 是否在临时停业时间内
     * @param temporaryClosureStatus 餐馆临时停业开关
     * @param temporaryClosureStartTime 餐馆临时停业开始时间
     * @param temporaryClosureEndTime 餐馆临时停业结束时间
     */
    setTemporaryClosure(temporaryClosureStatus: boolean, temporaryClosureStartTime: Date, temporaryClosureEndTime: Date) {
        try {
            if (this.countdownInterval) clearInterval(this.countdownInterval);
            // 是否在临时停业时间内
            if (temporaryClosureStatus && temporaryClosureStartTime && temporaryClosureEndTime) {
                // $ 将本地当前时间转化为餐馆时区的时间
                let currentTime = moment(new Date()).format(moment.defaultFormatUtc);
                currentTime = convertDateByTimeZoneName(currentTime, momentTimeZone.tz.guess(), this.state.restaurantsTimeZoneName)
                // 现在时间于结束时间的差（结束时间-现在时间）
                const countdownValue = moment(moment(temporaryClosureEndTime)).diff(currentTime, 'seconds');
                if (countdownValue > 0) {
                    this.setState({
                        countdownValue,
                        temporaryClosureStatus: true
                    }, () => {
                        this.startCountdown();
                    })
                }
            } else {
                this.setState({
                    temporaryClosureStatus: false
                })
            }
        } catch (error) {
            console.log(error);
            this.setState({
                temporaryClosureStatus: false
            })
        }
    }
    /**
     * 临时停业开始倒计时
     * @param initValue 倒计时的初始值（秒）
     */
    startCountdown() {
        try {
            if (this.state.temporaryClosureStatus) {
                if (this.countdownInterval) clearInterval(this.countdownInterval);
                this.countdownInterval = setInterval(() => {
                    let countdownValue = this.state.countdownValue;
                    countdownValue = countdownValue - 1;
                    const countdownText = moment('00:00:00', 'HH:mm:ss').add(countdownValue, 's').format('HH:mm:ss')
                    // 秒转成时分秒
                    this.setState({ countdownText, countdownValue });
                    if (countdownValue <= 0) {
                        if (this.countdownInterval) clearInterval(this.countdownInterval);
                        this.setState({
                            temporaryClosureStatus: false
                        })
                    }
                }, 1000);
            } else {
                if (this.countdownInterval) clearInterval(this.countdownInterval);
                this.setState({
                    temporaryClosureStatus: false
                })
            }
        } catch (error) {
        }
    }

    /**
     * 预定托盘更新
     */
    updateOrderPageState() {
        const string_id = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID)
        this.searchRestaurant(string_id);
    }

    /**
     * 前往登录页面
     * - 登录后自动执行，加入会员操作，（领取优惠券 | 打开领取优惠券弹窗）
     */
    goToLogin() {
        const showTip = this.state.showTip
        Store.dispatch({
            type: 'SET_STATE',
            path: 'showTipCallBackAction',
            value: {
                member: true,
                getCoupon: showTip.actionType === 'getCoupon' ? showTip.couponItem : false,
                openCouponDialog: showTip.actionType === 'couponDialog'
            }
        })
        createHashHistory().push('/sign-in');
    }
    /**
     * 获取预定信息
     * - 获取餐厅信息的api
     */
    async getBookingServiceSchedule() {
        // 滚到最顶
        const dom = document.querySelector('#bookingBoxMain');
        if (dom) { dom.scrollTo?.({ top: 0 }); }
        Store.dispatch({
            type: 'SET_STATE',
            path: 'showTipCallBackAction',
            value: undefined
        })
        this.setState(({ isShowBookingBox: true, checkoutComponent: 'BOOKING' }));

        const { restaurantStringId, accountID } = this.state
        // 餐馆信息
        const results = await apiManage.getRestaurantInfo(restaurantStringId, {}, accountID);
        if (results.dbResults && results.dbResults[0]) {
            const restaurantInfo: Restaurant = results.dbResults[0] || {};
            this.setState({
                // 预定时间表
                bookingServiceSchedule: _.get(restaurantInfo, 'setting_booking_service_schedule', []),
                // 预定休业时间表
                bookingNoServiceSchedule: _.get(restaurantInfo, 'setting_booking_no_service_schedule', []),
                // 预定服务是否开启
                restaurantBookingServiceStatus: _.get(restaurantInfo, 'setting_booking_service_status', false),
                // 最大可预定时间（天数）未设置则14天
                settingMaxBookingDay: _.get(restaurantInfo, 'setting_max_booking_day', 14)
            })
        }
    }


    // !!更新数据方法 不要区更改这个方法
    public updateState(value: any) {
        this.setState({ ...value });
    }


    // *********************
    // View
    // *********************
    render() {
        const {
            orderHeaderData, description, Chipdata, isDialogVisible, isScheduleDialogvisible,
            scheduledata, restaurantlogo, isShowRestaurant, restaurantTakeawayServiceStatus,
            showTip, isShowAddress, accountID, customerInfo, isShowShareBox, isShowBookingBox, QRcodePic,
            bookingServiceSchedule, settingClosedSchedule, bookingNoServiceSchedule,
            countdownText, temporaryClosureStatus, isVisibleClosedInfo, closedDialogshowPage,
            restaurantTheme, restaurantGoogleMapLat, restaurantGoogleMapLng,
            couponPackage, loadingStatus, blacklistedUser, showBubbles, restaurantBookingServiceStatus,
            stopTakeawayStatus, stopSelftakeStatus, settingMaxBookingDay, restaurantsTimeZoneName, nextOpenTimeText, restaurantsNeedSignIn,
            checkoutComponent
        } = this.state
        const BGColor = restaurantTheme === 'SECOND_THEME' ? 'darkTopbar' : 'unknown';
        const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN) || {};
        return (
            <div className='restaurantpage' style={BGColor === 'darkTopbar' ? { background: '#282733' } : {}}>
                {/* 加载动画 */}
                {loadingStatus && <LoadingPage />}
                {/* 头部 */}
                {isShowRestaurant === 0 || isShowRestaurant === 1 ?
                    <TopBar
                        isShowClosed={temporaryClosureStatus}
                        closedTime={countdownText}
                        title={orderHeaderData.title}
                        info={orderHeaderData.subtitle}
                        isGoBack={() => createHashHistory().push('/')}
                        backgroundstyle={BGColor}
                        isHideGoback={isShowRestaurant === 0 ? false : !_.get(token, 'access_token', '')}
                    />
                    : null}
                {/* 页面内容 */}
                {
                    isShowRestaurant === 1 ?
                        restaurantTheme === 'FIRST_THEME' ?
                            // 默认第一个布局
                            <DefaultThemeRestaurantPage
                                params={this.state}
                                updateState={(value) => { this.setState({ ...value }) }}
                                onPaper={(item) => this.onPaper(item)}
                                onOrder={(value) => this.onOrder(value)}
                                onInsertCodeCoupon={(value) => this.onInsertCodeCoupon(value)}
                                onScanCodeCoupon={(value) => this.onScanCodeCoupon(value)}
                                onAddCoupon={async (value) => await this.onAddCoupon(value, true)}
                            />
                            :
                            restaurantTheme === 'SECOND_THEME' ?
                                // 第二个布局（黑金时代）
                                <BlackGoldThemeRestaurantPage
                                    params={this.state}
                                    updateState={(value) => { this.setState({ ...value }) }}
                                    onPaper={(item) => this.onPaper(item)}
                                    onOrder={(value) => this.onOrder(value)}
                                    onInsertCodeCoupon={(value) => this.onInsertCodeCoupon(value)}
                                    onScanCodeCoupon={(value) => this.onScanCodeCoupon(value)}
                                    onAddCoupon={async (value) => await this.onAddCoupon(value, true)}
                                    todyOpenTime={this.getTodyOpenTime()}
                                    getCustomerInfo={async () => { await this.getCustomerInfo(accountID) }}
                                />
                                :// 第三个布局（墨绿科技）
                                <div></div>
                        : isShowRestaurant === 0 ? <div className='notfind'>{i18n.t('restaurant_info_page_text_restaurant_not_found')}</div> : ''
                }
                {/* 两个点餐按钮（解决浏览器状态栏高度导致被遮住）*/}
                {isShowRestaurant === 1 && (restaurantTheme === 'FIRST_THEME' ?
                    <OrderButtons
                        showBubbles={showBubbles}
                        closeBubbles={() => { this.setState({ showBubbles: false }) }}
                        isBlacklistedUser={blacklistedUser}
                        onOrder={(val: string) => { this.onOrder(val) }}
                        restaurantTakeawayServiceStatus={restaurantTakeawayServiceStatus}
                        stopTakeawayStatus={stopTakeawayStatus}
                        stopSelftakeStatus={stopSelftakeStatus}
                        restaurantBookingServiceStatus={restaurantBookingServiceStatus}
                        showBooking={(() => { this.getBookingServiceSchedule() })}
                    />
                    : <BlackGoldOrderButtons
                        onOrder={(val: string) => { this.onOrder(val) }}
                        restaurantTakeawayServiceStatus={restaurantTakeawayServiceStatus} />)}
                {/* 餐馆简介 */}
                {restaurantTheme === 'FIRST_THEME' ?
                    <RestaurantInfoDialog restaurantlogo={restaurantlogo}
                        orderHeaderData={orderHeaderData} Chipdata={Chipdata} description={description}
                        open={isDialogVisible} handleClose={() => this.setState({ isDialogVisible: false })} />
                    :
                    <BlackGoldRestaurantInfoDialog restaurantlogo={restaurantlogo}
                        orderHeaderData={orderHeaderData} Chipdata={Chipdata} description={description}
                        open={isDialogVisible} handleClose={() => this.setState({ isDialogVisible: false })} />
                }
                {/* 餐馆时间表 */}
                {restaurantTheme === 'FIRST_THEME' ?
                    <ScheduleDialog
                        serviceAllBtn={restaurantTakeawayServiceStatus}
                        className='scheduleDialog'
                        scheduledata={scheduledata}
                        settingClosedSchedule={settingClosedSchedule}
                        open={isScheduleDialogvisible}
                        handleClose={() => this.setState({ isScheduleDialogvisible: false })} />
                    :
                    <BlackGoldScheduleDialog
                        serviceAllBtn={restaurantTakeawayServiceStatus}
                        scheduledata={scheduledata}
                        settingClosedSchedule={settingClosedSchedule}
                        open={isScheduleDialogvisible}
                        handleClose={() => this.setState({ isScheduleDialogvisible: false })} />
                }
                {/* 请先登录提示窗 */}
                {showTip.show ?
                    <PleaseLogin restaurantTheme={restaurantTheme}
                        handleClose={() => { this.setState({ showTip: { show: false } }) }}
                        onConfirm={() => { this.goToLogin() }} />
                    : null}
                {/* 修改/添加/确认 地址信息 */}
                {
                    isShowAddress &&
                    <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 9 }}>
                        {restaurantTheme === 'FIRST_THEME' ?
                            <DeliveryAddress
                                type='restaurant'
                                customerInfo={customerInfo}
                                isGoBack={() => { this.setState({ isShowAddress: false }) }}
                                changeStatus={(customerInfo, status) => {
                                    this.onAddressConfirm(customerInfo, status)
                                }}
                                restaurantTheme={restaurantTheme}
                                restaurantPosition={
                                    restaurantGoogleMapLat ?
                                        {
                                            lat: restaurantGoogleMapLat,
                                            lng: restaurantGoogleMapLng
                                        }
                                        : undefined
                                }
                            />
                            :
                            <BlackGoldThemeDeliceryAddress
                                type='restaurant'
                                customerId={accountID}
                                customerInfo={customerInfo}
                                isGoBack={() => { this.setState({ isShowAddress: false }) }}
                                changeStatus={(customerInfo, status) => {
                                    this.onAddressConfirm(customerInfo, status)
                                }}
                                restaurantTheme={restaurantTheme}
                                restaurantPosition={
                                    restaurantGoogleMapLat ?
                                        {
                                            lat: restaurantGoogleMapLat,
                                            lng: restaurantGoogleMapLng
                                        }
                                        : undefined
                                }
                            />}
                    </div>
                }
                {/* // $ 暂不需要；谷歌地图搜素填写地址 */}
                {/* {
                    isShowAddressNotlogin &&
                    <GoogleMapSearch
                        restaurantTheme={restaurantTheme}
                        open={true}
                        onCancel={() => this.setState({ isShowAddressNotlogin: false })}
                        onOk={() => {
                            this.setState({ isShowAddressNotlogin: false })
                            this.goOrederPage()
                        }}
                        onSkip={() => {
                            this.setState({ isShowAddressNotlogin: false })
                            this.goOrederPage()
                        }}
                    />
                } */}
                {/* 分享 */}
                {
                    isShowShareBox ?
                        <ShareBox onhandleClose={() => { this.setState({ isShowShareBox: false }) }}
                            QRcodePic={QRcodePic} />
                        : null
                }
                {/* 预定托盘 */}
                <Checkout
                    componentType={checkoutComponent}
                    visible={isShowBookingBox}
                    closeCheckoutComponent={() => { this.setState({ isShowBookingBox: false }) }}
                    updateOrderPageState={() => { this.updateOrderPageState() }}
                    bookingServiceSchedule={bookingServiceSchedule}
                    bookingNoServiceSchedule={bookingNoServiceSchedule}
                    drawerMainClassName={'bookingMain'}
                    isBlacklistedUser={blacklistedUser}
                    settingMaxBookingDay={settingMaxBookingDay}
                    restaurantsTimeZoneName={restaurantsTimeZoneName}
                    restaurantsNeedSignIn={restaurantsNeedSignIn}
                />
                {/* 谷歌地图 */}
                {/* {isshowGoogleMap ? <GoogleMapComponent
                    componentType='RESTAURANT'
                    onCancel={() => {
                        this.setState({ isshowGoogleMap: false })
                    }}
                    onConfirm={() => { }}
                    center={{ lat: restaurantGoogleMapLat, lng: restaurantGoogleMapLng }}
                /> : null} */}

                {/* 停业弹窗显示 */}
                {
                    isVisibleClosedInfo ?
                        <SwipeableTemporaryClosedDialog
                            showPage={closedDialogshowPage}
                            visible={isVisibleClosedInfo}
                            countdownText={this.state.countdownText}
                            coupons={couponPackage}
                            onClose={() => {
                                this.onCloseDialog()
                            }}
                            nextOpenTimeText={nextOpenTimeText}
                        /> : null
                }
            </div >
        )
    }
}

export default RestaurantPage

// *********************
// Types
// *********************

type Props = {
    location: any;
    closedTime: any;
}
interface State extends RestaurantPageState {

}

export type RestaurantPageState = {
    // 头部信息
    orderHeaderData: orderHeaderDatatype;
    // 餐馆展览图
    tutorialSteps: Array<{ label: string, imgPath: any }>;
    // 餐馆描述
    description: string;
    // 餐馆标签
    Chipdata: Array<string>;
    // 餐馆详细描述弹窗
    isDialogVisible: boolean;
    // 餐馆营业时间、地址、手机、网址、facebook、goole
    allPaperbar: Array<Bardatatype>;
    // 餐馆营业表弹窗
    isScheduleDialogvisible: boolean;
    // 餐馆营业表data
    scheduledata: Array<Schedule>;
    // 餐馆是否自取
    selftake_status: boolean;
    // 餐馆是否派送
    takeaway_status: boolean;
    // 餐馆logo
    restaurantlogo: any;
    // 餐馆StringId
    restaurantStringId: string;
    // 餐馆Id
    restaurantId: string;
    // 是否找到餐馆
    isShowRestaurant: 0 | 1 | 2;
    // 餐馆当天当前时间是否营业
    isRestaurantOpen: boolean;
    // 餐馆营业总开关
    restaurantTakeawayServiceStatus: boolean;
    // 添加优惠券方式box
    showGetCuoponBox: boolean;
    // 输入code
    showInsertCode: boolean;
    // 扫码
    showQR: boolean;
    // 餐馆优惠券Arr
    couponPackage: CheckoutCouponPackage[];
    // 用户Id
    accountID: string;
    // 登录提示
    showTip: {
        show: boolean;
        actionType?: string;
        couponItem?: CheckoutCouponPackage;
    };
    // 显示选择地址
    isShowAddress: boolean;
    // 用户信息
    customerInfo: Customer;
    // 点餐方式
    orderway: string;
    // 显示确认地址(没有登录)
    isShowAddressNotlogin: boolean;
    isShowShareBox: boolean;
    isShowBookingBox: boolean;
    QRcodePic: string;
    bookingServiceSchedule: Array<BookingServiceSchedule>;
    settingClosedSchedule: Array<SettingClosedSchedule>;
    bookingNoServiceSchedule: Array<SettingClosedSchedule>;
    restaurantBookingServiceStatus: boolean;
    restaurantTheme: RestaurantTheme;
    restaurantThemeColor: RestaurantThemeColor;
    isshowGoogleMap: boolean;
    restaurantGoogleMapLat: number;
    restaurantGoogleMapLng: number;
    restaurantGoogleMapPlaceId: string;
    countdownText: string;
    temporaryClosureStatus: boolean,
    countdownValue: number;
    restaurantsTimeZoneName: string;
    restaurantPosition: { lat: number, lng: number } | undefined;
    // 停业弹窗
    isVisibleClosedInfo: boolean;
    // 停业弹窗显示内容 停业'closed', 临时停业'TemporaryClosed', 优惠券'coupon'
    closedDialogshowPage: string[],
    // 下个营业时间文本
    nextOpenTimeText: string;
    // 今天是否关闭
    todyClose: boolean,
    // 停业时间
    resClosedTime: number;
    start_time: Date;
    end_time: Date;
    loadingStatus: boolean;
    blacklistedUser: boolean;
    showBubbles: boolean;
    // 所属集团id
    belongedGroupId: string
    // 禁止外送
    stopTakeawayStatus: boolean;
    // 禁止自取
    stopSelftakeStatus: boolean;
    // 最大可预定时间（天）
    settingMaxBookingDay: number;
    // 当前餐厅所在的集团积分
    currentIntegrals: number;
    // 是否是会员
    isMember: boolean;
    // 免登录预定
    restaurantsNeedSignIn: boolean;
    // 托盘类型
    checkoutComponent: ICheckoutProps['componentType'];
};

export declare type Bardatatype = {
    leftIcon?: () => JSX.Element;
    rightIcon?: () => JSX.Element;
    title?: (() => JSX.Element) | string;
    content?: (() => JSX.Element | boolean) | string;
    key: string;
}

export type orderHeaderDatatype = {
    leftIco: (className?: string) => JSX.Element
    title: string
    subtitle: string
    rightIco: (className?: string) => JSX.Element
    renderFunction?: (className?: string) => JSX.Element
}