import React from 'react';
import { Clear, Check } from '@material-ui/icons';
import './account-information.component.scss';
import { Button } from '@material-ui/core';
import i18n from '../../i18n';
import { IS_VCODE } from '../../constants/base.constant';

import ReCaptChaDialog from '../recaptcha-dialog/recaptcha-dialog';
import apiManage from '../../request';
import Store from '../../rudex/store';
import _ from 'lodash';
import Notification from '../../component/snackbar/snackbar';
import { GOOGLE_ROBOT_SCORE } from '../../gconfig';
import { FeatGoogleReCaptcha } from '../../page/sign-in/sign-in.page';

// 校验验证码
export default function VerificationCode(props: {
    className: any;
    defaultValue: string;
    codeSecond: number;
    isCountDown: boolean;
    onChangeSecond: () => void;
    onChange?: (val: string) => void;
}) {
    // const IS_VCODE = /^\d{6}$/;
    const [isCode, setIsCode] = React.useState(true);
    // 谷歌人机校验获取token
    const [showGoogleReCaptcha, onShowGoogleReCaptcha] = React.useState(false);
    // 谷歌人机校验图像弹窗
    const [showReCaptChaDialog, onShowReCaptChaDialog] = React.useState(false);
    // 是否通过人机校验
    const [isPassReCaptcha, onIsPassReCaptcha] = React.useState(false);

    /**
     * 处理ReCaptchaV2回调
     */
    function handelReCaptchaCallBack(result: 'Success' | 'Expire' | 'Error') {
        // 操作成功
        let message = 'general_text_successful_operation';
        // let level: 'Warning' | 'Error' | 'Default' | 'Info' | 'Success' | undefined = 'Success';
        if (result === 'Success') {
            // 通过成功
            onIsPassReCaptcha(true)
        } else if (result === 'Expire' || result === 'Error') {
            // 操作失败
            message = 'general_text_operation_failed';
            // level = 'Error';
        }

        Notification.notification(i18n.t(message));
    }

    /**
     * 通过reCaptchaToken解析分值
     */
    async function checkReCaptchaToken(reCaptchaToken) {
        try {
            if (reCaptchaToken) {
                const result = await apiManage.googleReCaptchaSiteverify({ token: reCaptchaToken })
                if (!_.get(result, 'error')) {
                    if (result.score <= GOOGLE_ROBOT_SCORE) {
                        onShowReCaptChaDialog(true)
                    } else {
                        // 评分高，已验证不是机器人
                        onIsPassReCaptcha(true)
                        props.onChangeSecond()
                    }
                }
            }
        } catch (error) { }
        // 关闭全局loading
        Store.dispatch({
            type: 'SET_STATE',
            path: 'globalLoading',
            value: false
        })
    }
    return (
        <div
            className={props.className}
        >
            <div className='code-content'>
                <div className='pos-relative'>
                    <input
                        className='verification-code-insert-in'
                        value={props.defaultValue}
                        type='tel'
                        pattern='[0-9]'
                        maxLength={6}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const EXP = /^[0-9]*$/;
                            if (EXP.test(event.target.value)) {
                                if (typeof props.onChange === 'function') props.onChange(event.target.value);
                                setIsCode(IS_VCODE.test(event.target.value))
                            }
                        }}
                    />

                    {
                        props.defaultValue.length > 0 ? (
                            isCode ?
                                <Check style={{ position: 'absolute', right: 10, color: '#fff', width: 20, height: 20, padding: 1, backgroundColor: '#008000', borderRadius: 50 }} className='signin-label' /> :
                                <Clear style={{ position: 'absolute', right: 10, color: '#fff', width: 20, height: 20, padding: 1, backgroundColor: 'red', borderRadius: 50 }} className='signin-label' />) :
                            <Check style={{ position: 'absolute', top: -100, color: '#FFF', fontSize: 18, width: 20, height: 20, backgroundColor: '#008000', borderRadius: 50 }} />
                    }

                </div>
                {/* 验证码倒计时 */}
                <div className='code-timing'>
                    <Button className='send-code'
                        disabled={props.isCountDown}
                        onClick={() => {
                            if (!props.isCountDown) {
                                // 是否通过校验
                                if (isPassReCaptcha) {
                                    props.onChangeSecond()
                                } else {
                                    Store.dispatch({
                                        type: 'SET_STATE',
                                        path: 'globalLoading',
                                        value: true
                                    })
                                    onShowGoogleReCaptcha(true);
                                }
                            }
                        }}>
                        {
                            props.isCountDown ? `${i18n.t('signup_page_button_resend', { 1: props.codeSecond })}` : `${i18n.t('account_page_text_send')}`
                        }
                    </Button>
                </div>
                {/* 谷歌人机校验获取token*/}
                {<FeatGoogleReCaptcha
                    showGoogleReCaptcha={showGoogleReCaptcha}
                    onVerify={async (token) => {
                        onShowGoogleReCaptcha(false);
                        checkReCaptchaToken(token);
                    }} />}
                {/* 谷歌人机选择图像校验*/}
                {showReCaptChaDialog &&
                    <ReCaptChaDialog
                        handleClose={() => { onShowReCaptChaDialog(false) }}
                        handelReCaptchaCallBack={(result) => { handelReCaptchaCallBack(result) }}
                    />}
            </div>
        </div>
    )
}