import React from 'react';
import { Check, Clear } from '@material-ui/icons';
import { IS_PHONE } from '../../constants/base.constant';
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import '../../static/country-style/country-select.scss'
import i18n from '../../i18n';
/**
 * 用户账号手机号输入框
 */
export default function PhoneInput(props: {
    className: string;
    defaultValue: string;
    phonePrefix?: string;
    onChangePhonePrefix?: (val: string) => void;
    onChange?: (val: string) => void;
    disabled?: boolean;
}) {

    return (
        <div
            className={props.className}
        >
            <ReactPhoneInput
                disabled={props.disabled}
                enableSearch={false}
                disableSearchIcon={true}
                searchPlaceholder={i18n.t('general_text_search')}
                searchNotFound={''}
                // preferredCountries={['it', 'es', 'lu']}
                onlyCountries={['it', 'cn', 'es', 'lu']}
                onChange={(_, data) => {
                    props?.onChangePhonePrefix?.((data as { dialCode: string })?.dialCode);
                }}
                containerClass='countrySelectBox'
                searchClass='search-pre'
                dropdownClass='drop-pre'
                buttonClass='button-pre'
                inputStyle={{ display: 'none' }}
                value={props.phonePrefix || '39'}
            />
            <input
                disabled={props.disabled}
                className='phone-insert-in'
                type='tel'
                pattern='[0-9]'
                value={props.defaultValue}
                onChange={(val: any) => {
                    const EXP = /^[0-9]*$/;
                    if (EXP.test(val.target.value)) {
                        if (typeof props.onChange === 'function') props.onChange(val.target.value);
                    }
                }}
            />

            {
                props.defaultValue.length > 0 ? (
                    IS_PHONE(props.phonePrefix || '39', props.defaultValue) ?
                        <Check style={{ position: 'absolute', right: 10, color: '#fff', width: 20, height: 20, padding: 1, backgroundColor: '#2dcc56', borderRadius: 50 }} className='signin-label' /> :
                        <Clear style={{ position: 'absolute', right: 10, color: '#fff', width: 20, height: 20, padding: 1, backgroundColor: 'red', borderRadius: 50 }} className='signin-label' />) :
                    <Check style={{ position: 'absolute', color: '#FFF', fontSize: 18, width: 20, height: 20, backgroundColor: '#fff', borderRadius: 50 }} />
            }
        </div>
    )
}

// 下拉选项
export declare type Option = {
    // 组名
    label: string;
}
// function Selete(props: {
//     defaultValue: string | number;
//     options: Array<any>;
//     handleChange?: (val: string) => void;
//     disabled?: boolean
// }) {
//     let useStyle = makeStyles({
//         root: {
//             '& .MuiSelect-root': {
//                 border: 'none',
//                 backgroundColor: '#FFF',
//                 width: '100%'
//             },
//             '& .MuiInputBase-root': {
//                 border: 'none'
//             }

//         },
//         margin: {
//             justifyContent: 'center',
//             alignItems: 'center',
//             height: '100%',
//             borderRight: '1px solid #ccc'
//             // overflow: 'hidden'
//         }
//     });
//     return (
//         <FormControl className={useStyle().margin}>
//             <CountrySelect
//                 disabled={props.disabled}
//                 defaultValue={props.defaultValue || ''}
//                 onChange={(val: any) => {
//                     if (typeof props.handleChange === 'function')
//                         props.handleChange(val);
//                 }}
//             ></CountrySelect>
//         </FormControl>
//     )
// }