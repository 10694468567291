import React from 'react';
import { MdDirectionsBike, MdCardGiftcard, MdAccessTime } from 'react-icons/md';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import i18n from '../../i18n';
import { AirBubbles } from '../payment/payment.componet';

export function OrderButtons(props: {
    onOrder: (val: string) => void;
    restaurantBookingServiceStatus: boolean;
    restaurantTakeawayServiceStatus: boolean;
    isBlacklistedUser: boolean;
    showBubbles: boolean;
    closeBubbles: () => void;
    showBooking: () => void;
    stopTakeawayStatus: boolean;
    stopSelftakeStatus: boolean;
}) {
    const { onOrder, restaurantTakeawayServiceStatus, isBlacklistedUser, showBubbles, closeBubbles, stopTakeawayStatus, stopSelftakeStatus
        , showBooking, restaurantBookingServiceStatus
    } = props;
    return (
        <div className='orderbuttons'>
            {/* 黑名单气泡提示 */}
            {showBubbles && <div className='bubblesBox'>
                {isBlacklistedUser && <AirBubbles title={i18n.t('general_text_you_have_been_banned_by_this_restaurant')} attachClass='bubbles' onClick={closeBubbles} />}
            </div>}
            {/* 预定功能 */}
            {
                <div className={`reservationButton ${(isBlacklistedUser || !restaurantBookingServiceStatus) ? 'disable' : ''}`}
                    onClick={() => {
                        if (!isBlacklistedUser && restaurantBookingServiceStatus) {
                            showBooking();
                        }
                    }}
                >
                    <span className='iconBox'>{<MdAccessTime />}</span>
                    <span className='title'>{i18n.t('restaurant_info_page_text_reservation')}</span>
                </div>
            }
            <div className={`deliveryButton pickup ${(!restaurantTakeawayServiceStatus) || (isBlacklistedUser) || stopSelftakeStatus ? 'disable' : ''}`}
                onClick={() => {
                    LocalStorageManager.setLocalStorage(
                        {
                            path: LOCAL_STORAGE_KEYS.DELIVERY_FEE,
                            value: {}
                        }
                    )
                    LocalStorageManager.setLocalStorage(
                        {
                            path: LOCAL_STORAGE_KEYS.DELIVERY_FEE_ARR,
                            value: []
                        }
                    )
                    onOrder('pickup')
                }}>
                <span className='iconBox'><MdCardGiftcard /></span>
                <span className='title'>{i18n.t('restaurant_info_page_text_order_takeaway')}</span>
            </div>
            <div className={`deliveryButton delivery ${(!restaurantTakeawayServiceStatus) || (isBlacklistedUser) || stopTakeawayStatus ? 'disable' : ''}`}
                onClick={() => {
                    onOrder('delivery')
                }} >
                <span className='iconBox'><MdDirectionsBike /></span>
                <span className='title'>{i18n.t('restaurant_info_page_text_order_delivery')}</span>
            </div>
        </div>
    )
}