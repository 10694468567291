import React from 'react';
import './insert-code-dialog.style.scss'
import { Dialog, DialogContent, Button, Input } from '@material-ui/core';
import i18n from '../../i18n';
import ClearIcon from '@material-ui/icons/Clear';
import { showApiMessage } from '../../messenger/messageManager';

//  输入code
export function InsertCode(
    props: {
        className?: string;
        handleClose: () => void
        onConfirm: (insertCode) => void
        restaurantStringId?: string
    }
) {
    const [insertCode, opChangeValue] = React.useState('');
    const [restaurantId, setRestaurantId] = React.useState(val());

    function val() {
        const { restaurantStringId } = props
        return restaurantStringId ? restaurantStringId : ''
    }


    const { handleClose, onConfirm, className } = props;
    return (
        <Dialog
            open={true}
            onClose={() => {
                handleClose()
            }}
            className={`InsertCodeDialog ${className}`}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                <div className='dialogContent'>
                    <div className='dialogName'>
                        <div className='fontStyle'>
                            {i18n.t('coupon_text_insert_Code')}
                            <div className='close' onClick={() => {
                                handleClose()
                            }}>
                                <ClearIcon className='clearIcon' />
                            </div>
                        </div>
                    </div>
                    <div className='insertCode  orderlivery-bottom-96'>
                        <Input
                            classes={{ root: 'leftInput' }}
                            defaultValue={insertCode}
                            rows={1}
                            disableUnderline
                            placeholder={i18n.t('coupon_text_insert_Code')}
                            fullWidth
                            onChange={(event) => {
                                opChangeValue(event.target.value.toUpperCase())
                            }}
                        />
                        -
                        <Input
                            classes={{ root: 'rightInput' }}
                            defaultValue={restaurantId}
                            rows={1}
                            placeholder={i18n.t('coupon_Text_enter_the_restaurant_code')}
                            // disabled={restaurantStringId ? true : false}
                            disableUnderline
                            fullWidth
                            onChange={(event) => {
                                setRestaurantId(event.target.value.toUpperCase())
                            }} />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button className='insertCodebtn ok'
                            onClick={() => {
                                // 正则转义符eslint
                                // eslint-disable-next-line no-useless-escape
                                const exp = /^[a-zA-Z0-9\-\.]{5,12}[\-?|\.?][a-zA-Z0-9]{5}$/
                                const code = `${insertCode}-${restaurantId}`
                                if (insertCode && restaurantId) {
                                    if (exp.test(code)) {
                                        onConfirm(`${insertCode}-${restaurantId}`)
                                    } else {
                                        showApiMessage({ error: true, status: 404, result: '' }, 'coupon',
                                            { message: i18n.t('coupon_text_coupon_does_not_exist'), level: 'Error' })
                                    }
                                }
                            }}>
                            {i18n.t('general_text_ok')}
                        </Button>
                        <Button className='insertCodebtn cancel'
                            onClick={() => {
                                handleClose()
                            }}>
                            {i18n.t('general_text_cancel')}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}