
// 创建订单可能会出现的错误。不在错误列表统一提出   创建订单失败请联系商家
const errorCode = [
    // *********************
    // General Error
    // *********************
    {
        statusCode: 404,
        code: 1001,
        message: 'request_failure_text_resource_not_found'
    },
    {
        statusCode: 403,
        code: 1002,
        message: 'request_failure_text_invalid_permission'
    },
    {
        statusCode: 403,
        code: 1003,
        message: 'general_text_login_information_is_invalid_please_log_in_again'
    },
    {
        statusCode: 400,
        code: 1004,
        message: 'request_failure_text_bad_request'
    },
    {
        statusCode: 500,
        code: 1005,
        message: 'request_failure_text_service_is_not_available'
    },
    {
        statusCode: 503,
        code: 1006,
        message: 'request_failure_text_database_unavailable'
    },
    {
        statusCode: 403,
        code: 1007,
        message: 'request_failure_text_resource_already_exists'
    },
    {
        statusCode: 422,
        code: 1008,
        message: 'request_failure_text_invalid_parameter'
    },
    {
        statusCode: 403,
        code: 1009,
        message: 'request_failure_text_invaild_socket'
    },
    {
        statusCode: 403,
        code: 1010,
        message: 'request_failure_text_websocket_error'
    },
    {
        statusCode: 500,
        code: 1011,
        message: 'request_failure_text_development_time_error'
    },
    {
        statusCode: 400,
        code: 1012,
        message: 'request_failure_text_service_temporarily_unavailable'
    },
    {
        statusCode: 503,
        code: 1013,
        message: 'request_failure_text_paypal_service_error'
    },
    {
        statusCode: 204,
        code: 1014,
        message: 'request_failure_text_no_content'
    },
    // *********************
    // Restaurant Error
    // *********************
    {
        statusCode: 404,
        code: 'RES_1001',
        message: 'request_failure_text_restaurant_not_found'
    },
    {
        statusCode: 400,
        code: 'RES_1002',
        message: 'request_failure_text_restaurant_is_temporarily_closed'
    },
    {
        statusCode: 400,
        code: 'RES_1003',
        message: 'request_failure_text_restaurant_tax_information_not_found_or_incorrect'
    },
    {
        statusCode: 400,
        code: 'RES_1004',
        message: 'request_failure_text_delivery_fees_or_minimum_cost_of_delivery_or_self_taking_maximum_fee_minimum_invoice_fee_is_invalid'
    },
    {
        statusCode: 400,
        code: 'RES_1005',
        message: 'request_failure_text_order_must_choose_delivery_time_or_self_pick_up_time'
    },
    {
        statusCode: 400,
        code: 'RES_1006',
        message: 'request_failure_text_restaurant_tax_information_is_invalid'
    },
    {
        statusCode: 400,
        code: 'RES_1007',
        message: 'request_failure_text_the_scheduled_service_time_is_invalid'
    },
    {
        statusCode: 400,
        code: 'RES_1008',
        message: 'request_failure_text_dish_is_invalid'
    },
    {
        statusCode: 400,
        code: 'RES_1009',
        message: 'request_failure_text_bags_is_empty'
    },
    {
        statusCode: 400,
        code: 'RES_1010',
        message: 'request_failure_text_dish_not_found'
    },
    {
        statusCode: 400,
        code: 'RES_1011',
        message: 'request_failure_text_order_minimum_delivery_fee_not_reached'
    },
    {
        statusCode: 400,
        code: 'RES_1012',
        message: 'request_failure_does_not_comply_with_order_delivery_minimum_fee_rule'
    },
    {
        statusCode: 400,
        code: 'RES_1013',
        message: 'request_failure_text_does_not_comply_with_order_selftake_maximum_fee_rule'
    },
    {
        statusCode: 400,
        code: 'RES_1014',
        message: 'request_failure_text_does_not_comply_with_order_invoice_minimum_fee_rule'
    },
    {
        statusCode: 400,
        code: 'RES_1015',
        message: 'request_failure_text_order_total_price_is_invalid'
    },
    {
        statusCode: 422,
        code: 'ORDER_1001',
        message: 'request_failure_text_not_in_the_scope_of_delivery'
    },
    {
        statusCode: 400,
        code: 'ORDER_1002',
        message: 'reset_password_page_text_vcode_mismatched'
    },
    {
        statusCode: 400,
        code: 'RES_1027',
        message: 'request_failure_text_the_current_account_is_a_test_account_and_the_restaurant_does_not_have_test_mode_turned_on'
    },
    {
        statusCode: 400,
        code: 'ORDER_1006',
        message: 'request_failure_text_there_can_be_no_more_than_5_unprocessed_orders'
    },
    {
        statusCode: 400,
        code: 'ORDER_1021',
        message: 'request_failure_text_current_date_cannot_exceed_3_orders'
    },
    {
        statusCode: 400,
        code: 'ORDER_1022',
        message: 'request_failure_text_No_more_than_10_orders_this_week'
    },
    {
        statusCode: 400,
        code: 'ORDER_1007',
        message: 'request_failure_text_dishes_do_not_support_delivery_method'
    },
    {
        statusCode: 400,
        code: 'ORDER_1008',
        message: 'request_failure_text_Set_Meal_not_found'
    },
    {
        statusCode: 400,
        code: 'ORDER_1009',
        message: 'request_failure_text_Set_Meal_Area_not_found'
    },
    {
        statusCode: 400,
        code: 'ORDER_1010',
        // 套餐区域不属于套餐
        message: 'request_failure_text_Set_Meal_Area_not_belong_to_Set_Meal'
    }, {
        statusCode: 400,
        code: 'ORDER_1011',
        // 菜品不属于套餐区域
        message: 'request_failure_text_Dish_not_belong_to_Set_Meal_Area'
    }, {
        statusCode: 400,
        code: 'ORDER_1012',
        // 菜品数量大于套餐区域可选数量
        message: 'request_failure_text_Dish_number_greater_than_Set_Meal_Area_number_available'
    }, {
        statusCode: 400,
        code: 'ORDER_1013',
        // 菜品数量大于套餐最大可选数量
        message: 'request_failure_text_Dish_number_greater_than_Set_Meal_Dish_max_number_available'
    }, {
        statusCode: 400,
        code: 'ORDER_1014',
        // 包餐未找到
        message: 'request_failure_text_Package_Meal_not_found'
    }, {
        statusCode: 400,
        code: 'ORDER_1015',
        // 包餐区域不属于包餐
        message: 'request_failure_text_Package_Meal_Area_not_belong_to_Package_Meal'
    }, {
        statusCode: 400,
        code: 'ORDER_1016',
        // 包餐区域未找到
        message: 'request_failure_text_Package_Meal_Area_not_found'
    }, {
        statusCode: 400,
        code: 'ORDER_1017',
        // 菜品不属于包餐区域
        message: 'request_failure_text_Dish_not_belong_to_Package_Meal_Area'
    }, {
        statusCode: 400,
        code: 'ORDER_1018',
        // 套餐不属于包餐区域
        message: 'request_failure_text_Set_Meal_not_belong_to_Package_Meal_Area'
    }, {
        statusCode: 400,
        code: 'ORDER_1019',
        // 菜品数量大于包餐区域可选最大数量
        message: 'request_failure_text_Dish_number_greater_than_Package_Meal_Area_Dish_max_number_available'
    }, {
        statusCode: 400,
        code: 'ORDER_1020',
        // 菜品数量大于包餐可选数量
        message: 'request_failure_text_Dish_number_greater_than_Package_Meal__Dish_number_available'
    }, {
        statusCode: 400,
        code: 'ORDER_1024',
        // 菜品数量小于包餐区域最小可选数量
        message: 'request_failure_text_Dish_number_Less_than_Package_Meal_Area_Dish_min_number_available'
    },
    {
        statusCode: 400,
        code: 'ORDER_1025',
        // 菜品数量小于套餐区域最小可选数量
        message: 'request_failure_text_Dish_number_Less_than_Set_Meal_Dish_min_number_available'
    },
    {
        statusCode: 400,
        code: 'ORDER_1026',
        // 套餐区域不能重复选择
        message: 'request_failure_text_Set_Meal_Area_is_repeated_false'
    }, {
        statusCode: 400,
        code: 'ORDER_1027',
        // 包餐区域不能重复选择
        message: 'request_failure_text_Package_Meal_Area_is_repeated_false'
    }, {
        statusCode: 400,
        code: 'ORDER_1028',
        // 包餐区域为必选
        message: 'request_failure_text_Package_Meal_Area_is_required_true'
    }, {
        statusCode: 400,
        code: 'ORDER_1029',
        // 套餐区域为必选
        message: 'request_failure_text_Set_Meal_Area_is_required_true'
    },
    {
        statusCode: 400,
        code: 'ORDER_1030',
        message: 'request_failure_text_restaurant_temporarily_business'
    },
    {
        statusCode: 403,
        code: 'ORDER_1032',
        message: 'general_text_you_have_been_banned_by_this_restaurant'
    }, {
        statusCode: 400,
        code: 'ORDER_1033',
        message: 'request_failure_text_no_payment_method_set'
    }, {
        statusCode: 400,
        code: 'RES_1030',
        message: 'request_failure_text_no_payment_method_set'
    }, {
        statusCode: 400,
        code: 'ORDER_1035',
        message: `request_failure_text_no_payment_method_of_cash_set`
    }, {
        statusCode: 400,
        code: 'ORDER_1036',
        message: 'request_failure_text_It_is_forbidden_to_place_orders_in_this_area_during_the_current_time_period'
    }, {
        statusCode: 400,
        code: 'ORDER_1037',
        message: 'request_failure_text_STRIPE_payment_cannot_be_less_than'
    }, {
        statusCode: 400,
        code: 'RES_1032',
        message: 'request_failure_text_Restaurant_does_not_open_takeaway_service'
    }, {
        statusCode: 400,
        code: 'RES_1033',
        message: 'request_failure_text_Restaurant_does_not_open_the_self_pickup_service'
    }, {
        statusCode: 400,
        code: 'ORDER_1043',
        message: 'request_failure_text_The_menu_is_not_available_during_business_hours'
    }, {
        statusCode: 400,
        code: 1024,
        message: 'order_page_text_not_enough_for_points'
    }
]

export default errorCode