import React from 'react';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { ICheckoutNewAddress, ICheckoutNewAddressProps, ICheckoutNewAddressState } from '../../interfaces';
import { PhoneInput } from '../payment/payment.componet';
import GoogleMapComponent from '../googlemap-search/google-search';
import { Address } from '../../models';
import _ from 'lodash';
import './scss/checkout.styles.scss';
import InputComponent from '../input-component';
import { MdDone } from 'react-icons/md';
import i18n from '../../i18n';
import ButtonComponent from '../button.component';
import apiManage from '../../request';
import '../../page/payment/payment.scss';
import Notification from '../../component/snackbar/snackbar';
import { IS_CUSTOMER_NAME, IS_PHONE } from '../../constants/base.constant';
import { getBrowserPhoneCode } from '../../constants/tool-get-browser-language';

class CheckoutNewAddress extends React.Component<Props, State> implements ICheckoutNewAddress {

    public state: State = {
        address: {},
        addressLocal: '',
        doorbell: '',
        zipCode: '',
        detailAddress: '',
        isContacts: false,
        isDefaultAddress: true,
        firstName: '',
        lastName: '',
        phonePrefix: getBrowserPhoneCode(),
        phone: '',
        isAdd: false,
        isEdit: this.props.isEditAddress,
        // 是否为谷歌选中地址
        isGoogleAddress: true,
        // 谷歌选中地址的邮编
        isGoogleCodeInput: '',
        // 谷歌选中地址的具体位置
        googleInputVal: ''
    }

    // *********************
    // Life Cycle Function
    // *********************

    public UNSAFE_componentWillMount() {
        if (this.props.isEditAddress && this.props.editAddress) {
            this.init(this.props.editAddress);
        }
    }

    // *********************
    // Default Function
    // *********************

    init(editAddress: Address) {
        const accout = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        this.setState({
            address: editAddress,
            addressLocal: _.get(editAddress, 'google_map_formatted_address', ''),
            doorbell: _.get(editAddress, 'home_number', ''),
            zipCode: _.get(editAddress, 'code', ''),
            detailAddress: _.get(editAddress, 'details', ''),
            isContacts: accout.phone !== _.get(editAddress, 'phone', ''),
            isDefaultAddress: _.get(editAddress, 'default', false),
            firstName: accout.phone !== _.get(editAddress, 'phone', '') ? _.get(editAddress, 'first_name', '') : '',
            lastName: accout.phone !== _.get(editAddress, 'phone', '') ? _.get(editAddress, 'last_name', '') : '',
            phonePrefix: _.get(editAddress, 'phone_prefix', ''),
            phone: accout.phone !== _.get(editAddress, 'phone', '') ? _.get(editAddress, 'phone', '') : '',
            isEdit: true
        })
    }

    /**
     *  新增一个地址
     */

    public async addNewAddress() {
        const account = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);

        const { doorbell, zipCode, detailAddress, isContacts, isDefaultAddress, firstName, lastName, phonePrefix, phone, address } = this.state;
        const newAddress = {
            first_name: isContacts ? firstName : _.get(account, 'first_name', ''),
            last_name: isContacts ? lastName : _.get(account, 'last_name', ''),
            phone: isContacts ? phone : _.get(account, 'phone', ''),
            phone_prefix: isContacts ? phonePrefix : _.get(account, 'phone_prefix', ''),
            default: isDefaultAddress,
            country: _.get(address, 'country', ''),
            state: _.get(address, 'administrative_area_level_1') ?? _.get(address, 'state'),
            city: _.get(address, 'locality') ?? _.get(address, 'city'),
            home_number: doorbell,
            street: _.get(address, 'google_map_formatted_address'),
            code: zipCode ?? _.get(address, 'postal_code', ''),
            details: detailAddress,
            google_map_formatted_address: _.get(address, 'google_map_formatted_address', ''),
            google_map_lat: _.get(address, 'google_map_lat', 0),
            google_map_lng: _.get(address, 'google_map_lng', 0),
            google_map_place_id: _.get(address, 'google_map_place_id', '')
        };
        let allAddresses = _.cloneDeep(this.props.allAddresses);
        if (this.props.isEditAddress) {
            allAddresses = _.filter(allAddresses, item => {
                return _.get(item, 'isEdit', '') !== 'CHANGE';
            })
        }

        allAddresses.forEach((item: Address) => {
            if (isDefaultAddress) {
                item.default = false;
            }
        })
        allAddresses = allAddresses.concat([newAddress]);
        const param = {
            address: allAddresses
        }
        try {
            const customerInfo = await apiManage.patch_customer_info(param, account._id);
            if (!_.get(customerInfo, 'error')) {
                this.props.addNewAddress(allAddresses)
            } else {
                Notification.notification(i18n.t('payment_page_text_update_failed'))
            }
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * !! 数据修改方法
     */
    updateState(value) {
        this.setState({ ...value });
    }

    zipCodeBlurEvent(inputValue: string) {
        try {
            const { isGoogleCodeInput, googleInputVal, addressLocal } = this.state
            const input = document.getElementById('input-component');
            const searchBox = document.getElementById('google-map-search');
            const completeInfoBox = document.getElementById('completeInfoBox');
            if (isGoogleCodeInput === inputValue || !inputValue || !isGoogleCodeInput) {
                if (input) {
                    input.style.border = 'unset'
                }
                // 这里还要在判断谷歌地址和输入地址是否相同
                if (searchBox && (googleInputVal === addressLocal || !googleInputVal)) {
                    searchBox.style.border = 'unset';
                    this.setState({ isGoogleAddress: true })
                }
            } else {
                if (input) {
                    input.style.border = '1px solid #fd7b88';
                    input.style.borderRadius = '88px'
                }
                if (searchBox) {
                    searchBox.style.boxSizing = 'border-box';
                    searchBox.style.border = '1px solid #fd7b88';
                    searchBox.style.height = 'unset';
                }
                if (completeInfoBox) completeInfoBox.style.margin = 'unset';
                this.setState({ isGoogleAddress: false })
            }
        } catch (error) {

        }
    }

    // *********************
    // View
    // *********************

    render() {
        const { doorbell, zipCode, detailAddress, isContacts, isDefaultAddress, firstName, lastName, phonePrefix, phone, address, isAdd,
            addressLocal, isGoogleAddress } = this.state;
        const infoArea = [
            {
                text: i18n.t('delivery_address_page_text_client_home_number'),
                value: 'doorbell',
                label: i18n.t('delivery_address_page_text_enter_your_home_number'),
                state: doorbell,
                type: 'text'
            },
            {
                text: i18n.t('text_client_zip_code'),
                value: 'zipCode',
                label: i18n.t('delivery_address_page_text_enter_your_zip_code'),
                state: zipCode,
                type: 'text'
            }
        ];
        const name = [
            {
                text: i18n.t('signup_page_text_first_name'),
                value: 'firstName',
                label: i18n.t('delivery_address_page_text_enter_your_first_name'),
                state: firstName,
                type: 'text'
            },
            {
                text: i18n.t('signup_page_text_last_name'),
                value: 'lastName',
                label: i18n.t('delivery_address_page_text_enter_your_last_name'),
                state: lastName,
                type: 'text'
            }
        ];
        return (
            <div
                className={isAdd ? 'checkout-add-address isNoContent' : 'checkout-add-address'}>
                <span className='text'>
                    {i18n.t('delivery_address_page_your_address')}
                </span>
                <GoogleMapComponent
                    componentType={'completeInfo'}
                    defaultValue={addressLocal}
                    className={isAdd && !addressLocal ? 'complete-google-map not-address' : 'complete-google-map'}
                    placeholder={i18n.t('delivery_address_page_text_enter_your_address')}
                    setInputVal={(value: string) => this.setState({ googleInputVal: value })}
                    setIsGoogleAdress={(val: boolean) => this.setState({ isGoogleAddress: val })}
                    onSave={(val: Address) => {
                        this.setState({
                            isAdd: false,
                            address: val,
                            addressLocal: _.get(val, 'google_map_formatted_address') || '',
                            zipCode: _.get(val, 'postal_code') || '',
                            isGoogleCodeInput: _.get(val, 'postal_code') || ''
                        })
                    }}
                />
                {!isGoogleAddress ? <div className={'tipsText'}>{i18n.t('general_text_incorrect_address_entered')}</div> : null}
                {
                    infoArea.map((item: InputInfo, index: number) => (
                        <div className='info-area' key={`${index}-input`}>
                            <span className='text'>
                                {item.text}
                            </span>
                            <div id={item.value === 'zipCode' ? 'input-component' : ''}>
                                <InputComponent
                                    value={item.state}
                                    label={item.label}
                                    type={item.type}
                                    onChange={(val: string | number) => {
                                        this.updateState({ [`${item.value}`]: val })
                                    }}
                                    blurEvent={item.value === 'zipCode' ? (inputValue) => this.zipCodeBlurEvent(inputValue)
                                        : undefined}
                                />
                            </div>
                        </div>
                    ))
                }
                <div className='detail-info-area'>
                    <span className='text'>
                        {i18n.t('delivery_address_page_text_details')}
                    </span>
                    <input
                        className='detail-input'
                        value={detailAddress}
                        placeholder={i18n.t('delivery_address_page_text_describe_details_about_the_address')}
                        onChange={(e) => {
                            this.setState({
                                detailAddress: e.target.value
                            })
                        }}
                    />
                </div>
                <div className='selectDefault'>
                    <div className='optionIco' style={{ background: '#fff' }}
                        onClick={() => {
                            this.setState({
                                isContacts: !isContacts
                            })
                        }}
                    >
                        {
                            isContacts ? <MdDone style={{ color: '#9791BF', height: 36, width: 36 }} /> : null
                        }
                    </div>
                    <p className='defaultTip'>{i18n.t('delivery_address_page_text_contact_person')}</p>
                </div>
                {
                    isContacts && name.map((item: InputInfo, index: number) => (
                        <div className='info-area' key={`${index}-input-name`}>
                            <span className='text'>
                                {item.text}
                            </span>
                            <InputComponent
                                value={item.state}
                                label={item.label}
                                type={item.type}
                                onChange={(val: string | number) => {
                                    if (val) {
                                        if (IS_CUSTOMER_NAME.test(val.toString())) {
                                            this.updateState({ [`${item.value}`]: val })
                                        }
                                    } else {
                                        this.updateState({ [`${item.value}`]: '' })
                                    }
                                }}
                            />
                        </div>
                    ))
                }
                {
                    isContacts && <PhoneInput
                        className={'signUpPhoneInput'}
                        hideLabel={true}
                        selectItem={phonePrefix}
                        defaultInput={phone}
                        handleChange={(val) => {
                            this.setState({
                                phone: val
                            })
                        }}
                        handleSelect={(val) => {
                            this.setState({
                                phonePrefix: val
                            });
                        }}
                        type='autoComplete'
                    />
                }

                <div className='selectDefault'>
                    <div className='optionIco' style={{ background: '#fff' }}
                        onClick={() => {
                            this.setState({
                                isDefaultAddress: !isDefaultAddress
                            })
                        }}
                    >
                        {
                            isDefaultAddress ? <MdDone style={{ color: '#9791BF', height: 36, width: 36 }} /> : null
                        }
                    </div>
                    <p className='defaultTip'>{i18n.t('delivery_address_page_text_set_as_default')}</p>
                </div>

                <ButtonComponent
                    className={(JSON.stringify(address) !== '{}' && (isContacts ? (firstName?.trim() !== '' && lastName?.trim() !== '' && IS_PHONE(phonePrefix, phone) && phonePrefix) : true)
                        && doorbell?.trim() !== '' && zipCode?.trim() !== '') && isGoogleAddress ? 'complace-btn-active' : 'complace-btn-active not-active'}
                    value={this.props.isEditAddress ? i18n.t('payment_page_text_edit') : i18n.t('payment_page_text_adjunction')}
                    onClick={() => {
                        if (JSON.stringify(address) !== '{}' && (isContacts ? (firstName?.trim() !== '' && lastName?.trim() !== '' && IS_PHONE(phonePrefix, phone) && phonePrefix) : true)
                            && doorbell?.trim() !== '' && zipCode?.trim() !== '' && isGoogleAddress) {
                            this.addNewAddress()
                        } else {
                            this.setState({
                                isAdd: true
                            })
                        }
                    }}
                />
            </div>
        )
    }
}
export default CheckoutNewAddress

// *********************
// Props & State
// *********************


interface State extends ICheckoutNewAddressState {
    address: Address;
    addressLocal: string;
    doorbell: string;
    zipCode: string;
    detailAddress: string;
    isContacts: boolean;
    isDefaultAddress: boolean;
    firstName: string;
    lastName: string;
    phonePrefix: string;
    phone: string;
    isAdd: boolean;
    isEdit: boolean;
    isGoogleAddress: boolean;
    isGoogleCodeInput: string;
    googleInputVal: string
}
interface Props extends ICheckoutNewAddressProps {
    addNewAddress: (allAddresses: Array<Address>) => void;
    isEditAddress: boolean;
    editAddress: Address;
}

export type InputInfo = {
    text: string;
    value: string;
    label: string;
    state: string;
    type: string;
}
