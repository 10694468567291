import '../../component/my-order/my-order.style.scss';
import './my-reservations-detail.styles.scss';
import { Fab } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import _ from 'lodash';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import OrderDetailPanelComponent from '../../component/my-order/order-detail-panel.component';
import Scroll from '../../component/scroll/scroll.component';
import TopBar from '../../component/top-bar/top-bar.component';
import { Address, Bag, Customer, OrderDish, Restaurant } from '../../models';
import apiManage from '../../request';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import Notification from '../../component/snackbar/snackbar';
import i18n from '../../i18n';
import { inject_unount } from '../../component/decorators/helper.decorator';
import { createHashHistory } from 'history';

@inject_unount
class MyReservationsDetail extends React.Component<Props, State> {
    // *********************
    // 构造函数
    // *********************
    constructor(props: Props) {
        super(props);
        this.state = {
            showOrderDetailDialog: false,
            confirmDialogData: {},
            showCancelOrderDialog: false,
            orderDetailData: {
                _id: '',
                address: {},
                bags: [],
                dishes: {
                    totalNum: 0,
                    data: []
                },
                belonged_customer: [],
                belonged_restaurant: {}
            }
        }
    }

    // *********************
    // 生命周期
    // *********************
    componentDidMount() {
        // we check if already has a account
        if (this.checkToken()) {
            const params: any = this.props.match.params;
            if (params.booking_order_id) {
                this.getCustomerBookOrder({
                    booking_order_id: params.booking_order_id
                })
            }
        }
    }

    // *********************
    // 方法区
    // *********************
    /**
     * 检查token
     */
    checkToken = () => {
        const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
        if (!_.get(token, 'access_token')) {
            Notification.notification(i18n.t('order_page_text_after_login_view'))
            setTimeout(() => {
                this.props['history'].replace('/')
            }, 1000);
            return false;
        }
        return true;
    }
    /**
     * 获取订单详情
     */
    getCustomerBookOrder = async (params?: any) => {
        try {
            const result: any = await apiManage.getCustomerBookOrder(params)
            if (result.error) {
                const code = _.get(result, 'result.code');
                const statusCode = _.get(result, 'result.statusCode');
                if (code === 1003 && statusCode === 403) {
                    Notification.notification(i18n.t('order_page_text_after_login_view'))
                    setTimeout(() => {
                        this.props['history'].replace('/')
                    }, 1000);
                    return false;
                }
            }
            this.setState({
                orderDetailData: result.dbResults[0] || {}
            })
        } catch (error) { }
    }
    // *********************
    // 视图区
    // *********************

    render() {
        const { orderDetailData } = this.state;
        const restaurantPhone = orderDetailData?.belonged_restaurant?.tel;
        const telPrefix = _.get(orderDetailData, 'belonged_restaurant.tel_prefix') ? `+${_.get(orderDetailData, 'belonged_restaurant.tel_prefix')} ` : ''

        return (
            <div className='myOrderDetailPage myReservationsDetail'>
                <TopBar
                    title={i18n.t('my_reservations_page_text')}
                    isGoBack={() => {
                        createHashHistory().replace(`/my-reservations-page`);
                    }}
                ></TopBar>
                <div className='myOrderDetailPageContainer'>
                    <div className='orderDetail'>
                        <Scroll data={[orderDetailData]}>
                            <OrderDetailPanelComponent
                                componentType={'myReservations'}
                                orderDetailData={orderDetailData}
                            ></OrderDetailPanelComponent>
                        </Scroll>
                    </div>
                    <div className='footer'>
                        <div className='footerBtn'>
                            <a href={`tel:${telPrefix}${restaurantPhone}`}>
                                <Fab className='call'>
                                    <CallIcon />
                                </Fab>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default MyReservationsDetail;

// *********************
// Types
// *********************

type State = {
    // 显示订单详情弹框
    showOrderDetailDialog: boolean;
    // 显示确认框内容
    confirmDialogData: {
        title?: string;
        visible?: boolean;
        content?: string;
        onCancelFn?: () => void;
    }
    // 取消订单弹框
    showCancelOrderDialog: boolean;
    orderDetailData: {
        _id: string;
        address: Address;
        bags: Bag[];
        belonged_customer: Array<Customer>;
        belonged_restaurant: Restaurant;
        dishes: {
            totalNum: number;
            data: OrderDish[]
        };
        [key: string]: any
    }
}

type Props = {
} & WithTranslation & RouteComponentProps
