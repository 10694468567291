import { IconButton, Collapse, Chip, InputBase } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { AiFillMinusCircle, AiFillPlusCircle, AiOutlineQuestionCircle, AiFillDelete } from 'react-icons/ai';
import { MdLocalActivity, MdRateReview, MdClose, } from 'react-icons/md';
import i18n from '../../i18n';
import { CheckoutDetailCartDish } from '../../interfaces';
import { DishFixed } from '../../models/fixed/dish.entity';
import { AirBubbles } from '../payment/payment.componet';
import { DishTagBox } from '../order/order.component';
import { PM } from '../order/tool-points';
import { Schedule } from '../../models';

export function CheckoutCartItem(props: {
    // 菜品详情
    dishItem: CheckoutDetailCartDish;
    // 相同一个套餐、包餐数量不能大于99
    dishTotalNum?: number;
    // 气泡提示对应key
    airBubblesKey?: string;
    // 显示备注框
    showCollapseKey?: string;
    // 点击优惠券提示块
    clickAirBubles?: (airBubblesKey: string) => void;
    // 点击行
    clickRow?: () => void;
    // 加减菜品数量
    handleDish?: (isAdd: boolean, num?: number) => void;
    // 更改备注
    changeRemark: (action) => void;
    // 打开菜品可售时间
    openDishScheduleDialog: (availableSchedule?: Schedule[]) => void;
}) {
    const { dishItem, dishTotalNum, changeRemark, airBubblesKey, showCollapseKey, clickAirBubles, clickRow, handleDish, openDishScheduleDialog } = props;
    // 相同一个套餐、包餐数量不能大于99
    // let dishTotalNum = _.sumBy(_.filter(cartDishes, (n) => n.dish.firstId === dishItem.dish.firstId), (m) => m.num)
    // 优惠券气泡
    let airBubblesText = '';
    if (dishItem.coupons.length > 0) {
        dishItem.coupons.forEach((n => {
            if (airBubblesText) {
                airBubblesText = airBubblesText + '<br/>';
            }
            airBubblesText += n.name
        }))
    }


    const textareaRefs = useRef<HTMLTextAreaElement>(null)
    const [remark, setRemark] = useState(dishItem.dish.remark);
    const [editing, setEditing] = useState(false);

    const setMealDishes = renderSetMenuDetail(dishItem);
    const IMGURL = dishItem.dish && dishItem.dish.realpics && dishItem.dish.realpics[0];

    const isView = () => dishItem.dish.remark !== undefined && dishItem.dish.remark !== '' && dishItem.dish.remark !== null;

    useEffect(() => {
        if (editing) {
            textareaRefs.current?.focus();
        }
    }, [editing]);

    useEffect(() => {
        setRemark(dishItem.dish.remark);
    }, [dishItem.dish.remark]);

    return (
        <div className={'dishItem'} >
            {/* 特殊菜品标识 */}
            <DishTagBox dishItem={dishItem.dish} />
            <div className={`dishItemContent`}
                onClick={(evt) => {
                    evt.stopPropagation();
                    clickRow?.()
                }}
            >
                <div className={`dishImgbox`}>
                    <img className='dishImg' src={IMGURL} alt='' />
                </div>
                <div className='right-content'>
                    {/* 菜品名称 */}
                    <div className='titleBox' >
                        <div className='title'>{dishItem.dish.name}</div>
                        <div className='controls-bar'>
                            {/* 添加备注控件 */}
                            <IconButton
                                className='mdRateReviewBtn'
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    console.log('showCollapseKey', 1, showCollapseKey, 2, dishItem)
                                    changeRemark(dishItem.helpfulId);
                                    textareaRefs.current?.focus()
                                    setRemark(dishItem.dish.remark)
                                    setEditing(true)
                                }}>
                                <MdRateReview
                                    className={'mdRateReviewIcon'}
                                />
                            </IconButton>
                            {/* 优惠券 */}
                            {dishItem.showCoupon ?
                                <div className='couponIconBox'>
                                    <IconButton
                                        className='couponIconBtn'
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            clickAirBubles?.(dishItem.helpfulId === airBubblesKey ? '' : (dishItem.helpfulId || ''))
                                        }}>
                                        <MdLocalActivity className={'MdLocalActivity'} />
                                    </IconButton>
                                    <span className='iconText'>{dishItem.coupons.length}</span>
                                    {dishItem.showCoupon && airBubblesText && dishItem.helpfulId === airBubblesKey &&
                                        <AirBubbles onClick={() => { clickAirBubles?.('') }} attachClass='cartAirBubbless' title={airBubblesText} />}
                                </div>
                                : null}
                        </div>
                        <Collapse
                            in={editing || isView()}
                            className='collapse'
                        >
                            {
                                <Chip
                                    size='small'
                                    className='chip'
                                    label={
                                        <InputBase
                                            value={remark || ''}
                                            inputRef={textareaRefs}
                                            onChange={(evt) => setRemark(evt.target.value)}
                                            onBlur={() => {
                                                console.log('remark', remark)
                                                setEditing(false)
                                                if (remark === dishItem.dish.remark) {
                                                    return
                                                }
                                                changeRemark({ type: 'REMARK', id: dishItem.helpfulId, remark })
                                            }}
                                            inputProps={{
                                                'aria-label': 'naked', 'style': {
                                                    width: (remark ?? '').length * 5 + 50 + 'px',
                                                    maxWidth: '200px'
                                                }
                                            }}
                                            fullWidth={false}
                                            onFocus={() => setEditing(true)}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }
                                            }
                                        />
                                    }
                                    deleteIcon={< MdClose />}
                                    onDelete={() => {
                                        console.log('delete');
                                        if (remark === '') {
                                            setEditing(false)
                                            return
                                        }
                                        setRemark('')
                                        changeRemark({ type: 'REMARK', id: dishItem.helpfulId, remark: '' })
                                    }}
                                />
                            }
                        </Collapse>

                        {/* 价格 */}
                        <div className='price'>
                            {PM.showPointsOrPrice(dishItem.dish.is_points_redemption, dishItem.dish.price || 0)}
                        </div>
                        {dishItem.dish.unavailableTime ? <div className='dish-tip-box'>
                            <div className='dish-tip' onClick={(e) => { e.stopPropagation(); openDishScheduleDialog(dishItem.dish.availableSchedule) }}>
                                {i18n.t('order_page_text_Non-saleable_time')} <AiOutlineQuestionCircle />
                            </div>
                            <IconButton
                                className={`delete-iconBtn`}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    handleDish?.(false, dishItem.num ?? 0);
                                }}>
                                <AiFillDelete
                                    className='minusBtn'
                                />
                            </IconButton>
                        </div> : <div className='priceNumber'>
                            {/* 减号控件 */}
                            <IconButton
                                className={`iconBtn`}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    handleDish?.(false);
                                }}>
                                <AiFillMinusCircle
                                    className='minusBtn'
                                />
                            </IconButton>
                            <p className={'number'}>
                                {dishItem.num ?? 0}
                            </p>
                            {/* 加号控件 */}
                            <IconButton
                                className={`iconBtn ${(dishItem.num >= 99 || (dishTotalNum || 0) >= 99) ? 'disabledIconBtn' : ''}`}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    handleDish?.(true);
                                }}>
                                <AiFillPlusCircle className='plusBtn' />
                            </IconButton>
                        </div>}
                    </div>
                </div>
            </div>

            {/* 套餐\包餐详情 */}
            {
                (dishItem.dish.dish_type === '1' || dishItem.dish.dish_type === '2') && (
                    <div className='set-meal-dishes-box'>
                        <div className='set-meal-dishes-box-tip-text'>
                            {i18n.t('restaurant_order_page_text_please_add_dishes')}
                        </div>
                        <div className='set-meal-dishes-list'>
                            {(setMealDishes || []).map((item, index) => {
                                return (
                                    <div className='set-meal-dish-item' key={index}>
                                        <span className='dot'></span>
                                        <div className='setmenu-dish-name'>{item.name}</div>
                                        {/* 本身价格 + 套餐内的额外价格 */}
                                        {(item?.price || item?.CollectiveDishTotalPrice) ?
                                            <span className='setmenu-extra-price-text'>
                                                {`+`}{PM.showPointsOrPrice(dishItem.dish.is_points_redemption, item?.CollectiveDishTotalPrice || item?.price || 0)}
                                            </span>
                                            : null}
                                        {item.num && item.num > 1 ?
                                            <span className='setmenu-extra-price-text'>
                                                {`×${item.num}`}
                                            </span>
                                            : null}
                                    </div >
                                )
                            })}
                        </div>
                    </div>
                )
            }
        </div >
    )
}
/**
 * 整理套餐组内的菜品
 */
export function renderSetMenuDetail(dishItem: CheckoutDetailCartDish): DishFixed[] {
    let setMealDishes: DishFixed[] = [];
    try {
        const dish = dishItem.dish || {};
        let areas: any = [];
        // 包餐
        if (dish.dish_type === '1') {
            areas = _.get(dish, 'package_meal_areas', []);
        }
        // 套餐
        else if (dish.dish_type === '2') {
            areas = _.get(dish, 'set_meal_areas', []);
        }
        if (Array.isArray(areas)) {
            setMealDishes = _.flatMap(areas?.map((areasItem: any) => {
                return _.concat(areasItem?.set_meals || [], areasItem?.dishes || [])
            }))
        }
    } catch (error) { }
    return setMealDishes as DishFixed[];
}