import React from 'react';
import i18n from '../i18n';
import '../static/css/upgrading.scss';

export default class Upgrading extends React.Component<Props, State> {

    // *********************
    // Default Function
    // *********************

    // *********************
    // Life Cycle Function
    // *********************

    // *********************
    // Service Function
    // *********************

    // *********************
    // View
    // *********************

    render() {
        return (
            <div className='upgradingPage'>
                <p>{i18n.t('System is upgrading.')}</p>
            </div>
        )
    }
}


// *********************
// Props & State
// *********************

type Props = {}

type State = {}