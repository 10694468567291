import React from 'react';
import './get-cuopon-box.style.scss';
import i18n from '../../i18n';
import vpnKeyBlackLogo from '../../static/images/baseline_vpn_key_black_48dp.png';
// import foucsBlackLogo from '../../static/images/baseline_center_focus_strong_black_48dp.png';
// 获取优惠券的方式按钮
export function GetCuoponBox(
    props: {
        className?: string;
        onhandleQR: () => void;
        onhandleInsertCode: () => void;
        onhandleClose: () => void;
    }
) {
    const { /*onhandleQR,*/ onhandleInsertCode, onhandleClose, className } = props
    return (
        <div className={`getCuoponBoxBackgorund ${className}`}
            onClick={(event) => {
                onhandleClose()
                event.stopPropagation()
            }}>
            <div className='getCuoponBox'>
                {/*
                 // TODO 暂时屏蔽扫码功能
                */}
                {/* <div className='cuoponBoxRow' onClick={() => { onhandleQR() }}>
                    <div className='icon'>
                        <img className='cuoponImg'
                            src={foucsBlackLogo}
                            alt='qrCode' />
                    </div>
                    <div>QR Code</div>
                </div> */}
                <div className='cuoponBoxRow' onClick={() => { onhandleInsertCode() }}>
                    <div className='icon'>
                        <img className='cuoponImg'
                            src={vpnKeyBlackLogo}
                            alt='qrCode' />
                    </div>
                    <div>{i18n.t('coupon_text_insert_Code')}</div>
                </div>
            </div>
        </div >
    )
}