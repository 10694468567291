import * as React from 'react';
import _ from 'lodash';
import TopBar from '../top-bar/top-bar.component';
import './account-information.component.scss';
import { Button } from '@material-ui/core';
import PasswordInput from '../password-input.component';
import apiManage from '../../request';
import { IS_PASSWORD } from '../../constants/base.constant';
import { withSnackbar } from 'notistack';
import { withTranslation, WithTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import Notification from '../../component/snackbar/snackbar';

export enum CHANG_PASSWORD_COMPONENT_TYPE {
	FIRST_SETTING = 'FIRST_SETTING'
}
class ChangePassword extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			// 当前密码
			currentPassword: '',
			// 重复密码
			repeatNewPassword: '',
			// 密码
			newPassword: '',
			customerId: ''
		};
	}
	// ***************
	//  生命周期
	// ***************

	// 判断用户是否存在
	UNSAFE_componentWillMount() {
		const accountInfo = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
		if (accountInfo) {
			this.setState({
				customerId: accountInfo._id
			});
		}
	}

	// ***************
	// 方法区
	// ***************


	public async setCustomerPassword() {
		const { currentPassword, newPassword, customerId } = this.state;
		const { componentType } = this.props
		let passwordData = {}
		// 账号第一次设置密码
		if (componentType === CHANG_PASSWORD_COMPONENT_TYPE.FIRST_SETTING) {
			passwordData = {
				_id: customerId,
				first_setting: true,
				password: newPassword
			};
		} else {
			passwordData = {
				_id: customerId,
				currentPassword,
				newPassword
			};
		}
		try {

			const setCustomer = await apiManage.patch_customer_password(passwordData);
			if (setCustomer.error) {
				if (!/^5/.test(_.get(setCustomer, 'status'))) {
					Notification.notification(i18n.t('reset_password_page_text_insert_current_password'));
				}
			} else {
				Notification.notification(i18n.t('reset_password_page_text_password_reseted_successfully'));
				this.props.changeStatus(false, true);
			}
		} catch (error) {

		}

	}

	updateState = (value: any) => {
		this.setState(value);
	};

	// ***************
	// VIEW
	// ***************
	public render() {
		const { currentPassword, newPassword, repeatNewPassword } = this.state;
		const { componentType } = this.props
		return (
			<div className='change-password-main overall-center'>
				{/* Top bar */}
				<TopBar
					backgroundstyle={'backgroundTransparent'}
					isHideMenu={true}
					title={this.props.t('account_page_text_change_password')}
					isGoBack={() => {
						this.props.changeStatus(false);
					}}
				/>

				{/* 修改密码 */}
				<div className='change-password-content'>
					<div className='main-body'>
						<div style={{ overflow: 'auto' }}>
							{componentType === CHANG_PASSWORD_COMPONENT_TYPE.FIRST_SETTING ?
								null :
								<div className='item-style'>
									<span className='tag-style'>{i18n.t('account_page_text_current_password')}</span>
									<PasswordInput
										className='password-insert'
										defaultValue={this.state.currentPassword}
										isSame={true}
										onChange={(val: string) => {
											this.setState({
												currentPassword: val
											});
										}}
									/>
								</div>}

							<div className='item-style'>
								<span className='tag-style'>{i18n.t('account_page_text_new_password')}</span>
								<PasswordInput
									className='password-insert'
									defaultValue={this.state.newPassword}
									isSame={true}
									onChange={(val: string) => {
										this.setState({
											newPassword: val
										});
									}}
								/>
							</div>

							<div className='item-style'>
								<span className='tag-style'>
									{i18n.t('reset_password_page_text_reset_password')}
								</span>
								<PasswordInput
									className='password-insert'
									defaultValue={repeatNewPassword}
									isSame={newPassword === repeatNewPassword}
									onChange={(val: string) => {
										this.setState({
											repeatNewPassword: val
										});
									}}
								/>
							</div>
						</div>
						<Button
							className='confirm boxShadow-btn'
							variant='contained'
							onClick={() => {
								if (!componentType && (currentPassword === '' || !IS_PASSWORD.test(this.state.currentPassword))) {
									Notification.notification(i18n.t('reset_password_page_text_insert_current_password'));
									return;
								} else if (newPassword !== repeatNewPassword) {
									Notification.notification(i18n.t('reset_password_page_text_password_mismatched'));
									return;
								} else if (!IS_PASSWORD.test(this.state.newPassword)) {
									Notification.notification(i18n.t('signup_page_text_invalid_password_format'));
									return;
								}
								this.setCustomerPassword()
							}}>
							{i18n.t('signup_page_button_confirm')}
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()<Props>(withSnackbar(ChangePassword as any) as any);

// **********
// TYPE
// **********

type Props = {
	changeStatus: (val: boolean, isUpdata?: boolean) => void; // isUpdata为true 的时候更新数据
	componentType?: string;
} & WithTranslation;

type State = {
	// 当前密码
	currentPassword: string;
	// 新密码
	newPassword: string;
	// 重复新密码
	repeatNewPassword: string;
	// 用户ID
	customerId: string;
};
