import React from 'react'
import Calendar from 'react-calendar';
import './date-calendar.style.scss'
import moment from 'moment';
import _ from 'lodash';
import { SettingClosedSchedule } from '../../models';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';

/**
 * 日历选择器
 * @param onChange 点击函数
 * @param value 日期值
 * @param disabledWeeks 不可点击的周，数组
 * @param disabledDates 不可点击的天，数组
 */
export function DateCalendar(
    props: {
        onChange: (date: Date | Date[]) => void;
        value: Date | Date[] | null;
        disabledWeeks: string[];
        disabledDates: SettingClosedSchedule[];
        settingMaxBookingDay?: number;
    }
) {
    const { onChange, value, disabledWeeks, disabledDates, settingMaxBookingDay = 1 } = props;
    const [language, setLanguage] = React.useState('IT')
    React.useEffect(() => {
        setLanguage(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.LANGUAGE) || 'IT')
    }, [])
    return (
        <div className='dateCalendar boxShadow'>
            <Calendar
                locale={language}  // 语言
                // calendarType={'US'}                                     // 第一列是星期日
                minDetail={'month'}                                     // 不可点击年份(看到年月)
                next2Label={null}                                       // 隐藏切换年的按钮
                prev2Label={null}                                       // 隐藏切换年的按钮
                minDate={new Date()}                                    // 最小可选日期
                maxDate={moment(moment().add(settingMaxBookingDay - 1, 'days').format('YYYY-MM-DD 23:59:00')).toDate()}            // 最大可选日期
                onChange={
                    (date) => onChange(date)
                }
                value={value}
                tileDisabled={({ date }) => {
                    const momentDate = moment(date).format('YYYY-MM-DD')
                    const Weekday = moment(momentDate).isoWeekday()
                    if (_.includes(disabledWeeks, String(Weekday))) {
                        return true
                    }
                    let disabled = false
                    if (disabledDates) {
                        disabledDates.forEach((item) => {
                            // 是否包括开始的这一天
                            let isIncludingToday = false
                            // 是否包括结束的这一天
                            let isIncludingLastDay = false
                            if (item.start_time_interval === '00:00:01') {
                                isIncludingToday = true
                            }
                            if (item.end_time_interval === '23:59:59') {
                                isIncludingLastDay = true
                            }
                            // moment函数规则(是否包括当天)
                            const isAllDay: '[]' | '[)' | '(]' | '()' | undefined =
                                (isIncludingToday && isIncludingLastDay)
                                    ? '[]' : (isIncludingToday && !isIncludingLastDay) ? '[)' :
                                        (!isIncludingToday && isIncludingLastDay) ? '(]' : '()'

                            const start_date = formateDate(item.start_date, 'YYYY-MM-DD')
                            const end_date = formateDate(item.end_date, 'YYYY-MM-DD')
                            if (moment(momentDate).isBetween(start_date, end_date, 'date', isAllDay)) {
                                disabled = true
                                return true
                            }
                        })
                    }
                    return disabled
                }}
            />
        </div>
    )
}

/**
 * 格式化日期
 * @param date 日期
 * @param format 要转换的格式
 */
function formateDate(date?: string, format?: string) {
    return (date && format) ? moment(date, moment.HTML5_FMT.DATETIME_LOCAL_MS).format(format) : ''
}