import { EventEmitter } from 'events';
import io from 'socket.io-client';
import {
    OrderCreatedEvent,
    OrderUpdatedEvent,
    OrderPaypalPaymentUpdatedEvent,
    RestaurantResourceCreatedEvent,
    RestaurantResourceUpdatedEvent,
    AdminCreateResourceEvent,
    AdminUpdateResourceEvent,
    AdminDeleteResourceEvent,
    ServerSideEventType,
    ServerSideEvent,
    BookingOrderCreatedEvent,
    BookingOrderUpdateEvent,
    OrderStripePaymentUpdatedEvent,
    Order2PayNowPaymentUpdatedEvent,
    SystemUpdateEvent,
    ClearMessageEvent,
    BlockedUsersCreatedEvent,
    BlockedUsersRelieveEvent,
    VivaOrderUpdatedEvent,
    CheckSharkCustomerVerifyEvent
} from './type';

export class WebsocketClientClass extends EventEmitter {

    private socket: SocketIOClient.Socket = null as any;

    /**
     * 连接服务器
     *
     * @param websocketServerUrl 远程Websocket服务器连接地址
     * @param authorizationToken 令牌字符串
     * @param authorizationType 验证类型
     */
    public connect(
        websocketServerUrl: string,
        authorizationToken: string,
        authorizationType: string
    ) {
        // $ init
        this.socket = io(
            websocketServerUrl,
            {
                transports: ['websocket', 'polling', 'flashsocket'],
                query: {
                    'authorization': `Bearer ${authorizationToken}`,
                    'authorization-type': `${authorizationType}`
                },
                secure: true
            }
        );
        // $ init events
        // 默认事件
        this.socket.on('connect', (data: any) => this.emit('CONNECT_EVENT', data));
        this.socket.on('disconnect', (data: any) => this.emit('DISCONNECT_EVENT', data));
        // 重连成功之后才触发的
        this.socket.on('reconnect', (data: any) => this.emit('RECONECT_EVENT', data));
        // 重连失败
        this.socket.on('reconnect_error', (data: any) => this.emit('RECONECT_ERROR', data));
        this.socket.on('reconnect_failed', (data: any) => this.emit('RECONECT_ERROR', data));

        // 订单相关
        this.socket.on('ORDER_CREATED_EVENT', (data: OrderCreatedEvent) => this.emit('ORDER_CREATED_EVENT', data));
        this.socket.on('ORDER_UPDATED_EVENT', (data: OrderUpdatedEvent) => this.emit('ORDER_UPDATED_EVENT', data));
        this.socket.on('ORDER_PAYPAL_PAYMENT_UPDATED_EVENT', (data: OrderPaypalPaymentUpdatedEvent) => this.emit('ORDER_PAYPAL_PAYMENT_UPDATED_EVENT', data));
        this.socket.on('ORDER_STRIPE_PAYMENT_UPDATED_EVENT', (data: OrderStripePaymentUpdatedEvent) => this.emit('ORDER_STRIPE_PAYMENT_UPDATED_EVENT', data));
        this.socket.on('ORDER_2PAYNOW_PAYMENT_UPDATED_EVENT', (data: Order2PayNowPaymentUpdatedEvent) => this.emit('ORDER_2PAYNOW_PAYMENT_UPDATED_EVENT', data));
        // 餐馆资源相关
        this.socket.on('RESTAURANT_RESOURCE_CREATED_EVENT', (data: RestaurantResourceCreatedEvent) => this.emit('RESTAURANT_RESOURCE_CREATED_EVENT', data));
        this.socket.on('RESTAURANT_RESOURCE_UPDATED_EVENT', (data: RestaurantResourceUpdatedEvent) => this.emit('RESTAURANT_RESOURCE_UPDATED_EVENT', data));
        // 管理员操作相关
        this.socket.on('ADMIN_CREATE_RESOURCE_EVENT', (data: AdminCreateResourceEvent) => this.emit('ADMIN_CREATE_RESOURCE_EVENT', data));
        this.socket.on('ADMIN_UPDATE_RESOURCE_EVENT', (data: AdminUpdateResourceEvent) => this.emit('ADMIN_UPDATE_RESOURCE_EVENT', data));
        this.socket.on('ADMIN_DELETE_RESOURCE_EVENT', (data: AdminDeleteResourceEvent) => this.emit('ADMIN_DELETE_RESOURCE_EVENT', data));
        // 预定订单相关
        this.socket.on('BOOKING_ORDER_CREATED_EVENT', (data: BookingOrderCreatedEvent) => this.emit('BOOKING_ORDER_CREATED_EVENT', data));
        this.socket.on('BOOKING_ORDER_UPDATE_EVENT', (data: BookingOrderUpdateEvent) => this.emit('BOOKING_ORDER_UPDATE_EVENT', data));
        // 系统消息
        this.socket.on('SYSTEM_UPDATE_EVENT', (data: SystemUpdateEvent) => this.emit('SYSTEM_UPDATE_EVENT', data));
        // 同步消息
        this.socket.on('CLEAR_MESSAGES_EVENT', (data: ClearMessageEvent) => this.emit('CLEAR_MESSAGES_EVENT', data));
        // 黑名单用户
        this.socket.on('BLOCKED_USERS_CREATE_EVENT', (data: BlockedUsersCreatedEvent) => this.emit('BLOCKED_USERS_CREATE_EVENT', data))
        this.socket.on('BLOCKED_USERS_RELIEVE_EVENT', (data: BlockedUsersRelieveEvent) => this.emit('BLOCKED_USERS_RELIEVE_EVENT', data))
        // viva 支付通知
        this.socket.on('ORDER_VIVA_PAYMENT_UPDATED_EVENT', (data: VivaOrderUpdatedEvent) => this.emit('ORDER_VIVA_PAYMENT_UPDATED_EVENT', data))
        // shark身份审核
        this.socket.on('CHECK_SHARK_CUSTOMER_VERIFY_EVENT', (data: CheckSharkCustomerVerifyEvent) => this.emit('CHECK_SHARK_CUSTOMER_VERIFY_EVENT', data))
        // $ connect
        this.socket.connect();
    }

    /**
     * 断开服务器
     */
    public disconnect() {
        if (this.socket && this.socket.close) {
            this.socket.close();
        }
        if (this.socket && this.socket.disconnect) {
            this.socket.disconnect();
        }
    }

    /**
     * 往服务器发送消息
     */
    public emitToServer(
        event: ServerSideEventType,
        params: ServerSideEvent,
        callback: () => void = () => { }
    ) {
        this.socket.emit(
            event,
            params,
            callback
        );
    }

    /**
     * 往服务器发送消息（同步版本）
     */
    public async emitToServerAsync(
        event: ServerSideEventType,
        params: ServerSideEvent
    ) {
        return await new Promise((resolve: any, reject: any) => {
            try {
                this.socket.emit(
                    event,
                    params,
                    () => { resolve() }
                );
            } catch (error) {
                reject(error);
            }
        });
    }

}

export const DefaultWebsocketClient = new WebsocketClientClass();
