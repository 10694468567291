import React from 'react';
import { Typography } from '@material-ui/core';
import { ICheckoutShowedPageNames, ICheckoutSignInInputVcodeProps } from '../../interfaces';
import { MdChevronLeft } from 'react-icons/md';
import { IS_PASSWORD, IS_VCODE } from '../../constants/base.constant';
import i18n from '../../i18n';
import apiManage from '../../request';
import _ from 'lodash';
import ButtonComponent from '../../component/button.component';
import { signIn_param } from '../../component/checkout/checkout-sign-in-password-page';
import VerificationCode from '../../component/checkout/verification-code.component';
import PasswordInput from '../../component/password-input.component';
import { showApiMessage } from '../../messenger/messageManager';
import Notification from '../../component/snackbar/snackbar';

import ReCaptChaDialog from '../../component/recaptcha-dialog/recaptcha-dialog';
import { GOOGLE_ROBOT_SCORE } from '../../gconfig';
import { FeatGoogleReCaptcha, TimeoutAtCodeDelay } from './sign-in.page';

class ForgetPassword extends React.Component<Props, State> {
    public state: State = {
        // 文本框 - 验证码
        vcode: '',
        // 文本框 - 密码框
        password: '',
        // 谷歌人机校验获取token
        showGoogleReCaptcha: false,
        // 谷歌人机校验图像弹窗
        showReCaptChaDialog: false,
        // 是否通过人机校验
        isPassReCaptcha: false
    }

    // *********************
    // Life Cycle Function
    // *********************

    // *********************
    // Default Function
    // *********************

    /**
     * 通过reCaptchaToken解析分值
     */
    public async checkReCaptchaToken(reCaptchaToken) {
        try {
            if (reCaptchaToken) {
                const result = await apiManage.googleReCaptchaSiteverify({ token: reCaptchaToken })
                if (!_.get(result, 'error')) {
                    if (result.score <= GOOGLE_ROBOT_SCORE) {
                        this.setState({
                            showReCaptChaDialog: true
                        })
                    } else {
                        // 评分高，已验证不是机器人
                        this.setState({
                            isPassReCaptcha: true
                        })
                        // 发送验证码
                        this.props.sendPhoneGetVerificationCode();
                    }
                }
            }
        } catch (error) { }
    }

    /**
     * 处理ReCaptchaV2回调
     */
    public handelReCaptchaCallBack(result: 'Success' | 'Expire' | 'Error') {
        // 操作成功
        let message = 'general_text_successful_operation';
        if (result === 'Success') {
            // 通过成功
            this.setState({
                isPassReCaptcha: true
            })
            // 发送验证码
            this.props.updateShowedPage(ICheckoutShowedPageNames.SIGN_IN_INPUT_VCODE_PAGE);
            this.props.sendPhoneGetVerificationCode();
        } else if (result === 'Expire' || result === 'Error') {
            // 操作失败
            message = 'general_text_operation_failed';
        }
        // 提示
        Notification.notification(i18n.t(message));
    }

    /**
     * #### 密码登录 获取顾客头像和顾客信息,
     * 判断顾客信息是否完善, 不完善跳入信息补全页面
     */
    public async signIn() {
        const { password } = this.state;
        const { phone, phonePrefix } = this.props;
        const params = {
            phone,
            phone_prefix: phonePrefix,
            password
        }
        this.props.signIn(params);
    }

    /**
     * 重置密码
     */
    public async confirm() {
        const { phone, phonePrefix } = this.props;
        const { vcode, password } = this.state;
        // 验证码格式错误
        if (vcode === '' || !IS_VCODE.test(vcode)) {
            Notification.notification(i18n.t('reset_password_page_text_vcode_mismatched'));
            return;
        }
        // 密码格式错误
        else if (!(password && IS_PASSWORD.test(password))) {
            Notification.notification(i18n.t('signup_page_text_invalid_password_format'));
            return;
        }
        // 表单正确
        else {
            // 修改密码
            const params = {
                vcode: {
                    value: vcode,
                    type: 'RESET_PASSWORD_BY_PHONE'
                },
                password,
                phone,
                phone_prefix: phonePrefix
            }
            try {
                const result = await apiManage.patch_customer_password(params, {
                    headers: {
                        Authorization: undefined
                    }
                });
                if (result.error) {
                    showApiMessage(result, 'forgetPassword')
                    return;
                }
                Notification.notification(i18n.t('delivery_address_page_text_success'));
                this.signIn();
            } catch (error) {
                //
            }
        }

    }

    // *********************
    // View
    // *********************
    render() {
        const { vcode, password, isPassReCaptcha, showGoogleReCaptcha, showReCaptChaDialog } = this.state;
        const { phone, phonePrefix, vcodeTimeMS } = this.props;
        return (
            <div className='checkout-sign-in-phone'>
                <div className='headBox'>
                    <span className='actionIcon' onClick={() => this.props.updateShowedPage(ICheckoutShowedPageNames.SIGN_IN_INPUT_PHONE_PAGE)}>
                        <MdChevronLeft />
                    </span>
                    <span className='title'>
                        {i18n.t('signin_page_text_forget_password')}
                    </span>
                </div >
                <Typography className='tip-text'>
                    {i18n.t('delivery_address_page_please_your_verification_code')} {phonePrefix} {phone}
                </Typography>
                <VerificationCode
                    defaultValue={vcode}
                    onChange={(vCode) => this.setState({
                        vcode: vCode
                    })}
                />
                <Typography className='change-button'
                    onClick={() => {
                        if (this.props.vcodeTimeMS !== TimeoutAtCodeDelay) return;
                        if (isPassReCaptcha) {
                            this.props.sendPhoneGetVerificationCode();
                        } else {
                            this.setState({ showGoogleReCaptcha: true })
                        }
                    }}
                >
                    {
                        vcodeTimeMS < TimeoutAtCodeDelay ?
                            i18n.t('delivery_address_page_resend_verification_code', { number: vcodeTimeMS })
                            :
                            i18n.t('signup_page_text_send_vcode')
                    }
                </Typography>
                <div className='mt30'>
                    <Typography className='tip-text'>
                        {i18n.t('reset_password_page_text_password_mismatched_rule')}
                    </Typography>
                    <PasswordInput
                        className='sign-up-password'
                        defaultValue={password}
                        isSame={true}
                        onChange={(val: string) => {
                            this.setState({
                                password: val
                            });
                        }}
                        placeholder={i18n.t('delivery_address_page_please_enter_your_password')}
                    />
                </div>

                <ButtonComponent
                    className={!(IS_VCODE.test(vcode) && IS_PASSWORD) ? 'signup-button-not-phone' : 'signup-button-div'}
                    value={i18n.t('payment_page_text_next')}
                    onClick={() => {
                        if ((IS_VCODE.test(vcode) && IS_PASSWORD)) {
                            this.confirm()
                        }

                    }}
                />

                {/* 谷歌人机校验获取token*/}
                {<FeatGoogleReCaptcha
                    showGoogleReCaptcha={showGoogleReCaptcha}
                    onVerify={async (token) => {
                        this.setState({
                            showGoogleReCaptcha: false
                        })
                        this.checkReCaptchaToken(token)
                    }} />}  
                {/* 谷歌人机选择图像校验*/}
                {showReCaptChaDialog &&
                    <ReCaptChaDialog
                        handleClose={() => { this.setState({ showReCaptChaDialog: false }) }}
                        handelReCaptchaCallBack={(result) => { this.handelReCaptchaCallBack(result) }}
                    />}
            </div>
        )
    }
}

export default ForgetPassword


// *********************
// Props & State
// *********************

type State = {
    vcode: string;
    password: string;
    showGoogleReCaptcha: boolean;
    showReCaptChaDialog: boolean;
    isPassReCaptcha: boolean;
}

interface Props extends ICheckoutSignInInputVcodeProps {
    sendPhoneGetVerificationCode: () => void;
    // 登录接口
    signIn: (param: signIn_param) => void;
}
