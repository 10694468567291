import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import PhoneIcon from '@material-ui/icons/Phone';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { Dish, Order, Schedule } from '../../models';
import { SlideTypeObj } from '../../page/order/order.page'
import PaymentIcon from '@material-ui/icons/Payment';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
// import _ from 'lodash';
import './order-component.style.scss';
import { AiFillPlusCircle, AiFillMinusCircle, AiOutlineQuestionCircle } from 'react-icons/ai';
import { MdFastfood, MdShoppingBasket, } from 'react-icons/md';
import i18n from '../../i18n';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { RestaurantTheme } from '../../constants/tool-restaurant-theme';
import _ from 'lodash';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { IoLogoWhatsapp } from 'react-icons/io';
import { MenuFixed } from '../../models/fixed/menu.entity';
import { SetMeal } from '../../models/abstract/set-meal.entity';
import { PackageMeal } from '../../models/abstract/package-meal.entity';
import { SetMealArea } from '../../models/abstract/set-meal-area.entity';
import { PackageMealArea } from '../../models/abstract/package-meal-area.entity';
import { DishDisabledMask } from './dish-disabled-mask';
import { FaStar } from 'react-icons/fa';
import { ReactComponent as HotIcon } from '../../static/images/hot.svg';
import { Loyalty } from '@material-ui/icons';
import { ShopCar } from '../../models/fixed/shop-car.entity';
import { PM } from './tool-points';

// 点餐头部标题
export function OrderHeader(props: {
    orderHeaderData: {
        headerClassName?: string;
        leftIco: (className?: string) => JSX.Element;
        title?: string;
        subtitle?: string;
        rightIco: (className?: string) => JSX.Element;
        renderFunction?: (className?: string) => JSX.Element;
    };
    handleRightBtn: () => void;
    handleLeftBtn: () => void;
    handleMiddleBtn?: () => void;

}) {
    const { orderHeaderData } = props
    return (
        <div className={orderHeaderData.headerClassName ? `orderHeader ${orderHeaderData.headerClassName}` : 'orderHeader'}>
            <IconButton className='icoButton' onClick={() => props.handleLeftBtn()}>
                {orderHeaderData.leftIco()}
            </IconButton>
            <div>
                {
                    typeof orderHeaderData.renderFunction !== 'function' ?
                        <p>{orderHeaderData.title}</p>
                        : orderHeaderData.renderFunction()
                }
                {
                    orderHeaderData.subtitle && <span>{orderHeaderData.subtitle}</span>
                }
            </div>
            <IconButton className='icoButton' onClick={(evt: any) => {
                evt.stopPropagation()
                props.handleRightBtn()
            }}>
                {orderHeaderData.rightIco()}
            </IconButton>

        </div >
    )
}
// 点餐头部标题第二种主题样式
export function SecondOrderHeader(props: {
    orderHeaderData: {
        headerClassName?: string;
        leftIco: (className?: string) => JSX.Element;
        title?: string;
        subtitle?: string;
        rightIco: (className?: string) => JSX.Element;
        renderFunction?: (className?: string) => JSX.Element;
    };
    handleRightBtn: () => void;
    handleLeftBtn: () => void;
    handleMiddleBtn?: () => void;

}) {
    const { orderHeaderData } = props
    return (
        <div className='secOrderHeader'>
            <IconButton className='icoButton' onClick={(evt: any) => {
                evt.stopPropagation()
                props.handleRightBtn()
            }}>
                {orderHeaderData.rightIco()}
            </IconButton>
            <div className='searchBar' onClick={() => props.handleLeftBtn()}>
                <IconButton className='icoButton' onClick={() => props.handleLeftBtn()}>
                    {orderHeaderData.leftIco()}
                </IconButton>
            </div>


        </div >
    )
}

// $ 优化滑动事件导致重复渲染 滑块先择器
export const OrderSlide = React.memo(function OrderSlide(props: {
    slideData: Array<SlideTypeObj>;
    handleChangeSlideIndex: (id: string) => void;
    menuSearchValue?: string;
    RestaurantTheme: RestaurantTheme
}) {
    const { slideData, menuSearchValue, RestaurantTheme } = props;
    return (
        <div className={RestaurantTheme === 'FIRST_THEME' ? 'slideContainer' : 'secondSlide'}>
            {slideData.map((item) => {
                if (item.title === 'special_dish') {
                    return (
                        <Chip
                            className={menuSearchValue === item.id ? 'activeBg slideChip' : 'slideChip'}
                            key={item.id} label={i18n.t('restaurant_info_page_text_featured_dishes')}
                            onClick={() => props.handleChangeSlideIndex(item.id)}
                        />
                    )
                } else {
                    return (
                        <Chip
                            className={menuSearchValue === item.id ? 'activeBg slideChip' : 'slideChip'}
                            key={item.id} label={item.title}
                            onClick={() => props.handleChangeSlideIndex(item.id)}
                        />
                    )
                }

            })}
        </div>
    )
}, isUpdateSlide)
// $ 判断组件是否需要更新函数
function isUpdateSlide(prevProps, nextProps) {
    // 判断props是否没有更新 true为不更新
    return (prevProps.slideData === nextProps.slideData
        && prevProps.menuSearchValue === nextProps.menuSearchValue && prevProps.RestaurantTheme === nextProps.RestaurantTheme)
}

// $ 优化滑动事件导致重复渲染 菜单列表
export const DishList = React.memo(function DishList(
    props: {
        dishListData: Array<Dish> | undefined;
        dishTitle: string;
        // itemData 每一项数据  status为true为加法  false为减法
        updateShopCatList: (itemData: Dish, status: boolean) => void;
        saveShopCatDate: Array<Dish>;
        isShowMinusIco?: boolean;
        isRemarksDialog?: boolean;
        onshowRemarksDialog?: (itemData: Dish) => void;
        onshowDishDetails?: (itemData: Dish) => void;
        style?: any;
        RestaurantTheme?: string;
        isShopCatShow?: boolean;
        choosePackageMeal?: (itemData: Dish) => void;
        openSchedulesModel: (schedule: Schedule[]) => void;
    }
) {
    const { dishListData, dishTitle, saveShopCatDate, isRemarksDialog,
        onshowRemarksDialog, onshowDishDetails, style, RestaurantTheme, isShopCatShow, choosePackageMeal } = props;
    return (
        <div className={RestaurantTheme === 'FIRST_THEME' ? 'DishList' : 'secDishList'} style={{ ...style }}>
            {dishTitle && <h6>{dishTitle === 'special_dish' ? i18n.t('restaurant_info_page_text_featured_dishes') : dishTitle}</h6>}
            <ul>
                {
                    dishListData && dishListData.map((item, index) => {
                        // 获取到保存购买数量的对应数据
                        // const buyObject = saveShopCatDate.find(i => {
                        //     return i.firstId === item.firstId
                        // })
                        // const number = buyObject && buyObject.buyNumber;
                        let buyNumber = 0
                        saveShopCatDate.forEach(i => {
                            if (i.firstId === item.firstId) {
                                buyNumber += i.buyNumber ?? 0
                            }
                        })
                        return (
                            <li key={index}>
                                {RestaurantTheme === 'FIRST_THEME' ? <DishListItem
                                    isShowMinusIco={props.isShowMinusIco ? true : false}
                                    itemData={item}
                                    updateShopCatList={(itemData, status) => {
                                        props.updateShopCatList(itemData, status);
                                    }} number={buyNumber} isRemarksDialog={isRemarksDialog}
                                    onshowRemarksDialog={onshowRemarksDialog &&
                                        ((itemData) => onshowRemarksDialog(itemData))}
                                    onshowDishDetails={onshowDishDetails && ((dishes) => onshowDishDetails(dishes))}
                                    RestaurantTheme={RestaurantTheme}
                                    choosePackageMeal={(itemData: Dish) => { choosePackageMeal?.(itemData); }}
                                    isShopCatShow={isShopCatShow}
                                    openSchedulesModel={() => {
                                        props.openSchedulesModel(item.availableSchedule || [])
                                    }}
                                /> : <SecondDishListItem
                                    isShowMinusIco={props.isShowMinusIco ? true : false}
                                    itemData={item}
                                    updateShopCatList={(itemData, status) => {
                                        props.updateShopCatList(itemData, status)
                                    }} number={buyNumber} isRemarksDialog={isRemarksDialog}
                                    onshowRemarksDialog={onshowRemarksDialog &&
                                        ((itemData) => onshowRemarksDialog(itemData))}
                                    onshowDishDetails={onshowDishDetails && ((dishes) => onshowDishDetails(dishes))}
                                    choosePackageMeal={(itemData: Dish) => { choosePackageMeal?.(itemData) }}
                                    isShopCatShow={isShopCatShow}
                                />}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}, isUpdateDishList)

// $ 判断组件是否需要更新函数
function isUpdateDishList() {
    /**
     * 1、 判断props是否没有更新 true为不更新
     * 2、_.isEqual深度对比对象
     */
    // TODO影响到pics拿不到最新，先注释
    // console.log(prevProps.dishListData,'prevProps.dishListData');
    // console.log(nextProps.dishListData,'nextProps.dishListData');

    // return (_.isEqual(prevProps.saveShopCatDate, nextProps.saveShopCatDate)
    //     && _.isEqual(prevProps.dishListData, nextProps.dishListData)
    //     && (prevProps.dishTitle === nextProps.dishTitle)
    //     && (prevProps.isShowMinusIco === nextProps.isShowMinusIco)
    //     && (prevProps.isRemarksDialog === nextProps.isRemarksDialog))
    return false
}

// 菜单每一项
export function DishListItem(
    props: {
        itemData: Dish;
        // itemData 每一项数据  status为true为加法  false为减法
        updateShopCatList: (itemData: Dish, status: boolean) => void;
        number?: undefined | number;
        historyNumber?: number;
        isShowMinusIco?: boolean;
        // 是否点击购物车的菜品添加备注
        isRemarksDialog?: boolean;
        onshowRemarksDialog?: (itemData: Dish) => void;
        onshowDishDetails?: (itemData: Dish) => void;
        RestaurantTheme?: RestaurantTheme;
        // 选择套餐、包餐
        choosePackageMeal?: (itemData: Dish) => void;
        isShopCatShow?: boolean;
        // 订单类型1：自取 2：外送 3：堂食
        orderType?: string;
        openSchedulesModel: () => void;
    }
) {
    const { itemData, isRemarksDialog, onshowRemarksDialog, onshowDishDetails, choosePackageMeal, isShopCatShow } = props;
    const IMGURL = itemData && itemData.realpics && itemData.realpics[0];
    function handleDetails(itemData) {
        if (isRemarksDialog) {
            if (onshowRemarksDialog) {
                onshowRemarksDialog(itemData)
            }
        } else {
            if (onshowDishDetails) {
                onshowDishDetails(itemData)
            }
        }
    }
    // 菜品标识 0：菜品 1：包餐 2：套餐
    const dishType = itemData.dish_type || '0';
    // 显示禁用蒙层
    const showDisabledMask = [itemData.filter_status, itemData.status === '2'].filter((item) => item);

    return (
        <div className={`dishListItemBox `}>
            <div className={`dishListItem ${showDisabledMask?.length > 0 ? 'dishImgboxDisabled' : ''}`}>
                {/* 特殊菜品标识 */}
                <DishTagBox dishItem={itemData} />
                <div className={`dishImgbox `}>
                    <img className='dishImg' src={IMGURL} alt='' />
                </div>
                <div className='content'>
                    <div className='dishListtitle center'>
                        <p> {itemData.name}</p>
                    </div>
                    <div className='dishListdescription center' >
                        <p>{itemData.description}</p>
                    </div>
                    <div className='pricebox'>
                        <p className='priceboxprice'>
                            <span>
                                {PM.showPointsOrPrice(itemData.is_points_redemption, itemData.price || 0)}
                            </span>
                            {itemData.quantity_description
                                && <span className='fewPart'>{itemData.quantity_description}</span>}
                        </p>
                        {itemData.unavailableTime ? <div className='dish-tip' onClick={props.openSchedulesModel}>
                            {i18n.t('order_page_text_Non-saleable_time')} <AiOutlineQuestionCircle />
                        </div> : dishType !== '0' ?
                            (// 套餐、包餐的选购按钮
                                isShopCatShow ?
                                    <div>
                                    </div>
                                    :
                                    <Button className={((props.number || 0) < 99)
                                        ? 'setMenuBtn'
                                        : 'setMenuBtn disSetMenuBtn'} onClick={(evt) => {
                                            evt.stopPropagation();
                                            choosePackageMeal?.(itemData);
                                        }}>
                                        {i18n.t('restaurant_order_page_text_order')}
                                    </Button>
                            ) :
                            // 普通菜品的加减按钮
                            < div className='priceNumber'>
                                {/* 减号控件 */}
                                {
                                    // props.isShowMinusIco ? (
                                    <IconButton onClick={(evt) => {
                                        evt.stopPropagation();
                                        props.updateShopCatList(itemData, false);
                                    }}>
                                        <AiFillMinusCircle
                                            className={props.number
                                                ? 'AiFillMinusCircle'
                                                : 'AiFillMinusCircle no_number_Circle'}
                                        />
                                    </IconButton>
                                    // ) : null
                                }
                                <p className={props.number ? 'number' : 'number is_no_number'}>
                                    {(props?.number || 0) + (props?.historyNumber || 0)}
                                </p>
                                <IconButton onClick={(evt) => {
                                    evt.stopPropagation();
                                    props.updateShopCatList(itemData, true);
                                }}>
                                    <AiFillPlusCircle className={((props.number || 0) < 99)
                                        ? 'AiFillPlusCircle'
                                        : 'AiFillPlusCircle no_number_Circle'}
                                    />
                                </IconButton>
                            </div>
                        }
                    </div>
                </div>
                <div className='clickBox' onClick={() => handleDetails(itemData)}></div>
                {/* 禁用蒙层 */}
                {<DishDisabledMask onClick={() => handleDetails(itemData)} soldOut={itemData.status === '2'} filterOut={!!itemData.filter_status} />}
            </div>
        </div >
    )
}

// 第二种主题 菜单每一项
export function SecondDishListItem(
    props: {
        itemData: Dish;
        // itemData 每一项数据  status为true为加法  false为减法
        updateShopCatList: (itemData: Dish, status: boolean) => void;
        number?: undefined | number;
        isShowMinusIco?: boolean;
        // 是否点击购物车的菜品添加备注
        isRemarksDialog?: boolean;
        onshowRemarksDialog?: (itemData: Dish) => void;
        onshowDishDetails?: (itemData: Dish) => void;
        isShopCatShow?: boolean;
        choosePackageMeal?: (itemData: Dish) => void;
    }
) {
    const { itemData, isRemarksDialog, onshowRemarksDialog, onshowDishDetails, isShopCatShow } = props;
    const IMGURL = itemData && itemData.realpics && itemData.realpics[0];
    function handleDetails(itemData) {
        if (isRemarksDialog) {
            if (onshowRemarksDialog) {
                onshowRemarksDialog(itemData)
            }
        } else {
            if (onshowDishDetails) {
                onshowDishDetails(itemData)
            }
        }
    }
    // 菜品标识 0：菜品 1：包餐 2：套餐
    const dishType = itemData.dish_type || '0';
    return (
        <div className='secDishItemBox'>
            {dishType !== '0' && <div className='dishHead' style={{ backgroundColor: dishType === '2' ? '#5C5A66' : '#b07c4d' }}>
                {dishType === '2' ? i18n.t('restaurant_order_page_text_set_meal') : i18n.t('restaurant_order_page_text_package_meal')}
            </div>}
            <div className={`${isShopCatShow ? 'carDishListItem' : 'secdishListItem'} ${dishType !== '0' ? 'noTopRadius' : ''}`} >
                <div className='dishImgbox' >
                    <img className='dishImg' src={IMGURL} alt='' />
                </div>
                <div className='content'>
                    <div className='dishListtitle center' >
                        <p> {itemData.name}</p>
                    </div>
                    {/* 购物车弹窗不显示 */}
                    {isShopCatShow ? null : <div className='dishListdescription center' >
                        <p>{itemData.description}</p>
                    </div>}

                    <div className='pricebox'>
                        <p className='priceboxprice'>
                            <span>€{itemData.price && itemData.price.toFixed(2)}</span>
                            {itemData.quantity_description
                                && <span className='fewPart'>{itemData.quantity_description}</span>}
                        </p>
                        <div className={`${dishType === '0' ? 'priceNumber' : 'setMenuBtnBox'}`}>
                            {dishType !== '0' ?
                                (// 套餐、包餐的选购按钮
                                    isShopCatShow ?
                                        <div>
                                            {/* // todo 可能需要删除按钮 */}
                                        </div>
                                        :
                                        <Button className='setMenuBtn' onClick={(evt) => {
                                            evt.stopPropagation();
                                            // choosePackageMeal?.();
                                            props.updateShopCatList(itemData, true);
                                        }}>
                                            {i18n.t('restaurant_order_page_text_order')}
                                        </Button>
                                ) :
                                <>
                                    {isShopCatShow ?
                                        <IconButton onClick={(evt) => {
                                            evt.stopPropagation()
                                            props.updateShopCatList(itemData, false)
                                        }}>
                                            <AiFillMinusCircle
                                                className={props.number
                                                    ? 'AiFillMinusCircle'
                                                    : 'AiFillMinusCircle no_number_Circle'}
                                            />
                                        </IconButton>
                                        : null
                                    }
                                    {isShopCatShow ?
                                        <p className={props.number ? 'number' : 'number is_no_number'}>
                                            {props.number ? props.number : 0}
                                        </p> : null}
                                    <IconButton onClick={(evt) => {
                                        evt.stopPropagation()
                                        props.updateShopCatList(itemData, true)
                                    }}>
                                        {isShopCatShow ?
                                            <AiFillPlusCircle className='AiFillPlusCircle' />
                                            : <span className='AiFillPlusCircle'>+</span>}

                                    </IconButton>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className='clickBox' onClick={() => handleDetails(itemData)}></div>
            </div >
        </div >
    )
}

// 电话购物车总价格
export function OrderFloatDialog(
    props: {
        OrderPrice: number;
        saveShopCatDate: Array<Dish>;
        totalShopPrice: number;
        totalShopPoints: number;
        isShopCatShow: boolean;
        handlePhoneCall: () => void;
        handleShopCat: () => void;
        orderway: string | null;
        showleastPriceAnimation: boolean;
        temporaryClosureStatus: boolean;
        whatsAppEnable: boolean;
    }
) {
    const { totalShopPrice, totalShopPoints, handlePhoneCall, handleShopCat,
        orderway, saveShopCatDate, showleastPriceAnimation, temporaryClosureStatus, whatsAppEnable } = props;
    const totalCartDish = _.sum(_.map(saveShopCatDate, (item) => { return item.buyNumber }))
    const hasDeliveryFee = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE);
    const hasToken = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
    let leastPriceText = '';
    if (orderway === '2') {
        if (!hasDeliveryFee || JSON.stringify(hasDeliveryFee) === '{ }' || !hasToken) {
            // 选择地址后显示配送费
            leastPriceText = i18n.t('restaurant_order_page_the_shipping_fee_is_displayed_when_the_address_is_selected')
        } else {
            // 为0时不显示
            leastPriceText = hasDeliveryFee.delivery_minimum_fee ? i18n.t('payment_page_text_order_delivery_at_least',
                { 1: `€${hasDeliveryFee.delivery_minimum_fee ? hasDeliveryFee.delivery_minimum_fee.toFixed(2) : '0'}` }) : ''
        }
    }
    return (
        <div className='orderFloatDialog' onClick={(evt) => { evt.stopPropagation() }}>
            <IconButton className='iconContainer' onClick={() => {
                handlePhoneCall()
            }}>
                {
                    whatsAppEnable ?
                        <IoLogoWhatsapp className='IoLogoWhatsappIcon' />
                        : <PhoneIcon className='PhoneIcon' style={{ color: '#4a456c' }} />
                }
            </IconButton>
            <div className='content'>
                <div className='bag'>
                    <span className='totalTip'>{i18n.t('restaurant_order_page_text_all')}</span>
                    <span className='priceBox'>
                        <span className='price'>€{totalShopPrice.toFixed(2)}</span>
                        {totalShopPoints ? <span className='price'>{`+${totalShopPoints} ${i18n.t('my_fidelity_page_scores')}`}</span> : null}
                    </span>
                </div>
                <div className='totalPrice'>
                    <p className={`leastPrice ${(showleastPriceAnimation) ? 'leastPriceAnimation' : ''}`}>
                        {leastPriceText}
                    </p>
                </div>
            </div>

            <IconButton
                className={(saveShopCatDate.length === 0 || temporaryClosureStatus)
                    ? 'iconContainer isMinOrderPrice'
                    : 'iconContainer'}
                style={{ background: '#ffdc33' }} onClick={
                    () => {
                        handleShopCat()
                    }
                }>
                {/* 默认显示购物车按钮，点击之后切换到卡片 */}
                <div className='cartIconbox'>
                    <span className='cartNum'>{totalCartDish}</span>
                    <MdShoppingBasket style={{ color: '#0d0544' }} />
                    {/* {
                        isShopCatShow ?
                            <PaymentIcon style={{ color: '#323161' }} />
                            : <ShoppingCartIcon style={{ color: '#323161' }} />
                    } */}
                </div>
            </IconButton>
        </div >
    )
}

// 第二主题电话购物车总价格
export function SecondOrderFloatDialog(
    props: {
        OrderPrice: number;
        saveShopCatDate: Array<Dish>;
        totalShopPrice: number;
        isShopCatShow: boolean;
        handlePhoneCall: () => void;
        handleShopCat: () => void;
        orderway: string | null;
        showleastPriceAnimation: boolean;
        temporaryClosureStatus: boolean;
        whatsAppEnable: boolean;
    }
) {
    const { totalShopPrice, handlePhoneCall, handleShopCat, isShopCatShow, OrderPrice,
        orderway, saveShopCatDate, showleastPriceAnimation, temporaryClosureStatus, whatsAppEnable } = props;
    return (
        <div className='secorderFloatDialog' onClick={(evt) => { evt.stopPropagation() }}>
            <div className='content'>
                <div className='bag'><span>{i18n.t('restaurant_order_page_text_all')}</span></div>
                <div className='totalPrice'>
                    <p className='price'>€{totalShopPrice.toFixed(2)}</p>
                    {/* 配送费为0时不显示 */}
                    <p className={`leastPrice ${(showleastPriceAnimation) ? 'leastPriceAnimation' : ''}`}>
                        {orderway === '1'
                            ? ''
                            : orderway === '2' && OrderPrice
                                ? i18n.t('payment_page_text_order_delivery_at_least',
                                    { 1: `€${OrderPrice ? OrderPrice.toFixed(2) : '0'}` })
                                : ''}
                    </p>
                </div>
            </div>

            <div className='rightIcon'>
                <IconButton className='iconContainer' onClick={() => { handlePhoneCall() }
                }>
                    {
                        whatsAppEnable ?
                            <IoLogoWhatsapp className='IoLogoWhatsappIcon' />
                            : <PhoneIcon style={{ color: '#ffffff' }} />
                    }
                </IconButton>
                <IconButton
                    className={(saveShopCatDate.length === 0 || totalShopPrice < OrderPrice || temporaryClosureStatus)
                        ? 'iconContainer isMinOrderPrice'
                        : 'iconContainer'}
                    style={{ background: '#a97646' }} onClick={
                        () => {
                            handleShopCat()
                        }
                    }>
                    {/* 默认显示购物车按钮，点击之后切换到卡片 */}
                    {
                        isShopCatShow ?
                            <PaymentIcon style={{ color: '#ffffff' }} />
                            : <ShoppingCartIcon style={{ color: '#ffffff' }} />
                    }
                </IconButton>
            </div>
        </div >
    )
}



// 弹窗的每一列表
export function OrderDishFilterItem(
    props: {
        dishesFilter: Array<SlideTypeObj>;
        handleDishFilter: (item: SlideTypeObj) => void;
    }
) {
    const { dishesFilter, handleDishFilter } = props;
    return (
        <>
            <ul>
                <ul>
                    {
                        dishesFilter.map((item: SlideTypeObj, index) => {
                            return (
                                <li
                                    className={item.select ? 'selectBg' : ''}
                                    key={index}
                                    onClick={() => handleDishFilter(item)}>
                                    <span>
                                        {item.title}
                                    </span>
                                </li>
                            )
                        })
                    }
                </ul>
            </ul>
        </>
    )
}

// 搜索
export function OrderSearch(
    props: {
        handleRemoveHistory: (item: number) => void;
        handleSearchInput: (value: string) => void;
        handleCloseSearch: () => void;
        historyList: Array<string>;
        filterList: Array<MenuFixed>;
        saveShopCatDate: Array<Dish>;
        showSearchList: boolean;
        isShowNoDateTip: boolean;
        // itemData 每一项数据  status为true为加法  false为减法
        updateShopCatList: (itemData: Dish, status: boolean) => void;
        onshowDishDetails: (itemData: Dish) => void;
        RestaurantTheme: 'FIRST_THEME' | 'SECOND_THEME' | 'THIRD_THEME';
        choosePackageMeal?: (itemData: Dish) => void;
        openSchedulesModel: (schedule: Schedule[]) => void;
    }
) {
    const [InputValue, setInputValue] = useState('');
    const { historyList, saveShopCatDate, filterList, showSearchList, isShowNoDateTip, onshowDishDetails, RestaurantTheme, choosePackageMeal } = props;
    return (
        <div className='orderSearch'>
            <header>
                <div className='inputContainer'>
                    <input
                        className={RestaurantTheme === 'FIRST_THEME' ? 'seachVal' : 'secSeachVal'}
                        type='text'
                        placeholder={i18n.t('restaurant_order_page_text_dish_search_placeholder')}
                        value={InputValue}
                        onChange={(evt) => {
                            setInputValue(evt.target.value)
                            if (evt.target.value === '') {
                                props.handleSearchInput('')
                            }
                        }}
                        onKeyUp={(evt) => {
                            if (evt.keyCode === 13) {
                                props.handleSearchInput(InputValue)
                            }

                        }} />
                    <IconButton className={RestaurantTheme === 'FIRST_THEME' ? 'searchIcon' : 'secSearchIcon'} onClick={() => props.handleSearchInput(InputValue)}>
                        <SearchIcon />
                    </IconButton>

                </div>
                {/* 暂时屏蔽筛选 */}
                {/* <IconButton className='closeIco'>
                    <CloseIcon style={{ fontWeight: 'bold', color: '#333333' }} />
                </IconButton> */}
                <IconButton
                    className={RestaurantTheme === 'FIRST_THEME' ? 'closeIco' : 'secCloseIco'}
                    onClick={() => {
                        props.handleCloseSearch()
                    }}>
                    <CloseIcon />
                </IconButton>
            </header>

            {
                !showSearchList ? (
                    //    搜索记录
                    <div className='searchHistory'>
                        <p className={RestaurantTheme === 'FIRST_THEME' ? 'title' : 'secTitle'}>{i18n.t('general_text_recently_searched')}</p>
                        {
                            Array.isArray(historyList) && historyList.length > 0 ? (
                                <ul>
                                    {
                                        historyList.map((item, index) => {
                                            return (
                                                <li key={index} onClick={() => {
                                                    setInputValue(item);
                                                    props.handleSearchInput(item)
                                                }}>
                                                    <span >{item}</span>
                                                    <IconButton onClick={(evt) => {
                                                        evt.stopPropagation()
                                                        props.handleRemoveHistory(index)
                                                    }} style={{ paddingRight: '0' }}>
                                                        <CloseIcon style={{ fontWeight: 'bold', color: '#cfcfcf' }} />
                                                    </IconButton>

                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            ) : <div className='no_related_date'>{i18n.t('general_text_no_search_history_data')}</div>
                        }

                    </div>
                ) : (
                    //  搜索内容显示
                    <div className='searchContent' >
                        {
                            isShowNoDateTip ? (
                                Array.isArray(filterList) && filterList.length > 0 && filterList.map((item, index) => {
                                    const dishes = item.dishs &&
                                        item.dishs.filter(item => Object.keys(item).length !== 0)
                                    return (
                                        dishes && dishes.length > 0 ? (
                                            <DishList
                                                RestaurantTheme={RestaurantTheme}
                                                key={`${item.firstId}${index}`}
                                                dishListData={dishes}
                                                isShowMinusIco={true}
                                                dishTitle={`${item.name}`}
                                                updateShopCatList={(updateItem, status) => {
                                                    props.updateShopCatList(updateItem, status)
                                                }}
                                                saveShopCatDate={saveShopCatDate}
                                                onshowDishDetails={onshowDishDetails &&
                                                    ((dishes) => onshowDishDetails(dishes))}
                                                choosePackageMeal={(itemData: Dish) => choosePackageMeal?.(itemData)}
                                                openSchedulesModel={(schedule) => props.openSchedulesModel(schedule)}
                                            />
                                        ) : null
                                    )
                                })
                            ) : <div className='no_related_date'>
                                {i18n.t('general_text_no_search_history_data')}</div>

                        }
                    </div >
                )
            }



        </div >
    )
}

// 文本框
export function TextBox(
    props: {
        placeholderTxt?: string;
        styleName?: string;
        inputValue: string;
        labelName?: string;
        handleChange: (value: string) => void
        className?: string;
    }
) {
    const { placeholderTxt, styleName, handleChange, inputValue, labelName } = props;
    return (
        <div className='textBox'>
            <label htmlFor={labelName}>{labelName}</label>
            <textarea
                className={styleName}
                placeholder={placeholderTxt}
                value={inputValue}
                onChange={(evt) => { handleChange(evt.target.value) }}></textarea>
        </div>
    )
}

// 第二主题文本框
export function BlackGoldThemeTextBox(
    props: {
        placeholderTxt?: string;
        styleName?: string;
        inputValue: string;
        labelName?: string;
        handleChange: (value: string) => void
    }
) {
    const { placeholderTxt, styleName, handleChange, inputValue, labelName } = props;
    return (
        <div className='textBox'>
            <label htmlFor={labelName}>{`${i18n.t('restaurant_order_page_text_dish_remarks')}  ( ${labelName} )`}</label>
            <textarea
                className={styleName}
                placeholder={placeholderTxt}
                value={inputValue}
                onChange={(evt) => { handleChange(evt.target.value) }}></textarea>
        </div>
    )
}
// 点击购物车添加备注 弹窗
export const RemarksDialog = React.memo(function RemarksDialog(
    props: {
        handleClose: () => void;
        inputValue: string;
        className: string;
        open: boolean;
        onbutton: (value: string, ischeck: boolean) => void;
        title?: string;
    }
) {
    const { open, className, handleClose, onbutton, inputValue, title } = props;
    const [noteValue, setNoteValue] = React.useState(inputValue);

    return (
        <Dialog className={className} maxWidth='md' fullWidth={true}
            aria-labelledby='simple-dialog-title'
            open={open} onClose={handleClose}>
            <div className='closeIcon'>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div >
            <div className='head'>{title}</div>
            <TextBox
                labelName={i18n.t('restaurant_order_page_text_dish_remarks')}
                styleName='addNoteText'
                inputValue={noteValue}
                handleChange={(value) => { setNoteValue(value) }} />
            <div className='remarksbuttons'>
                <IconButton onClick={() => onbutton(noteValue, true)}>
                    <div className='okbutton'>
                        <span className='orderbold'>{i18n.t('general_text_ok')}</span>
                    </div>
                </IconButton>
                <IconButton onClick={() => onbutton(noteValue, false)} >
                    <div className='cancelbutton'>
                        <span className='orderbold'>{i18n.t('general_text_cancel')}</span>
                    </div>
                </IconButton>
            </div>
        </Dialog>
    )
}, isUpDateRemark)

// $ 判断组件是否需要更新函数
function isUpDateRemark() {
    // return prevProps.inputValue === nextProps.inputValue
    return true
}

// 第二种主题 点击购物车添加备注 弹窗
export function SecRemarksDialog(
    props: {
        handleClose: () => void;
        inputValue: string;
        className: string;
        open: boolean;
        onbutton: (value: string, ischeck: boolean) => void;
        title?: string;
    }
) {
    const { open, handleClose, onbutton, inputValue, title } = props;
    const [noteValue, setNoteValue] = React.useState(inputValue);
    return (
        <SwipeableDrawer
            open={open}
            onClose={handleClose}
            onOpen={() => { }}
            anchor={'bottom'}>
            <div className='SecRemarksDialog'>
                {/* <div className='closeIcon'>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div > */}
                <div className='head'><div></div></div>
                <BlackGoldThemeTextBox
                    labelName={title}
                    styleName='addNoteText'
                    inputValue={noteValue}
                    handleChange={(value) => { setNoteValue(value) }} />
                <div className='remarksbuttons'>
                    <IconButton onClick={() => onbutton(noteValue, true)}>
                        <div className='okbutton'>
                            <span className='orderbold'>{i18n.t('general_text_ok')}</span>
                        </div>
                    </IconButton>
                    <IconButton onClick={() => onbutton(noteValue, false)} >
                        <div className='cancelbutton'>
                            <span className='orderbold'>{i18n.t('general_text_cancel')}</span>
                        </div>
                    </IconButton>
                </div>
            </div>
        </SwipeableDrawer>
    )
}
// 提示弹窗
export function TipsDialog(
    props: {
        className?: string
        open: boolean
        handleClose: () => void
    }
) {
    const { open, handleClose, className } = props

    return (
        <Dialog className={className} maxWidth='md' fullWidth={true}
            aria-labelledby='simple-dialog-title'
            open={open} onClose={handleClose}>
            <div className='closeIcon'>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div >
            {/* <div className='head'></div>
            <div className='dialogbody'>
                <div>
                    <Button variant='contained' style={{ background: '#ffdc33' }} onClick={() => createHashHistory().push('/signInPage')}>
                        {i18n.t('restaurant_info_page_text_not_logged_in')}
                    </Button>
                </div>
            </div> */}

        </Dialog>
    )
}

// 筛选弹窗
export function FiterDraw(
    props: {
        showFilterDialog: boolean
        onclose: () => void
        onSelect: (item: string, type: 'Allergen' | 'Ingredients' | 'clear') => void
        isSelected: (item: string, type: string) => boolean
        allAllergen: Array<{ key: string, text: string }>
        allIngredients: Array<{ key: string, text: string }>
    }
) {
    const { showFilterDialog, onclose, onSelect, isSelected, allAllergen, allIngredients } = props
    return (
        <SwipeableDrawer
            open={showFilterDialog}
            onClose={onclose}
            onOpen={() => { }}
            anchor={'bottom'}>
            <div className='fiterDraw'>
                <div className='dialogName'>
                    <div className='fontStyle'>
                        {i18n.t('restaurant_order_page_text_dishes_filter')}
                        <div className='close' onClick={onclose}>
                            <ClearIcon className='clearIcon' />
                        </div>
                    </div>

                </div>
                <div className='clear-filter-box'>
                    <span className='title'>
                        {i18n.t('restaurant_order_page_text_allergen')}
                    </span>
                    <span className='clear-filter'
                        onClick={() => onSelect('Allergen', 'clear')}>
                        {i18n.t('general_text_clear_all')}
                    </span>
                </div>
                <div className='subtitle'>
                    <span>
                        {i18n.t('restaurant_order_page_text_hide_dishes_selected_allergen')}
                    </span>
                </div>
                <div className='filter'>
                    <div className='filter-content'>
                        {
                            allAllergen.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='filter-labeld'
                                        onClick={() => onSelect(item.key, 'Allergen')}
                                        style={isSelected(item.key, 'Allergen') ? {
                                            borderColor: '#b07c4d',
                                            color: '#b07c4d',
                                            backgroundColor: '#b07c4d26'
                                        } : {}}
                                    >{i18n.t(item.text)}</div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='clear-filter-box second-clear-filter-box'>
                    <span className='title'>
                        {i18n.t('restaurant_order_page_text_other_dish_marks')}
                    </span>
                    <span className='clear-filter'
                        onClick={() => onSelect('Ingredients', 'clear')}>
                        {i18n.t('general_text_clear_all')}
                    </span>
                </div>
                <div className='subtitle'>
                    <span>
                        {i18n.t('restaurant_order_page_text_hide_dishes_with_selected_types')}
                    </span>
                </div>
                <div className='filter'>
                    <div className='filter-content'>
                        {
                            allIngredients.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='filter-labeld'
                                        onClick={() => onSelect(item.key, 'Ingredients')}
                                        style={isSelected(item.key, 'Ingredients') ? {
                                            borderColor: '#b07c4d',
                                            color: '#b07c4d',
                                            backgroundColor: '#b07c4d26'
                                        } : {}}
                                    >{i18n.t(item.text)}</div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </SwipeableDrawer>
    )
}


/**
 * 菜单列表的套餐、包餐，是否可选
 * - 校验 必选区域是否有符合规则的菜品可选 && 菜品状态是正常
 * @param filterKeys (包含过敏源和其他菜品标识)
 * @returns filterStatus 过滤器，soldout 售罄
 */
export function checkSetDishOrMeal(dish: Dish | SetMeal | PackageMeal, filterKeys: string[]) {
    let filterStatus = false;
    let soldout = false;
    const areas = (dish as SetMeal).set_meal_areas ?? (dish as PackageMeal).package_meal_areas;
    // $ 套餐、包餐才有区域字段
    if (areas) {
        areas.forEach((item: SetMealArea | PackageMealArea) => {
            // 必选
            if (item.is_required) {
                // 套餐(包餐里的)
                const selMeals = (item as PackageMealArea)?.set_meals || [];
                if (selMeals) {
                    selMeals.forEach(selMealsItem => {
                        const selMealsItemRes = checkSetDishOrMeal(selMealsItem.setMealsInfo!, filterKeys);
                        selMealsItem!.setMealsInfo!.filter_status = selMealsItemRes.filterStatus;
                        selMealsItem!.setMealsInfo!.status = selMealsItemRes.soldout ? '2' : selMealsItem?.setMealsInfo?.status;
                    });
                }
                // 普通菜品和套餐
                const allDish = [...(item?.dishes || []), ...selMeals];
                if (allDish?.length) {
                    // $ 售罄
                    const dishes = allDish.filter((dishesItem) => {
                        const info = (dishesItem as Dish)?.dishInfo ?? (dishesItem as SetMeal).setMealsInfo;
                        return info?.status === '0'
                    })
                    //  菜品种类等于0 || 最少选n个，不可复选，菜品种类小于最小值n
                    if (dishes?.length === 0 || (!item.is_repeated && (dishes.length < (item.customer_min_num_dishes || 0)))) {
                        soldout = true;
                    }
                    if (dishes?.length > 0 && filterKeys?.length > 0) {
                        // $ 过滤器(包含过敏源和其他菜品标识)
                        const filterDishes = dishes.filter((dishesItem) => {
                            const info = (dishesItem as Dish)?.dishInfo ?? (dishesItem as SetMeal).setMealsInfo;
                            const allergenAndIngredients = (info?.allergen || []).concat(info?.ingredients || [])
                            return _.difference(allergenAndIngredients, filterKeys).length === allergenAndIngredients.length && !info?.filter_status
                        })
                        //  菜品种类等于0 || 最少选n个，不可复选，菜品种类小于最小值n
                        if (filterDishes?.length === 0 || (!item.is_repeated && (filterDishes.length < (item.customer_min_num_dishes || 0)))) {
                            filterStatus = true;
                        }
                    }
                } else {
                    soldout = true;
                }
            }
        })
    }
    // $ 普通菜品
    else {
        const dishesItem = dish as Dish;
        const allergenAndIngredients = (dishesItem.allergen || []).concat(dishesItem.ingredients || []);
        if (_.difference(allergenAndIngredients, filterKeys).length !== allergenAndIngredients.length) {
            filterStatus = true;
        }
        soldout = dishesItem.status === '2';
    }
    return { filterStatus, soldout }
}

/**
 * 获取菜品数量
 * - （历史数量 + 购物车数量）
 * - 购物车数量为零时，减号不能再减
 * @param props.dishItem 目标菜品
 * @param props.saveShopCatDate 购物车
 * @param props.orderInfo 订单详情
 * @returns
 *
 */
export function getDishNum(props: {
    dishItem: any & { firstId: string, _id: string, menuId: string },
    saveShopCatDate: any & { firstId: string, buyNumber: number }[],
    orderInfo: Order
}) {
    const { dishItem, saveShopCatDate, orderInfo } = props;
    let buyNumber = 0;
    saveShopCatDate.forEach(i => {
        if (i.firstId === dishItem.firstId) {
            buyNumber += i.buyNumber ?? 0;
        }
    })
    const bag = orderInfo.bags?.[0] || {};
    let historyNumber = 0;
    const orderDishList = _.concat([], bag.dishs || [], bag.set_meals || [], bag.package_meals || []);
    orderDishList.forEach((i) => {
        if (i.origin_id === dishItem._id && i.menu_id === dishItem.menuId) {
            historyNumber += i.num ?? 0;
        }
    })
    return { number: buyNumber, historyNumber }
}
/**
 * 特殊菜品标识
 */
export function DishTagBox({ dishItem }: { dishItem: Dish }) {
    const tags = {
        '1': {
            className: 'specialTagHot',
            icon: <HotIcon className='specialTagStar' />,
            text: 'Hot'
        },
        '2': {
            className: 'specialTagNew',
            icon: <FaStar className='specialTagStar' />,
            text: 'New'
        },
        '3': {
            className: 'specialTagNew',
            icon: <Loyalty className='specialTagStar' style={{ fontSize: 'inherit' }} />,
            text: 'Sale'
        }
    }
    const { className, icon, text }: {
        className: string;
        icon: JSX.Element;
        text: string;
    } = dishItem.special_type ? tags[dishItem.special_type] || {} : {};
    return <div className='dishTagBox'>
        {dishItem.special_type && <div className={`specialTag ${className}`}>
            {icon}
            <span className='text'>
                {text}
            </span>
        </div>}
        {/* 套餐图标 */}
        {dishItem.dish_type !== '0' ?
            <div className='MdFastfood-icon-box'>
                <MdFastfood className='MdFastfood-icon' />
            </div>
            : null}
    </div>
}


/**
 * 分析购物车
 * - 总价格
 * - 总积分
 */
export function analysisCart(cart?: ShopCar): ShopCar {
    // 积分
    let points = 0;
    let totalPrice = 0;
    // 计算出总价格
    cart?.shopCarList?.forEach((item) => {
        if (item.buyNumber) {
            if (item.is_points_redemption) {
                points += item.buyNumber * (item?.price || 0);
            } else {
                totalPrice += item.buyNumber * (item?.price || 0);
            }
        }
    })

    return {
        shopCarList: cart?.shopCarList || [],
        totalPrice: totalPrice,
        totalPoints: Math.ceil(points)
    }
}