import React from 'react';
import './black-gold-coupon-item.style.scss';
import { Done } from '@material-ui/icons';
import i18n from '../../../i18n';
import { analysisRulesOfReceive, analysisRulesOfUse, formateDate } from '../coupon-item.component';

export default function BlackGoldCouponItem(props: {
    couponItem: any;
    isCheck?: boolean;
    onClick?: () => void;
    onClickMyCoupon?: () => void;
    type?: string;
    className?: string;
}) {
    const { couponItem, onClick, isCheck, className, type, onClickMyCoupon } = props
    const { name, date_rule_effective_date,
        date_rule_expiration_date, discount_value, status, disClickstatus,
        discount_type, date_rule_enable, ignore_get_enable, mealPreparationStatus } = couponItem

    let itemclass = ''
    if (type !== 'restaurant') {
        if (disClickstatus || status === '1' || mealPreparationStatus === true) {
            itemclass = 'disabledItem'
        } else if (isCheck) {
            itemclass = 'checkedItem'
        }
    }
    // 2:16vw; 3:12vw; 4:8vw; 5:6vw 6:5.5vw;
    // 2:23vw; 3:23vw; 4:17vw; 5:14vw 6:11vw;
    // 设置文字大小（文字越多字体越小）
    function setFontSize(value: number, originSize: number) {
        const length = `${value}`.length;
        if (originSize === 16) {
            if (length <= 2) {
                return `${originSize}vw`;
            } else if (length <= 4) {
                return `${originSize - ((length - 2) * 4)}vw`
            } else if (length === 5) {
                return `6vw`;
            } else {
                return `5.5vw`;
            }
        } else if (originSize === 23) {
            if (length <= 3) {
                return `${originSize}vw`
            } else if (length === 4) {
                return `17vw`;
            } else if (length === 5) {
                return `14vw`;
            } else {
                return `11vw`;
            }
        }

    }

    return (
        <div className={`blackGoldCouponItem ${className} ${(isCheck && type !== 'payment') ? 'isCheckCoupon' : ''}`}>
            {(isCheck && type !== 'payment') ?
                <div className='placeholderBox'>
                    <Done className='doneicon' />
                </div>
                : null}
            <div className={`couponItem ${itemclass} `}
                onClick={() => {
                    if (type !== 'restaurant') {
                        onClick?.()
                    }
                }}>
                <div className={`ruleText ${type === 'restaurant' ? 'restaurantCouponItem' : ''}`}>
                    <div className='backgroundValue'
                        style={{ fontSize: setFontSize(discount_value, 23) }}
                    >
                        {discount_value}
                    </div>
                    <div className='circle left'></div>
                    <div className='circle right'></div>
                    <div className='discountValueBox'>
                        <div className='discountValue'
                            style={{ fontSize: setFontSize(discount_value, 16) }}
                        >
                            {discount_value}
                        </div>
                        <div className='unit'>
                            <span>{discount_type === '0' ? `%` : `€`}</span>
                            <span>OFF</span>
                        </div>
                    </div>
                    <div className='nameBox'>
                        <div className='name'><span>{name}</span></div>
                        <div className='date'>
                            {date_rule_enable
                                ? `${formateDate(date_rule_effective_date, 'DD.MM.YYYY')} - ${formateDate(date_rule_expiration_date, 'DD.MM.YYYY')}`
                                : ''}
                        </div>
                    </div>
                </div>
                {(type === 'restaurant') &&
                    <div className='getBox'>
                        <div className='getBoxItem' onClick={() => { onClick?.() }}>
                            {i18n.t('coupon_text_receive')}
                        </div>
                        <div className='line'></div>
                        <div className='getBoxItem' onClick={() => { onClickMyCoupon?.() }}>
                            ...
                        </div>
                    </div>
                }
            </div>
            {type !== 'restaurant' &&
                <div className={`description ${(isCheck && type !== 'payment') ? 'checkedDescription' : ''} `}>
                    {/* 使用规则 */}
                    <span>{analysisRulesOfUse(couponItem)}</span>
                    {/* 免领取的规则 */}
                    {ignore_get_enable && <span className='exemptionRulesText'>
                        <span className='title'>{i18n.t('coupon_Text_rules_exemption_rules')}:</span>
                        {analysisRulesOfReceive(couponItem)}
                    </span>}
                </div>}
        </div>
    )
}