/**
 * @author liweikang
 */
import '../../component/my-order/my-order.style.scss';

import _ from 'lodash';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import CategoryPanelComponent from '../../component/my-order/category-panel.component';
import SwitchStatusComponent from '../../component/my-order/switch-status.component';
import Scroll from '../../component/scroll/scroll.component';
import TopBar from '../../component/top-bar/top-bar.component';
import { CustomerOrder } from '../../models';
import apiManage from '../../request';
import Store from '../../rudex/store';
import { createHashHistory } from 'history';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import Notification from '../../component/snackbar/snackbar';
import i18n from '../../i18n';
import { inject_unount } from '../../component/decorators/helper.decorator';
import { checkToken } from '../../constants/tool-check-token';
import { getQueryString } from '../../constants/tool-get-parms';

@inject_unount
class MyOrderPage extends React.Component<Props, State> {
    private twoPayNowInterval: { [key: string]: NodeJS.Timeout } = {};
    private orderStatus: { text: string, value: string }[] = []
    // *********************
    // 构造函数
    // *********************
    constructor(props: Props) {
        super(props);
        this.state = {
            pendingOrder: [],
            historyOrder: [],
            pag_per_page: 5,
            pag_page: 1,
            currentOrderStatus: undefined
        }
        this.orderStatus = [{
            text: 'order_page_text_all',
            value: 'All'
        }, {
            text: 'order_page_text_completed',
            value: '4'
        }, {
            text: 'order_page_text_closed',
            value: '3'
        }]
    }
    // *********************
    // 生命周期
    // *********************
    public async UNSAFE_componentWillMount() {
        // 检查token是否失效
        const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
        if (token) {
            const isTokenInvalid = checkToken();
            if (isTokenInvalid) {
                Notification.notification(i18n.t('general_text_Not_logged_in_for_a_long_time_need_to_log_in_again'), { autoHideDuration: 5000 });
                createHashHistory().push(`/`)
            }
        } else {
            Notification.notification(i18n.t('general_text_Not_logged_in_for_a_long_time_need_to_log_in_again'), { autoHideDuration: 5000 });
            createHashHistory().push(`/`)
        }
    }
    componentDidMount() {
        const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
        if (token) {
            this.getOrder({
                pag_page: 1
            })
        }
        this.checkVivaOrder()
    }
    async componentWillUnmount() {
        // 清空所有定时器
        this.clearAllInterval()
    }

    // *********************
    // 方法区
    // *********************

    /**
     * 获取订单
     */
    getOrder = async (params?: any, config?: any) => {
        try {
            const result: any = await apiManage.getCustomerOrder({
                pag_per_page: this.state.pag_per_page,
                origin_status: this.state.currentOrderStatus,
                pag_page: this.state.pag_page,
                sort_value: -1,
                ...params
            }, { ...config });
            if (result.error) {
                const code = _.get(result, 'result.code');
                const statusCode = _.get(result, 'result.statusCode');
                if (code === 1003 && statusCode === 403) {
                    Notification.notification(i18n.t('order_page_text_after_login_view'));
                    setTimeout(() => {
                        createHashHistory().push('/');
                    }, 1000);
                }
                throw new Error('');
            }
            const order: Array<any> = result.dbResults
            let historyOrder: any = []
            if (Array.isArray(order) && order.length > 0 && order[0].orders) {
                // 把历史订单提取出来
                historyOrder = _.remove(order, (item: any) => {
                    if (item && item.orders && item.orders.origin_status) {
                        return item.orders.origin_status === '3' || item.orders.origin_status === '4'
                    } else return false;
                })
                // 如何是不是第一页的数据，则把数据添加到数组中，如果是一页，就重新替换新数组
                if (params && params.pag_page > 1) {
                    this.setState({
                        pendingOrder: this.state.pendingOrder.concat(order),
                        historyOrder: this.state.historyOrder.concat(historyOrder)
                    })
                } else {
                    this.setState({
                        pendingOrder: order,
                        historyOrder
                    })
                }
                // 清空所有定时器
                this.clearAllInterval()
                // 检查2PayNow订单
                this.get2PayNowOrder(order)
            }
            return {
                pendingOrder: order || [],
                historyOrder: historyOrder || []
            };
        } catch (error) {
            return {
                pendingOrder: [],
                historyOrder: []
            }
        }

    }
    /**
     * 跳转路由
     */
    jumpRoute() {
        try {
            const merchantWebsite = _.get(Store.getState(), 'merchantWebsite');
            const currentResStringID = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
            if (merchantWebsite) {
                createHashHistory().push(`/restaurantPage?string_id=${currentResStringID}${merchantWebsite
                    ? '&merchant_website=true' : ''}`);
            } else {
                createHashHistory().push('/');
            }
        } catch (error) {
        }
    }
    /**
     * 检查2PayNow订单
     */
    get2PayNowOrder(pendingOrder) {
        try {
            let order = _.cloneDeep(pendingOrder);
            // 过滤出进行中未付款的2PayNow的订单
            order = _.remove(order, (item: any) => {
                if (item && item.orders && item.orders.origin_status) {
                    return item.orders.origin_payment_status === '0' && item.orders.origin_payment_2paynow_cycle_state
                } else return false;
            })
            if (order.length > 0) {
                order.forEach((v) => {
                    this.checkOrder2PayNowStatus(v.orders.origin_belonged_restaurant, v.orders.origin_id)
                })
            }
        } catch (error) {
        }
    }
    /**
     * 检查2PayNow订单付款状态
     */
    checkOrder2PayNowStatus(stringId: string, orderId: string) {
        try {
            let i = 0;
            this.twoPayNowInterval[orderId] = setInterval(async () => {
                if (i > 100) { // 超时执行
                    clearInterval(this.twoPayNowInterval[orderId]);
                    return;
                }
                const result = await apiManage.getOrder2PayNowStatus(stringId, orderId, { notNeedLoading: true })
                if (_.get(result, 'error')) {
                    clearInterval(this.twoPayNowInterval[orderId]);
                    return
                } else {
                    const transStatus = _.get(result, 'trans_status')
                    if (transStatus === 'TRADE_CLOSED' || transStatus === 'TRADE_FAILED' || transStatus === 'TRADE_SUCCESS') {
                        clearInterval(this.twoPayNowInterval[orderId]);
                        return
                    }
                    i++;
                }
            }, 3000)
        } catch (error) {
        }
    }

    /**
     * 清理所有定时器
     */
    clearAllInterval() {
        try {
            if (this.twoPayNowInterval) {
                for (const key in this.twoPayNowInterval) {
                    if (Object.prototype.hasOwnProperty.call(this.twoPayNowInterval, key)) {
                        clearInterval(this.twoPayNowInterval[key]);
                    }
                }
            }
        } catch (error) {
            console.log(error, 'error');
        }
    }
    /**
     * 检查viva付款状态
     */
    checkVivaOrder() {
        // eventid = 10006
        const eventid = getQueryString('eventid');
        if (eventid && eventid !== '0') {
            // web端 同时间有"请使用APP"的提示，因此需要延迟通知
            setTimeout(() => {
                Notification.notification(i18n.t('app_page_text_Your_order_Viva_payment_failed'));
            }, 2000);
        }
    }
    // *********************
    // 视图区
    // *********************

    render() {
        const { pendingOrder, historyOrder, pag_page, currentOrderStatus } = this.state
        const { t } = this.props;
        return (
            <div className='myOrderPage'>
                <TopBar
                    title={t('order_page_text_my_orders')}
                    isGoBack={() => {
                        this.jumpRoute()
                    }}
                >
                </TopBar>
                <div className='myOrderPageContainer'>
                    <SwitchStatusComponent
                        statusData={this.orderStatus}
                        onClick={(val: string) => {
                            this.getOrder({
                                pag_page: 1,
                                origin_status: val === 'All' ? undefined : val
                            })
                            this.setState({
                                pag_page: 1,
                                pendingOrder: [],
                                historyOrder: [],
                                currentOrderStatus: val === 'All' ? undefined : val
                            })
                        }}
                    />
                    {
                        (pendingOrder.length > 0 && currentOrderStatus !== '3' && currentOrderStatus !== '4')
                            || historyOrder.length > 0 ?
                            <div className='wrappper'>
                                <Scroll
                                    data={currentOrderStatus !== '3' && currentOrderStatus !== '4' ?
                                        pendingOrder.concat(historyOrder) : historyOrder}
                                    options={{
                                        pullDownRefresh: true,
                                        pullUpLoad: true
                                    }}
                                    pullDownRefresh={() => {
                                        this.getOrder({
                                            pag_page: 1
                                        }, { notNeedLoading: true })
                                        // 把页码设置回第一页
                                        this.setState({
                                            pag_page: 1
                                        })
                                    }}
                                    pullUpLoad={async () => {
                                        let result: { pendingOrder: any[], historyOrder: [] } = {
                                            pendingOrder: [],
                                            historyOrder: []
                                        }
                                        result = await this.getOrder({
                                            pag_page: pag_page + 1
                                        }, { notNeedLoading: true })
                                        const concatResult: any[] = result.pendingOrder.concat(result.historyOrder)
                                        // 当有数据返回的时候才需要把页码加1
                                        if (concatResult.length > 0) {
                                            this.setState({
                                                pag_page: pag_page + 1
                                            })
                                        }
                                        return concatResult.length > 0 ? true : false
                                    }}
                                >
                                    <div className='categoryWrapper'>
                                        {
                                            currentOrderStatus !== '3' && currentOrderStatus !== '4' ?
                                                <CategoryPanelComponent
                                                    title={'order_page_text_pending'}
                                                    panelData={pendingOrder}
                                                /> : null
                                        }
                                        <CategoryPanelComponent
                                            title={'order_page_text_history'}
                                            panelData={historyOrder}
                                        />
                                    </div>
                                </Scroll>
                            </div> : _.get(Store.getState(), 'fetch') && _.get(Store.getState(), 'fetch').isFetching ?
                                null : <div className='noFoundData'>{t('order_page_text_no_order_found')}</div>
                    }
                </div>
            </div>
        );
    }

}

export default withTranslation()<Props>(MyOrderPage as any);

// *********************
// Types
// *********************

type State = {
    // 进行中的订单
    pendingOrder: Array<CustomerOrder>
    // 历史订单
    historyOrder: Array<CustomerOrder>
    // 每页显示条数
    pag_per_page: number
    // 页码
    pag_page: number
    // 当前需要展示的订单状态
    currentOrderStatus: string | undefined;
}

type Props = {
} & WithTranslation