import _ from 'lodash';
import moment from 'moment';
import { CheckoutCoupon } from '../../models';
import { PM } from '../order/tool-points';
/**
 * 选择优惠券
 * @param couponPackage 我的优惠券
 * @param item 每张优惠券
 * @param type 类型
 * @param usedCouponIds 选中的优惠券数组coupon_id
 * @param originalTotalPrice 订单原总价
 * @returns
 * - `newUsedCouponIds` 选中的优惠券数组coupon_id
 * - `discountedPrice` 优惠后的价格
 * - `deducted` 扣除了多少
 * - `newCouponPackage` 分析后的优惠券数组
 */
export function onSelectCoupons(
    couponPackage: Array<CheckoutCoupon>,
    item: CheckoutCoupon | null,
    usedCouponIds: Array<string>,
    originalTotalPrice: number,
    integralValue: number,
    fulfillmentTime: Date,
    type?: string
): CheckCouponsReport {
    const { coupon_id, status, disClickstatus, mealPreparationStatus } = item || {};
    if (disClickstatus || status === '1' || mealPreparationStatus) {
        return
    }
    if (!item || (status === '0' && type !== 'myCoupon')) {
        let newUsedCouponIds: Array<string> = []
        if (coupon_id) {
            if (_.includes(usedCouponIds, coupon_id)) {
                newUsedCouponIds = _.filter(usedCouponIds, (n) => { return n !== coupon_id })
            } else {
                newUsedCouponIds = _.concat(usedCouponIds, [coupon_id])
            }
        } else {
            newUsedCouponIds = usedCouponIds;
        }
        // 选中的优惠券
        const selectCoupon = _.filter(couponPackage, (n) => { return _.includes(newUsedCouponIds, n.coupon_id) });
        // 未选中，并且可选的优惠券组
        const unselectCoupon = _.filter(
            _.filter(couponPackage, (n) => { return n.status === '0' || n.mealPreparationStatus === true }),
            (n) => { return !_.includes(newUsedCouponIds, n.coupon_id) });
        // 积分
        // 已选择的优惠券，剩余多少的积分总数
        let pointsDestroyed = _.sum(_.map(selectCoupon, (n) => { return n.ignore_get_enable && n.integral_deduction_enable && n.integral_deduction_value }));
        if (!pointsDestroyed) {
            pointsDestroyed = 0;
        }
        // 更新积分
        if (item) {
            PM.updatePoints({ key: 'couponUsedPoints', value: pointsDestroyed });
        }
        const totalRemainingPoints = PM.getAvailablePoints();
        const result = analysisPrice(couponPackage, selectCoupon, unselectCoupon, item, originalTotalPrice, totalRemainingPoints, fulfillmentTime);

        const { discountedPrice, deducted, newCouponPackage } = result;

        return {
            newUsedCouponIds,
            discountedPrice,
            deducted,
            newCouponPackage,
            usedPoints: PM.getUsedPoints()
        }
    }
}
/**
 * 分析价格、分析优惠券互斥状态
 * @param couponPackage 我的优惠券
 * @param selectCoupon 已选的优惠券
 * @param unselectCoupon 未选的优惠券
 * @param selectItem 点击的这张优惠券
 * @param originalTotalPrice 订单价格
 * @param totalRemainingPoints 剩余的积分
 */
export function analysisPrice(
    couponPackage: Array<CheckoutCoupon>,
    selectCoupon: Array<CheckoutCoupon>,
    unselectCoupon: Array<CheckoutCoupon>,
    selectItem: CheckoutCoupon | null,
    originalTotalPrice: number,
    totalRemainingPoints: number,
    fulfillmentTime: Date
) {
    let cloneCouponPackage = _.cloneDeep(couponPackage)
    const cloneSelcetCoupon = _.cloneDeep(selectCoupon)
    let cloneUnSelcetCoupon = _.cloneDeep(unselectCoupon)

    // $ 折扣类型 (0: 百分比 1: 现金) 计算顺序（先减后打折）
    const discountTypeCash = _.filter(cloneSelcetCoupon, (n) => { return n.discount_type === '1' })
    const discountTypePercent = _.filter(cloneSelcetCoupon, (n) => { return n.discount_type === '0' })

    let discountedPrice = originalTotalPrice || 0
    discountTypeCash.map((item) => {
        return discountedPrice = discountedPrice - Number(item.discount_value || 0);
    })
    discountTypePercent.map((item) => {
        return discountedPrice = discountedPrice * (1 - Number(item.discount_value || 0) * 0.01)
    })
    // $如果优惠后价格是负数则0
    if (discountedPrice <= 0) {
        discountedPrice = 0
    }
    discountedPrice = Number(discountedPrice.toFixed(2));
    const deducted = originalTotalPrice - discountedPrice
    // 已选的优惠券所有的种类名，帮助性字段
    const minTypeRuleNumObj: { [key: string]: { max: number, hasLength: number } } = {};
    const hasTypeRuleCouponIds: string[] = [];
    cloneSelcetCoupon.forEach((item) => {
        if (item.coupon_id && item.type_rule_enable && item.type_rule_num !== 0) {
            hasTypeRuleCouponIds.push(item.coupon_id);
            if (_.has(minTypeRuleNumObj, (item.type_rule_name || ''))) {
                if (minTypeRuleNumObj) {
                    const minTypeRuleNumObjItem = _.get(minTypeRuleNumObj, (item.type_rule_name || ''));
                    if (minTypeRuleNumObjItem) {
                        const max = minTypeRuleNumObjItem.max < (item.type_rule_num || 0) ? minTypeRuleNumObjItem.max : (item.type_rule_num || 0)
                        minTypeRuleNumObj[item.type_rule_name || ''] = { max, hasLength: minTypeRuleNumObjItem.hasLength + 1 }
                    }
                }
            } else {
                minTypeRuleNumObj[item.type_rule_name || ''] = { max: (item.type_rule_num || 0), hasLength: 1 }
            }
        }
    })
    if (!selectItem?.disClickstatus) {
        if (cloneSelcetCoupon.length === 0) {
            cloneUnSelcetCoupon.map((j) => {
                // 是否可点击
                let disClickstatus: boolean = false;
                // $ 排斥规则1---消耗积分（免领取的优惠券）
                j.receive_fail_rule = _.remove(j.receive_fail_rule || [], (n) => n !== 'integral_deduction_enable');
                // $ 排斥规则2（种类名相同的优惠卷存在最大数）
                j.use_fail_rule = _.remove(j.use_fail_rule || [], (n) => n !== 'type_rule_enable');
                // $ 排斥规则3 （是否启用满减规则）
                if (j.order_price_rule_enable) {
                    if (originalTotalPrice < (j.order_price_rule_value || 0)) {
                        disClickstatus = true;
                        j.use_fail_rule = _.concat(j.use_fail_rule || [], ['order_price_rule_enable']);
                    } else if (originalTotalPrice >= (j.order_price_rule_value || 0)) {
                        j.use_fail_rule = _.remove(j.use_fail_rule || [], (n) => n !== 'order_price_rule_enable');
                    }
                }
                // $ 排斥规则4（无法同任何优惠券一起使用)
                j.use_fail_rule = _.remove(j.use_fail_rule || [], (n) => n !== 'exclusion_rule_enable');
                // $ 备餐时间
                if (fulfillmentTime) {
                    if (j.meal_preparation_date_rule_enable) {
                        // 现在时间加上备餐时间，在履约时间之后，则不能使用。
                        if (moment(new Date()).add(j.meal_preparation_date_rule_value || 0, 'minute').isAfter(moment(fulfillmentTime), 'minute')) {
                            disClickstatus = true;
                            j.use_fail_rule = _.concat(j.use_fail_rule || [], ['meal_preparation_date_rule_enable']);
                        } else {
                            j.use_fail_rule = _.remove(j.use_fail_rule || [], (n) => n !== 'exclusion_rule_enable');
                        }
                    }
                }
                return j.disClickstatus = disClickstatus;
            })
        } else {
            // $ 选中的优惠券中没有“排斥规则2”，则先把disClickstatus:false
            if (!_.find(cloneSelcetCoupon, (n) => n.type_rule_enable === true)) {
                cloneUnSelcetCoupon = cloneUnSelcetCoupon.map((j) => {
                    j.disClickstatus = false;
                    j.use_fail_rule = _.remove(j.use_fail_rule || [], (n) => n !== 'type_rule_enable');
                    return j
                })
            }
            const now = new Date();
            cloneSelcetCoupon.map((v) => {
                cloneUnSelcetCoupon.map((j) => {
                    // 排斥规则1是否不符合
                    let receive_fail_rule_integral_deduction = false;
                    // 排斥规则2是否不符合
                    let receive_fail_rule_type_rule = false;
                    // $ 排斥规则1---消耗积分（免领取的优惠券）
                    if (
                        v.ignore_get_enable && j.ignore_get_enable
                    ) {
                        if (totalRemainingPoints < ((j.integral_deduction_enable && j.integral_deduction_value) || 0)) {
                            receive_fail_rule_integral_deduction = true;
                            j.disClickstatus = true;
                            j.receive_fail_rule = _.concat(j.receive_fail_rule || [], ['integral_deduction_enable']);
                        } else if (totalRemainingPoints >= ((j.integral_deduction_enable && j.integral_deduction_value) || 0)) {
                            j.receive_fail_rule = _.remove(j.receive_fail_rule || [], (n) => n !== 'integral_deduction_enable');
                        }
                    }
                    // $ 排斥规则2（种类名相同的优惠卷存在最大数）
                    if ((v.type_rule_enable && v.type_rule_num !== 0) || (j.type_rule_enable && j.type_rule_num !== 0)) {
                        if (hasTypeRuleCouponIds.length > 0) {
                            if ((j.type_rule_enable && j.type_rule_num !== 0)) {
                                const minTypeRuleNumObjItem = _.get(minTypeRuleNumObj, (j.type_rule_name || ''));
                                if (minTypeRuleNumObjItem) {
                                    if (minTypeRuleNumObjItem.hasLength === minTypeRuleNumObjItem.max) {
                                        receive_fail_rule_type_rule = true;
                                        j.disClickstatus = true;
                                        j.use_fail_rule = _.concat(j.use_fail_rule || [], ['type_rule_enable']);
                                    } else if ((minTypeRuleNumObjItem.hasLength < minTypeRuleNumObjItem.max) && (j.type_rule_num || 0) <= minTypeRuleNumObjItem.hasLength) {
                                        receive_fail_rule_type_rule = true;
                                        j.disClickstatus = true;
                                        j.use_fail_rule = _.concat(j.use_fail_rule || [], ['type_rule_enable']);
                                    } else if ((j.type_rule_num || 0) <= minTypeRuleNumObjItem.hasLength) {
                                        receive_fail_rule_type_rule = true;
                                        j.disClickstatus = true;
                                        j.use_fail_rule = _.concat(j.use_fail_rule || [], ['type_rule_enable']);
                                    } else {
                                        if (!receive_fail_rule_integral_deduction) {
                                            j.disClickstatus = false;
                                        }
                                        j.use_fail_rule = _.remove(j.use_fail_rule || [], (n) => n !== 'type_rule_enable');
                                    }
                                } else {
                                    if (!receive_fail_rule_integral_deduction) {
                                        j.disClickstatus = false;
                                    }
                                    j.use_fail_rule = _.remove(j.use_fail_rule || [], (n) => n !== 'type_rule_enable');
                                }
                            }
                        }
                    }

                    // $ 排斥规则3 （是否启用满减规则）
                    if (j.order_price_rule_enable) {
                        if (originalTotalPrice < (j.order_price_rule_value || 0)) {
                            j.disClickstatus = true;
                            j.use_fail_rule = _.concat(j.use_fail_rule || [], ['order_price_rule_enable']);
                        } else if (originalTotalPrice >= (j.order_price_rule_value || 0)) {
                            if (!(receive_fail_rule_type_rule || receive_fail_rule_integral_deduction)) {
                                j.disClickstatus = false;
                            }
                            j.use_fail_rule = _.remove(j.use_fail_rule || [], (n) => n !== 'order_price_rule_enable');
                        }
                    }
                    // $ 排斥规则4（无法同任何优惠券一起使用)
                    if (j.exclusion_rule_enable || v.exclusion_rule_enable) {
                        j.disClickstatus = true;
                        j.use_fail_rule = _.concat(j.use_fail_rule || [], ['exclusion_rule_enable']);
                    }
                    // $ 备餐时间
                    if (fulfillmentTime) {
                        if (j.meal_preparation_date_rule_enable) {
                            // 现在时间加上备餐时间，在履约时间之后，则不能使用。
                            if (moment(now).add(j.meal_preparation_date_rule_value || 0, 'minute').isAfter(moment(fulfillmentTime), 'minute')) {
                                j.disClickstatus = true;
                                j.use_fail_rule = _.concat(j.use_fail_rule || [], ['meal_preparation_date_rule_enable']);
                            } else {
                                j.use_fail_rule = _.remove(j.use_fail_rule || [], (n) => n !== 'meal_preparation_date_rule_enable');
                            }
                        }
                    }
                    return j
                })
                return v
            })
        }
    }
    // $ 重新更新couponPackage（保证位置不变）
    cloneCouponPackage = cloneCouponPackage.map((v) => {
        cloneUnSelcetCoupon.map((j) => {
            if (v.coupon_id === j.coupon_id) {
                v = j
            }
            return j
        })
        return v
    })
    return {
        discountedPrice,
        deducted,
        newCouponPackage: [...cloneCouponPackage]
    }
}

// 类型定义
export declare type CheckCouponsReport = {
    newUsedCouponIds: Array<string>,
    discountedPrice: number,
    deducted: number,
    newCouponPackage: Array<any>,
    usedPoints: number,
} | undefined;