/**
 * @CE AKclown
 */
import _ from 'lodash';
import React from 'react';
import { HashRouter, Prompt, Route, Switch } from 'react-router-dom';
import MyAccount from './page/account/my-account.page';
import ForgetPasswordPage from './page/forget-password';
import GroupPage from './page/groupPage/group-page.page';
import MyFidelity from './page/my-fidelity/my-fidelity.page';
import MyOrderDetailPage from './page/my-order/my-order-detail.page';
import MyOrderPage from './page/my-order/my-order.page';
import Ordelivery from './page/ordelivery.page';
import DishDetailsPage from './page/order/dish.details.page';
import Orderpage from './page/order/order.page';
import PaymentMethod from './page/payment-method/paymentMethod.page';
// import PaymentPage from './page/payment/payment.page';
import RestaurantPage from './page/restaurant/restaurant-page';
import SettingsPage from './page/setting/Settings.page';
import SignPage from './page/sign-in/sign-in.page';
import Upgrading from './page/upgrading.page';
import Store from './rudex/store';
import MyNoticesPage from './page/my-notices/my-notices.page';
import MyReservations from './page/my-reservations/my-reservations.page';
import MyReservationsDetail from './page/my-reservations/my-reservations-detail.page';
import { PrivacyApgreement } from './component/privacy-apgreement/privacy-apgreement.componet';
import ReservationsPage from './page/reservations/reservations.page';
import { Authentication } from './page/authentication/authentication';

const BasicRoute = () => {
    const merchantWebsite = _.get(Store.getState(), 'merchantWebsite')
    return (
        <HashRouter>
            <Switch>
                <Route exact path='/' component={Ordelivery} />
                {/* <Route exact path='/signInPage' component={SignInPage} />
                <Route exact path='/sign-up' component={SignUpPage} /> */}
                <Route exact path='/forgetPassword' component={ForgetPasswordPage} />
                <Route exact path='/orderpage' component={Orderpage} />
                {/* <Route exact path='/paymentpage' component={PaymentPage} /> */}
                <Route exact path='/scanCodePage' component={Ordelivery} />
                <Route exact path='/my-order-page' component={MyOrderPage} />
                <Route exact path='/my-order-detail-page/:string_id/:id/:status' component={MyOrderDetailPage} />
                <Route exact path='/restaurantPage' component={RestaurantPage} />
                <Route exact path='/my-account' component={MyAccount} />
                <Route exact path='/dishDetailsPage' component={DishDetailsPage} />
                <Route exact path='/settingsPage' component={SettingsPage} />
                <Route exact path='/upgrading' component={Upgrading} />
                <Route exact path='/my-fidelity-page' component={MyFidelity} />
                <Route exact path='/my-reservations-page' component={MyReservations} />
                <Route exact path='/my-reservations-detail-page/:booking_order_id'
                    component={MyReservationsDetail} />
                <Route exact path='/groupPage' component={GroupPage} />
                <Route exact path='/paymentMethodPage' component={PaymentMethod} />
                <Route exact path='/sign-in' component={SignPage}></Route>
                <Route exact path='/my-notices-page' component={MyNoticesPage}></Route>
                <Route exact path='/ordelivery-privacy-policy' component={PrivacyApgreement} />
                <Route exact path='/reservations' component={ReservationsPage} />
                <Route exact path='/authentication' component={Authentication} />
            </Switch>
            {/*
             // ! 处理商家网站 merchantWebsite为true不需要替换父级地址 *
            */}
            {!merchantWebsite && <Prompt message={(location) => {
                if (window !== window.top) {
                    // ! 这里是为了boxed模式下使用的，因为使用iframe来实现，所以为了保持浏览器的路劲与ifram的路径一致而使用了这种办法。
                    if (window!.top!.history && typeof window!.top!.history.replaceState === 'function') {
                        window!.top!.history.replaceState(null, '', '#' + location.pathname + location.search)
                    } else {
                        window!.top!.location.hash = location.pathname + location.search
                    }
                }
                return true;
            }} when={true}></Prompt>}
        </HashRouter>
    );
};

export default BasicRoute;