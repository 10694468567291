

import React from 'react';
import './privacy-apgreement-dialog.style.scss';
import { Dialog, DialogContent } from '@material-ui/core';
import i18n from '../../i18n';
import PrivacyApgreementText from './privacy-apgreement-text';
import ClearIcon from '@material-ui/icons/Clear';
import loginLogo from '../../static/images/ordelivery_login.png';

export function PrivacyApgreementDialog(props: {
    // onConfirm: () => void;
    handleClose: () => void;
}) {
    return (
        <Dialog
            open={true}
            onClose={() => {
                props.handleClose()
            }}
            className='PrivacyApgreementDialog'
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <div className='close' onClick={() => {
                props.handleClose()
            }}>
                <ClearIcon className='clearIcon' />
            </div>
            <DialogContent>
                <div>
                    <div className='PrivacyApgreementText'>
                        <div className='PrivacyApgreementLogoBox'>
                            <img
                                className='PrivacyApgreementLogo'
                                src={loginLogo} alt=''
                            />
                        </div>
                        <div className='subTitle'>
                            <span>  ————————  </span>
                            <span>{i18n.t('privacy_agreement_text')}</span>
                            <span>  ————————  </span>
                        </div>
                        <PrivacyApgreementText />
                    </div>
                </div>
            </DialogContent>
        </Dialog>

    )
}