
import React, { useRef } from 'react';
import './featured-dish-item.style.scss';
import { BsPlus } from 'react-icons/bs';
import { Dish } from '../../../models';

export function FeaturedDishItem(
    props: {
        className?: string;
        onClick: () => void;
        dishItem: Dish;
    }
) {
    const { className, onClick, dishItem } = props
    // const [showAnimation, setShowAnimation] = React.useState(false)
    const dom: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef()

    // function timer() {
    //     dom.current = setTimeout(() => {
    //         setShowAnimation(false)
    //     }, 300);
    // }
    return (
        <div className={`featuredDishItem ${className || ''}`} >
            <div className='dishImgbox' >
                <img className='dishImg' src={dishItem.realpics?.[0] || ''} alt='' />
            </div>
            <div className='dishNameBox'>
                <div className='title'>{dishItem.name}</div>
                <div className='plusButton'
                    onClick={() => {
                        onClick()
                        // 开启加一动画
                        // setShowAnimation(true)
                        if (dom.current) {
                            clearInterval(dom.current)
                        }
                        // timer()
                    }}>
                    <BsPlus />
                </div>
                {/* <div className={`add ${showAnimation ? 'addAnimation' : ''}`}>+1</div> */}
            </div>
        </div>
    )
}
