import './order-package-meal.style.scss'

import React, { Component } from 'react'
import TopBar from '../../top-bar/top-bar.component';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { Button } from '@material-ui/core';
import i18n from '../../../i18n';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import { ShoppingBasket } from './shopping-basket.component';
import { PackageMealItem } from './package-meal-item.component';
import { Dish } from '../../../models';
import apiManage from '../../../request';
import _ from 'lodash';
import { PropType } from '../../../constants/base.constant';
import DishDetail from '../../../page/order/dish.details.page';
import { BasketDish, BasketPackageMealArea, BasketSetMeal, BasketSetMealArea, CartActions, CollectiveItem, CollectiveItemArea, ICollectiveDishPanel, ICollectiveDishPanelBasket, ICollectiveDishPanelProps, ICollectiveDishPanelState, ICollectiveDishPanelType } from '../../../interfaces/collective-dish-panel.interface';
import { LocalStorageManager } from '../../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../../constants/local-storage.constant';
import { v4 as uuidv4 } from 'uuid';
import Notification from '../../snackbar/snackbar';
import { isCollectiveItemIsBasketPackageMeal, isCollectiveItemIsBasketSetMeal } from '../../../constants/type-checker.constant';
import axios, { Canceler, CancelToken } from 'axios';
import BScroll from 'better-scroll';
import { OrderPackageDialog } from './order-package-dialog.component';
import SetDishDetailItem from '../packageMeal/set-dish-detail-item.conponent';
import { DishFixed } from '../../../models/fixed/dish.entity';
import { analysisCart, checkSetDishOrMeal } from '../order.component';
import { PM } from '../tool-points';
class OrderPackageMeal extends Component<Props, State> implements ICollectiveDishPanel {
    // 取消请求
    public cancel: Canceler | undefined;
    public cancelToken: CancelToken;
    // 鼠标拖拽事件 滚动插件
    public scroll: any;
    public SlideScroll: any;

    public theSetDishId: string = '';

    constructor(props: Props) {
        super(props);
        // axios拦截异步请求的方法
        this.cancelToken = new axios.CancelToken(() => {
            this.cancel = undefined;
        });
    }

    public state: State = {
        // 当前集合性商品数据
        collectiveData: {},
        // 包餐里面的套餐未修改的数据
        setUnmodifiedData: {},
        // 被选中的菜单
        chosenMenu: 0,
        // 是否打开购物篮
        isShowBasket: false,
        // 区域
        mealAreas: [],
        // 区域内的菜品
        areasDish: [],
        // 套餐内的套餐菜品
        areasSetDish: [],
        // 购物篮数据
        basketAreas: [],
        // 包餐里面的套餐购物篮数据
        basketInsideSet: [],
        // 菜品可否多选
        isRepeatedly: false,
        // 菜品ID
        setDishId: this.props.dishId,
        // 菜品类型：1 包餐 2 套餐
        setDishType: this.props.dishType,
        // 是否要修改已点套餐
        isHandleSetMeal: false,
        // 包餐最大菜品数量
        packageMaxNum: 0, // 0为没有设置最大菜品数量
        // 用户选择的菜品数量
        selectDishesNum: 0,
        // 菜品已达最大数量或区域最大数量
        isDisableAdd: false,
        // 区域的_id
        areasId: '',
        // 购物篮点击菜品的所属区域id
        basketAreasId: '',
        // 集合性商品对应的购物篮id
        collectiveCurrentBasketId: '',
        // 包餐初始价格
        packagePrice: 0,
        // 是否显示菜品详情
        isShowDishDetails: false,
        // 被点击的菜品详情
        dishesDetail: {},
        // 当前购物篮id
        currentBasketId: '',
        // 页面购物篮层级
        currentBasketLevel: 0,
        // 当前购物篮的类型
        currentBasketType: ICollectiveDishPanelType.SET_MEAL,
        // 集合性商品名字
        collectiveName: '',
        // 总购物篮
        basket: [],
        // 当前区域
        collectiveItemArea: {
            dishes: [],
            // $ 视图帮助性字段
            // 区域内菜品总数量
            totalDishes: 0,
            // 区域内菜品总价格
            totalDishesPrice: 0,
            // 是否此区域条件已符合
            isAchievedCriteria: false
        },
        // 该套餐的上级区域对象
        higherLeverCollectiveItemArea: {
            dishes: [],
            // $ 视图帮助性字段
            // 区域内菜品总数量
            totalDishes: 0,
            // 区域内菜品总价格
            totalDishesPrice: 0,
            // 是否此区域条件已符合
            isAchievedCriteria: false
        },
        // 菜品所属的购物篮id
        belongedBasketId: '',
        // 父级区域视图
        higherleverCollectArea: [],
        // 是否返回上级弹窗
        openDiolog: false,
        // 要修改的集合性菜品数据
        handleCollectiveDish: undefined,
        // 打开包餐内的套餐
        isShowPackageMeal: false
    }

    // *********************
    // Life Cycle Function
    // *********************
    async componentDidMount() {
        // 获取包餐或套餐信息
        await this.getSetMealData();
        // 初始化滚动插件
        this.initBetterScroll();
    }

    componentWillUnmount() {
        if (this.scroll) {
            this.scroll.destroy()
        }
        if (this.SlideScroll) {
            this.SlideScroll.destroy()
        }
    }


    // *********************
    // Default Function
    // *********************


    /**
     * 找到区域组
     * - 为了区分包餐区域，套餐区域字段
     */
    findAreas(collectiveItem: CollectiveItem): CollectiveItemArea[] | undefined {
        // 获取 包餐 区域数据
        if (collectiveItem?.dish_type === '1') {
            return _.get(collectiveItem, 'package_meal_areas', []);
        }
        // 获取 套餐 区域数据
        else if (collectiveItem?.dish_type === '2') {
            return _.get(collectiveItem, 'set_meal_areas', []);
        }
    }

    /**
     * 批量执行购物车修改事件
     * @param areaCloned 克隆的CollectiveItemArea对象
     * @param actions 购物车事件数组
     */
    excuteCartActions(areaCloned: CollectiveItemArea, actions: Array<CartActions>) {

        try {
            actions.forEach(item => {
                // 执行加号操作
                if (item.type === 'ADD') {
                    if (item.isICollectiveDish) {
                        // 集合性商品
                        let areaSetMealItemNum = 0
                        _.get(areaCloned, 'set_meals', []).forEach(setItem => {
                            if (setItem.id === item.id) areaSetMealItemNum += setItem.num
                        })
                        if (areaSetMealItemNum >= 99) {
                            Notification.notification(`${i18n.t('checkout_page_text_the_number_of_dishes_cannot_be_greater_than', { number: 99 })}`);
                        } else {
                            const areaSetMeals = _.get(areaCloned, 'set_meals', []).map(iitem => {
                                if (iitem.differentSetDishId === item.differentSetDishId) {
                                    iitem.num++
                                    // 区域总数量+1，总价格加该菜品价格
                                    areaCloned.totalDishes++
                                    areaCloned.totalDishesPrice += iitem.CollectiveDishTotalPrice ?? 0
                                }
                                return iitem
                            })
                            _.set(areaCloned, 'set_meals', areaSetMeals)
                        }
                        this.theSetDishId = item.id
                    } else {
                        if (item.num >= 99) {
                            Notification.notification(`${i18n.t('checkout_page_text_the_number_of_dishes_cannot_be_greater_than', { number: 99 })}`);
                        } else {
                            areaCloned.dishes = areaCloned.dishes.map((iitem) => {
                                if (iitem.id === item.id && iitem.num < 99) {
                                    iitem.num++
                                    // 区域总数量+1，总价格加该菜品价格
                                    areaCloned.totalDishes++
                                    areaCloned.totalDishesPrice += iitem.price ?? 0
                                }
                                return iitem
                            })
                        }
                    }

                } else if (item.type === 'REDUCE') {
                    // 执行减号操作
                    if (item.isICollectiveDish) {
                        // 集合性商品
                        const areaSetMeals = _.get(areaCloned, 'set_meals', []).map(iitem => {
                            if (iitem.differentSetDishId === item.differentSetDishId && item.num > 0) {
                                if (item.clearAll) {
                                    // 当时修改套餐返回操作时清空当前集合性商品
                                    areaCloned.totalDishes -= iitem.num;
                                    areaCloned.totalDishesPrice -= (iitem.CollectiveDishTotalPrice * iitem.num) ?? 0;
                                    iitem.num = 0
                                } else {
                                    iitem.num--
                                    // 区域总数量-1，总价格减该集合性商品总价格
                                    areaCloned.totalDishes--
                                    areaCloned.totalDishesPrice -= iitem.CollectiveDishTotalPrice ?? 0
                                    // 集合性商品当菜品数量为0时 清空区域数据
                                    if (iitem.num === 0) {
                                        _.set(iitem, 'set_meal_areas', [])
                                    }
                                }
                            }
                            return iitem
                        })
                        _.set(areaCloned, 'set_meals', areaSetMeals)
                        this.theSetDishId = item.id
                    } else {
                        areaCloned.dishes = areaCloned.dishes.map((iitem) => {
                            if (iitem.id === item.id && item.num > 0) {
                                iitem.num--
                                // 区域总数量-1，总价格减该菜品价格
                                areaCloned.totalDishes--
                                areaCloned.totalDishesPrice -= iitem.price ?? 0
                            }
                            return iitem
                        })
                    }
                }
            })
        } catch (error) {

        }
        return areaCloned
    }

    /**
     * 更新集合性商品区域视图
     * @param areaCloned 克隆的CollectiveItemArea对象
     */
    rebuildCollectiveArea(areaCloned: CollectiveItemArea) {
        const { mealAreas, packageMaxNum } = this.state
        try {
            // 拿到当前对应购物篮的区域数据 当不符合套餐或包餐的可选条件时 返回这个数据
            const newBasketAreasItem: CollectiveItemArea = this.getBasketAreasItem(areaCloned)
            // 达到区域最大时为true
            let isLargestArea: boolean = false

            // 是否已达当前套餐或包餐的最大可选数量
            if (packageMaxNum) {
                let setMaxNum: number = 0
                mealAreas.forEach(item => {
                    if (item._id === areaCloned._id) {
                        setMaxNum += areaCloned.totalDishes
                    } else {
                        setMaxNum += item.totalDishes
                    }
                })
                if (setMaxNum > packageMaxNum) return newBasketAreasItem
            }
            // 该区域是否可多选
            let setMeals
            // 该区域有最大数量限制
            if (areaCloned.customer_max_num_dishes) {
                // 用户选择的数量
                let areaTotal: number = 0
                areaCloned.dishes.forEach(item => {
                    areaTotal += item.num
                })
                _.get(areaCloned, 'set_meals', []).forEach(setMealsItem => {
                    areaTotal += setMealsItem.num
                })
                if (areaTotal === 0) {
                    areaCloned.dishes = areaCloned.dishes.map(item => {
                        item.addButtonStatus = true
                        item.reduceButtonStatus = false
                        return item
                    })
                    setMeals = _.get(areaCloned, 'set_meals', []).map(item => {
                        item.addButtonStatus = true
                        item.reduceButtonStatus = false
                        return item
                    })
                }
                // 小于区域最大数量时恢复加号按钮
                if (areaTotal < areaCloned.customer_max_num_dishes && areaTotal !== 0) {
                    areaCloned.dishes = areaCloned.dishes.map(item => {
                        item.addButtonStatus = true
                        item.reduceButtonStatus = true
                        return item
                    })
                    setMeals = _.get(areaCloned, 'set_meals', []).map(item => {
                        item.addButtonStatus = true
                        item.reduceButtonStatus = true
                        return item
                    })
                    isLargestArea = false
                }
                // 达到区域最大数量时屏蔽加号按钮
                if (areaTotal === areaCloned.customer_max_num_dishes) {
                    areaCloned.dishes = areaCloned.dishes.map(item => {
                        item.addButtonStatus = false
                        item.reduceButtonStatus = true
                        return item
                    })
                    setMeals = _.get(areaCloned, 'set_meals', []).map(item => {
                        item.addButtonStatus = false
                        item.reduceButtonStatus = true
                        return item
                    })
                    isLargestArea = true
                }
                _.set(areaCloned, 'set_meals', setMeals)
                if (areaTotal > areaCloned.customer_max_num_dishes) return newBasketAreasItem
            }

            // 是否可复选
            if (!_.get(areaCloned, 'is_repeated', false)) {
                const dishItem = areaCloned.dishes.findIndex(item => item.num > 1)
                const setDishItem = _.get(areaCloned, 'set_meals', []).findIndex(item => item.num > 1)
                if (dishItem !== -1 || setDishItem !== -1) return newBasketAreasItem
                // 菜品数量为1的加号状态不可用 如果已达区域最大数量 就不要更新加减号状态
                if (!isLargestArea) {
                    areaCloned.dishes = areaCloned.dishes.map(item => {
                        if (item.num === 1) {
                            item.addButtonStatus = false
                            item.reduceButtonStatus = true
                        } else if (item.num === 0) {
                            item.addButtonStatus = true
                        }
                        return item
                    })
                    setMeals = _.get(areaCloned, 'set_meals', []).map(item => {
                        if (item.num === 1) {
                            item.addButtonStatus = false
                            item.reduceButtonStatus = true
                        } else if (item.num === 0) {
                            item.addButtonStatus = true
                        }
                        return item
                    })
                    _.set(areaCloned, 'set_meals', setMeals)
                }
            }

            // 该区域是否必选
            if (_.get(areaCloned, 'is_required', false)) {
                if (!areaCloned.totalDishes || areaCloned.totalDishes < _.get(areaCloned, 'customer_min_num_dishes', 0)) {
                    areaCloned.isAchievedCriteria = false
                } else if (areaCloned.totalDishes && areaCloned.totalDishes >= _.get(areaCloned, 'customer_min_num_dishes', 0)) {
                    areaCloned.isAchievedCriteria = true
                }
            } else {
                if (areaCloned.totalDishes && areaCloned.totalDishes < _.get(areaCloned, 'customer_min_num_dishes', 0)) {
                    areaCloned.isAchievedCriteria = false
                } else if (areaCloned.totalDishes && areaCloned.totalDishes >= _.get(areaCloned, 'customer_min_num_dishes', 0)) {
                    areaCloned.isAchievedCriteria = true
                }
                else if (!areaCloned.totalDishes) {
                    areaCloned.isAchievedCriteria = true
                }
            }
            // 所有为0的减号按钮都为false,大于0的为true
            areaCloned.dishes = areaCloned.dishes.map(item => {
                if (item.num === 0) {
                    item.reduceButtonStatus = false
                } else if (item.num > 0 && item.num < 99) {
                    item.reduceButtonStatus = true
                    if (!packageMaxNum && _.get(areaCloned, 'is_repeated', false) && !areaCloned.customer_max_num_dishes) item.addButtonStatus = true
                } else if (item.num >= 99) {
                    item.addButtonStatus = false
                    item.reduceButtonStatus = true
                }
                return item
            })
            let buyNumber = 0
            _.get(areaCloned, 'set_meals', []).forEach(setItem => {
                if (setItem.id === this.theSetDishId) {
                    buyNumber += setItem.num
                }
            })

            const newSetMeals = _.get(areaCloned, 'set_meals', []).map(newSetMealsItem => {
                if (newSetMealsItem.num === 0) {
                    newSetMealsItem.reduceButtonStatus = false
                } else if (newSetMealsItem.num > 0) {
                    newSetMealsItem.reduceButtonStatus = true
                }
                if (newSetMealsItem.id === this.theSetDishId && buyNumber >= 99) {
                    // 当同一种集合性商品总数量大于99时 按钮灰掉
                    newSetMealsItem.addButtonStatus = false
                    newSetMealsItem.reduceButtonStatus = true
                } else if (buyNumber < 99 && !packageMaxNum && _.get(areaCloned, 'is_repeated', false) && !areaCloned.customer_max_num_dishes) {
                    newSetMealsItem.addButtonStatus = true
                }
                return newSetMealsItem
            })
            _.set(areaCloned, 'set_meals', newSetMeals)
        } catch (error) {

        }

        return areaCloned
    }

    /**
     * 显示新增集合性商品视图
     * @param areaCloned 克隆的父级区域对象
     * @param itemCloned 克隆的新增的集合性商品对象
     */
    async showNewCollectiveItemView(
        areaCloned: CollectiveItemArea,
        itemCloned: CollectiveItem
    ) {
        const { currentBasketLevel, basket } = this.state

        try {
            const isMealAreas: Array<CollectiveItemArea> = [];
            const basketAreas: Array<CollectiveItemArea> = [];
            let defaultDishes = [];
            let defaultSetDishes = [];
            let isRepeatedly: boolean = false;
            let isAreasId: string = '';
            let shoppingBaskt: Array<ICollectiveDishPanelBasket> = [];
            let collectiveItemArea;
            // 从属的购物篮的唯一标识码;
            const uuId = uuidv4();
            let cartCollectAreas: CollectiveItemArea[] | undefined;
            // 已选菜品总数
            let selectNum = 0;
            if (this.props.isEdit && this.props.details) {
                cartCollectAreas = this.findAreas(this.props.details as CollectiveItem);
            }
            // 包餐
            if (isCollectiveItemIsBasketPackageMeal(itemCloned)) {
                itemCloned.package_meal_areas = (itemCloned.package_meal_areas || []).map((item) => {
                    const targetAreas = cartCollectAreas?.find((cartCollectAreasItem) => cartCollectAreasItem._id === item._id);
                    const otherSetMeals: (BasketDish & BasketSetMeal)[] = [];
                    item.dishes = item.dishes?.map((iitem) => {
                        // 这里后台返回的num可能为null或undefined
                        iitem.num = 0;
                        // 菜品唯一标识符
                        iitem.id = uuidv4();
                        // 添加从属的购物篮的唯一标识码
                        iitem.belongedBasketId = uuId;
                        // 是否是集合性商品
                        iitem.isICollectiveDish = false;
                        // 数量减少可用状态
                        iitem.reduceButtonStatus = false;
                        // 数量增加可用状态
                        iitem.addButtonStatus = true;
                        // 在购物车编辑
                        if (targetAreas) {
                            const targetDish = targetAreas.dishes.find((dishItem) => dishItem._id === iitem._id);
                            if (targetDish) {
                                iitem.num = targetDish.num;
                                iitem.realpics = targetDish.realpics ?? [];
                                // 数量减少可用状态
                                iitem.reduceButtonStatus = targetDish.num > 0;
                                // 数量增加可用状态
                                iitem.addButtonStatus = itemCloned.customer_max_num_dishes === targetDish.num;
                            }
                        }
                        return iitem
                    })
                    // 包餐里面包含套餐
                    if (item.set_meals) {
                        // 购物篮里同种套餐不同内容的套餐

                        item.set_meals = item.set_meals?.map((iitem) => {
                            // 这里后台返回的num可能为null或undefined
                            iitem.num = 0;
                            // 菜品唯一标识符
                            iitem.id = uuidv4();
                            // 添加包餐里面的套餐从属的购物篮的唯一标识码
                            iitem.belongedBasketId = uuId;
                            // 集合性商品
                            iitem.isICollectiveDish = true;
                            // 数量减少可用状态
                            iitem.reduceButtonStatus = false;
                            // 数量增加可用状态
                            iitem.addButtonStatus = true;

                            // 检查套餐内的区域，过敏源过滤和售罄后的规则
                            const res = checkSetDishOrMeal(iitem, [...this.props.selectFilterLabeldAllergen, ...this.props.selectFilterLabeldIngredients])
                            iitem.filter_status = res.filterStatus;
                            iitem.status = res.soldout ? '2' : iitem.status;

                            // 在购物车编辑套餐
                            if (targetAreas) {
                                const targetDish = (targetAreas as BasketPackageMealArea).set_meals.filter((dishItem) => dishItem._id === iitem._id);


                                if (targetDish && targetDish.length > 0) {
                                    const targetDishNum = _.cloneDeep(targetDish).reduce((pre, cur) => (cur?.num || 0) + pre, 0);
                                    iitem.realpics = targetDish?.[0]?.realpics ?? [];
                                    iitem.num = targetDishNum;
                                    // 数量减少可用状态
                                    iitem.reduceButtonStatus = targetDishNum > 0;
                                    // 数量增加可用状态
                                    iitem.addButtonStatus = itemCloned.customer_max_num_dishes !== targetDishNum;
                                    targetDish.forEach((targetDishItem) => {
                                        const cloneItem = _.cloneDeep(iitem)
                                        cloneItem.num = targetDishItem.num;
                                        // 数量减少可用状态
                                        cloneItem.reduceButtonStatus = targetDishItem.num > 0;
                                        // 已选区域
                                        cloneItem.set_meal_areas = targetDishItem.set_meal_areas || [];
                                        // 套餐唯一值
                                        cloneItem.differentSetDishId = uuidv4();
                                        // 套餐总价
                                        cloneItem.CollectiveDishTotalPrice = targetDishItem.CollectiveDishTotalPrice
                                        otherSetMeals.push(cloneItem);
                                    })
                                } else {
                                    otherSetMeals.push(iitem);
                                }
                            } else {
                                otherSetMeals.push(iitem);
                            }
                            return iitem
                        })
                    }
                    // 添加总数量字段
                    item.totalDishes = targetAreas ? targetAreas.totalDishes : 0;
                    // 添加区域总价格字段(注：这里默认为区域价格)
                    item.totalDishesPrice = targetAreas ? targetAreas.totalDishesPrice : (item.additional_fees ?? 0);
                    if (item.is_required) {
                        // 是否此区域条件已符合
                        item.isAchievedCriteria = item.totalDishes > 0;
                    } else {
                        item.isAchievedCriteria = true;
                    }
                    isMealAreas.push(item);

                    selectNum += item.totalDishes;
                    // 处理购物篮里的套餐
                    const cloneItem = _.cloneDeep(item);
                    cloneItem.set_meals = otherSetMeals;
                    basketAreas.push(cloneItem);
                    return item
                })

                collectiveItemArea = _.get(itemCloned, 'package_meal_areas[0]', {})
                defaultDishes = _.get(collectiveItemArea, 'dishes', []) // 默认包餐区域菜品
                defaultSetDishes = _.get(collectiveItemArea, 'set_meals', []) // 默认包餐区域套餐菜品
                isRepeatedly = _.get(collectiveItemArea, 'is_repeated', false) // 默认菜品是否可选多次
                isAreasId = _.get(collectiveItemArea, '_id', '') // 默认包餐区域Id
            }
            // 套餐
            else if (isCollectiveItemIsBasketSetMeal(itemCloned)) {
                itemCloned.set_meal_areas = (itemCloned.set_meal_areas || []).map((item) => {
                    item.dishes = item.dishes.map((iitem) => {
                        iitem.num = 0  // 这里后台返回的num可能为null或undefined
                        iitem.id = uuidv4()  // 菜品唯一标识符
                        iitem.belongedBasketId = uuId   // 添加从属的购物篮的唯一标识码
                        iitem.isICollectiveDish = false   // 是否是集合性商品
                        // 视图帮助性字段
                        iitem.reduceButtonStatus = false   // 数量减少可用状态
                        iitem.addButtonStatus = true    // 数量增加可用状态
                        return iitem
                    })
                    item.totalDishes = 0   // 添加总数量字段
                    item.totalDishesPrice = item.additional_fees ?? 0 // 添加区域总价格字段(注：这里默认为区域价格)
                    if (item.is_required) {
                        item.isAchievedCriteria = false  // 是否此区域条件已符合
                    } else {
                        item.isAchievedCriteria = true
                    }

                    isMealAreas.push(item);
                    basketAreas.push(item);
                    return item
                })
                collectiveItemArea = _.get(itemCloned, 'set_meal_areas[0]', {})
                defaultDishes = _.get(collectiveItemArea, 'dishes', []) // 默认套餐区域菜品
                isRepeatedly = _.get(collectiveItemArea, 'is_repeated', false) // 默认菜品是否可选多次
                isAreasId = _.get(collectiveItemArea, '_id', '') // 默认套餐区域Id
            }
            // 更新总购物篮数据
            let backetLevel: number = currentBasketLevel

            if (basket.length !== 0) {
                shoppingBaskt = _.cloneDeep(basket);
                backetLevel += 1;
            }
            if (isCollectiveItemIsBasketPackageMeal(itemCloned)) {
                itemCloned.package_meal_areas = basketAreas as BasketPackageMealArea[];
            }
            shoppingBaskt.push({
                id: uuId,
                level: backetLevel,
                type: itemCloned.dish_type === '1' ? ICollectiveDishPanelType.PACKAGE_MEAL : ICollectiveDishPanelType.SET_MEAL,
                item: itemCloned
            })

            const packageMaxNum = itemCloned.customer_max_num_dishes ?? 0;
            this.setState({
                collectiveName: itemCloned.name ?? '',
                selectDishesNum: 0,
                higherLeverCollectiveItemArea: areaCloned,
                collectiveItemArea,
                collectiveData: itemCloned,
                packagePrice: itemCloned.price ?? 0,
                currentBasketType: itemCloned.dish_type === '1' ? ICollectiveDishPanelType.PACKAGE_MEAL : ICollectiveDishPanelType.SET_MEAL,
                currentBasketId: uuId,
                currentBasketLevel: backetLevel,
                mealAreas: isMealAreas,
                areasDish: defaultDishes ?? [],
                areasSetDish: defaultSetDishes ?? [],
                isRepeatedly,
                areasId: isAreasId,
                packageMaxNum,
                basket: shoppingBaskt,
                basketAreas,
                isDisableAdd: packageMaxNum !== 0 ? selectNum === packageMaxNum : false
            }, async () => {
                if (this.props.isEdit) {
                    if (basketAreas && basketAreas?.[0]) {
                        await this.updateShoppingBasketOperation([], basketAreas[0]);
                        // 购物篮 菜品图片补充
                        await this.getCartDishPic();
                    }
                }
                // 获取菜品图片
                if (this.state.collectiveItemArea) {
                    await this.areasClickEvent(collectiveItemArea, 0)
                }
            })

        } catch (error) {
        }
    }

    /**
     * 新增集合性商品
     * @param areaCloned 克隆的父级区域对象
     * @param itemCloned 克隆的新增的集合性商品对象
     */
    // addNewCollectiveItem(
    //     areaCloned: CollectiveItemArea,
    //     itemCloned: CollectiveItem
    // ) {
    //     const { basket, currentBasketId, setDishId, belongedBasketId, higherleverCollectArea, isHandleSetMeal } = this.state
    //     try {
    //         // 保存购物篮数据
    //         let newBasket = _.cloneDeep(basket);
    //         let itemClonedAreas = _.cloneDeep(_.get(itemCloned, 'set_meal_areas', []));
    //         // 区分菜品区域是否相同
    //         let isDifferent: boolean = false;
    //         // 记录相同且已加一 (帮助性常量)
    //         let isAddOne: boolean = false;
    //         // 当前区域的单个菜品数据
    //         let setDishAreaItem;
    //         // 当菜品区域相同时 更新一个新的购物篮 过滤掉一个旧的购物篮
    //         let isFilterBasketId: string = '';
    //         // 过滤当前集合性商品为0 的菜品
    //         itemClonedAreas = itemClonedAreas.filter(item => {
    //             if (_.isArray(item.dishes)) {
    //                 item.dishes = item.dishes.filter(iitem => iitem.num !== 0)
    //             }
    //             return item.totalDishes !== 0
    //         });
    //         // 计算新增集合性商品的附加价格
    //         let collectiveTotalPrice: number = 0;
    //         // 当前集合性商品里面的菜品总价格
    //         if (isCollectiveItemIsBasketSetMeal(itemCloned)) {
    //             (itemCloned.set_meal_areas || []).forEach(setItem => {
    //                 if (setItem.totalDishes) {
    //                     collectiveTotalPrice += setItem.totalDishesPrice
    //                 }
    //             })
    //         };
    //         const recordedPrice: number = collectiveTotalPrice;
    //         // 新增集合性商品的原附加价格
    //         let oldCollectiveTotalPrice: number = 0;
    //         // 区域要加上的新价格
    //         let newSetDishPrice: number = 0
    //         newBasket.map(iitem => {
    //             // 保存当前层级所属购物篮数据
    //             if (iitem.id === currentBasketId) {
    //                 iitem.item = itemCloned
    //             }
    //             // 保存上级所属购物篮数据
    //             if (iitem.id === belongedBasketId) {
    //                 if (isCollectiveItemIsBasketPackageMeal(iitem.item)) {
    //                     iitem.item.package_meal_areas = (iitem.item.package_meal_areas || []).map(areaItem => {
    //                         // 找到对应的区域
    //                         if (areaItem._id === areaCloned._id) {
    //                             let isEqItem
    //                             let setMealsDishItem
    //                             if (isHandleSetMeal) {
    //                                 areaItem.set_meals.forEach(smItem => {
    //                                     if (smItem.differentSetDishId !== this.state.collectiveCurrentBasketId) {
    //                                         let backetAreasData = _.cloneDeep(smItem.set_meal_areas);
    //                                         let currentItmeAreas = _.cloneDeep(itemClonedAreas);
    //                                         (backetAreasData || []).map(item => {
    //                                             item.dishes = item.dishes.map(iitem => {
    //                                                 delete iitem.id
    //                                                 delete iitem.belongedBasketId
    //                                                 delete iitem.isCloneDish
    //                                                 return iitem
    //                                             })
    //                                             return item
    //                                         });
    //                                         currentItmeAreas.map(item => {
    //                                             item.dishes = item.dishes.map(iitem => {
    //                                                 delete iitem.id
    //                                                 delete iitem.belongedBasketId
    //                                                 delete iitem.isCloneDish
    //                                                 return iitem
    //                                             })
    //                                             return item
    //                                         });
    //                                         if (_.isEqual(backetAreasData, currentItmeAreas)) {
    //                                             isEqItem = _.cloneDeep(smItem)
    //                                         }
    //                                     }
    //                                 })
    //                                 if (isEqItem) setMealsDishItem = areaItem.set_meals.find(sItem => this.state.collectiveCurrentBasketId === sItem.differentSetDishId)
    //                             }
    //                             // 包餐内选择了多个同一套餐(为了处理，在初始化时购物车篮本来有一个数量为0的套餐)
    //                             const isHasOrginSetMeal = _.cloneDeep(areaItem.set_meals).filter((mealItem) => mealItem._id === setDishId).length === 1;
    //                             areaItem.set_meals = areaItem.set_meals.map((setDishItem) => {
    //                                 // 对应区域的对应菜品
    //                                 if (setDishItem._id === setDishId) {
    //                                     // 每次循环回来后价格变回原来
    //                                     collectiveTotalPrice = recordedPrice
    //                                     collectiveTotalPrice += setDishItem.price ?? 0
    //                                     isDifferent = false
    //                                     if (isHandleSetMeal) {
    //                                         // 修改套餐的情况
    //                                         if (!isEqItem && this.state.collectiveCurrentBasketId === setDishItem.differentSetDishId) {
    //                                             // 当修改的套餐跟其它套餐不一样时 修改套餐 直接保存数据
    //                                             oldCollectiveTotalPrice = (setDishItem.CollectiveDishTotalPrice ?? 0) * setDishItem.num
    //                                             setDishItem.CollectiveDishTotalPrice = collectiveTotalPrice
    //                                             newSetDishPrice = collectiveTotalPrice * setDishItem.num
    //                                             setDishItem.set_meal_areas = itemClonedAreas
    //                                         } else if (isEqItem && isEqItem.differentSetDishId === setDishItem.differentSetDishId) {
    //                                             // 当修改的套餐跟其它套餐一样 去除其中一个购物篮 记录要去除的购物篮id 数量相加 减去修改前的价格
    //                                             oldCollectiveTotalPrice = (setMealsDishItem.CollectiveDishTotalPrice ?? 0) * setMealsDishItem.num
    //                                             setDishItem.num += setMealsDishItem.num
    //                                             newSetDishPrice = collectiveTotalPrice * setMealsDishItem.num
    //                                             isFilterBasketId = this.state.collectiveCurrentBasketId
    //                                         }
    //                                     } else {
    //                                         // 判断当前菜品是否有区域
    //                                         if ((!_.isArray(setDishItem.set_meal_areas) || setDishItem.set_meal_areas.length === 0) && isHasOrginSetMeal) {
    //                                             // 没有区域直接加1 并添加区域
    //                                             setDishItem.num++
    //                                             setDishItem.CollectiveDishTotalPrice = collectiveTotalPrice
    //                                             setDishItem.differentSetDishId = currentBasketId
    //                                             setDishItem.set_meal_areas = itemClonedAreas
    //                                         } else {
    //                                             //    如果有区域那就判断菜品是否相同 (两个要对比的区域数据去掉不同菜品独有的key 然后做比较)
    //                                             let backetAreasData = _.cloneDeep(setDishItem.set_meal_areas) as BasketSetMealArea[];
    //                                             let currentItmeAreas = _.cloneDeep(itemClonedAreas);
    //                                             backetAreasData?.map(item => {
    //                                                 item.dishes = item.dishes.map(iitem => {
    //                                                     delete iitem.id
    //                                                     delete iitem.belongedBasketId
    //                                                     delete iitem.isCloneDish
    //                                                     return iitem
    //                                                 })
    //                                                 return item
    //                                             });
    //                                             currentItmeAreas.map(item => {
    //                                                 item.dishes = item.dishes.map(iitem => {
    //                                                     delete iitem.id
    //                                                     delete iitem.belongedBasketId
    //                                                     delete iitem.isCloneDish
    //                                                     return iitem
    //                                                 })
    //                                                 return item
    //                                             });
    //                                             isDifferent = !_.isEqual(backetAreasData, currentItmeAreas)
    //                                             // 当菜品有不同时克隆一份数据，并把 《过滤当前集合性商品为0 的菜品》添加区域
    //                                             if (isDifferent && !isAddOne) {
    //                                                 setDishAreaItem = _.cloneDeep(setDishItem)
    //                                                 _.set(setDishAreaItem, 'isCloneDish', true)
    //                                                 setDishAreaItem.num = 1
    //                                                 _.set(setDishAreaItem, 'CollectiveDishTotalPrice', collectiveTotalPrice)
    //                                                 _.set(setDishAreaItem, 'set_meal_areas', itemClonedAreas)
    //                                                 // 区分相同套餐的不同菜品
    //                                                 _.set(setDishAreaItem, 'differentSetDishId', currentBasketId)
    //                                             }
    //                                             // 这里当有多道菜品相同时，都会加一 所以要!isAddOne
    //                                             if (!isDifferent && !isAddOne) {
    //                                                 // 菜品相同加1
    //                                                 isDifferent = false
    //                                                 isAddOne = true
    //                                                 isFilterBasketId = setDishItem.differentSetDishId ?? ''
    //                                                 // 重新赋值id
    //                                                 setDishItem.differentSetDishId = currentBasketId
    //                                                 setDishItem.num++
    //                                                 setDishItem.CollectiveDishTotalPrice = collectiveTotalPrice
    //                                             }
    //                                         }
    //                                     }
    //                                 }
    //                                 return setDishItem
    //                             })

    //                             // 菜品不同时 添加一份新的数据
    //                             if (isDifferent && !isAddOne) {
    //                                 areaItem.set_meals.push(setDishAreaItem)
    //                             }
    //                             if (!isHandleSetMeal) {
    //                                 areaItem.totalDishes++
    //                                 areaItem.totalDishesPrice += collectiveTotalPrice
    //                                 areaCloned = _.cloneDeep(areaItem)
    //                             } else {
    //                                 if (isEqItem) areaItem.set_meals = areaItem.set_meals.filter(isSmItem => isSmItem.differentSetDishId !== this.state.collectiveCurrentBasketId)
    //                                 areaItem.totalDishesPrice -= oldCollectiveTotalPrice
    //                                 areaItem.totalDishesPrice += newSetDishPrice
    //                                 areaCloned = _.cloneDeep(areaItem)
    //                             }
    //                         }
    //                         return areaItem
    //                     })
    //                 }

    //             }
    //             return iitem
    //         });
    //         // 当菜品相同加一时
    //         if (isFilterBasketId) {
    //             newBasket = newBasket.filter(newBasketItem => newBasketItem.id !== isFilterBasketId)
    //         }

    //         const higherLeverAreaId = areaCloned._id;
    //         // 获取上级购物篮数据
    //         const collectiveData = newBasket.find(item => item.id === belongedBasketId);
    //         let collectiveDataItem = collectiveData?.item ?? {};
    //         // 当前区域数据
    //         let newCollectiveItemArea = _.get(collectiveDataItem, 'package_meal_areas', []).find(item => item._id === higherLeverAreaId);
    //         // 找到上级套餐区域的下标
    //         let chosenMenuIndex = (_.get(collectiveDataItem, 'package_meal_areas', [])
    //             || _.get(collectiveDataItem, 'set_meal_areas', [])).findIndex(item => item._id === higherLeverAreaId);

    //         // 视图显示
    //         let isAreasSetDish;  // 区域集合性商品
    //         let ishigherleverCollectArea = _.cloneDeep(higherleverCollectArea); // 总区域
    //         // 计算当前所选的菜品数量
    //         let selectNum: number = 0;
    //         if (!isHandleSetMeal) {
    //             ishigherleverCollectArea.map(item => {
    //                 if (item._id === higherLeverAreaId) {
    //                     isAreasSetDish = _.get(item, 'set_meals', []).map((iitem) => {
    //                         if (iitem._id === setDishId) {
    //                             iitem.num++
    //                             // $储存id 后面判断是否99的时候可用
    //                             this.theSetDishId = iitem.id
    //                         }
    //                         return iitem
    //                     })
    //                     _.set(item, 'set_meals', isAreasSetDish)
    //                     item.totalDishes++
    //                     item.totalDishesPrice += collectiveTotalPrice
    //                 }
    //                 selectNum += item.totalDishes
    //                 return item
    //             })
    //         } else {
    //             ishigherleverCollectArea.map(item => {
    //                 selectNum += item.totalDishes
    //                 if (item._id === higherLeverAreaId) {
    //                     // 当修改菜品数据时 先减去原先的集合性商品总价格 再加新修改的价格
    //                     item.totalDishesPrice -= oldCollectiveTotalPrice
    //                     item.totalDishesPrice += newSetDishPrice
    //                     isAreasSetDish = _.cloneDeep(_.get(item, 'set_meals', []))
    //                 }
    //                 return item
    //             })
    //         }
    //         // 菜品最大数量
    //         let isMaxNum = collectiveDataItem.customer_max_num_dishes ?? 0;

    //         this.setState({
    //             isDisableAdd: isMaxNum !== 0 ? selectNum === isMaxNum : false,
    //             selectDishesNum: selectNum,
    //             setDishId: collectiveData?.item._id ?? '',
    //             setDishType: (collectiveData?.type === 'PACKAGE_MEAL' ? '1' : '2') ?? '',
    //             collectiveItemArea: newCollectiveItemArea,
    //             collectiveData: collectiveDataItem,
    //             collectiveName: collectiveDataItem.name ?? '',
    //             packagePrice: collectiveDataItem.price ?? 0,
    //             currentBasketType: collectiveDataItem.dish_type === '1' ? ICollectiveDishPanelType.PACKAGE_MEAL : ICollectiveDishPanelType.SET_MEAL,
    //             currentBasketId: collectiveData?.id ?? '',
    //             currentBasketLevel: collectiveData?.level ?? 0,
    //             mealAreas: ishigherleverCollectArea,
    //             areasDish: _.get(areaCloned, 'dishes', []),
    //             areasSetDish: isAreasSetDish,
    //             isRepeatedly: _.get(areaCloned, 'is_repeated', false),
    //             areasId: _.get(areaCloned, '_id', ''),
    //             packageMaxNum: isMaxNum,
    //             basket: newBasket,
    //             chosenMenu: chosenMenuIndex,
    //             isShowBasket: false,
    //             isHandleSetMeal: false,
    //             basketAreas: collectiveDataItem.dish_type === '1' ? _.get(collectiveDataItem, 'package_meal_areas', []) : _.get(collectiveDataItem, 'set_meal_areas', []),
    //         }, async () => {
    //             // 更新视图帮助性字段
    //             if (!isHandleSetMeal) await this.updateShoppingBasketOperation([], areaCloned)
    //             // 查看图片是否未加载完成
    //             const mealAreasItem = this.state.mealAreas.find(item => item._id === higherLeverAreaId)
    //             if (mealAreasItem) await this.areasClickEvent(mealAreasItem, this.state.chosenMenu)
    //         })
    //     } catch (error) {

    //     }
    //     return areaCloned
    // };

    // *********************
    // Service Function
    // *********************

    /**
     * 获取当前层级区域和菜品数据
     * @param areaCloned 当前区域数据
     */
    async getSetMealData(areaCloned?: CollectiveItemArea) {
        const { stringId } = this.props
        const { setDishId, setDishType } = this.state


        let result: CollectiveItem = {};
        let resultClone: CollectiveItem = {};
        let itemArea: CollectiveItemArea = {
            dishes: [],
            // $ 视图帮助性字段
            // 区域内菜品总数量
            totalDishes: 0,
            // 区域内菜品总价格
            totalDishesPrice: 0,
            // 是否此区域条件已符合
            isAchievedCriteria: false
        }

        try {
            if (setDishType === '1') {
                // 获取包餐区域数据
                result = await apiManage.getPackageMeal(stringId, setDishId);
                resultClone = _.cloneDeep(result);
                itemArea = _.get(resultClone, 'package_meal_areas[0]', {});

            } else if (setDishType === '2') {
                // 获取套餐区域数据
                result = await apiManage.getSetMeal(stringId, setDishId);
                resultClone = _.cloneDeep(result);
                itemArea = _.get(resultClone, 'set_meal_areas[0]', {});
            }
            // 当有克隆的父级区域对象时
            if (areaCloned) {
                itemArea = areaCloned
            }
            // 显示新增集合性商品视图
            await this.showNewCollectiveItemView(itemArea, resultClone);

        } catch (error) {

        }
    }

    /**
     * 区域点击事件
     * @param areasItemData 当前区域数据
     * @param areaIndex 当前区域下标
     */
    async areasClickEvent(areasItemData: CollectiveItemArea, areaIndex: number) {
        const { collectiveData, areasSetDish, basket, currentBasketId } = this.state
        const { stringId } = this.props

        try {
            // 获取菜品图片
            const picIds: Array<string> = [];
            let picResults;
            // 当前集合性商品原数据
            const isCollectiveData = _.cloneDeep(collectiveData);
            // 当前区域集合性商品菜品视图
            let newAreasSetDish = _.cloneDeep(areasSetDish);
            // 总购物篮数据
            const newBasket = _.cloneDeep(basket);
            // 已经有图片
            let alreadyHavePics = false;
            areasItemData.dishes.forEach(dishesItem => {
                if (_.get(dishesItem, 'realpics', []).length === 0) {
                    picIds.push(dishesItem._id ?? '');
                }
            });
            if (_.get(areasItemData, 'set_meals', []).length > 0) {
                _.get(areasItemData, 'set_meals', []).forEach(setMealsItem => {
                    if (_.get(setMealsItem, 'realpics', []).length === 0) {
                        picIds.push(setMealsItem._id ?? '');
                    }
                })
            }
            alreadyHavePics = picIds.length === 0;
            this.setState({
                chosenMenu: areaIndex,
                areasDish: areasItemData.dishes,
                areasSetDish: _.get(areasItemData, 'set_meals', []),
                isRepeatedly: areasItemData.is_repeated || false,
                areasId: areasItemData._id || '',
                collectiveItemArea: areasItemData
            }, async () => {
                // 取消上一个点击的请求
                this.cancel?.();
                // 当有图片或没有菜品时停止获取菜品图片
                if (alreadyHavePics || (areasItemData.dishes.length === 0 && _.get(areasItemData, 'set_meals', []).length === 0)) return
                // 每次请求前都先重置axios.CancelToken
                this.cancelToken = new axios.CancelToken(c => {
                    this.cancel = c;
                });
                picResults = await apiManage.getSetDishImg(stringId, picIds, { notNeedLoading: true }, this.cancelToken);
                // 图片获取失败 或者没网
                if (_.get(picResults, 'error', false)) return
                // 更新原数据和当前购物篮显示数据

                if (isCollectiveItemIsBasketPackageMeal(this.state.collectiveData)) {
                    const packageAreas = (this.state.collectiveData.package_meal_areas || []).map(item => {
                        if (areasItemData._id === item._id) {
                            let picObj: { picUrl: any; }
                            item.dishes = item.dishes.map(iitem => {
                                picObj = _.find(picResults, { _id: iitem._id })
                                if (picObj) {
                                    _.set(iitem, 'realpics', [picObj.picUrl ?? ''])
                                }
                                return iitem
                            })
                            item.set_meals = item.set_meals.map(setMealsItem => {
                                picObj = _.find(picResults, { _id: setMealsItem._id })
                                if (picObj) {
                                    _.set(setMealsItem, 'realpics', [picObj.picUrl ?? ''])
                                }
                                return setMealsItem
                            })
                        }
                        return item
                    })
                    _.set(isCollectiveData, 'package_meal_areas', packageAreas ?? [])
                } else if (isCollectiveItemIsBasketSetMeal(this.state.collectiveData)) {
                    const setAreas = (this.state.collectiveData.set_meal_areas || []).map(item => {
                        if (areasItemData._id === item._id) {
                            item.dishes = item.dishes.map(iitem => {
                                const picObj = _.find(picResults, { _id: iitem._id })
                                if (picObj) {
                                    _.set(iitem, 'realpics', [picObj.picUrl ?? ''])
                                }
                                return iitem
                            })
                        }
                        return item
                    })
                    _.set(isCollectiveData, 'set_meal_areas', setAreas ?? []);
                }
                // 购物篮菜品赋值图片
                const newBasketAreas = this.state.basketAreas;
                newBasketAreas.forEach((item) => {
                    if (item._id === areasItemData._id) {
                        item.dishes = item.dishes.map(iitem => {
                            const picObj = _.find(picResults, { _id: iitem._id })
                            if (picObj) {
                                _.set(iitem, 'realpics', [picObj.picUrl ?? ''])
                            }
                            return iitem
                        })
                        if ((item as BasketPackageMealArea)?.set_meals) {
                            const setMeals = (item as BasketPackageMealArea).set_meals.map(setMealsItem => {
                                const picObj = _.find(picResults, { _id: setMealsItem._id })
                                if (picObj) {
                                    _.set(setMealsItem, 'realpics', [picObj.picUrl ?? ''])
                                }
                                return setMealsItem
                            })
                            _.set(item, 'set_meals', setMeals);
                        }
                    }
                })
                // 更新区域视图
                const newMealAreas = this.state.mealAreas
                newMealAreas.map(mealAreasItem => {
                    if (mealAreasItem._id === areasItemData._id) {
                        let picObj
                        mealAreasItem.dishes = mealAreasItem.dishes.map(iitem => {
                            picObj = _.find(picResults, { _id: iitem._id })
                            if (picObj) {
                                _.set(iitem, 'realpics', [picObj.picUrl])
                            }
                            return iitem
                        })
                        _.set(areasItemData, 'dishes', mealAreasItem.dishes) // 菜品视图数据
                        if (_.get(mealAreasItem, 'set_meals', []).length > 0) {
                            const newSetMeals = _.get(mealAreasItem, 'set_meals', []).map(setMealsItem => {
                                picObj = _.find(picResults, { _id: setMealsItem._id })
                                if (picObj) {
                                    _.set(setMealsItem, 'realpics', [picObj.picUrl ?? ''])
                                }
                                return setMealsItem
                            });
                            _.set(mealAreasItem, 'set_meals', newSetMeals);
                            newAreasSetDish = _.get(mealAreasItem, 'set_meals', []);  // 更新集合性菜品视图
                        } else {
                            newAreasSetDish = []
                        }
                    }
                    return mealAreasItem
                })
                // 保存至总购物篮
                newBasket.map(basketItem => {
                    if (basketItem.id === currentBasketId) {
                        basketItem.item = isCollectiveData
                    }
                    return basketItem
                });

                this.setState({
                    basket: newBasket,
                    mealAreas: newMealAreas,
                    basketAreas: newBasketAreas,
                    collectiveData: isCollectiveData,
                    chosenMenu: areaIndex,
                    areasDish: areasItemData.dishes,
                    areasSetDish: newAreasSetDish,
                    isRepeatedly: areasItemData.is_repeated || false,
                    areasId: areasItemData._id || '',
                    collectiveItemArea: areasItemData
                });
            })

        } catch (error) {
        }
    }

    /**
     * 进入集合性商品视图按钮
     * @param dishItem.dishId 菜品的_id
     * @param dishItem.dishType 菜品类型
     * @param dishItem.belongedBasketId 菜品所属的购物篮id
     * @param isHandleSetMeal 是否编辑购物篮里的套餐
     */
    goValoreOperation(dishItem: BasketDish, isHandleSetMeal?: boolean) {

        const { _id, dish_type, belongedBasketId } = dishItem;
        const { collectiveItemArea, mealAreas, packageMaxNum } = this.state
        try {
            // this.cancel?.()
            let selectNum: number = 0;
            const newHigherleverCollectArea = _.cloneDeep(mealAreas);
            mealAreas.forEach(item => selectNum += item.totalDishes);
            if (!isHandleSetMeal) {
                // 菜品已达最大数量
                if (packageMaxNum && selectNum >= packageMaxNum && !isHandleSetMeal) {
                    return
                }
                // 菜品已达区域最大数量
                if (collectiveItemArea.customer_max_num_dishes && collectiveItemArea.totalDishes === collectiveItemArea.customer_max_num_dishes) {
                    return
                }
            }
            // 菜品只可单选
            if (!collectiveItemArea.is_repeated && _.get(collectiveItemArea, 'set_meals', []).length > 0) {
                _.get(collectiveItemArea, 'set_meals', []).forEach(selMealsItem => {
                    if (selMealsItem._id === _id && selMealsItem.num >= 1) throw new Error('');
                })
            }
            // 集合性商品菜品总数量大于99时不可订购
            let collectiveItemNum = 0
            if (_.get(collectiveItemArea, 'set_meals', []).length > 0) {
                _.get(collectiveItemArea, 'set_meals', []).forEach(setItem => {
                    if (setItem._id === _id) {
                        collectiveItemNum += setItem.num
                    }
                })
            }
            if (collectiveItemNum >= 99) {
                Notification.notification(`${i18n.t('checkout_page_text_the_number_of_dishes_cannot_be_greater_than', { number: 99 })}`);
                return
            }

            this.setState({
                setDishType: dish_type || '',
                setDishId: _id || '',
                belongedBasketId: belongedBasketId || '',
                higherleverCollectArea: newHigherleverCollectArea,
                isShowDishDetails: false,
                isShowPackageMeal: true,
                dishesDetail: dishItem,
                isShowBasket: false,
                isHandleSetMeal: !!isHandleSetMeal,
                higherLeverCollectiveItemArea: collectiveItemArea
            }, () => {
                // 有新套餐视图，暂时注释，以下旧套餐视图。
                // this.getSetMealData(collectiveItemArea)
            })
        } catch (error) {

        }
    }


    /**
     * 是否返回上级
     */
    whetherToReturn() {
        const { basketAreas } = this.state
        let totalDishes = 0
        basketAreas.forEach(item => {
            totalDishes += item.totalDishes
        })
        if (totalDishes) {
            // 当有菜品数量未确认的时候提示用户是否返回
            this.setState({ openDiolog: true })
        } else {
            this.goBackOperation()
        }
    }

    /**
     * 返回操作
     */
    goBackOperation() {
        const { basket, currentBasketLevel, higherLeverCollectiveItemArea, belongedBasketId, higherleverCollectArea, isHandleSetMeal, currentBasketId } = this.state
        try {
            if (currentBasketLevel !== 0) {
                // 这个操作是当图片异步加载还未加载完的时候，拦截请求
                this.cancel?.()
                const higherLeverAreaId = higherLeverCollectiveItemArea._id
                // 获取上级购物篮数据
                const collectiveData = basket.find(item => item.id === belongedBasketId)
                const collectiveDataItem = collectiveData?.item ?? {}
                // if (isHandleSetMeal) {
                //     // 如果是要修改套餐菜品的返回 拿到之前进来的数据 给当前购物篮
                //     newBasket = basket.map(basketItem => {
                //         if (basketItem.id === currentBasketId) {
                //             basketItem.item = _.cloneDeep(setUnmodifiedData)
                //         }
                //         return basketItem
                //     })
                // } else {
                // 当第一次进集合性商品未添加至购物车时 过滤掉返回前的集合性商品数据
                const newBasket = basket.filter(item => item.id !== currentBasketId)
                // }
                // 找到上级套餐区域的下标
                const chosenMenuIndex = (_.get(collectiveDataItem, 'package_meal_areas', []) || _.get(collectiveDataItem, 'set_meal_areas', [])).findIndex(item => item._id === higherLeverAreaId)
                // 上级套餐区域用户选择的菜品数量
                let isSelectDishesNum = 0
                const newCollectiveItemArea = _.find(_.get(collectiveDataItem, 'package_meal_areas', []), { _id: higherLeverAreaId })
                _.get(collectiveDataItem, 'package_meal_areas', []).forEach(areaItem => {
                    isSelectDishesNum += areaItem.totalDishes
                })
                const maxNum: number = collectiveDataItem.customer_max_num_dishes ?? 0
                this.setState({
                    isDisableAdd: maxNum ? isSelectDishesNum === maxNum : false,
                    selectDishesNum: isSelectDishesNum,
                    setDishId: collectiveData?.item._id ?? '',
                    setDishType: (collectiveData?.type === 'PACKAGE_MEAL' ? '1' : '2') ?? '',
                    collectiveItemArea: newCollectiveItemArea ?? {},
                    collectiveData: collectiveDataItem,
                    collectiveName: collectiveDataItem.name ?? '',
                    packagePrice: collectiveDataItem.price ?? 0,
                    currentBasketType: collectiveDataItem.dish_type === '1' ? ICollectiveDishPanelType.PACKAGE_MEAL : ICollectiveDishPanelType.SET_MEAL,
                    currentBasketId: collectiveData?.id ?? '',
                    currentBasketLevel: collectiveData?.level ?? 0,
                    mealAreas: higherleverCollectArea,
                    areasDish: higherleverCollectArea[chosenMenuIndex].dishes ?? [],
                    areasSetDish: _.get(higherleverCollectArea[chosenMenuIndex], 'set_meals', []),
                    isRepeatedly: _.get(higherleverCollectArea[chosenMenuIndex], 'is_repeated', false),
                    areasId: _.get(higherleverCollectArea[chosenMenuIndex], '_id', ''),
                    packageMaxNum: maxNum,
                    basket: newBasket,
                    chosenMenu: chosenMenuIndex,
                    isHandleSetMeal: false,
                    openDiolog: false,
                    basketAreas: collectiveDataItem.dish_type === '1' ? _.get(collectiveDataItem, 'package_meal_areas', []) : _.get(collectiveDataItem, 'set_meal_areas', [])
                }, async () => {
                    // 修改套餐时 返回清空当前层级购物篮数据
                    if (isHandleSetMeal) {
                        const dishData = this.state.handleCollectiveDish;
                        let actions: Array<CartActions> = []
                        if (dishData) {
                            actions = [{ type: 'REDUCE', id: dishData?.id || '', num: dishData?.num, isICollectiveDish: !!dishData?.isICollectiveDish, differentSetDishId: dishData?.differentSetDishId, clearAll: true }]
                            await this.updateShoppingBasketOperation(actions, higherLeverCollectiveItemArea)
                        }
                    }
                    // 查看图片是否未加载完成
                    const mealAreasItem = this.state.mealAreas.find(item => item._id === higherLeverAreaId)
                    if (mealAreasItem) await this.areasClickEvent(mealAreasItem, this.state.chosenMenu)
                })
            } else {
                // 层级为0 返回点餐页
                this.props.goBack()
            }
        } catch (error) {
        }
    }

    /**
     *  添加到购物篮的未满足条件提示
     *
     */
    basketErrorMessage() {
        const { basketAreas } = this.state
        try {
            let selectDishes: number = 0
            let isEnough: boolean = true
            let isNeedNum: number = 0
            // 用户选择的总菜品数量
            basketAreas.forEach(basketItem => selectDishes += basketItem.totalDishes)
            // if (!selectDishes) {
            //     Notification.notification(i18n.t('restaurant_order_page_text_choose_at_least_one_dish'))
            //     return false
            // }
            // 用户选择条件是否已满足
            basketAreas.forEach((item) => {
                if (isEnough) {
                    if (item.is_required) {
                        if (item.customer_min_num_dishes) {
                            // 当该区域必选 且有必选满数量
                            if (item.customer_min_num_dishes > item.totalDishes) {
                                isNeedNum = item.customer_min_num_dishes - item.totalDishes
                                Notification.notification(i18n.t('restaurant_order_page_text_regional_dishes_are_not_satisfied', { 1: item.name, 2: isNeedNum }))
                                isEnough = false
                            }
                        } else if (!item.totalDishes) {
                            // 当该区域必选 且没有要必选满几个
                            Notification.notification(i18n.t('restaurant_order_page_text_regional_dishes_are_not_satisfied', { 1: item.name, 2: 1 }))
                            isEnough = false
                        }
                    } else {
                        if (item.customer_min_num_dishes && item.totalDishes) {
                            // 当该区域非必选 且有必选满数量
                            if (item.customer_min_num_dishes > item.totalDishes) {
                                isNeedNum = item.customer_min_num_dishes - item.totalDishes
                                Notification.notification(i18n.t('restaurant_order_page_text_regional_dishes_are_not_satisfied', { 1: item.name, 2: isNeedNum }))
                                isEnough = false
                            }
                        }
                    }
                }
            })
            if (!isEnough) return false
        } catch (error) {
            return false
        }
        return true
    }

    /**
     *  购物篮添加到购物车
     */
    toShoppingCar() {
        const { collectiveData, mealAreas, currentBasketLevel, basketAreas } = this.state
        const { stringId, updateOrderState, menuSearchValue, menuName, goBack, isEdit, details } = this.props
        // 获取缓存里购物车
        const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
        // 添加到购物车
        let shopCartList = _.cloneDeep(cacheShopCar[this.props.stringId]?.shopCarList);
        // 计算包餐套餐总价格
        let totalPrice: number = 0
        let newBasketAreas: Array<CollectiveItemArea> = _.cloneDeep(basketAreas);
        try {
            // 判断区域是否满足条件
            if (!this.basketErrorMessage()) return;
            if (currentBasketLevel === 0) {
                // 包餐或套餐添加到购物篮

                // 计算该套餐或包餐价格
                mealAreas.forEach((item) => {
                    // 该套餐的总价格
                    if (item.totalDishesPrice && item.totalDishes !== 0) totalPrice += item.totalDishesPrice
                })

                // 过滤出选择的菜品区域
                newBasketAreas = _.filter(newBasketAreas, (item) => { return item.totalDishes !== 0 });
                // 过滤出选择的菜品
                newBasketAreas.map(item => {
                    if (_.isArray(item.dishes)) {
                        item.dishes = _.filter(item.dishes, (iitem) => { return iitem.num !== 0 });
                    }
                    if (_.get(item, 'set_meals', []).length > 0) {
                        _.set(item, 'set_meals', _.filter(_.get(item, 'set_meals', []), (iitem) => { return iitem.num !== 0 }))
                    }
                    return item
                })

                // 包餐已选菜品数据
                if (_.get(collectiveData, 'package_meal_areas', '')) {
                    _.set(collectiveData, 'package_meal_areas', newBasketAreas);
                }
                // 套餐已选菜品数据
                if (_.get(collectiveData, 'set_meal_areas', '')) {
                    _.set(collectiveData, 'set_meal_areas', newBasketAreas);
                }
                // 添加购物车数据
                if (collectiveData.price || collectiveData.price === 0) {
                    collectiveData.price += totalPrice
                } else {
                    collectiveData.price = totalPrice
                }

                // 设置firstID
                const firstId: string = `${collectiveData._id}${menuSearchValue}`
                collectiveData.firstId = firstId;
                // 如果没有图片,调用api获取
                if (this.props.details?.realpics?.length) {
                    // 保存包餐图片
                    _.set(collectiveData, 'realpics', this.props.details?.realpics || []);
                } else {
                    if (this.props.details?._id) {
                        const pic = apiManage.getSetDishImg(this.props.stringId, [this.props.details?._id], { notNeedLoading: true })
                        if (pic?.[0]?.picUrl) {
                            _.set(collectiveData, 'realpics', [pic?.[0]?.picUrl]);
                        }
                    }
                }
                // 从购物车里编辑包餐
                if (isEdit) {
                    const editDishItem = shopCartList.find(shopCatItem => _.get(shopCatItem, 'inCartId') === _.get(details, 'inCartId'));
                    if (editDishItem) {
                        if (collectiveData?.dish_type === '1') {
                            _.set(editDishItem, 'package_meal_areas', _.get(collectiveData, 'package_meal_areas', []));
                        }
                        else if (collectiveData?.dish_type === '2') {
                            _.set(editDishItem, 'set_meal_areas', _.get(collectiveData, 'set_meal_areas', []));
                        }
                        // 设置新的价格
                        _.set(editDishItem, 'price', collectiveData.price)
                    }
                }

                // 查看包餐套餐已选菜品是否与购物车相同
                let isEqual: boolean = false;
                const objCollectiveData = this.RemoveBasketId(collectiveData)
                let isEqualItemId: string = '';

                shopCartList.map((shopCatItem) => {
                    const isSelf = _.get(shopCatItem, 'inCartId') === _.get(this.props.details, 'inCartId');

                    if (shopCatItem.firstId === objCollectiveData.firstId && !isEqual && !isSelf) {
                        isEqual = this.isEqualCollectiveData(shopCatItem as CollectiveItem, objCollectiveData)
                        if (isEqual) {
                            isEqualItemId = shopCatItem.inCartId || '';
                        }
                    }
                    return shopCatItem
                })

                if (isEqual) {
                    // 在购物车里编辑的菜品 isEidt
                    const isEdit = this.props.isEdit;
                    // 编辑的菜品 details
                    const editDishItem = shopCartList.find(shopCatItem => _.get(shopCatItem, 'inCartId') === _.get(this.props.details, 'inCartId'));
                    // 相同的菜品
                    const equalDishIItem = shopCartList.find(shopCatItem => _.get(shopCatItem, 'inCartId') === isEqualItemId);
                    // 编辑后，合并相同的套餐，合并后，删除编辑的套餐
                    if (equalDishIItem) {
                        const buyNumber = isEdit ? (editDishItem?.buyNumber || 0) : 1
                        // 相同的菜品的数量  +  编辑的菜品的数量
                        equalDishIItem.buyNumber = (equalDishIItem.buyNumber || 0) + buyNumber;
                        if (isEdit && editDishItem) {
                            // 合并后，删除编辑的套餐
                            shopCartList = shopCartList.filter((shopCatItem) => shopCatItem.inCartId !== editDishItem.inCartId);
                        }
                    }
                } else {
                    if (!isEdit) {
                        _.set(collectiveData, 'menuName', menuName)
                        shopCartList.push({
                            ...collectiveData,
                            buyNumber: 1,
                            menuId: menuSearchValue,
                            inCartId: uuidv4()
                        })
                    }
                }
                const cart = analysisCart({ shopCarList: shopCartList });
                // 是否已登录， token
                const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
                const pass = PM.updatePoints({ key: 'dishUsedPoints', value: cart.totalPoints || 0 }, !token);
                if (pass) {
                    // 更新购物车数据
                    updateOrderState({
                        saveShopCatDate: cart.shopCarList,
                        totalShopPrice: cart.totalPrice,
                        totalShopPoints: cart.totalPoints
                    })
                    // 更新存储里面的购物车数据
                    const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};

                    LocalStorageManager.setLocalStorage({
                        path: LOCAL_STORAGE_KEYS.SHOP_CAR,
                        value: {
                            ...cacheShopCar,
                            [stringId]: cart
                        }
                    });
                    goBack();
                } else {
                    Notification.notification(`${i18n.t('order_page_text_not_enough_for_points')}`);
                }

            } else {
                // ! 暂时注释，使用新的套餐视图
                // let isBasekt = _.cloneDeep(basket)
                // const itemData = isBasekt.find(item => item.id === currentBasketId)
                // const areaCloned = _.cloneDeep(higherLeverCollectiveItemArea)
                // this.addNewCollectiveItem(areaCloned, itemData?.item ?? {})
            }
        } catch (error) {

        }
    }

    /**
     * 套餐添加至购物篮（包餐里的套餐）
     */
    updateBesket(basketItem: CollectiveItem) {
        const { higherLeverCollectiveItemArea, basket, belongedBasketId, setDishId, isHandleSetMeal } = this.state;


        let areaCloned = _.cloneDeep(higherLeverCollectiveItemArea)
        const itemClonedAreas = _.cloneDeep(_.get(basketItem, 'set_meal_areas', []));
        // 保存购物篮数据
        const newBasket = _.cloneDeep(basket);
        // 设置套餐在购物篮的唯一值
        const uuId = uuidv4();


        // $ 修改购物篮数据
        // 计算新增集合性商品的附加价格
        let collectiveTotalPrice: number = basketItem.price || 0;
        const recordedPrice: number = basketItem.price || 0;
        // 区分菜品区域是否相同
        let isDifferent: boolean = false;
        // 记录相同且已加一 (帮助性常量)
        let isAddOne: boolean = false;
        // 当前区域的单个菜品数据
        let setDishAreaItem;
        // 新增集合性商品的原附加价格
        let oldCollectiveTotalPrice: number = 0;
        // 区域要加上的新价格
        let newSetDishPrice: number = 0;
        // 套餐在购物篮的唯一id
        const basketItemdifferentSetDishId = basketItem.differentSetDishId;
        newBasket.map(iitem => {
            // // 保存当前层级所属购物篮数据
            // if (iitem.id === currentBasketId) {
            //     iitem.item = itemCloned
            // }
            // 保存上级所属购物篮数据
            if (iitem.id === belongedBasketId) {
                if (isCollectiveItemIsBasketPackageMeal(iitem.item)) {
                    iitem.item.package_meal_areas = (iitem.item.package_meal_areas || []).map(areaItem => {
                        // 找到对应的区域
                        if (areaItem._id === areaCloned._id) {

                            // 编辑后的套餐，是否与已有的其他套餐相同
                            let isEqItem;
                            let setMealsDishItem;
                            if (isHandleSetMeal) {
                                areaItem.set_meals.forEach((smItem) => {
                                    const isSelf = smItem.differentSetDishId === basketItemdifferentSetDishId;

                                    if (!isSelf) {
                                        if (this.isEqualCollectiveData(basketItem, smItem)) {
                                            isEqItem = _.cloneDeep(smItem);
                                        }

                                    }
                                })
                                if (isEqItem) setMealsDishItem = areaItem.set_meals.find(sItem => basketItemdifferentSetDishId === sItem.differentSetDishId)
                            }

                            // 包餐内选择了多个同一套餐(为了处理，在初始化时购物车篮本来有一个数量为0的套餐)
                            const isHasOrginSetMeal = _.cloneDeep(areaItem.set_meals).filter((mealItem) => mealItem._id === setDishId).length === 1;
                            areaItem.set_meals = areaItem.set_meals.map((setDishItem) => {
                                // 对应区域的对应菜品
                                if (setDishItem._id === setDishId) {
                                    // 每次循环回来后价格变回原来
                                    collectiveTotalPrice = recordedPrice;
                                    isDifferent = false;
                                    if (isHandleSetMeal) {
                                        // 修改套餐的情况
                                        if (!isEqItem && basketItemdifferentSetDishId === setDishItem.differentSetDishId) {
                                            // 当修改的套餐跟其它套餐不一样时 修改套餐 直接保存数据
                                            oldCollectiveTotalPrice = (setDishItem.CollectiveDishTotalPrice ?? 0) * setDishItem.num;
                                            setDishItem.CollectiveDishTotalPrice = collectiveTotalPrice;
                                            newSetDishPrice = collectiveTotalPrice * setDishItem.num;
                                            setDishItem.set_meal_areas = itemClonedAreas;
                                        } else if (isEqItem && isEqItem.differentSetDishId === setDishItem.differentSetDishId) {
                                            // 当修改的套餐跟其它套餐一样 去除其中一个购物篮 记录要去除的购物篮id 数量相加 减去修改前的价格
                                            oldCollectiveTotalPrice = (setMealsDishItem?.CollectiveDishTotalPrice ?? 0) * (setMealsDishItem?.num || 0);
                                            setDishItem.num += (setMealsDishItem?.num || 0);
                                            newSetDishPrice = collectiveTotalPrice * (setMealsDishItem?.num || 0);
                                        }
                                    } else {
                                        // 判断当前菜品是否有区域
                                        if ((!_.isArray(setDishItem.set_meal_areas) || setDishItem.set_meal_areas.length === 0) && isHasOrginSetMeal) {
                                            // 没有区域直接加1 并添加区域
                                            setDishItem.num++;
                                            setDishItem.CollectiveDishTotalPrice = collectiveTotalPrice;
                                            //  赋值唯一值 区分相同套餐的不同菜品
                                            setDishItem.differentSetDishId = uuId;
                                            setDishItem.set_meal_areas = itemClonedAreas;
                                        } else {
                                            //    如果有区域那就判断菜品是否相同 (两个要对比的区域数据去掉不同菜品独有的key 然后做比较)
                                            const backetAreasData = _.cloneDeep(setDishItem.set_meal_areas) as BasketSetMealArea[];
                                            const currentItmeAreas = _.cloneDeep(itemClonedAreas);
                                            backetAreasData?.map(item => {
                                                item.dishes = item.dishes.map(iitem => {
                                                    delete iitem.id
                                                    delete iitem.belongedBasketId
                                                    delete iitem.isCloneDish
                                                    return iitem
                                                })
                                                item.dishes.sort((a, b) => (a?._id || '') > (b._id || '') ? 1 : -1)
                                                return item
                                            });
                                            currentItmeAreas.map(item => {
                                                item.dishes = item.dishes.map(iitem => {
                                                    delete iitem.id
                                                    delete iitem.belongedBasketId
                                                    delete iitem.isCloneDish
                                                    return iitem
                                                })
                                                item.dishes.sort((a, b) => (a?._id || '') > (b._id || '') ? 1 : -1)
                                                return item
                                            });

                                            isDifferent = !_.isEqual(backetAreasData, currentItmeAreas)
                                            // 当菜品有不同时克隆一份数据，并把 《过滤当前集合性商品为0 的菜品》添加区域
                                            if (isDifferent && !isAddOne) {
                                                setDishAreaItem = _.cloneDeep(setDishItem)
                                                _.set(setDishAreaItem, 'isCloneDish', true)
                                                setDishAreaItem.num = 1
                                                _.set(setDishAreaItem, 'CollectiveDishTotalPrice', collectiveTotalPrice)
                                                _.set(setDishAreaItem, 'set_meal_areas', itemClonedAreas)
                                                //  赋值唯一值 区分相同套餐的不同菜品
                                                _.set(setDishAreaItem, 'differentSetDishId', uuId)
                                            }
                                            // 这里当有多道菜品相同时，都会加一 所以要!isAddOne
                                            if (!isDifferent && !isAddOne) {
                                                // 菜品相同加1
                                                isDifferent = false
                                                isAddOne = true
                                                // 重新赋值唯一值
                                                setDishItem.differentSetDishId = uuId
                                                setDishItem.num++
                                                setDishItem.CollectiveDishTotalPrice = collectiveTotalPrice
                                            }
                                        }
                                    }
                                }
                                return setDishItem
                            })

                            // 菜品不同时 添加一份新的数据
                            if (isDifferent && !isAddOne) {
                                areaItem.set_meals.push(setDishAreaItem)
                            }
                            if (isHandleSetMeal) {
                                // 如果相同的去掉被编辑的套餐
                                if (isEqItem) areaItem.set_meals = areaItem.set_meals.filter(isSmItem => isSmItem.differentSetDishId !== basketItemdifferentSetDishId);
                                areaItem.totalDishesPrice -= oldCollectiveTotalPrice;
                                areaItem.totalDishesPrice += newSetDishPrice;
                                areaCloned = _.cloneDeep(areaItem);
                            } else {
                                areaItem.totalDishes++;
                                areaItem.totalDishesPrice += collectiveTotalPrice;
                                // 直接修改父级区域
                                areaCloned = _.cloneDeep(areaItem);
                            }
                        }
                        return areaItem
                    })
                }

            }
            return iitem
        });

        const higherLeverAreaId = areaCloned._id;
        // 获取上级购物篮数据
        const collectiveData = newBasket.find(item => item.id === belongedBasketId);
        const collectiveDataItem = collectiveData?.item ?? {};
        // 菜品最大数量
        const isMaxNum = collectiveDataItem.customer_max_num_dishes ?? 0;

        // 更新区域所选数量
        const ishigherleverCollectArea = _.cloneDeep(this.state.mealAreas); // 总区域

        // 当前区域集合性商品(更新数量)
        let isAreasSetDish;

        ishigherleverCollectArea.map(item => {
            if (item._id === higherLeverAreaId) {
                if (!isHandleSetMeal) {
                    isAreasSetDish = _.get(item, 'set_meals', []).map((iitem) => {
                        if (iitem._id === setDishId) {
                            iitem.num++;
                            // $储存id 后面判断是否99的时候可用
                            this.theSetDishId = iitem.id;
                        }
                        return iitem
                    })
                    _.set(item, 'set_meals', isAreasSetDish);
                    item.totalDishes++;
                    item.totalDishesPrice += collectiveTotalPrice;
                } else {
                    if (item._id === higherLeverAreaId) {
                        // 当修改菜品数据时 先减去原先的集合性商品总价格 再加新修改的价格
                        item.totalDishesPrice -= oldCollectiveTotalPrice;
                        item.totalDishesPrice += newSetDishPrice
                        isAreasSetDish = _.cloneDeep(_.get(item, 'set_meals', []))
                    }
                }

            }
            return item
        })

        const selectNum = _.cloneDeep(ishigherleverCollectArea).reduce((pre, cur) => pre += (cur.totalDishes || 0), 0);


        this.setState({
            isDisableAdd: isMaxNum !== 0 ? selectNum === isMaxNum : false,
            selectDishesNum: selectNum,
            setDishId: collectiveData?.item._id ?? '',
            setDishType: collectiveData?.item?.dish_type ?? '',
            //     collectiveItemArea: newCollectiveItemArea,
            // collectiveData: collectiveDataItem,
            //     collectiveName: collectiveDataItem.name ?? '',
            //     packagePrice: collectiveDataItem.price ?? 0,
            //     currentBasketType: collectiveDataItem.dish_type === '1' ? ICollectiveDishPanelType.PACKAGE_MEAL : ICollectiveDishPanelType.SET_MEAL,
            //     currentBasketId: collectiveData?.id ?? '',
            //     currentBasketLevel: collectiveData?.level ?? 0,
            mealAreas: ishigherleverCollectArea,
            //     areasDish: _.get(areaCloned, 'dishes', []),
            areasSetDish: isAreasSetDish,
            //     isRepeatedly: _.get(areaCloned, 'is_repeated', false),
            //     areasId: _.get(areaCloned, '_id', ''),
            //     packageMaxNum: isMaxNum,
            basket: newBasket,
            //     chosenMenu: chosenMenuIndex,
            isShowBasket: false,
            isHandleSetMeal: false,
            isShowPackageMeal: false,
            basketAreas: collectiveDataItem.dish_type === '1' ? _.get(collectiveDataItem, 'package_meal_areas', []) : _.get(collectiveDataItem, 'set_meal_areas', [])
        }, async () => {
            // 更新视图帮助性字段
            await this.updateShoppingBasketOperation([], areaCloned)
        })
    }

    /**
     * 去除区域数据购物篮ID
     * @param areaItem 集合性商品数据
     */
    RemoveBasketId(areaItem: CollectiveItem) {
        try {
            if (isCollectiveItemIsBasketPackageMeal(areaItem)) {
                areaItem.package_meal_areas = (areaItem.package_meal_areas || []).map(PackageMealItem => {
                    if (PackageMealItem.dishes.length > 0) {
                        PackageMealItem.dishes = PackageMealItem.dishes.map(dishesItem => {
                            delete dishesItem.belongedBasketId
                            delete dishesItem.id
                            return dishesItem
                        })
                    }

                    if (PackageMealItem.set_meals.length > 0) {
                        PackageMealItem.set_meals = (PackageMealItem.set_meals || []).map(setDishItem => {
                            delete setDishItem.id
                            delete setDishItem.belongedBasketId
                            delete setDishItem.differentSetDishId
                            const newSetDishItem = this.RemoveBasketId(setDishItem)
                            return newSetDishItem as BasketDish & BasketSetMeal
                        })
                    }
                    return PackageMealItem
                })
            } else if (isCollectiveItemIsBasketSetMeal(areaItem)) {
                areaItem.set_meal_areas = (areaItem.set_meal_areas || []).map(setMealItem => {
                    if (setMealItem.dishes.length > 0) {
                        setMealItem.dishes = setMealItem.dishes.map(dishesItem => {
                            delete dishesItem.belongedBasketId
                            delete dishesItem.id
                            return dishesItem
                        })
                    }
                    return setMealItem
                })
            }
        } catch (error) {

        }
        return areaItem
    }

    /**
     * 从购物篮获取相应的区域数据
     * @param areaCloned 克隆的CollectiveItemArea对象
     */
    getBasketAreasItem(areaCloned: CollectiveItemArea) {
        const { basket, currentBasketId } = this.state
        // 拿到当前对应购物篮的区域数据 当不符合套餐或包餐的可选条件时 返回这个数据
        let newBasketAreasItem: CollectiveItemArea = {
            dishes: [],
            // $ 视图帮助性字段
            // 区域内菜品总数量
            totalDishes: 0,
            // 区域内菜品总价格
            totalDishesPrice: 0,
            // 是否此区域条件已符合
            isAchievedCriteria: false
        }
        basket.forEach(basketItem => {
            if (basketItem.id === currentBasketId) {
                if (isCollectiveItemIsBasketPackageMeal(basketItem.item)) {
                    newBasketAreasItem = (basketItem.item.package_meal_areas || []).find(item => item._id === areaCloned._id) ?? newBasketAreasItem
                } else if (isCollectiveItemIsBasketSetMeal(basketItem.item)) {
                    newBasketAreasItem = (basketItem.item.set_meal_areas || []).find(item => item._id === areaCloned._id) ?? newBasketAreasItem
                }
            }
        })
        return newBasketAreasItem
    }

    /**
     * 更新购物篮数据
     * @param actions 点击的商品项数据
     * @param itemData 购物篮点击的当前区域数据
     */
    async updateShoppingBasketOperation(actions: Array<CartActions>, itemData: CollectiveItemArea) {
        const { basket, mealAreas, currentBasketId, areasId, packageMaxNum, basketAreas,
            collectiveData, setDishType } = this.state
        try {

            // 从总购物篮获取当前区域数据
            const areaCloned = _.cloneDeep(this.getBasketAreasItem(itemData))
            // 当前层级购物篮数据
            let isBasket = _.cloneDeep(basket)
            let itemArea
            // 批量执行购物车修改事件
            if (_.isArray(actions) && actions.length > 0) {
                itemArea = this.excuteCartActions(areaCloned, actions)
            } else {
                itemArea = _.cloneDeep(areaCloned)
            }
            // 更新区域视图
            const rebuildArea = this.rebuildCollectiveArea(itemArea)
            const newAreasDish = rebuildArea.dishes
            const newAreaMeals = _.cloneDeep(mealAreas)
            const newCollectiveData = _.cloneDeep(collectiveData)
            let newBasketAreaMeals: CollectiveItemArea[] = []
            let selectNum: number = 0
            let newSelMeals
            // 减号操作要删除的购物篮Id
            const deleteBasketId: Array<string> = []
            _.get(rebuildArea, 'set_meals', []).forEach(setItem => {
                if (setItem.num === 0 && setItem.differentSetDishId) {
                    deleteBasketId.push(setItem.differentSetDishId)
                }
            })
            // 这里当是克隆的菜品为0还需要过滤掉
            const rebuildSetMeal = _.get(rebuildArea, 'set_meals', []).filter(setItem => {
                if (_.get(setItem, 'isCloneDish', false) && setItem.num === 0) {
                    return false
                }
                return true
            })
            _.set(rebuildArea, 'set_meals', rebuildSetMeal)

            // 区域视图
            newAreaMeals.map(iitem => {
                if (iitem._id === rebuildArea?._id) {
                    _.set(iitem, 'dishes', rebuildArea.dishes)
                    _.set(iitem, 'totalDishes', rebuildArea.totalDishes)
                    _.set(iitem, 'totalDishesPrice', rebuildArea.totalDishesPrice)
                    _.set(iitem, 'isAchievedCriteria', rebuildArea.isAchievedCriteria)
                    // 改变集合性商品每一项的加减号状态 以及数量
                    _.get(rebuildArea, 'set_meals', []).forEach(item => {
                        let collectiveItemNum: number = 0
                        newSelMeals = _.get(iitem, 'set_meals', []).map(selItem => {
                            if (item.id === selItem.id) {
                                collectiveItemNum += item.num
                                selItem.addButtonStatus = item.addButtonStatus
                                selItem.reduceButtonStatus = item.reduceButtonStatus
                                selItem.num = collectiveItemNum
                            }
                            return selItem
                        })
                    })
                    _.set(iitem, 'set_meals', newSelMeals)
                }
                selectNum += iitem.totalDishes
                return iitem
            })
            // 当前层级购物篮视图
            newBasketAreaMeals = basketAreas.map(item => {
                if (item._id === rebuildArea?._id) {
                    item = rebuildArea
                }
                return item
            })
            // 保存至总购物篮
            isBasket.map(basketItem => {
                if (basketItem.id === currentBasketId) {
                    // 包餐已选菜品数据
                    if (_.get(basketItem.item, 'package_meal_areas', '')) {
                        _.set(basketItem.item, 'package_meal_areas', newBasketAreaMeals)
                    }
                    // 套餐已选菜品数据
                    if (_.get(basketItem.item, 'set_meal_areas', '')) {
                        _.set(basketItem.item, 'set_meal_areas', newBasketAreaMeals)
                    }
                }
                return basketItem
            })
            if (deleteBasketId.length > 0) {
                deleteBasketId.forEach(idsItem => {
                    isBasket = isBasket.filter(basketItem => basketItem.id !== idsItem)
                })
            }

            // 保存原数据
            if (setDishType === '1') {
                _.set(newCollectiveData, 'package_meal_areas', newBasketAreaMeals)
            } else if (setDishType === '2') {
                _.set(newCollectiveData, 'set_meal_areas', newBasketAreaMeals)
            }

            if (areasId === rebuildArea._id) {
                // 购物篮点击时如果是当前区域,便更新当前区域菜品数据
                this.setState({
                    isDisableAdd: packageMaxNum !== 0 ? selectNum === packageMaxNum : false,
                    basketAreas: newBasketAreaMeals,
                    mealAreas: newAreaMeals,
                    areasDish: newAreasDish,
                    areasSetDish: newSelMeals ?? [],
                    collectiveItemArea: rebuildArea,
                    selectDishesNum: selectNum,
                    basket: isBasket,
                    collectiveData: newCollectiveData,
                    basketAreasId: ''
                })
            } else {
                this.setState({
                    isDisableAdd: packageMaxNum !== 0 ? selectNum === packageMaxNum : false,
                    basketAreas: newBasketAreaMeals,
                    mealAreas: newAreaMeals,
                    // collectiveItemArea: rebuildArea,
                    selectDishesNum: selectNum,
                    basket: isBasket,
                    collectiveData: newCollectiveData,
                    basketAreasId: ''
                })
            }
        } catch (error) {
        }
    }


    /**
     * 修改已点套餐或包餐数据
     * @param itemData 菜品数据
     * @param basketAreasId 点击的购物篮区域id
     */
    async handleCollectiveData(itemData: BasketDish, basketAreasId: string) {
        const { stringId } = this.props
        const { basket, mealAreas, collectiveData } = this.state
        try {
            this.cancel?.();
            let result: CollectiveItem = {};
            let resultClone: CollectiveItem = {};
            // 菜品_id
            const collectiveDishId: string = itemData._id || '';
            // 菜品类型
            const collectiveDishType: string = itemData.dish_type || '';
            // 菜品所属的父级购物篮id
            const basketId: string = itemData.belongedBasketId || '';
            // 当前集合性商品的购物篮id
            const dishBasketId: string = itemData.differentSetDishId || '';
            const newBasket = _.cloneDeep(basket);
            const newHigherleverCollectArea = _.cloneDeep(mealAreas);
            const newHigherLeverCollectiveItemArea = _.get(collectiveData, 'package_meal_areas', []).find(item => item._id === basketAreasId);

            this.setState({
                higherleverCollectArea: newHigherleverCollectArea,
                higherLeverCollectiveItemArea: newHigherLeverCollectiveItemArea,
                setDishId: collectiveDishId,
                setDishType: collectiveDishType,
                belongedBasketId: basketId,
                isShowBasket: false,
                collectiveCurrentBasketId: dishBasketId,
                handleCollectiveDish: itemData
            }, async () => {
                if (this.state.setDishType === '1') {
                    // 获取包餐区域数据
                    result = await apiManage.getPackageMeal(stringId, collectiveDishId);
                    resultClone = _.cloneDeep(result)
                } else if (this.state.setDishType === '2') {
                    // 获取套餐区域数据
                    result = await apiManage.getSetMeal(stringId, collectiveDishId)
                    resultClone = _.cloneDeep(result)
                }
                // 更新源数据
                let newCollectiveData: CollectiveItem = {}
                // 当前购物篮id
                let newBasketId
                // 当前购物篮层级
                let basketLevel
                // 当前购物篮总区域数据
                let accountSelectNum: number = 0
                // 更新总购物篮数据
                newBasket.map(basketItem => {
                    if (basketItem.id === dishBasketId) {
                        if (isCollectiveItemIsBasketPackageMeal(basketItem.item)) {
                            basketItem.item.package_meal_areas = (basketItem.item.package_meal_areas || []).map(packageAreasItem => {
                                // 更新的区域价格
                                let newTotalPrice: number = 0
                                // 更新的区域菜品数量
                                let newTotalNum: number = 0
                                if (isCollectiveItemIsBasketPackageMeal(resultClone)) {
                                    (resultClone.package_meal_areas || []).forEach(resItem => {
                                        if (packageAreasItem._id === resItem._id) {
                                            // 更新最新的菜品数据,查看是否有删除菜品
                                            packageAreasItem.dishes = packageAreasItem.dishes.filter(dishesItem => {
                                                resItem.dishes.forEach(resDishItem => {
                                                    if (dishesItem._id === resDishItem._id) {
                                                        if (dishesItem.price) newTotalPrice += dishesItem.price * dishesItem.num
                                                        newTotalNum += dishesItem.num
                                                    }
                                                })
                                                return resItem.dishes.findIndex(resDishItem => resDishItem._id === dishesItem._id) !== -1
                                            })
                                            packageAreasItem.set_meals = packageAreasItem.set_meals.filter(setDishesItem => {
                                                resItem.set_meals.forEach(resDishItem => {
                                                    if (setDishesItem._id === resDishItem._id) {
                                                        if (setDishesItem.price) newTotalPrice += setDishesItem.price * setDishesItem.num
                                                        newTotalNum += setDishesItem.num
                                                    }
                                                })
                                                return resItem.set_meals.findIndex(resDishItem => resDishItem._id === setDishesItem._id) !== -1
                                            })

                                            // 重新计算区域价格，还要加上额外的可能修改的区域附加价格
                                            newTotalPrice += packageAreasItem.additional_fees ?? 0
                                        }
                                    })
                                }

                                packageAreasItem.totalDishesPrice = newTotalPrice
                                packageAreasItem.totalDishes = newTotalNum
                                return packageAreasItem
                            })
                        } else if (isCollectiveItemIsBasketSetMeal(basketItem.item)) {
                            basketItem.item.set_meal_areas = (basketItem.item.set_meal_areas || []).map(setAreasItem => {
                                // 更新的区域价格
                                let newTotalPrice: number = 0
                                // 更新的区域菜品数量
                                let newTotalNum: number = 0
                                if (isCollectiveItemIsBasketSetMeal(resultClone)) {
                                    (resultClone.set_meal_areas || []).forEach(resItem => {
                                        if (setAreasItem._id === resItem._id) {
                                            // 更新最新的菜品数据,查看是否有删除菜品
                                            setAreasItem.dishes = setAreasItem.dishes.filter(dishesItem => {
                                                resItem.dishes.forEach(resDishItem => {
                                                    if (dishesItem._id === resDishItem._id) {
                                                        if (dishesItem.price) newTotalPrice += dishesItem.price * dishesItem.num
                                                        newTotalNum += dishesItem.num
                                                    }
                                                })
                                                return resItem.dishes.findIndex(resDishItem => resDishItem._id === dishesItem._id) !== -1
                                            })
                                            // 重新计算区域价格，还要加上额外的可能修改的区域附加价格
                                            newTotalPrice += resItem.additional_fees ?? 0
                                        }
                                    })
                                }
                                setAreasItem.totalDishesPrice = newTotalPrice
                                setAreasItem.totalDishes = newTotalNum
                                accountSelectNum += newTotalNum
                                return setAreasItem
                            })

                        }
                        newBasketId = basketItem.id
                        basketLevel = basketItem.level
                        newCollectiveData = basketItem.item
                    }
                    return basketItem
                })
                // $ 这里目前先只有套餐区域
                const newMealAreas = _.get(newCollectiveData, 'set_meal_areas', [])
                const isPackageMaxNum = _.get(newCollectiveData, 'customer_max_num_dishes', 0)

                this.setState({
                    selectDishesNum: accountSelectNum,
                    isDisableAdd: isPackageMaxNum ? accountSelectNum === isPackageMaxNum : false,
                    collectiveItemArea: newMealAreas[0] ?? {},
                    collectiveData: newCollectiveData,
                    collectiveName: newCollectiveData.name ?? '',
                    setUnmodifiedData: newCollectiveData,
                    packagePrice: newCollectiveData.price ?? 0,
                    currentBasketType: newCollectiveData.dish_type === '1' ? ICollectiveDishPanelType.PACKAGE_MEAL : ICollectiveDishPanelType.SET_MEAL,
                    currentBasketId: newBasketId,
                    currentBasketLevel: basketLevel,
                    mealAreas: newMealAreas,
                    areasDish: _.get(newMealAreas[0], 'dishes', []),
                    areasSetDish: _.get(newMealAreas[0], 'set_meals', []),
                    isRepeatedly: _.get(newMealAreas[0], 'is_repeated', false),
                    areasId: _.get(newMealAreas[0], '_id', ''),
                    chosenMenu: 0,
                    packageMaxNum: isPackageMaxNum,
                    basket: newBasket,
                    basketAreas: _.get(newCollectiveData, 'set_meal_areas', []),
                    isHandleSetMeal: true
                })
            })
        } catch (error) {

        }
    }

    /**
     * 大图模式下添加购物车的方式
     * @param actions 点击的商品项数据
     */
    handleToAddCart(action: Array<CartActions>) {
        try {
            if (this.state.basketAreasId) {
                let isCollectiveAreasItem: CollectiveItemArea = {
                    dishes: [],
                    // $ 视图帮助性字段
                    // 区域内菜品总数量
                    totalDishes: 0,
                    // 区域内菜品总价格
                    totalDishesPrice: 0,
                    // 是否此区域条件已符合
                    isAchievedCriteria: false
                }
                isCollectiveAreasItem = this.state.basketAreas.find(item => item._id === this.state.basketAreasId) ?? _.cloneDeep(this.state.collectiveItemArea)
                this.updateShoppingBasketOperation(action, isCollectiveAreasItem)
            } else {
                this.updateShoppingBasketOperation(action, this.state.collectiveItemArea)
            }
        } catch (error) {

        }
    }

    /**
     * 设置滚动插件初始化
     */
    initBetterScroll() {
        // !!! 注意如果点餐列表不能点击,就是这个插件问题
        // 使用插件解决滑动问题
        try {
            const wrapper: any = document.querySelector('#dishList')
            this.scroll = new BScroll(wrapper, {
                scrollY: true,
                mouseWheel: true,
                click: true
            })
            // 区域 滑动
            const SlideWrapper: any = document.querySelector('#category')
            this.SlideScroll = new BScroll(SlideWrapper, {
                scrollX: true,
                scrollY: false,
                mouseWheel: true,
                click: true
            })
        } catch (error) { }
    }

    // 更新数据方法
    public updateState(value: any) {
        return new Promise((resolve: any) => {
            this.setState({ ...value }, resolve)
        })
    }

    /**
     * 比较两个套餐选的菜品是否一致
     */
    isEqualCollectiveData(menuItem: CollectiveItem, cartItem: CollectiveItem) {
        let isEqual = true;

        // 套餐 dish_type=2
        if (isCollectiveItemIsBasketSetMeal(menuItem) && isCollectiveItemIsBasketSetMeal(cartItem)) {
            if (menuItem?.set_meal_areas?.length === cartItem?.set_meal_areas?.length) {
                const menuItemResult = this.flatCollectiveItem(menuItem);
                const cartItemResult = this.flatCollectiveItem(cartItem);
                isEqual = _.isEqual(menuItemResult?.dishes, cartItemResult?.dishes)
            } else {
                isEqual = false
            }
        }
        // 包餐 dish_type=1
        else if (isCollectiveItemIsBasketPackageMeal(menuItem) && isCollectiveItemIsBasketPackageMeal(cartItem)) {
            if (menuItem?.package_meal_areas?.length === cartItem?.package_meal_areas?.length) {
                const menuItemResult = this.flatCollectiveItem(menuItem);
                const cartItemResult = this.flatCollectiveItem(cartItem);
                isEqual = _.isEqual(menuItemResult?.dishes, cartItemResult?.dishes) && _.isEqual(menuItemResult?.setMeals, cartItemResult?.setMeals)
            } else {
                isEqual = false
            }
        }


        return isEqual
    }

    /**
     * 将区域里的菜品重组
     */
    flatCollectiveItem(collectiveItem: CollectiveItem): { dishes: BasketDish[], setMeals: { num: number, basketDish: BasketDish[] }[] } | undefined {
        // 套餐
        if (isCollectiveItemIsBasketSetMeal(collectiveItem)) {
            const newItem: BasketDish[] = [];
            (collectiveItem?.set_meal_areas || [])?.forEach((areaItem) => {
                (areaItem?.dishes || []).forEach((dishItem) => (
                    newItem.push(
                        {
                            belongedAreaId: areaItem._id,
                            _id: dishItem._id,
                            num: dishItem.num || 0
                        }
                    )
                ))
            })
            return { dishes: newItem, setMeals: [] }
        }
        // 包餐
        else if (isCollectiveItemIsBasketPackageMeal(collectiveItem)) {
            const newItem: BasketDish[] = [];
            const setMeals: { num: number, basketDish: BasketDish[] }[] = [];
            (collectiveItem?.package_meal_areas || [])?.forEach((areaItem) => {
                (areaItem?.dishes || []).forEach((dishItem) => (
                    newItem.push(
                        {
                            belongedAreaId: areaItem._id,
                            _id: dishItem._id,
                            num: dishItem.num || 0
                        }
                    )
                ));

                (areaItem?.set_meals)?.forEach((mealsItem) => {
                    const res = this.flatCollectiveItem(mealsItem);
                    const obj = {
                        num: mealsItem.num || 0,
                        basketDish: res?.dishes || []
                    }
                    res?.dishes && setMeals.push(obj);
                })

            })
            return { dishes: newItem, setMeals }
        }
    }

    /**
     * 包餐里的套餐返回操作
     */
    setDishDetailItemGoBack() {
        const { belongedBasketId, basket } = this.state;
        // 获取上级购物篮数据
        const collectiveData = basket.find(item => item.id === belongedBasketId)

        this.updateState({
            isShowPackageMeal: false,
            isEditSetMeal: false,
            setDishId: collectiveData?.item._id ?? '',
            setDishType: (collectiveData?.type === 'PACKAGE_MEAL' ? '1' : '2') ?? ''
        });
    }
    /**
     * 购物篮 菜品图片补充
     * @param shopCar
     */
    public async getCartDishPic() {
        const basketAreas = this.state.basketAreas;
        // $ 购物车菜品图片 没有图片的菜品
        let dishIds: string[] = [];
        basketAreas?.forEach((item) => {
            [...item.dishes, ...((item as BasketPackageMealArea)?.set_meals || [])].forEach((dishItem) => {
                if (!dishItem?.realpics?.length && dishItem._id && dishItem.num) {
                    dishIds.push(dishItem._id);
                }
            })
        });
        dishIds = [...new Set(dishIds)];
        if (dishIds?.length) {
            const picResults = await apiManage.getSetDishImg(this.props.stringId, dishIds, { notNeedLoading: true }, this.cancelToken);    // 图片获取失败 或者没网
            if (_.get(picResults, 'error', false)) return;
            // 购物篮菜品赋值图片
            const newBasketAreas = basketAreas.map((item) => {
                item.dishes = item.dishes.map(iitem => {
                    const picObj = _.find(picResults, { _id: iitem._id })
                    if (picObj) {
                        _.set(iitem, 'realpics', [picObj.picUrl ?? ''])
                    }
                    return iitem
                })
                if ((item as BasketPackageMealArea)?.set_meals) {
                    const setMeals = (item as BasketPackageMealArea).set_meals.map(setMealsItem => {
                        const picObj = _.find(picResults, { _id: setMealsItem._id })
                        if (picObj) {
                            _.set(setMealsItem, 'realpics', [picObj.picUrl ?? ''])
                        }
                        return setMealsItem
                    })
                    _.set(item, 'set_meals', setMeals);
                }
                return item
            })
            this.setState({ basketAreas: newBasketAreas });
        }
    }
    // *********************
    // View
    // *********************

    render() {
        const {
            mealAreas, areasDish, isShowBasket, chosenMenu, isRepeatedly, setDishType, packageMaxNum, selectDishesNum, isDisableAdd, basketAreas,
            packagePrice, isShowDishDetails, dishesDetail, areasSetDish, currentBasketLevel, collectiveName, openDiolog,
            isShowPackageMeal, isHandleSetMeal, collectiveData
        } = this.state;
        const { stringId, selectFilterLabeldAllergen, selectFilterLabeldIngredients } = this.props;
        return (
            <div className='OrderPackageMeal' >
                <TopBar
                    isHideMenu={false}
                    title={collectiveName}
                    isGoBack={() => {
                        this.whetherToReturn()
                    }}
                    rightIcon={() => (
                        <Button className='basket' onClick={() => { this.setState({ isShowBasket: true }) }} >
                            <ShoppingBasketIcon className='tool-icon' />
                            {setDishType === '1' ?
                                <div>
                                    <span>{selectDishesNum}{packageMaxNum ? '/' : ''}</span>
                                    {packageMaxNum ? <span>{packageMaxNum}</span> : null}
                                </div>
                                : null}
                        </Button>
                    )}
                    isConfirm={() => { }}
                />
                <div className='main_container'>
                    {/* 菜品分类 */}
                    <div className='category' id='category'>
                        <ul>
                            {_.isArray(mealAreas) && mealAreas.length > 0 ?
                                mealAreas.map((item, index) => {
                                    return (
                                        <li key={`${index}-meal`} className={`${chosenMenu === index ? 'chosenLi' : ''}`}
                                            onClick={async () => {
                                                const itemData = _.cloneDeep(item)
                                                await this.areasClickEvent(itemData, index)
                                            }}>
                                            <span>{item.name || ''}</span>
                                            <div className={` ${item.totalDishes && item.totalDishes === item.customer_max_num_dishes ? 'chosenMaxNumDish' : 'maxNumDish'} `}>
                                                <span className={`${item.is_required && !item.totalDishes && !item.customer_max_num_dishes ? 'handleRed' : ''}`}>{item.totalDishes}{item.customer_max_num_dishes ? '/' : ''}</span>
                                                {item.customer_max_num_dishes ?
                                                    <span className={`${!item.isAchievedCriteria ? 'handleRed' : ''}`}>{item.customer_max_num_dishes}</span> : null}
                                                {item.totalDishes && item.isAchievedCriteria ?
                                                    <div className={chosenMenu === index ? 'chosenCheckIcon' : 'checkIcon'}><DoneSharpIcon /></div> : null}
                                            </div>
                                            {item.totalDishesPrice && item.totalDishesPrice > 0 ? <div className='addtionalFees'>
                                                + {PM.showPointsOrPrice(collectiveData.is_points_redemption, item.totalDishesPrice || 0)}
                                            </div> : null}
                                        </li>
                                    )
                                }) : null}
                        </ul>
                    </div>
                    {/* 菜单每一项 */}
                    <div className='dishList' id='dishList'>
                        <div className='main_dishes_list_control'>
                            {(_.isArray(areasDish) && areasDish.length > 0) || (_.isArray(areasSetDish) && areasSetDish.length > 0) ?
                                <span className='repeatTxt'>{
                                    isRepeatedly ?
                                        i18n.t('restaurant_order_page_text_the_dishes_in_this_category_can_be_choose_repeatedly')
                                        : i18n.t('restaurant_order_page_text_the_dishes_in_this_category_are_single_choice')}</span>
                                : null}
                            <ul>
                                {_.isArray(areasSetDish) && areasSetDish.length > 0 ?
                                    areasSetDish.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <PackageMealItem
                                                    updateState={(val) => { this.updateState(val) }}
                                                    isDisableAdd={isDisableAdd}
                                                    type='common'
                                                    isRepeated={isRepeatedly}
                                                    itemData={item}
                                                    goMenuValore={() => {
                                                        this.goValoreOperation(item)
                                                    }}
                                                    allselectAllergen={[...selectFilterLabeldAllergen, ...selectFilterLabeldIngredients]}
                                                    isPoints={collectiveData.is_points_redemption}
                                                />
                                            </li>
                                        )
                                    }) :
                                    // 没有数据的时候显示
                                    null}
                                {_.isArray(areasDish) && areasDish.length > 0 ?
                                    areasDish.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <PackageMealItem
                                                    updateState={(val) => { this.updateState(val) }}
                                                    isDisableAdd={isDisableAdd}
                                                    type='common'
                                                    isRepeated={isRepeatedly}
                                                    itemData={item}
                                                    updataShoppingBasket={(actions) => {
                                                        this.updateShoppingBasketOperation(actions, this.state.collectiveItemArea)
                                                    }}
                                                    allselectAllergen={[...selectFilterLabeldAllergen, ...selectFilterLabeldIngredients]}
                                                    isPoints={collectiveData.is_points_redemption}
                                                />
                                            </li>
                                        )
                                    }) :
                                    // 没有数据的时候显示
                                    _.isArray(areasSetDish) && areasSetDish.length === 0 ?
                                        <li ><div className='notData'>{i18n.t('general_text_no_relevant_data')}</div></li> : null}
                            </ul>
                        </div>
                    </div>
                </div>

                {/* 购物篮 */}
                <ShoppingBasket
                    isEdit={this.props.isEdit}
                    handleCollectiveData={(itemData,) => {
                        // this.handleCollectiveData(itemData, basketAreasId)
                        this.goValoreOperation(itemData, true);
                    }}
                    currentBasketLevel={currentBasketLevel}
                    updateState={(val) => { this.updateState(val) }}
                    setDishType={setDishType}
                    basketPackagePrice={packagePrice}
                    isDisableAdd={isDisableAdd}
                    dishNum={selectDishesNum}
                    maxNum={packageMaxNum}
                    isRepeated={isRepeatedly}
                    basketDishData={basketAreas}
                    isShowBasket={isShowBasket}
                    addToShoppingCar={() => { this.toShoppingCar() }}
                    updataShoppingBasket={(actions, itemData) => {
                        this.updateShoppingBasketOperation(actions, itemData)
                    }}
                    onClose={() => { this.setState({ isShowBasket: false }) }}
                    isPoints={collectiveData.is_points_redemption}
                />
                {/* 套餐 */}
                {
                    isShowPackageMeal ?
                        <SetDishDetailItem
                            details={{ ...dishesDetail, is_points_redemption: collectiveData.is_points_redemption }}
                            stringId={stringId}
                            updateOrderState={(value) => { this.updateState(value); }}
                            goBack={() => { this.setDishDetailItemGoBack(); }}
                            isEdit={isHandleSetMeal}
                            isPackageMenu={true}
                            updateBesket={(basketItem: CollectiveItem) => this.updateBesket(basketItem)}
                            selectFilterLabeldAllergen={selectFilterLabeldAllergen}
                            selectFilterLabeldIngredients={selectFilterLabeldIngredients}
                        /> : null
                }
                {/* 菜品大图详情 */}
                {
                    isShowDishDetails ?
                        <div style={{ position: 'fixed', top: 0, bottom: 0 }}>
                            <DishDetail
                                setDishesIsMax={isDisableAdd}
                                isCollectiveData={true}
                                updateShoppoingBasket={(action) => { this.handleToAddCart(action) }}
                                onhandleClose={() => this.updateState({ isShowDishDetails: false, basketAreasId: '' })}
                                dishesDetail={{ ...dishesDetail, is_points_redemption: collectiveData.is_points_redemption }}
                                goMenuValore={() => {
                                    this.goValoreOperation(dishesDetail as BasketDish)
                                }} />
                        </div> : null
                }
                {/* 是否返回上级弹窗 */}
                <OrderPackageDialog
                    openDialog={openDiolog}
                    onClose={() => this.setState({ openDiolog: false })}
                    isConfirm={() => { this.goBackOperation() }}
                    contentText={this.props.isEdit ?
                        i18n.t('restaurant_order_page_text_there_are_unconfirmed_products_whether_to_continue_for_Edit') :
                        i18n.t('restaurant_order_page_text_there_are_unconfirmed_products_whether_to_continue')}
                />
            </div >
        )
    }
}
export default OrderPackageMeal


// *********************
// Types
// *********************
interface State extends ICollectiveDishPanelState {
    chosenMenu: number;
    isShowBasket: boolean;
    isRepeatedly: boolean;
    setDishId: string;
    setDishType: string;
    isHandleSetMeal: boolean;
    packageMaxNum: number;
    selectDishesNum: number;
    isDisableAdd: boolean;
    areasId: string;
    basketAreasId: string;
    collectiveCurrentBasketId: string;
    collectiveData: CollectiveItem;
    setUnmodifiedData: CollectiveItem;
    packagePrice: number;
    isShowDishDetails: boolean;
    dishesDetail: Dish;
    belongedBasketId: string;
    collectiveName: string;
    openDiolog: boolean;
    handleCollectiveDish: BasketDish | undefined
    mealAreas: Array<CollectiveItemArea>;
    areasDish: PropType<CollectiveItemArea, 'dishes'>;
    areasSetDish: PropType<CollectiveItemArea, 'dishes'>;
    basketAreas: Array<CollectiveItemArea>;
    basketInsideSet: Array<CollectiveItemArea>;
    collectiveItemArea: CollectiveItemArea;
    higherLeverCollectiveItemArea: CollectiveItemArea;
    higherleverCollectArea: Array<CollectiveItemArea>;
    isShowPackageMeal: boolean;
}

interface Props extends ICollectiveDishPanelProps {
    goBack: () => void;
    stringId: string;
    dishType: string; // 菜品类型 0：菜品 1：包餐 2：套餐
    menuSearchValue: string; // 点餐页菜单ID
    dishId: string;
    saveShopCatDate: Array<DishFixed>;
    updateOrderState: (value: any) => void;
    menuName: string;
    // 是否编辑
    isEdit: boolean;
    details?: Dish;
    // 刷选器选中的标签-过敏原
    selectFilterLabeldAllergen: Array<string>;
    // 刷选器选中的标签-配料
    selectFilterLabeldIngredients: Array<string>;
}
