import React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import TemporaryColsedComponent from './temporary-closed.component';
import CouponComponent from './coupon.component'
import { INotificationProps, INotificationState } from '../../interfaces/notification.interface';
import { Dialog, IconButton } from '@material-ui/core';
import './temporary-closed.scss';
import { Close } from '@material-ui/icons';
import { Coupon } from '../../models';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
/**
 *  临时停业弹窗组件可滑动
 */

class SwipeableTemporaryColsedDialog extends React.Component<Props, State>  {

    public timer;

    public state: State = {
        // 当前的下标
        activeStep: 0
    }

    // *********************
    // Life Cycle Function
    // *********************

    componentDidMount() {

    }

    componentWillUnmount() {
    }

    // *********************
    // Default Function
    // *********************

    handleStepChange = (step: number) => {
        this.setState({
            activeStep: step
        })
    };

    // **************
    // VIEW
    // **************

    public render() {
        const { activeStep } = this.state;
        const { nextOpenTimeText, showPage, countdownText } = this.props;
        const content: Array<JSX.Element> = [];

        if (showPage.includes('TemporaryClosed') || showPage.includes('closed')) {
            content.push(<TemporaryColsedComponent key={'1-show-page'} showPage={showPage} time={countdownText} nextOpenTimeText={nextOpenTimeText} />)
        }
        if (showPage.includes('coupon')) {
            content.push(<CouponComponent key={'2-show-page'} coupons={this.props.coupons} />)
        }
        return (
            <Dialog
                open={this.props.visible}
                fullScreen={true}
                className={'dialog-com'}
                onClick={() => { this.props.onClose(); }}
            >
                <IconButton className='close-btn' onClick={(e) => { e.stopPropagation(); this.props.onClose() }}>
                    <Close className='btn' />
                </IconButton>
                <AutoPlaySwipeableViews
                    index={activeStep}
                    onChangeIndex={this.handleStepChange}
                    enableMouseEvents
                    className='swipeable-page'
                    onClick={(e) => { e.stopPropagation(); }}
                >
                    {content.map((page: JSX.Element) => (page))}
                </AutoPlaySwipeableViews>
                {
                    content?.length > 1 ?
                        <MobileStepper
                            className='Stepperdots'
                            steps={content?.length || 0}
                            position='static'
                            variant='dots'
                            activeStep={activeStep}
                            nextButton={null}
                            backButton={null}
                        /> : null
                }
            </Dialog>
        )
    }
}

export default SwipeableTemporaryColsedDialog;

// ***************
// TYPE
// ***************

interface State extends INotificationState {
    // 当前的下标
    activeStep: number;
}

interface Props extends INotificationProps {
    // 停业时间
    countdownText: string;
    // 优惠劵
    coupons: Array<Coupon>;
    // 关闭弹窗事件
    onClose: () => void;
    // 停业弹窗显示内容 停业'closed', 临时停业'TemporaryClosed', 优惠券'coupon'
    showPage: string[];
    // 下个营业时间文本
    nextOpenTimeText?: string;
}