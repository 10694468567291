import { PropType } from '../constants/base.constant';

/**
 * 结算托盘，内部元素是由不同的子组件组成。
 *
 * #### 相关子组件
 *
 * - 自取时间结算子组件
 * - 配送时间结算子组件
 * - 顾客地址结算子组件
 * - 商家地址结算子组件
 * - 菜品详情结算子组件
 * - 优惠券详情结算子组件
 * - 总价格详情结算子组件
 *
 * #### 业务流程
 *
 * - 判断P1：检查登入状态；未登入前往S1；登入前往P2；
 * - 状态S1：显示“登入页面组件”；登入前往P2；
 * - 判断P2：检查账号信息是否完善，在不同的订单交付类型下，所需的信息分别为：自取 = 姓名；外卖 = 姓名 + 地址；不完善前往S2；完善前往S3；
 * - 检查订单类型；前往S2；
 * - 状态S2：显示“信息补全页面”；前往S3；
 * - 状态S3：显示“订单详情组件”；付款前往付款页面；
 *
 * #### 动画
 *
 * 根据props.visible判断样式，使用功能样式动画
 *
 * - 打开动画
 * - 关闭动画
 */
export interface ICheckout {

    state: ICheckoutState;

    props: ICheckoutProps;

    /**
     * 关闭结算托盘，修改props.visible为false，运行关闭动画。
     */
    closeCheckoutComponent(): void;

    /**
     * 切换子页面
     * @param targetPage 目标子页面
     */
    updateShowedPage(targetPage: PropType<ICheckoutState, 'showedPage'>): void;

    /**
     * 更新State
     */
    updateState(update: Partial<ICheckoutState>): void;

}

export interface ICheckoutState {
    /**
     * 当前显示的子页面
     * SIGN_IN_INPUT_PHONE_PAGE - 登入页面 - 输入手机号
     * SIGN_IN_INPUT_VCODE_PAGE - 登入页面 - 输入验证码
     * SIGN_IN_INPUT_PASSWORD_PAGE - 登入页面 - 输入密码
     * COMPLETE_CUSTOMER_INFO_PAGE - 信息补全页面
     * ORDER_DETAIL_PAGE - 订单详情页面
     */
    showedPage: ICheckoutShowedPageNames;
    /**
     * 验证码倒计时相关
     */
    // 验证码倒计时时间（秒）
    vcodeTimeMS: number;
    vcodeTimeoutObject?: NodeJS.Timeout;
    /**
     * 手机号码验证相关
     */
    phonePrefix: string;
    phone: string;
    // whatsapp按钮手机号码验证倒计时时间（秒）
    whatsappVcodeTimeMS: number
    whatsappVcodeTimeoutObject?: NodeJS.Timeout;
    referralsCode?: string | null;
    isReferralsCode: boolean;
}

export interface ICheckoutProps {
    // 显示状态（此属性改变时，会运行动画）
    visible: boolean;
    // 关闭结算托盘，修改props.visible为false，运行关闭动画。
    closeCheckoutComponent(): void;
    // 组件类型（预定、外卖、补全账号信息）
    componentType: 'BOOKING' | 'DELIVERY' | 'COMPLETE_CUSTOMER';
}

/**
 * 传递至子页面的Props，给予子页面一些结算页面本身的方法
 */
export interface ICheckoutIncomingProps {
    // 显示状态（此属性改变时，会运行动画）
    visible?: PropType<ICheckoutProps, 'visible'>;
    // 结算托盘方法
    closeCheckoutComponent: PropType<ICheckout, 'closeCheckoutComponent'>;
    updateShowedPage: PropType<ICheckout, 'updateShowedPage'>;
    updateState: PropType<ICheckout, 'updateState'>;
}

export enum ICheckoutShowedPageNames {
    'SIGN_IN_INPUT_PHONE_PAGE' = 'SIGN_IN_INPUT_PHONE_PAGE',
    'SIGN_IN_INPUT_VCODE_PAGE' = 'SIGN_IN_INPUT_VCODE_PAGE',
    'SIGN_IN_INPUT_PASSWORD_PAGE' = 'SIGN_IN_INPUT_PASSWORD_PAGE',
    'COMPLETE_CUSTOMER_INFO_PAGE' = 'COMPLETE_CUSTOMER_INFO_PAGE',
    'ORDER_DETAIL_PAGE' = 'ORDER_DETAIL_PAGE',
    'THIRD_PARTY_SIGN_IN_PHONE' = 'THIRD_PARTY_SIGN_IN_PHONE',
    'BOOKING_ORDER_PAGE' = 'BOOKING_ORDER_PAGE'
}
