import React from 'react';
import { inject_unount } from '../../component/decorators/helper.decorator';
import TopBar from '../../component/top-bar/top-bar.component';
import i18n from '../../i18n';
import { createHashHistory } from 'history';
import { getQueryString } from '../../constants/tool-get-parms';
import apiManage from '../../request';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import _ from 'lodash';
import { BookingServiceSchedule, Customer, Dish, Restaurant, SettingClosedSchedule, Token } from '../../models';
import errorCode from '../../static/error-code';
import Notification from '../../component/snackbar/snackbar';
import { getMessages } from '../../tool/notices.tool';
import momentTimeZone from 'moment-timezone';
import Checkout from '../../component/checkout/checkout';
import './reservations.styles.scss'
import Store from '../../rudex/store';

@inject_unount
class ReservationsPage extends React.Component<Props, State> {

    public state: State = {
        restaurantStringId: '',
        accountID: '',
        // 餐厅信息
        restaurantInfo: {},
        // 用户信息
        customerInfo: {},
        // 餐馆可预约时间段
        bookingServiceSchedule: [],
        // 餐馆不可预约时间段
        bookingNoServiceSchedule: [],
        // 餐馆预定服务开关
        restaurantBookingServiceStatus: false,
        // 最大可预定时间（天数）
        settingMaxBookingDay: 0,
        // 黑名单用户
        blacklistedUser: false,
        // 餐馆时区
        restaurantsTimeZoneName: '',
        // 0：当前餐馆找不到 1：显示餐馆 2：加载中 3:无授权
        showRestaurantView: 2,
        // 免登录
        restaurantsNeedSignIn: false
    }

    // *********************
    // Life Cycle Function
    // *********************
    public UNSAFE_componentWillMount() {
        // 处理商家页面
        const merchantWebsite = getQueryString('merchant_website');
        Store.dispatch({
            type: 'SET_STATE',
            path: 'merchantWebsite',
            value: merchantWebsite === 'true' ? true : false
        })
    }

    async componentDidMount() {
        await this.init();
    }

    componentWillUnmount() {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.RWG_TOKEN);
    }

    // *********************
    // Default Function
    // *********************
    async init() {
        // 从地址中拿到餐馆的string_id
        const string_id = getQueryString('string_id');
        const url = window?.location?.search?.indexOf('rwg_token') !== -1 ? window?.location?.search : undefined;
        const rwg_token = getQueryString('rwg_token', url);
        // 存缓存(无则 undefined)
        LocalStorageManager.setLocalStorage({ path: LOCAL_STORAGE_KEYS.RWG_TOKEN, value: rwg_token });

        if (string_id) {
            await this.searchRestaurant(string_id.toUpperCase());
        } else {
            this.setState({ showRestaurantView: 0 });
        }
    }

    async searchRestaurant(stringId: string) {
        let restaurantsMag = {};
        this.setState({
            restaurantStringId: stringId
        }, async () => {
            // 存储当前餐馆stringID
            LocalStorageManager.setLocalStorage({
                path: LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID,
                value: stringId
            });
            try {
                const cacheAccount: Customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
                //  校验是否有餐馆基础权限（api设置餐馆暂时停业）
                const restaurantService = await apiManage.checkCustomerVoucher(stringId, 'AU1012')
                if (restaurantService) {
                    if (_.get(restaurantService, 'passedWarrant', []).length === 0 || _.get(restaurantService, 'error')) {
                        // 暂无操作
                    }
                }

                // 餐馆信息
                const resInfo = apiManage.getRestaurantInfo(stringId, { noNeedShowMssages: false }, cacheAccount._id) as Promise<{ dbResults: Array<Partial<Restaurant>> } & { error: { code: number } }>;
                // 获取餐馆 配送区域
                const [results] = await Promise.all([resInfo]);
                if (results.error) {
                    // 标识后台返回的错误
                    const errorTip = errorCode.filter(item => (item.code === results.error.code))
                    Notification.notification(i18n.t(errorTip[0].message));
                    this.setState({ showRestaurantView: 0 });
                    return
                }
                if (results.dbResults && results.dbResults[0]) {
                    const restaurantInfo: Restaurant = results.dbResults[0] || {};
                    // 保存当前餐厅名称
                    LocalStorageManager.setLocalStorage({
                        path: LOCAL_STORAGE_KEYS.CURRENT_RES_NICK_NAME,
                        value: _.get(restaurantInfo, 'nickname')
                    });
                    // 保存当前集团stringID
                    LocalStorageManager.setLocalStorage({
                        path: LOCAL_STORAGE_KEYS.CURRENT_GROUP_STRING_ID,
                        value: restaurantInfo.belonged_group
                    });
                    const cacheToken: Token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN) || {};
                    const cacheRestaurants: { [key: string]: { menuDish: Dish, restaurant: Restaurant } } = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS) || {};
                    // 是否有登入
                    if (cacheToken && cacheAccount) {
                        const isLogin = cacheToken.access_token;
                        const accountID = cacheAccount._id;
                        restaurantsMag = { ...cacheRestaurants };
                        if (isLogin && accountID) {
                            await this.getCustomerInfo(accountID);
                        }
                    }
                    // 存储用户餐馆信息
                    const menuDishInfo = _.get(cacheRestaurants, `${stringId}.menuDish`) || [];
                    _.set(restaurantsMag, `${stringId}.menuDish`, menuDishInfo);
                    _.set(restaurantsMag, `${stringId}.restaurant`, restaurantInfo);
                    LocalStorageManager.setLocalStorage({
                        path: LOCAL_STORAGE_KEYS.RESTAURANTS,
                        value: restaurantsMag
                    });
                    // 预定时间表
                    const bookingServiceSchedule = _.get(restaurantInfo,
                        'setting_booking_service_schedule') || [];
                    // 预定休业时间表
                    const bookingNoServiceSchedule = _.get(restaurantInfo,
                        'setting_booking_no_service_schedule') || [];
                    // 预定服务是否开启
                    let restaurantBookingServiceStatus = _.get(restaurantInfo, 'setting_booking_service_status', false);
                    // 最大可预定时间（天数）未设置则14天
                    const settingMaxBookingDay = _.get(restaurantInfo,
                        'setting_max_booking_day', 14);
                    // 检查预定服务授权是否拥有
                    const reservationService = await apiManage.checkCustomerVoucher(stringId, 'AU1004')
                    if (reservationService) {
                        if (_.get(reservationService, 'passedWarrant', []).length === 0 || _.get(reservationService, 'error')) {
                            restaurantBookingServiceStatus = false;
                        }
                    }
                    // 用户是否在集团下餐厅的黑名单
                    const blacklistedUser = _.get(restaurantInfo, 'is_blocked_user', false);
                    // 餐馆时区
                    const restaurantsTimeZoneName = _.get(restaurantInfo, 'time_zone', momentTimeZone.tz.guess());

                    this.setState({
                        restaurantInfo,
                        showRestaurantView: restaurantBookingServiceStatus ? 1 : 3,
                        bookingServiceSchedule,
                        restaurantBookingServiceStatus,
                        bookingNoServiceSchedule,
                        settingMaxBookingDay,
                        blacklistedUser,
                        restaurantsTimeZoneName,
                        // 不存在，默认是 true
                        restaurantsNeedSignIn: _.get(restaurantInfo, 'setting_booking_requires_login', true)
                    })

                } else {
                    this.setState({ showRestaurantView: 0 });
                }
            } catch (error) {
                this.setState({ showRestaurantView: 0 });
            }
        })
    }
    /**
     * 获取个人信息（配送地址)
     */
    public async getCustomerInfo(ID?: string) {
        const { accountID } = this.state;
        const param = accountID || ID;
        if (param) {
            const customerInfo = await apiManage.get_customer_info(param);
            this.setState({ customerInfo });
            // 获取通知信息
            getMessages(_.get(customerInfo, 'messages', 0));
        }
    }

    goRrestaurantPage() {
        const stringId = this.state.restaurantStringId;
        createHashHistory().push(`/restaurantPage?string_id=${stringId}`);
    }

    // *********************
    // View
    // *********************

    render() {
        const { restaurantInfo, bookingServiceSchedule, bookingNoServiceSchedule, settingMaxBookingDay, blacklistedUser, restaurantsTimeZoneName, showRestaurantView, restaurantsNeedSignIn } = this.state;
        return (
            <div className='reservations-page'>
                <TopBar
                    title={restaurantInfo.nickname ?? ''}
                    isGoBack={() => this.goRrestaurantPage()}
                />
                {showRestaurantView === 1 && <Checkout
                    componentType='BOOKING'
                    visible={true}
                    closeCheckoutComponent={() => { this.goRrestaurantPage() }}
                    updateOrderPageState={() => { }}
                    bookingServiceSchedule={bookingServiceSchedule}
                    bookingNoServiceSchedule={bookingNoServiceSchedule}
                    drawerMainBGClassName={'bookingMainPageBG'}
                    drawerMainClassName={'bookingMainPage'}
                    isBlacklistedUser={blacklistedUser}
                    settingMaxBookingDay={settingMaxBookingDay}
                    restaurantsTimeZoneName={restaurantsTimeZoneName}
                    showProvidedTip
                    restaurantsNeedSignIn={restaurantsNeedSignIn}
                />}
                {showRestaurantView === 0 && <div className='notfind'>
                    {i18n.t('restaurant_info_page_text_restaurant_not_found')}
                </div>}
                {showRestaurantView === 3 && <div className='notfind'>
                    {i18n.t('reservation_page_request_failure_text_restaurant_booking_service_is_temporarily_closed')}
                </div>}
            </div >
        )
    }
}
export default ReservationsPage

// *********************
// State & Props
// *********************

type State = {
    restaurantStringId: string;
    accountID: string;
    // 餐厅信息
    restaurantInfo: Restaurant;
    // 用户信息
    customerInfo: Customer;
    // 预定时间
    bookingServiceSchedule: Array<BookingServiceSchedule>;
    // 不可预定时间
    bookingNoServiceSchedule: Array<SettingClosedSchedule>;
    // 餐馆预定服务开关
    restaurantBookingServiceStatus: boolean;
    // 最大可预定时间（天数）
    settingMaxBookingDay: number;
    // 黑名单用户
    blacklistedUser: boolean,
    // 餐馆时区
    restaurantsTimeZoneName: string;
    // 0：当前餐馆找不到 1：显示餐馆 2：加载中
    showRestaurantView: number;
    // 免登录预定
    restaurantsNeedSignIn: boolean;
}

type Props = {}