import { createHashHistory } from 'history';
// 获取URL问号后的参数值
export function getQueryString(name?: string, url?: string): string | undefined {
    try {
        const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        let r: RegExpMatchArray | null = null;
        if (url && url.split('?').length > 1) {
            r = url.split('?')[1].match(reg);
        }
        else if (createHashHistory()) {
            r = createHashHistory().location.search.substring(1).match(reg);
        }
        if (r) {
            return decodeURI(r[2]);
        }
        return undefined;
    } catch (error) {
        return undefined;
    }
}