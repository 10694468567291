import _ from 'lodash';
import React from 'react';
import i18n from '../../i18n';
import { ICheckoutCouponsList, ICheckoutCouponsListProps, ICheckoutCouponsListState } from '../../interfaces';
import CouponItem from '../coupon/coupon-item.component';
import './scss/checkout-coupon-list.styles.scss';

class CheckoutCouponListComponent extends React.Component<Props, State> implements ICheckoutCouponsList {

    // *********************
    // Life Cycle Function
    // *********************

    // *********************
    // Default Function
    // *********************

    // *********************
    // View
    // *********************

    render() {
        const { allCoupons, selectedCoupons, analysisAndAdjustCoupon, newlyReceivedCouponCodes } = this.props;
        return (
            <div className='checkoutCouponListComponent'>
                <div className='allCouponsBox'>
                    {
                        Array.isArray(allCoupons) && allCoupons.length > 0
                            ? allCoupons.map((item, index) => {
                                return (
                                    <CouponItem
                                        key={index}
                                        couponItem={item}
                                        onClick={() => { analysisAndAdjustCoupon?.(item) }}
                                        isCheck={_.findIndex(selectedCoupons, (n) => n.coupon_id === item.coupon_id) !== -1}
                                        showNewTag={_.includes(newlyReceivedCouponCodes, item.code)}
                                        type='payment'
                                        className={item.showCantUseText ? 'showCantUseText' : ''}
                                    />
                                )
                            })
                            : <div className='notfind'>{i18n.t('coupon_text_no_more_coupons')}</div>
                    }
                </div>
            </div>
        )
    }
}
export default CheckoutCouponListComponent

// *********************
// Props & State
// *********************

interface State extends ICheckoutCouponsListState {

}

interface Props extends ICheckoutCouponsListProps {
    // 新领取的优惠券code数组
    newlyReceivedCouponCodes: string[];
}